import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  findKey, has, isEmpty, includes, isString, toUpper, get,
} from 'lodash-es';
import { StringParam, useQueryParam } from 'use-query-params';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { IDictionary } from '@ess/types';

import {
  AGENT_ATTRIBUTES_LIST_ACTION,
  CONTENT_SERVICE_URL,
} from '@ess/constants/api';

import {
  useSearchConditionsSelector,
  useSearchConditionsParticipants,
} from '@ess/v5-data-provider/mwsfunc/hooks';

import { IOfferListItem } from '@ess/v5-data-provider/interfaces';
import { promiseRequest } from '@ess/v5-data-provider/request';

import { AppConfigContext } from '@ess/context/AppConfigContext';

interface IStatusItem {
  Info: string;
  SectionName: string;
  StatusMsg: string;
  Status: string;
}

export enum Sections {
  Description = 'Description',
  Pictures = 'Pictures',
  OfferActions = 'OfferActions',
  TripAdvisor = 'TripAdvisor',
  RegionalInfo = 'RegionalInfo',
  PriceChart = 'PriceChart',
  Omnibus = 'Omnibus',
  WeatherStatic = 'WeatherStatic',
  VisaInfo = 'VisaInfo',
  AirportsNearby = 'AirportsNearby',
  BeachesNearby = 'BeachesNearby',
  HotelGPS = 'HotelGPS',
  MapInfo = 'MapInfo',
  Autosuggest = 'Autosuggest',
  AgentAttributes = 'AgentAttributes',
  Bookings = 'Bookings',
  Basket = 'Basket',
  AgentProfile = 'AgentProfile',
  MultiRoomFinder = 'MultiRoomFinder',
}

const SECTIONS_WITHOUT_OFFER_ID = [
  'RegionalInfo',
  'VisaInfo',
  'Basket',
  'AgentAttributes',
  'AgentProfile',
  'WeatherStatic',
  'Bookings',
];

type TWebServiceStatus = Record<keyof typeof Sections, IStatusItem>;

interface IWebServiceResult {
  Status: TWebServiceStatus;
  Sections: IDictionary<any>;
}

type FetchSectionsRequest = {
  sections: Array<keyof typeof Sections>;
  params?: IDictionary<any>;
  combine?: boolean
};

type TCombinedSections = Partial<{
  [key in keyof typeof Sections]: string[];
}>;

type SectionsParams = Partial<{
  [key in keyof typeof Sections]: IDictionary<string>;
}>;

const COMBINED_SECTIONS: TCombinedSections = {
  VisaInfo: ['CovidInfo'],
  PriceChart: ['OperatorChart', 'Omnibus'],
};

const paramsBySection: SectionsParams = {
  Omnibus: {
    OmnibusUrl: 'Base.Omnibus.URL',
    StartDate: 'Base.StartDate',
    Price: 'Base.Price.FirstPerson.amount',
    Currency: 'Base.Price.FirstPerson.currency',
  },
};

/**
 * Returns participants.
 * @param participantList
 */
const getParticipants = (participantList: any) => {
  const { multiRoom, rooms } = participantList ?? {};

  const participants = rooms?.map((room: any) => ({
    Adult: {
      value: room.ADULT.value,
    },
    Child: {
      value: room.CHILD.value,
      dates: room.CHILD.dates,
    },
  })) ?? {};

  return {
    OnlyOneOption: false,
    ...multiRoom?.mode === 'auto' ? {
      ...participants[0],
      ...multiRoom?.roomsCount > 1 ? {
        RoomCnt: multiRoom.roomsCount,
      } : {},
    } : {
      ParticipantGroups: participants,
    },
  };
};

/**
 * Returns offer params by given schema.
 * @param schema
 * @param offerData
 */
const getParams = (schema: any, offerData: any) => {
  const newParams: any = {};

  Object.keys(schema).map((paramName) => {
    newParams[paramName] = get(offerData, schema[paramName]);
  });

  return newParams;
};

/**
 * Returns request params.
 * @param offerData
 * @param dateRange
 * @param roomsCount
 * @param participantList
 * @param extraHotel
 */
const getDynamicRequestParams = (
  offerData: IOfferListItem,
  dateRange: any,
  roomsCount: number,
  participantList: any[] | IDictionary<any>,
  extraHotel: string | null | undefined,
) => {
  const { Base, Accommodation, Transport = undefined } = offerData.offer;

  return {
    OfferId: Base?.OfferId,
    RegionId: Base?.DestinationLocation?.Id || '',
    Operator: Base?.Operator,
    HotelCode: extraHotel || Accommodation?.Code || '',
    DateRangeFrom: dateRange?.After || '',
    DateRangeTo: dateRange?.Before || '',
    StartDate: Base?.StartDate || '',
    EndDate: Base?.ReturnDate || '',
    ...(Accommodation?.Location?.Coords
      ? {
        GPSLAT: Accommodation?.Location?.Coords[0],
        GPSLNG: Accommodation?.Location?.Coords[1],
      }
      : {}),
    Rooms: roomsCount,
    AccommodationType: Accommodation?.Type?.Id || '',
    TransportBus: has(Transport, 'Bus'),
    TransportFlight: has(Transport, 'Flight'),
    HotelXCode: extraHotel ? undefined : Accommodation?.XCode?.Id,
    AgentAttributes: {
      Action: AGENT_ATTRIBUTES_LIST_ACTION,
    },
    MultiFinder: participantList,
    PictureSizes: 'full,medium,small',
    QueryType: 'Daily',
    AlternativeOffers: {
      Type: 'SimilarOffers',
      AlternativeDates: false,
      AlternativeRegions: false,
      AlternativeOperators: false,
      Limit: 10,
    },
  };
};

const getCombinedSectionsData = (currentData: any, newData: any, combine: boolean) => {
  const newSection: any = !isEmpty(newData.Sections) ? newData.Sections : {};

  let retSections = {
    ...(currentData ? currentData.Sections : {}),
  };

  (Object.keys(newSection) as (keyof typeof Sections)[]).map((section) => {
    const hasCombinedData = has(COMBINED_SECTIONS, section);
    const isCombined = combine && !!findKey(COMBINED_SECTIONS, (item) => includes(item, section));

    if (hasCombinedData && combine) {
      retSections = {
        ...retSections,
        [section]: {
          [section]: newSection[section],
          ...COMBINED_SECTIONS[section]?.reduce((acc, item) => ({
            ...acc,
            [item]: newSection[item],
          }), {}),
        },
      };
    } else if (!isCombined) {
      retSections = {
        ...retSections,
        [section]: newSection[section],
      };
    }
  });

  return {
    Sections: retSections,
  };
};

const defaultProps = {
  initialSections: [],
};

/**
 * useOfferContentService hook.
 * Manages offer content service.
 */
const useOfferContentService = (
  initialSections?: Array<keyof typeof Sections> | undefined,
) => {
  const { state: SFContext } = useContext(AppConfigContext);
  const { language } = SFContext;
  const dateRange = useSearchConditionsSelector(
    (conditions) => conditions.Base?.StartDate ?? {},
  );
  const roomsCount = useSearchConditionsSelector(
    (conditions) => conditions.Accommodation?.Rooms ?? 1,
  );
  const participants: any = useSearchConditionsParticipants();
  const [offerData, setOfferData] = useState<IOfferListItem | null>(null);
  const [results, setResults] = useState<IWebServiceResult | null>(null);
  const [serviceError, setServiceError] = useState<boolean>(false);
  const [errors, setErrors] = useState<IDictionary<string | undefined>>({});
  const [extraHotel] = useQueryParam('extra_hotel', StringParam);
  const [isLoading, setIsLoading] = useState<string[]>([]);
  const [pendingSections, setPendingSections] = useState<Array<string>>([]);
  const [requestParams, setRequestParams] = useState<any>({
    Language: toUpper(language),
  });
  const { t } = useTranslation();

  const setSectionsError = useCallback(
    (
      sections: Array<keyof typeof Sections>,
      status: TWebServiceStatus | string,
    ) => {
      const newErrors: IDictionary<string | undefined> = errors;

      sections.map((section) => {
        const errorObject = !isString(status) ? status[section] : undefined;

        if (errorObject) {
          if (errorObject.Info) {
            newErrors[section] = errorObject.Info;
          } else {
            newErrors[section] = undefined;
          }

          if (errorObject.Status === 'ERROR') {
            newErrors[section] = t(`lbl_${errorObject.StatusMsg.toLowerCase().split(' ').join('_') as string}`);
          }
        } else if (isString(status)) {
          newErrors[section] = status;
        }
      });

      setErrors(newErrors);
    },
    [errors, requestParams],
  );

  /**
   * Set current offer data.
   */
  const setOffer = useCallback((offer: IOfferListItem) => {
    setOfferData(offer);
  }, []);

  /**
   * Fetch details section handler.
   * @param sections
   * @param params
   */
  const fetchSections = useCallback(
    async ({ sections = [], params = {}, combine = true }: FetchSectionsRequest) => {
      let data = {} as IWebServiceResult;
      const isOfferIdRequired = sections.every((section) => !includes(SECTIONS_WITHOUT_OFFER_ID, section));
      const postParams = {
        ...requestParams,
        ...params,
      };
      if (!sections.length || (isOfferIdRequired && !postParams?.OfferId)) {
        return;
      }

      sections.map((section) => {
        if (combine && has(COMBINED_SECTIONS, section)) {
          sections.push(...COMBINED_SECTIONS[section] as any);
        }
      });

      if (includes(pendingSections, sections[0])) {
        return;
      } if (sections.length > 1) {
        setPendingSections([...pendingSections, ...sections]);
      }

      setServiceError(false);
      setIsLoading((state) => [...state, ...sections]);

      try {
        const postData = {
          ...postParams,
        };

        setRequestParams({
          ...postData,
          Operator: requestParams.Operator,
          HotelCode: requestParams.HotelCode,
        });

        const request = Promise.all(sections.map((item) => {
          const requestParams = has(paramsBySection, item) ? getParams(paramsBySection[item], offerData?.offer) : postParams;
          const postData = encodeURIComponent(JSON.stringify(requestParams));

          return promiseRequest(`${CONTENT_SERVICE_URL}${item}/${postData}`, null, 3);
        }));

        const response = await request;

        response.map((item) => {
          data = {
            ...data,
            Sections: {
              ...data.Sections,
              ...item.Sections,
            },
            Status: {
              ...data.Status,
              ...item.Status,
            },
          };
        });

        setSectionsError(sections, data.Status);

        if (results) {
          setResults((state) => ({
            ...state,
            ...getCombinedSectionsData(results, data, combine),
            Status: {
              ...state?.Status,
              ...data.Status,
            },
          }));
        } else {
          setResults({
            ...data,
            ...getCombinedSectionsData(results, data, combine),
          });
        }
        // remove sections from pendingSections array.
        if (sections.length > 1) {
          setPendingSections([]);
        }
      } catch (error) {
        setSectionsError(sections, 'lbl_service_error');
        setServiceError(true);
        Sentry.captureException(error);
      }

      setIsLoading((state) => [...state].filter((item) => !includes(sections, item)));

      return data;
    },
    [requestParams, results?.Sections, offerData],
  );

  useEffect(() => {
    if (offerData) {
      const participantList = getParticipants(participants);

      const params = {
        ...requestParams,
        ...getDynamicRequestParams(
          offerData,
          dateRange,
          roomsCount,
          participantList,
          extraHotel,
        ),
      };

      if (initialSections?.length) {
        fetchSections({
          sections: initialSections,
          params,
        });
      }

      setRequestParams(params);
    }
  }, [offerData?.offer?.Base?.OfferId]);

  return {
    results,
    isLoading,
    errors,
    requestParams,
    pendingSections,
    fetchSections,
    serviceError,
    setOffer,
  };
};

useOfferContentService.defaultProps = defaultProps;

export default useOfferContentService;
