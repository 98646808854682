import styled from 'styled-components';

const TooltipContent = styled.div<{}>`
    width: 65px;
    text-align: right;
`;

export const Styled = {
  TooltipContent,
};
