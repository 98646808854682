import React, {
  forwardRef, useImperativeHandle, useRef, useState,
} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { floor } from 'lodash-es';
import { isFirefox } from 'react-device-detect';

import FlexBox from '@ess/ui/FlexBox';
import ScrollBar from '@ess/ui/ScrollBar';

import NavigationArrow, { Direction } from '@tourop/components/NavigationArrow';

type ScrollWrapperProps = {
  children: React.ReactElement
}

const ScrollWrapper = forwardRef<Scrollbars, ScrollWrapperProps>(({
  children,
}, ref) => {
  const scrollBarElement = useRef<any>(null);
  const [progress, setProgress] = useState(0);
  const [showArrows, setShowArrows] = useState(false);

  useImperativeHandle(ref, () => scrollBarElement.current);

  const onUpdateHandler = (values: any) => {
    setProgress(floor(values.left * 100));
  };

  /**
   * On Arrow click handler.
   * @param direction
   */
  const onArrowClickHandler = (direction: Direction) => {
    const scrollLeft = scrollBarElement?.current?.view.scrollLeft;

    scrollBarElement?.current?.view?.scrollTo({
      left: direction === Direction.Right ? scrollLeft + 150 : scrollLeft - 150,
      behavior: isFirefox ? 'instant' : 'smooth',
    });
  };

  /**
   * On Arrow mouse down handler.
   * @param direction
   */
  const onArrowMouseDownHandler = (direction: Direction) => {
    scrollBarElement?.current?.view?.scrollTo({
      left: direction === Direction.Right ? scrollBarElement?.current?.view.scrollWidth : 0,
      behavior: 'smooth',
    });
  };

  /**
   * On Arrow mouse up handler.
   */
  const onArrowMouseUpHandler = () => {
    setTimeout(() => {
      // eslint-disable-next-line no-self-assign
      scrollBarElement?.current?.view?.scrollTo({
        left: scrollBarElement.current.view.scrollLeft,
        behavior: isFirefox ? 'instant' : 'smooth',
      });
    }, 100);
  };

  return (
    <>
      {showArrows && (
        <>
          {progress > 0 && (
            <NavigationArrow
              direction={Direction.Left}
              onClick={(direction) => onArrowClickHandler(direction)}
              onMouseDown={onArrowMouseDownHandler}
              onMouseUp={onArrowMouseUpHandler}
            />
          )}
          {progress < 98 && (
            <NavigationArrow
              direction={Direction.Right}
              onClick={(direction) => onArrowClickHandler(direction)}
              onMouseDown={onArrowMouseDownHandler}
              onMouseUp={onArrowMouseUpHandler}
            />
          )}
        </>
      )}
      <ScrollBar
        ref={scrollBarElement}
        height={550}
        thumbSize={5}
        onUpdate={onUpdateHandler}
      >
        {({ isHorizontalScrollBarVisible }) => {
          setShowArrows(isHorizontalScrollBarVisible);
          return (
            <FlexBox
              width="100%"
              height="100%"
              justifyContent="center"
              position="relative"
            >
              {children}
            </FlexBox>
          );
        }}
      </ScrollBar>
    </>
  );
});

export default ScrollWrapper;
