import styled from 'styled-components';
import { space } from 'styled-system';
import { isNumber } from 'lodash-es';

const Image = styled.img<{ width: number | string, height: number | string, objectFit: string, cursor: string }>`
  ${space}
  width: ${({ width }) => (width ? isNumber(width) ? `${width}px` : width : null)};
  height: ${({ height }) => (height ? isNumber(height) ? `${height}px` : height : 'auto')};
  object-fit: ${({ objectFit }) => objectFit};
  cursor: ${({ cursor }) => (cursor)};
`;

export const Styled = {
  Image,
};
