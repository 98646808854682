import React, { useState } from 'react';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

const CustomLabel = (props: any) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    x, y, value, onClick, index,
  } = props;

  const onClickHandler = () => {
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <g>
      <foreignObject
        x={x - 30}
        y={y - 30}
        width={60}
        height={20}
      >
        <FlexBox
          width="auto"
          height="100%"
          alignItems="center"
          justifyContent="center"
          px="tiny"
          py="3px"
          backgroundColor={!isHovered ? 'transparent' : 'navy'}
          color={!isHovered ? 'navy' : 'white'}
          borderRadius={8}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onClickHandler}
          style={{
            cursor: 'pointer',
          }}
        >
          <Text fontWeight="bold">{value}</Text>
        </FlexBox>
      </foreignObject>
    </g>
  );
};

export default CustomLabel;
