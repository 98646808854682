import React from 'react';

import MultiRoom from '@tourop/components/MultiRoom';
import AgencyAccessControl from '@tourop/components/AgencyAccessControl';

type PropsWithFetch = {
    data: any
    fetchAsync: ({ sections, params }: { sections: string[], params: any }) => void
    requestParams: any
}

const MultiRoomFinder = ({
  data,
  fetchAsync,
  requestParams,
}: PropsWithFetch) => (
  <AgencyAccessControl>
    <MultiRoom
      data={data}
      fetchAsync={fetchAsync}
      requestParams={requestParams}
    />
  </AgencyAccessControl>
);

export default MultiRoomFinder;
