import { css } from 'styled-components';

const DatePicker = css`
    .DateRangePicker, .SingleDatePicker {
     position: relative;
       display: inline-flex;
       background-color: #ffffff;
       width: 100%;

       & > div {
          width: 100%;
       }
    }

    .DateRangePicker_picker .DayPicker_focusRegion {
       padding-bottom: 50px;
    }

    .DateRangePicker_picker, .SingleDatePicker_picker {
       top: 34px !important;
       border-radius: ${({ theme }) => theme.radii['br-4']} !important;
       z-index: ${({ theme }) => theme.zIndex.modal} !important;
    }

    .DateRangePicker_picker__portal, .SingleDatePicker_picker__portal {
      top: 0 !important;
    }

    .DateRangePickerInput, .SingleDatePickerInput {
        display: flex;
        align-items: center;
        width: 100%;
        height: 34px;
        border-radius: ${({ theme }) => theme.radii['br-4']} !important;
        border: 1px solid ${({ theme }) => theme.colors.gray};
        transition: all 0.2s ease;
        transition-property: box-shadow, border-color;

        input {
           cursor: pointer;
           font-weight: normal !important;
        }

        &:focus-within {
            border-color: transparent !important;
            box-shadow: 0 0 0 2.5px #2684ff !important
        }
    }

    .DayPicker_transitionContainer__horizontal {
      transition: none !important;
    }

    .DateInput_fang {
      display:none;
    }

    .DateRangePickerInput_arrow {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.gray};
        margin: 0;

        svg {
            fill: ${({ theme }) => theme.colors.gray};
            width: 20px;
        }
    }

    .DateInput {
        display: flex;
        flex-grow: 1;
        border-radius: ${({ theme }) => theme.radii['br-4']} !important;
        overflow: hidden;
        height: 100%;
        width: auto;

        input {
            padding: 0 6px;
            font-size: ${({ theme }) => theme.fontSizes.body};
            color: ${({ theme }) => theme.colors.textColor};
            border: 0;
        }
    }

    .DateRangePickerInput {
        .DateInput {
            &:first-child {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }

            &:last-child {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }

            input {
               &:focus {
                   background-color: #e6f1ff;
                   color: #3074a2;

                   &::placeholder {
                      color: #3074a2;
                   }
               }

                  &::placeholder {
                     font-size: 11px;
                  }
            }
        }
    }

     .DateInput {
         input {
            &:focus {
               border: none;
            }
         }
     }

    .DayPicker {
        background: #fffff !important;
        border-radius: ${({ theme }) => theme.radii['br-4']};
        box-shadow: none;
        overflow: hidden;
    }

    .DayPicker_weekHeader {
      top: 60px;
      z-index: 0 !important;
    }

    .DayPicker_weekHeaders__horizontal {
      margin-left: 13px !important;
    }

    .DayPicker_weekHeader_li {
       width: 39px !important;
       color: #102c58;

       small {
          font-size: 12px !important;
       }
    }

    .CalendarMonth {
      padding: 0 10px !important;
    }

    .CalendarMonth, .CalendarMonthGrid {
      background: transparent !important;
    }

    .CalendarMonth_caption {
      margin-top: 15px;
      padding-top: 0 !important;
      text-transform: capitalize;
      color: #102c58 !important;

      strong {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
      }
    }

    .CalendarDay__highlighted_calendar {
      background: #cde8ff;
      color: #2471b4;
    }

    .CalendarDay__default {
      font-size: 12px !important;
      width: 40px !important;
      height: 39px !important;
      border: none !important;
      transition: all .3s ease !important;
      transition-property: background !important;
      color: #102c58 !important;
    }

    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
        background: ${({ theme }) => theme.colors.navy} !important;
        color: ${({ theme }) => theme.colors.white} !important;
        border: none;
    }

    .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
        background: #f1f3f4 !important;
        color: #102c58 !important;
        border: none;
    }

    .CalendarDay__selected_span {
        background: #f1f3f4 !important;
        color: #102c58 !important;
        border: none;
    }

    .CalendarDay__selected_span:active,
    .CalendarDay__selected_span:hover,
    .CalendarDay__selected_start:hover,
    .CalendarDay__selected_end:hover,
    .CalendarDay__default:hover {
        background: #ebf8ff !important;
        color: #2684ff !important;
        border: none !important;
    }

    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
      cursor: not-allowed !important;
      background: #ffffff !important;
      color: #b6b6b6 !important;
    }
`;

export default DatePicker;
