import React, { useContext, forwardRef } from 'react';
import GoogleMapReact, { Coords, MapOptions, Props } from 'google-map-react';

import { DEFAULT_MAP_POSITION } from '@ess/constants/search';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import Box from '@ess/ui/Box';

type MapProps = {
  children?: React.ReactNode,
  width?: string,
  height?: string,
  zoom?: number,
  libraries?: string[],
  coordinates?: Coords,
  options?: MapOptions,
} & Props;

const defaultProps = {
  children: null,
  width: '100%',
  height: '100%',
  options: {},
  libraries: [],
  zoom: 2,
  coordinates: DEFAULT_MAP_POSITION,
};

const Map = forwardRef<HTMLDivElement, MapProps>(({
  width, height, zoom, coordinates, children, options, libraries, ...props
}, ref) => {
  const { state: SFContext } = useContext(AppConfigContext);
  const { googleApiKey } = SFContext;

  const styles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        { visibility: 'off' },
      ],
    },
  ];

  return (
    <Box ref={ref} width={width} height={height}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleApiKey,
          libraries,
        }}
        defaultCenter={coordinates}
        defaultZoom={zoom}
        options={{
          styles,
          clickableIcons: false,
          ...options,
        }}
        {...props}
      >
        {children}
      </GoogleMapReact>
    </Box>
  );
});

Map.defaultProps = defaultProps;

export default Map;
