import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { has } from 'lodash-es';
import { WidthProps } from 'styled-system';

import { IDictionary, IOfferBaseAvailability } from '@ess/types';

import {
  STATUS_TYPES,
  STATUS_DESCRIPTIONS,
  STATUS_ICONS,
  STATUS_COLOR,
  STATUS_LOADING,
  STATUS_OFFLINE,
} from '@ess/constants/status';

import { isDatePassed } from '@ess/utils/offerData/isDatePassed';

import FlexBox from '@ess/ui/FlexBox';

import IconStatus from './IconStatus';
import BarStatus from './BarStatus';

const VIEWS: IDictionary<(props: any) => React.ReactComponentElement<any>> = {
  bar: (props) => <BarStatus {...props}/>,
  icon: (props) => <IconStatus {...props}/>,
};

const DEFAULT_VIEW: string = 'icon';

type OfferStatusViewTypes = 'bar' | 'icon';
type OfferStatusProps = {
  status: IOfferBaseAvailability
  type?: OfferStatusViewTypes
  startDateCheck?: string
  isConfigSettingOnline?: boolean
  isLoading: boolean
  isQueued?: boolean
  onClick: (event: React.MouseEvent) => void
} & WidthProps;

type StatusData = {
  color: string
  icon: React.FunctionComponent | null
  message: string
  description: string
}

const defaultProps = {
  type: DEFAULT_VIEW as OfferStatusViewTypes,
  isConfigSettingOnline: true,
  isQueued: false,
  startDateCheck: '',
  width: 'auto',
};

const OfferStatus = ({
  status,
  type,
  isLoading,
  isConfigSettingOnline,
  startDateCheck,
  onClick,
  ...props
}: OfferStatusProps) => {
  const { t } = useTranslation();
  const [statusData, setStatusData] = useState<StatusData | null>(null);
  const datePassed = startDateCheck && isDatePassed(startDateCheck);

  /**
   * Returns status.
   */
  const getStatusData = (): StatusData => {
    const statusBase = datePassed ? { base: 'notavailable', message: t('lbl_offer_expired_message') } : status;
    const { base } = statusBase;

    const statusType = isConfigSettingOnline ? STATUS_TYPES[base as string] : STATUS_OFFLINE;
    const hasMessage = has(statusBase, 'message') && statusBase.message;
    const message = hasMessage ? statusBase.message as string : t(STATUS_DESCRIPTIONS[statusType]);
    const description = isLoading ? STATUS_DESCRIPTIONS[STATUS_LOADING] : STATUS_DESCRIPTIONS[statusType];

    return {
      icon: STATUS_ICONS[statusType],
      color: STATUS_COLOR[statusType],
      description: t(description),
      message,
    };
  };

  useEffect(() => {
    setStatusData(getStatusData());
  }, [status, isLoading]);

  /**
   * Status click handler.
   */
  const onStatusClick = (event: React.MouseEvent) => {
    if (!datePassed) {
      onClick(event);
    }
  };

  return statusData ? (
    <FlexBox
      onClick={onStatusClick}
      style={{ cursor: !datePassed ? 'pointer' : 'default' }}
      flexShrink={0}
      {...props}
    >
      {type && has(VIEWS, type)
        ? (VIEWS[type]({ ...props, statusData, isLoading }))
        : (VIEWS[DEFAULT_VIEW]({ ...props, statusData, isLoading }))}
    </FlexBox>
  ) : null;
};

OfferStatus.defaultProps = defaultProps;

export default OfferStatus;
