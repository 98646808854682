import { useRef } from 'react';
import * as Sentry from '@sentry/react';

type TCancellablePromise = {
  promise: Promise<any>
  cancel: Function
};

class CancellablePromiseRejectError extends Error {
  private isCanceled: boolean;

  constructor(isCanceled: boolean, ...params: any) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CancellablePromiseRejectError);
    }

    this.name = 'CancellablePromiseRejectError';
    this.isCanceled = isCanceled;
  }
}

const useCancellablePromises = () => {
  const pendingPromises = useRef<Array<TCancellablePromise>>([]);
  const appendPendingPromise = (promise: {promise: Promise<any>, cancel: Function}) => {
    pendingPromises.current.push(promise);
  };
  const removePendingPromise = (promise: {promise: Promise<any>, cancel: Function}) => {
    pendingPromises.current = pendingPromises.current.filter((p) => p !== promise);
  };
  const clearPendingPromises = () => pendingPromises.current.map((p) => p.cancel());

  return {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };
};
export const noop = () => {};

// eslint-disable-next-line no-promise-executor-return
export const delay = (n: number) => new Promise((resolve) => setTimeout(resolve, n));

export const cancellablePromise = (promise: Promise<any>): TCancellablePromise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject(new CancellablePromiseRejectError(true, 'Action Cancelled')) : resolve(value)),
      (error) => reject(new CancellablePromiseRejectError(isCanceled, error)),
    )
      .catch((errorInfo) => {
        Sentry.captureException(errorInfo);
      });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    },
  };
};

const useClickPreventionOnDoubleClick = (onClick: Function, onDoubleClick?: Function) => {
  const api = useCancellablePromises();

  const handleClick = (props: any) => {
    props.event.stopPropagation();
    props.event.nativeEvent.stopPropagation();

    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(300));
    api.appendPendingPromise(waitForClick);
    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick(props);
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.message;
        }
      });
  };

  const handleDoubleClick = () => {
    api.clearPendingPromises();
    if (onDoubleClick) {
      onDoubleClick();
    }
  };

  return [handleClick, handleDoubleClick];
};

export default useClickPreventionOnDoubleClick;
