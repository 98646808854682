import { IV5FieldStatus, IV5OnlineOffer } from '@ess/v5-data-provider/interfaces';
import { getFreePlaces } from '@ess/utils/offerData/getFreePlaces';

type FreePlaceItem = {
  Code: string
  Free: string
}

export type FreePlacesResponse = {
  fetch: () => void
  status: IV5FieldStatus
  hotel: FreePlaceItem[]
  transport: FreePlaceItem[]
  offerStatus: string
  statusMessage?: string
}

const useFreePlaces = (oo: IV5OnlineOffer): FreePlacesResponse => {
  const status = oo.fieldStatus('Transport.*');
  const { offerStatus, transport, hotel } = getFreePlaces(oo);

  return {
    status,
    fetch: () => {
      oo.sendOnlineRequest({ fields: ['Transport.*', 'Base.Availability'] });
    },
    transport,
    hotel,
    offerStatus,
  };
};
export default useFreePlaces;
