import React, { memo } from 'react';
import {
  LayoutProps, PositionProps, FlexboxProps, SpaceProps,
} from 'styled-system';

import { Styled } from './Col.styles';

type ColProps = {
  children: React.ReactNode,
} & LayoutProps & PositionProps & FlexboxProps & SpaceProps;

const Col = ({ children, ...props }: ColProps) => (
  <Styled.Col {...props}>
    {children}
  </Styled.Col>
);

export default memo(Col);
