import React from 'react';
import { useTranslation } from 'react-i18next';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

import getPriceSource from '@tourop/components/ContentSections/Omnibus/utils/getPriceSource';

const CustomTooltip = (props: any) => {
  const { t } = useTranslation();
  const { payload } = props;

  const data = payload?.length ? payload[0]?.payload : null;

  return data ? (
    <FlexBox
      backgroundColor="#153669"
      borderRadius={4}
      overflow="hidden"
      minWidth={100}
    >
      <FlexBox flexDirection="column">
        <FlexBox alignItems="center" width="100%" px="medium" py="small">
          <FlexBox width={60}>
            <Text color="white">
              {`${t('lbl_date')}:`}
            </Text>
          </FlexBox>
          <Text fontWeight="bold" color="white">
            {`${data.Date} ${data.Time}`}
          </Text>
        </FlexBox>

        <FlexBox backgroundColor="#0a254e" alignItems="center" px="medium" pt="small">
          <FlexBox width={60}>
            <Text color="white">
              {`${t('price')}:`}
            </Text>
          </FlexBox>
          <Text fontWeight="bold" color="white">
            {`${data.Price} ${data.Currency}`}
          </Text>

          {data.PriceDifference ? (
            <FlexBox
              ml="small"
              px="5px"
              py="2px"
              height="20px"
              alignItems="center"
              justifyContent="center"
              backgroundColor={data.PriceDifference > 0 ? 'errorRed' : 'green'}
              borderRadius={6}
            >
              <Text
                fontSize={11}
                letterSpacing={0.4}
                color="white"
              >
                {`${data.PriceDifference > 0 ? `+${data.PriceDifference}` : `${data.PriceDifference}`} ${data.Currency}`}
              </Text>
            </FlexBox>
          ) : null}
        </FlexBox>

        <FlexBox backgroundColor="#0a254e" alignItems="center" px="medium" py="small">
          <FlexBox width={60}>
            <Text color="white">
              {`${t('source')}:`}
            </Text>
          </FlexBox>
          <Text fontWeight="bold" color="white">
            {t(getPriceSource(data.Source))}
          </Text>
        </FlexBox>
      </FlexBox>

    </FlexBox>
  ) : null;
};

export default CustomTooltip;
