import moment from 'moment';
import { util } from 'protobufjs/light';
import { isEmpty, toNumber } from "lodash-es";

import { ADULT_CODE, CHILD_CODE, INFANT_CODE } from '@ess/constants/participants';
import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';
import { getHotelExtra } from "@ess/utils/offerData/extraHotel";

type GetParticipantsHashArgs = {
  adultsCount: number,
  childDates: string[]
  infantDates: string[]
};

/**
 * Returns participants in V5 format.
 * @param participants
 */
const getValidParticipants = (participants: any) => {
  const newValidList :any = {type: []};
  Object.keys(participants).map((name : string) => {
    switch (name) {
      case 'adultsCount':
        newValidList[ADULT_CODE] = {
          dates: [],
          value: participants?.adultsCount ?? 0
        }
        break;
      case 'childDates':
        newValidList[CHILD_CODE] = {
          dates: participants?.childDates ?? [],
          value: participants?.childDates?.length ?? 0
        }
        break;
      case 'infantDates':
        newValidList[INFANT_CODE] = {
          dates: participants?.infantDates ?? [],
          value: participants?.infantDates?.length ?? 0
        }
        break;
      default:
        break;
    }
  });
  return {rooms: [newValidList]};
};


/**
 * Returns participants base64 hash.
 * @param participants
 */
const getParticipantsHash = ({ adultsCount, childDates, infantDates }: GetParticipantsHashArgs): string => {
  const payload = Buffer.from([
    adultsCount,
    childDates.map((date: string) => moment(date, DATE_REQUEST_FORMAT).format(DATE_DISPLAY_FORMAT)).join(','),
    infantDates.map((date: string) => moment(date, DATE_REQUEST_FORMAT).format(DATE_DISPLAY_FORMAT)).join(','),
  ].join('|'));

  return util.base64.encode(payload, 0, Buffer.byteLength(payload));
};
/**
 * Returns participants from decoded hash .
 * @param hash
 */
const getParticipantsFromHash = (hash: string) =>{
  if (!isEmpty(hash)){
  const decodedPayload = Buffer.from(hash, 'base64');

  const decodedString = decodedPayload.toString();

  const [adultsCount, encodedChildDates, encodedInfantDates, extraHotel = undefined] = decodedString.split('|');

  const childDates = encodedChildDates ?  encodedChildDates.split(',').map((date) => moment(date, DATE_DISPLAY_FORMAT).format(DATE_REQUEST_FORMAT)): [];

  const infantDates = encodedInfantDates?  encodedInfantDates.split(',').map((date) => moment(date, DATE_DISPLAY_FORMAT).format(DATE_REQUEST_FORMAT)) : [];
  return {
    adultsCount: parseInt(adultsCount),
    childDates,
    infantDates,
    extraHotel,
  };

  }
  return {}
}

interface IEncodedOfferId {
  id: string
  operator: string
  adultsCount: number
  childDates: string[]
  infantDates: string[]
  extraHotel?: {
    code: string
    id: string
  }
}

/**
 * Returns encoded offerId
 * @param offerId
 */
const encodeOfferId = (offerId: string | undefined): IEncodedOfferId | null => {
  let data = null;

  if (offerId) {
    try {
      const [id, operator, extra] = offerId.split('|');
      const encodedPayload = Buffer.from(extra, 'base64').toString();
      const [
        adultsCount,
        childDates,
        infantDates,
        extraHotel,
      ] = encodedPayload.split('|');

      data = {
        id,
        operator,
        adultsCount: adultsCount ? toNumber(adultsCount) : 0,
        childDates: childDates ? childDates.split(',').map((date) => moment(date, DATE_DISPLAY_FORMAT).format(DATE_REQUEST_FORMAT)) : [],
        infantDates: infantDates ? infantDates.split(',').map((date) => moment(date, DATE_DISPLAY_FORMAT).format(DATE_REQUEST_FORMAT)) : [],
        extraHotel: extraHotel ? getHotelExtra(extraHotel) : undefined,
      }

    } catch (error) {
      return data;
    }
  }

  return data;
};

/**
 * Returns offerId from decoded offer hash.
 * @param decodedOffer
 */
const getOfferId = (decodedOffer: any) => {
  const {
    id, operator, adt, chdDates, infDates,
  } = decodedOffer;

  const participantsHash = getParticipantsHash({
    adultsCount: adt,
    infantDates: infDates,
    childDates: chdDates,
  });

  const params = [
    id,
    operator,
    participantsHash,
  ];

  return `${params.join('|')}`;
};

export default getOfferId;

export {
  getValidParticipants,
  getParticipantsFromHash,
  getParticipantsHash,
  encodeOfferId,
};
