import React, {
  forwardRef, memo, PropsWithChildren, useEffect, useState,
} from 'react';
import { positionValues, ScrollbarProps, Scrollbars } from 'react-custom-scrollbars';
import { useDebouncedCallback } from 'use-debounce';
import { floor } from 'lodash-es';

import { IDictionary } from '@ess/types';

enum ScrollBarType {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

enum ScrollDirection {
  Idle = 'idle',
  Top = 'top',
  Bottom = 'bottom'
}

interface ScrollBarApi {
  isVerticalScrollBarVisible: boolean
  isHorizontalScrollBarVisible: boolean
}

type ScrollBarProps = {
  children: React.ReactNode | ((api: ScrollBarApi) => React.ReactNode)
  onScrollEnd?: () => void
  onDirectionChange?: (direction: ScrollDirection) => void
  onHorizontalScrollVisibilityChange?: (isVisible: boolean) => void,
  onScroll?: (values: positionValues) => void
  height?: number
  thumbSize?: number
  isHorizontalTrackHidden?: boolean
} & ScrollbarProps;

const defaultProps = {
  height: 200,
  thumbSize: 6,
  onScrollEnd: undefined,
  onDirectionChange: undefined,
  onScroll: undefined,
  onHorizontalScrollVisibilityChange: undefined,
  isHorizontalTrackHidden: false,
};

const ScrollBar = forwardRef<Scrollbars, ScrollBarProps>(({
  height,
  children,
  onScroll,
  onUpdate,
  onDirectionChange,
  thumbSize,
  onScrollEnd,
  isHorizontalTrackHidden,
  onHorizontalScrollVisibilityChange,
  ...props
}, ref) => {
  const [scrollBarHeight, setScrollBarHeight] = useState<number | string | null>(null);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(ScrollDirection.Idle);
  const [scrollProgress, setScrollProgress] = useState<number>(0);
  const [scrollAreaHeight, setScrollAreaHeight] = useState<number>(0);
  const [isVerticalScrollBarVisible, setIsVerticalScrollBarVisible] = useState<boolean>(true);
  const [isHorizontalScrollBarVisible, setIsHorizontalScrollBarVisible] = useState<boolean>(false);

  const debouncedSetDirection = useDebouncedCallback((direction) => {
    setScrollDirection(direction);
  }, 200);

  /**
   * Updates scroll values.
   * @param values
   */
  const updateHandler = (values: positionValues) => {
    const {
      clientHeight, scrollHeight, clientWidth, scrollWidth, top,
    } = values;
    const isVerticalVisible = scrollHeight > clientHeight;
    const isHorizontalVisible = scrollWidth > clientWidth;
    const isSameScrollArea = scrollAreaHeight === scrollHeight;
    const progress = floor(top * 100);

    if (onUpdate) {
      onUpdate(values);
    }

    if (progress !== scrollProgress && isSameScrollArea && Math.abs(scrollHeight - clientHeight) > 250) {
      const isIdle = progress === 0;
      const direction = progress > scrollProgress ? ScrollDirection.Bottom : ScrollDirection.Top;
      debouncedSetDirection(isIdle ? ScrollDirection.Idle : direction);
    }

    setIsHorizontalScrollBarVisible(isHorizontalTrackHidden ? false : isHorizontalVisible);
    setIsVerticalScrollBarVisible(isVerticalVisible);
    setScrollAreaHeight(scrollHeight);
    setScrollProgress(progress);
  };

  /**
   * Fires callback if scroll reaches end.
   */
  const onScrollEndHandler = (): void => {
    if (onScrollEnd) {
      onScrollEnd();
    }
  };

  /**
   * Fires callback if scroll direction change.
   */
  const onScrollDirectionChangeHandler = (direction: ScrollDirection): void => {
    if (onDirectionChange) {
      onDirectionChange(direction);
    }
  };

  const onScrollHandler = (values: positionValues) => {
    if (onScroll) {
      onScroll(values);
    }
  };

  /**
   * Custom thumb render function.
   * @param type
   * @param style
   * @param props
   */
  const renderThumb = (type: ScrollBarType, { style, ...props }: { style: any }) => {
    const isHorizontal = type === ScrollBarType.Horizontal;
    const size = isHorizontal ? 'height' : 'width';

    return (
      <div
        {...props}
        style={{
          ...style,
          display: 'block',
          position: 'relative',
          cursor: 'pointer',
          borderRadius: 'inherit',
          backgroundColor: 'rgb(0 0 0 / 15%)',
          [size]: `${thumbSize}px`,
          ...isHorizontal ? { top: '1x' } : {},
        }}
      />
    );
  };

  useEffect(() => {
    if (onHorizontalScrollVisibilityChange) {
      onHorizontalScrollVisibilityChange(isHorizontalScrollBarVisible);
    }
  }, [isHorizontalScrollBarVisible]);

  useEffect(() => {
    setScrollBarHeight(height || 0);
  }, [height]);

  useEffect(() => {
    if (scrollProgress !== null && scrollProgress >= 99) {
      onScrollEndHandler();
    }

    if (scrollProgress === 0) {
      setScrollDirection(ScrollDirection.Idle);
    }
  }, [scrollProgress]);

  useEffect(() => {
    onScrollDirectionChangeHandler(scrollDirection);
  }, [scrollDirection]);

  const condProps: IDictionary<any> = {};

  if (isHorizontalTrackHidden) {
    condProps.renderTrackHorizontal = (props: PropsWithChildren<any>) => (
      <div
        {...props}
        style={{ display: 'none' }}
        className="track-horizontal"
      />
    );

    condProps.renderView = (props: PropsWithChildren<any>) => (
      <div
        {...props}
        style={{ ...props.style, overflowX: 'hidden', marginBottom: 0 }}
      />
    );
  }

  return (
    <Scrollbars
      ref={ref}
      style={{ height: `${scrollBarHeight}px`, scrollbarColor: '#0003 transparent' }}
      onScrollFrame={onScrollHandler}
      onUpdate={updateHandler}
      hideTracksWhenNotNeeded
      renderThumbHorizontal={(data) => renderThumb(ScrollBarType.Horizontal, data)}
      renderThumbVertical={(data) => renderThumb(ScrollBarType.Vertical, data)}
      {...props}
      {...condProps}
    >
      {typeof children === 'function'
        ? children({
          isVerticalScrollBarVisible,
          isHorizontalScrollBarVisible,
        })
        : children}
    </Scrollbars>
  );
});

ScrollBar.defaultProps = defaultProps;

export default memo(ScrollBar);
