import styled from 'styled-components';
import { darken } from 'polished';

import { IconButton } from '@ess/ui/Button';

const NextBtn = styled(IconButton)`
    position: absolute;
    right: 18px;
    top: 17px;
`;

const PrevBtn = styled(IconButton)`
    position: absolute;
    left: 18px;
    top: 17px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    padding-left: ${({ theme }) => theme.space.small};
    height: 40px;
    user-select: none;
`;

const Header__Title = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.head};
    line-height: ${({ theme }) => theme.lineHeights.title};
`;

const Header__CloseIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 100%;
    transition: all .3s ease-in;

    &:hover {
      background-color: ${({ theme }) => darken(0.1, theme.colors.blue)};
    }
`;

export const Styled = {
  Header,
  Header__Title,
  Header__CloseIcon,
  NextBtn,
  PrevBtn,
};
