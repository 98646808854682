
import React, { useEffect, useRef, useState } from 'react'
import { CellMeasurerCache, CellMeasurer, List } from 'react-virtualized';
import { findIndex, isEmpty } from 'lodash-es';

import Box from '@ess/ui/Box';

const DEFAULT_HEIGHT = 35;

const MenuList = (props) => {
  const {
    children, getStyles, isLoading, options,
  } = props;
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const childrenArray = React.Children.toArray(children);
  const inputRef = useRef(null);
  const styles = getStyles('menuList', props);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: DEFAULT_HEIGHT,
  });

  const height = isLoading || !childrenArray.length
    ? DEFAULT_HEIGHT
    : childrenArray.length > 10
      ? styles.maxHeight
      : 1;

  useEffect(() => {
    inputRef?.current?.Grid.recomputeGridSize();
  }, [inputRef, options, height]);

  useEffect(() => {
    if (props?.focusedOption) {
      const index = props.options.findIndex((item) => item.value === props.focusedOption.value);
      if (index > 0) {
        setScrollToIndex(index);
      } else {
        setScrollToIndex(0);
      }
    }
  }, [props.focusedOption]);

  const rowRenderer = ({ key, index, style, parent }) => (
    <CellMeasurer
      key={key}
      cache={cache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      <Box style={style} width="100%">
        {childrenArray[index]}
      </Box>
    </CellMeasurer>
  );

  return (
    <List
      ref={inputRef}
      style={{ width: '100%'}}
      containerStyle={{
        width: '100%',
        maxWidth: '100%',
      }}
      width={1}
      autoHeight={height <= 1}
      height={height}
      scrollToIndex={scrollToIndex}
      deferredMeasurementCache={cache}
      rowHeight={cache.rowHeight}
      rowCount={childrenArray.length}
      rowRenderer={rowRenderer}
      styles={getStyles('menuList', props)}
    />
  );
};

export default MenuList;
