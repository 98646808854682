import { IDictionary } from '@ess/types';
import {
  isArray, sortBy, toNumber,
} from 'lodash-es';
import moment from 'moment';

import { getPriceDifference } from '@ess/utils/offerData/priceDifference';

import { TIME_FORMAT, TIME_INTERVALS } from '../constants';

/**
 * Returns parsed Omnibus data.
 * @param data
 * @param groupedData
 */
const getParsedData = (data: any, groupedData = false): IDictionary<any> | null => {
  const {
    History = undefined, OmnibusPrice = undefined, Offer = undefined,
  } = data ?? {};

  const priceHistory = groupedData ? History?.PersonGrouped : History?.Person;

  const currentPrice = Offer ? {
    amount: Offer.Base.Price.FirstPerson.amount,
    currency: Offer.Base.Price.FirstPerson.currency,
  } : {};

  if (priceHistory?.length) {
    const processed = priceHistory.map((item: any) => {
      const price = isArray(item) ? item[item.length - 1] : item;
      const unix = moment.unix(price.timeStamp);
      const timeString = unix.format('HH:mm');
      const isAvailable = price?.currency !== '_X_';

      const interval = TIME_INTERVALS.find((interval) => {
        const time = moment(timeString, TIME_FORMAT);
        const isBetweenRange = time.isBetween(moment(interval.start, TIME_FORMAT), moment(interval.end, TIME_FORMAT));

        return [interval.start, interval.end].includes(timeString) || isBetweenRange;
      });

      const { difference = 0 } = price?.currency === currentPrice.currency
        ? getPriceDifference(price.amount, currentPrice?.amount, currentPrice.currency, 0)
        : {};

      return {
        Available: isAvailable,
        Price: Math.round(toNumber(price?.amount)),
        PriceDifference: isAvailable ? difference : 0,
        Source: price?.source,
        Currency: isAvailable ? price?.currency : '',
        Details: price?.details ?? {},
        TimeInterval: interval?.groupId,
        Time: timeString,
        Date: unix.format('DD.MM.YYYY'),
        TimeStamp: price.timeStamp,
        ...price?.highest ? {
          Highest: true,
        } : {},
        ...price?.lowest ? {
          Lowest: true,
        } : {},
      };
    });

    const historicData = sortBy(processed, ['TimeStamp']);

    return {
      Offer,
      IsGrouped: groupedData,
      Data: historicData,
      Price: {
        Current: currentPrice,
        ...OmnibusPrice,
      },
    };
  }

  return null;
};

export default getParsedData;
