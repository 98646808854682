import React, { memo, CSSProperties } from 'react';

import { OPERATOR_LOGO } from '@ess/constants/api';

import FlexBox from '@ess/ui/FlexBox';
import Image from '@ess/ui/Image';
import Tooltip from '@ess/ui/Tooltip';

type OperatorsColumnProps = {
  data: string[]
  getCellStyles: (isLastRow: boolean, isLastRowChild: boolean) => CSSProperties
}

const imageStyles = {
  borderRadius: '6px',
};

const OperatorsColumn = ({ data, getCellStyles }: OperatorsColumnProps) => (
  <FlexBox
    position="relative"
    zIndex={10}
    flexDirection="column"
    style={{
      boxShadow: '5px 0px 10px rgb(0 0 0 / 8%)',
    }}
  >
    <FlexBox
      flexShrink={0}
      width="150px"
      height="50px"
      style={getCellStyles(false, false)}
    />
    {data.map((operator: string, index: number) => {
      const isLastChild = data.length - 1 === index;
      return (
        <FlexBox key={operator}>
          <Tooltip content={operator}>
            <FlexBox
              alignItems="center"
              justifyContent="center"
              flexShrink={0}
              height="70px"
              flexBasis="150px"
              style={getCellStyles(isLastChild, false)}
            >

              <Image
                src={`${OPERATOR_LOGO}${operator}.png`}
                width="60px"
                height="40px"
                objectFit="contain"
                alt={operator}
                style={imageStyles}
              />
            </FlexBox>
          </Tooltip>
        </FlexBox>
      );
    })}
  </FlexBox>
);

export default memo(OperatorsColumn);
