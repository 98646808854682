import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { includes } from 'lodash-es';

import FlexBox from '@ess/ui/FlexBox';

import TooltipContent from '../../TooltipContent';

const CustomTooltip = (props: any) => {
  const [lockTooltipPosition, setLockTooltipPosition] = useState(false);
  const [tooltipCoordinate, setTooltipCoordinate] = useState({ x: 0, y: 0 });
  const [tooltipData, setTooltipData] = useState<any[]>([]);
  const { payload, coordinate, viewBox } = props;

  const additionalTooltipData = tooltipData
    ? tooltipData.filter((item: any) => item.dataKey !== 'Price').map(({ payload, name }) => ({
      value: `${payload[name]} ${includes(name, 'Temp') && payload.TempUnit}`,
      name: props.translationsMap[name],
    }))
    : [];

  const onMouseOver = () => {
    setLockTooltipPosition(true);
  };

  const onMouseLeave = () => {
    setLockTooltipPosition(false);
  };

  useEffect(() => {
    const { x } = coordinate;

    if (!lockTooltipPosition) {
      setTooltipCoordinate({
        x: x - 110,
        y: viewBox.height - 120,
      });
    }
  }, [coordinate, lockTooltipPosition]);

  useEffect(() => {
    if (!lockTooltipPosition) {
      setTooltipData(payload);
    }
  }, [payload, lockTooltipPosition]);

  return (
    <>
      {tooltipData?.length > 0 && createPortal(
        <FlexBox
          backgroundColor="navy"
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          borderRadius={4}
          overflow="hidden"
          width="220px"
          p="small"
          style={{
            ...props.wrapperStyle,
            transform: `translate(${tooltipCoordinate.x}px, ${tooltipCoordinate.y}px)`,
            transition: '-webkit-transform 400ms ease 0s',
          }}
        >
          <TooltipContent
            offer={tooltipData[0].payload.Offer}
            isSelected={tooltipData[0].payload.Selected}
            additionalTooltipData={additionalTooltipData}
          />
        </FlexBox>, document.getElementById('price-chart') as HTMLElement,
      )}
    </>
  );
};

export default CustomTooltip;
