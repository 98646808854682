import styled from 'styled-components';

const ImageSliderWrapper = styled.div<{
  isPortal: boolean | undefined,
  hasHeader: boolean | undefined,
  isMobile: boolean | undefined,
  hasOperatorSelect: boolean | undefined
}>`

  ${({ isPortal, theme, hasHeader }) => isPortal && `
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: ${theme.zIndex.modal};
      background-color: ${theme.colors.lightGray};
      min-height: 100vh;
      min-height: -webkit-fill-available;
      overflow: hidden;

      .image-gallery {
        height: ${(!hasHeader ? '100vh' : 'calc(100vh - 50px)')};
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  `};

  .image-gallery-content {
     width: 100%;

    .image-gallery-slide {
      height: 100%;

      img {
         max-height: 100%;
         max-width: 100%;
      }
    }
  }

  .image-gallery-index {
    background: rgb(0 0 0 / 62%);
    right: 50%;
    top: auto;
    bottom: 0;
    width: 120px;
    margin-right: -60px;
    text-align: center;
    font-weight: bold;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .image-gallery-slides {
    height: ${({
    isPortal, hasHeader, isMobile, hasOperatorSelect,
  }) => (
    isPortal
      ? hasHeader
        ? 'calc(100vh - 92px - 60px)' : 'calc(100vh - 92px)'
      : `calc(100vh - 92px - 117px${hasOperatorSelect ? ' - 64px' : ''}${isMobile ? '' : ' - 104px'})`
  )};
    background-color: #e9ebed;
  }

  .image-gallery-thumbnails {
    overflow: hidden;
    background-color: ${({ theme, isPortal }) => (!isPortal ? theme.colors.lightGray : theme.colors.lightGray)};
    padding: 15px;
    height: 92px;
  }

  .fullscreen {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

     .image-gallery-slides {
       background-color: transparent;
       height: calc(100vh - 90px);
     }

     .image-gallery-thumbnails {
      background-color: transparent;
     }
  }

  .image-gallery-thumbnail {
    border: none;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.lightGray};
    overflow: hidden;
    opacity: 0.5;
    transition: all .3s ease-in;

    +.image-gallery-thumbnail {
      margin-left: 10px;
    }

    &.active {
       opacity: 1;
    }

    &:hover, &:focus {
      border: none;
      opacity: 1;
    }
  }
`;

export const Styled = {
  ImageSliderWrapper,
};
