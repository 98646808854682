import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudSun,
  faCommentAlt,
  faFile,
  faGopuram,
  faImages,
  faInfo,
  faMapMarkedAlt,
  faPassport,
  faPeopleRoof,
  faPlane,
  faUmbrellaBeach,
  faUserCheck,
  faUsers,
  faUserTag,
} from '@fortawesome/pro-solid-svg-icons';

import { IDictionary } from '@ess/types';

/**
 * Agent settings - section state
 */
export const DETAILS_PAGE_SHRINK_VIEW = 'SHRINK';
export const DETAILS_PAGE_EXPANDED_VIEW = 'EXPANED';
export const DETAILS_PAGE_LINE_DISABLED = 'DISABLED';

export const SECTIONS_NAVIGATION_ENABLED = true;

/**
 * Section icons
 */
export const SECTION_ICONS: IDictionary<any> = {
  MultiRoomFinder: (<FontAwesomeIcon icon={faPeopleRoof}/>),
  Description: (<FontAwesomeIcon icon={faFile} color="inherit"/>),
  Pictures: (<FontAwesomeIcon icon={faImages}/>),
  TripAdvisor: (<FontAwesomeIcon icon={faUserCheck}/>),
  RegionalInfo: (<FontAwesomeIcon icon={faInfo}/>),
  PriceChart: (<FontAwesomeIcon icon={faUsers}/>),
  WeatherStatic: (<FontAwesomeIcon icon={faCloudSun}/>),
  VisaInfo: (<FontAwesomeIcon icon={faPassport}/>),
  AirportsNearby: (<FontAwesomeIcon icon={faPlane}/>),
  BeachesNearby: (<FontAwesomeIcon icon={faUmbrellaBeach}/>),
  MapInfo: (<FontAwesomeIcon icon={faMapMarkedAlt}/>),
  AgentAttributes: (<FontAwesomeIcon icon={faUserTag}/>),
  Bookings: (<FontAwesomeIcon icon={faFile}/>),
  AlternativeOffers: (<FontAwesomeIcon icon={faCommentAlt}/>),
};
