/**
 * Attributes icons map.
 * @type ATTRIBUTES_NAMES_MAP {object}
 */

interface IDictionary<T> {
  [Key: string]: T;
}

export const ATTRIBUTES_PRIO_ITEMS = [
  'facility_water_slides',
  'location_exotic_offers',
  'location_directly_on_the_beach',
  'location_without_passport_from_pl',
  'location_seaside_resorts',
  'location_ski_resorts',
  'facility_for_adult',
  'location_mountains',
  'facility_pets',
  'facility_indoor_pool',
];

export const ATTRIBUTES_EXTENDEDVIEW_MAX_ITEMS = 5;

export const ATTRIBUTES_NAMES_MAP: IDictionary<string> = {
  // Activity attribute
  activity_winter_sports: 'attr_wintersport',
  activity_sailing: 'attr_sailing',
  activity_windsurfing: 'attr_surfing',
  activity_nordic_walking: 'attr_walking',
  activity_riding: 'attr_horse',
  activity_diving: 'attr_diving',
  activity_water_sports: 'attr_watersports',
  activity_minigolf: 'attr_minigolf',
  activity_golf: 'attr_golf',
  activity_tennis: 'attr_tennis',
  activity_sport: 'attr_sports',
  activity_animation_for_adults: 'attr_adult',
  activity_bicycles: 'attr_bikes',

  // Facility attributes
  facility_facilities_for_disabled: 'attr_handicaped',
  facility_winter_equipment_rental: 'attr_skirent',
  facility_night_life: 'attr_nightclub',
  facility_air_conditioning: 'attr_ac',
  facility_internet_access: 'attr_internet',
  facility_pets: 'attr_pets',
  facility_water_slides: 'attr_waterslides',
  facility_indoor_pool: 'attr_insidepool',
  facility_outdoor_pool: 'attr_outsidepool',
  facility_fitness_or_gym: 'attr_fitness',
  facility_club_hotel: 'attr_club',
  facility_for_families_with_childrens: 'attr_kids',
  facility_parking: 'attr_parking',
  facility_fireplace: 'attr_fireplace',
  facility_home_appliances: 'attr_agdrtv',
  facility_gay_friendly: 'attr_gay',
  facility_wellness: 'attr_spa',
  facility_for_adult: 'male',
  facility_free_wifi: 'attr_wififree',
  facility_kindergarten: 'attr_kindergarten',
  facility_barbecue: 'attr_gril_bbq',

  // Location attributes
  location_near_the_center: 'attr_partycity',
  location_near_airport: 'attr_airport',
  location_directly_on_the_beach: 'attr_beach',
  location_ski_resorts: 'attr_skiregion',
  location_seaside_resorts: 'attr_searegion',
  location_lakes: 'attr_lakeregion',
  location_mountains: 'attr_mountains',
  location_city_breaks: 'attr_citybreak',
  location_exotic_offers: 'attr_exotica',
  location_without_passport_from_pl: 'attr_no_passport',
  location_near_the_slope: 'attr_near_slopes',
};
