import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionTypeBase } from 'react-select';

import { IDictionary } from '@ess/types';

import { useSelector } from '@ess/store/core';

import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

import FlexBox from '@ess/ui/FlexBox';
import Radio from '@ess/ui/Form/Radio';
import Select from '@ess/ui/Form/Select';
import Text from '@ess/ui/Text';

import Omnibus from '@tourop/components/ContentSections/Omnibus';

import Chart from './Chart';
import Matrix from './Matrix';

type StatsType = 'Chart' | 'Matrix' | 'History';

type PriceChartProps = {
  data: any
  showStatsSwitcher?: boolean
  offerData?: any
  fetchAsync?: ({ sections, params }: { sections: string[], params: any }) => void
  initialStats?: StatsType
}

const rangeOptions = [
  {
    label: 'lbl_daily',
    value: 'Daily',
  },
  {
    label: 'lbl_weekly',
    value: 'Weekly',
  },
  {
    label: 'lbl_monthly',
    value: 'Monthly',
  },
];

const defaultProps = {
  fetchAsync: undefined,
  showStatsSwitcher: true,
  offerData: undefined,
  initialStats: 'Chart' as StatsType,
};

const statsTypes: IDictionary<any> = {
  Chart: {
    component: Chart,
    dataName: 'PriceChart',
    label: 'lbl_price_chart',
  },
  Matrix: {
    component: Matrix,
    dataName: 'OperatorChart',
    label: 'lbl_operator_comparison',
  },
  History: {
    component: Omnibus,
    dataName: 'Omnibus',
    label: 'lbl_price_change_history',
  },
};

const PriceChart = ({
  data,
  offerData,
  showStatsSwitcher,
  fetchAsync,
  initialStats,
}: PriceChartProps) => {
  const { t } = useTranslation();
  const [statsType, setStatsType] = useState<StatsType>(initialStats ?? 'Chart');
  const [range, setRange] = useState('Monthly');
  const { trackEvent } = useGoogleAnalytics();
  const isMultiRoomMode = useSelector((state) => state.searchResults.multiRoomMode);

  const rangeOptionsWithTranslations = useMemo(() => rangeOptions.map((item) => ({
    ...item,
    label: t(item.label),
  })), []);

  // select with combo (wymiar czasowy)
  const onRangeChange = (item: OptionTypeBase) => {
    trackEvent({
      event: 'chart',
      eventCategory: 'B2B_CHART',
      eventAction: 'B2B_ON_RANGE_CHANGE',
      eventLabel: '',
    });
    if (!fetchAsync) {
      return;
    }
    setRange(item.value);
    fetchAsync({ sections: ['PriceChart'], params: { QueryType: item.value } });
  };

  // click on the radiobuttons
  const onChangeHandler = (value: string) => {
    trackEvent({
      event: 'chart',
      eventCategory: 'B2B_CHART',
      eventAction: 'B2B_ON_RADIOBUTTON_CHANGE',
      eventLabel: value,
    });
    setStatsType(value as StatsType);
  };

  return (
    <FlexBox
      flexDirection="column"
      width="100%"
    >
      {showStatsSwitcher && (
      <FlexBox
        px="medium"
        pt="medium"
        minHeight={50}
        flexShrink={0}
        alignItems={{
          md: 'center',
          _: 'flex-start',
        }}
        justifyContent={{
          md: 'space-between',
          _: 'flex-start',
        }}
        flexDirection={{
          md: 'row',
          _: 'column',
        }}
      >
        <FlexBox mr="medium">
          {Object.keys(statsTypes).map((stat) => {
            const hideHistory = stat === 'History' && isMultiRoomMode;

            if (hideHistory) {
              return null;
            }

            return (
              <FlexBox mr="small" key={stat}>
                <Radio
                  value={stat}
                  name="statsType"
                  label={t(statsTypes[stat].label)}
                  onChange={() => onChangeHandler(stat)}
                  checked={stat === statsType}
                />
              </FlexBox>
            );
          })}
        </FlexBox>
        {statsType !== 'History' && (
          <FlexBox>
            <FlexBox alignItems="center">
              <Text mr="tiny">
                {`${t('lbl_time_dimension')}:`}
              </Text>
              <FlexBox width="220px">
                <Select
                  isClearable={false}
                  options={rangeOptionsWithTranslations}
                  onChange={onRangeChange}
                  value={range}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
      )}
      {React.createElement(statsTypes[statsType].component, {
        data: data[statsTypes[statsType].dataName],
        offerData: offerData?.offer,
        fetchAsync,
      })}
    </FlexBox>
  );
};

PriceChart.defaultProps = defaultProps;

export default PriceChart;
