import React from 'react';

import { Styled } from './ModalOverlay.styles';

type ModalOverlayProps = {
  onClick?: () => void
}

const defaultProps = {
  onClick: undefined
}

const ModalOverlay = ({ onClick }: ModalOverlayProps) => {
  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  return (
    <Styled.ModalOverlay onClick={onClickHandler}/>
  );
};

ModalOverlay.defaultProps = defaultProps;

export default ModalOverlay;
