import React, { useEffect, useState } from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash-es';

import { IOption } from '@ess/types';

import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

import { Col, Row } from '@ess/ui/FlexGrid';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Box from '@ess/ui/Box';
import Select, { ClearIndicator, DropdownIndicator, LoadingIndicator } from '@ess/ui/Form/Select';

import { ImagesGrid, ImagesSlider } from '@tourop/components/Gallery';

enum GalleryType {
  Slider = 'ImageSlider',
  Grid = 'ImagesGrid',
}

interface IOperator {
  Code: string
  Name: string
  HtlCode: string
  PictureCnt?: number
}

type PicturesProps = {
  type?: GalleryType,
  data: {
      PictureUrlFull?: Array<string>,
      PictureUrlMedium?: Array<string>,
      PictureUrlLarge?: Array<string>,
      PictureUrlSmall?: Array<string>,
      ThumbUrl?: Array<string>,
      Touroperators?: Array<IOperator>,
      HotelName?: string
  }
  requestParams: any,
  fetchAsync: ({ sections, params }: { sections: string[], params: any }) => void
}

const defaultProps = {
  type: GalleryType.Grid,
  htlCode: '',
};

const operatorsToOptions = (operatorsResponse: Array<IOperator>): Array<IOption> => {
  const ret: Array<IOption> = [];
  operatorsResponse.map((item: IOperator) => {
    ret.push({
      label: `${(item.Name.length > 1 ? item.Name : item.Code)}`,
      value: item.Code,
    });
  });
  return ret;
};

const getImages = ({
  PictureUrlSmall, PictureUrlFull, PictureUrlMedium,
}: PicturesProps['data']): ReadonlyArray<ReactImageGalleryItem & {largeThumb: string}> => {
  const ret: Array<ReactImageGalleryItem & {largeThumb: string}> = [];
  if (PictureUrlFull && PictureUrlSmall && PictureUrlMedium) {
    PictureUrlFull.map((item: string, key: number) => {
      ret.push({
        original: PictureUrlFull[key],
        fullscreen: PictureUrlFull[key],
        largeThumb: PictureUrlMedium[key],
        thumbnail: PictureUrlSmall[key],
      });
    });
  }

  return ret;
};

const Pictures = ({
  data, type, fetchAsync, requestParams,
}: PicturesProps) => {
  const {
    PictureUrlMedium, PictureUrlSmall, PictureUrlFull, Touroperators, HotelName,
  } = data;
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const [pictures, setPictures] = useState<ReadonlyArray<ReactImageGalleryItem & {largeThumb: string}>>([]);
  const [selectedOperator, setSelectedOperator] = useState<string>('');
  const hasOtherOperators = Touroperators && Touroperators?.length > 1;

  /**
   * Operator change handler.
   * @param selectedOption
   */
  const onChangeHandler = async (selectedOption: OptionTypeBase) => {
    const htlCode = (find(Touroperators, { Code: selectedOption.value }))?.HtlCode;
    trackEvent({
      event: 'contentPictures',
      eventCategory: 'B2B_CONTENT_PICTURES',
      eventAction: 'B2B_OPERATOR_CHANGE',
      eventLabel: '',
    });
    setSelectedOperator(selectedOption.value);
    await fetchAsync({
      sections: ['Pictures'],
      params: {
        HotelCode: htlCode,
        Operator: selectedOption.value,
      },
    });
  };

  useEffect(() => {
    if (requestParams) {
      setSelectedOperator(requestParams?.Operator ?? '');
    }
  }, []);

  useEffect(() => {
    setPictures(getImages({ PictureUrlMedium, PictureUrlSmall, PictureUrlFull }));
  }, [data]);

  return (
    <FlexBox flexDirection="column" width="100%">
      {hasOtherOperators && (
      <FlexBox
        p="medium"
        alignItems="center"
        backgroundColor="lightGray"
      >
        <Row gapX={5} gapY={5} width="100%" flexWrap="wrap">
          <Col width={{
            lg: 2.4 / 12,
            md: 6 / 12,
            sm: 6 / 12,
            xs: 1,
            xxs: 1,
            print: '250px',
          }}
          >
            <Select
              isClearable={false}
              isSearchable={false}
              options={operatorsToOptions(Touroperators)}
              value={selectedOperator}
              menuShouldScrollIntoView
              onChange={onChangeHandler}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
                ClearIndicator,
                LoadingIndicator,
              }}
              menuPortalTarget={document.body as HTMLBodyElement}
            />
          </Col>
        </Row>
      </FlexBox>
      )}
      {pictures.length ? (
        <>
          {type === GalleryType.Grid ? (
            <Box
              px="medium"
              pb="medium"
              {...{ ...!hasOtherOperators ? { pt: 'medium' } : { } }}
            >
              <ImagesGrid items={pictures} maxItems={5} hotelName={HotelName} hasGallery/>
            </Box>
          ) : (
            <ImagesSlider items={pictures}/>
          )}
        </>
      ) : (
        <FlexBox
          height="200px"
          alignItems="center"
          justifyContent="center"
          backgroundColor="#e9ebed"
        >
          <Text color="darkGray">{t('no_pictures')}</Text>
        </FlexBox>
      )}
    </FlexBox>
  );
};

Pictures.defaultProps = defaultProps;

export default Pictures;
