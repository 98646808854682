import React, {
  forwardRef, MouseEventHandler, useEffect, useState,
} from 'react';
import ImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faCompress, faExpand, faPause, faPlay,
} from '@fortawesome/pro-solid-svg-icons';

import 'react-image-gallery/styles/css/image-gallery.css';

import { colors } from '@tourop/config/theme/colors';

import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

import { IconButton } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Image from '@ess/ui/Image';

import { isMobile } from 'react-device-detect';
import ImageSliderSkeleton from './ImageSlider.skeleton';

import { Styled } from './ImageSlider.styles';

type ImagesGridProps = {
  isPortal?: boolean
  startIndex?: number
  headerComponent?: React.ReactElement
  hasOperatorSelect?: boolean | undefined
} & ReactImageGalleryProps;

const defaultProps = {
  isPortal: false,
  startIndex: 0,
  headerComponent: undefined,
  hasOperatorSelect: false,
};

const ImagesSlider = forwardRef<ImageGallery, ImagesGridProps>(({
  items,
  isPortal,
  startIndex,
  headerComponent,
  hasOperatorSelect,
}, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const { trackEvent } = useGoogleAnalytics();

  /**
   * OnClickHandler : Handler for the clicks
   */
  const renderItem = (props: any) => (
    <FlexBox
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src={props.original}
        width="auto"
        height="auto"
        alt="dsa"
      />
    </FlexBox>
  );
  const renderLeftNav = (onClick: MouseEventHandler<HTMLElement>, disabled: boolean) => {
    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
      trackEvent({
        event: 'contentPictures',
        eventCategory: 'B2B_CONTENT_PICTURES',
        eventAction: 'B2B_LEFT_NAV',
        eventLabel: '',
      });
      if (event) {
        onClick(event);
      }
    };

    return isMobile ? <></> : (
      <FlexBox
        flexShrink={10}
        borderRadius={6}
        left={15}
        onClick={disabled ? undefined : onClickHandler}
        backgroundColor={disabled ? 'transparent' : colors.green}
        className="image-gallery-icon image-gallery-left-nav"
        boxShadow="unset"
        style={{ filter: 'unset' }}
        opacity={disabled ? '0.5' : 1}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          color={disabled ? 'transparent' : colors.white}
          size="3x"
          stroke="black"
        />
      </FlexBox>
    );
  };
  const renderRightNav = (onClick: MouseEventHandler<HTMLElement>, disabled: boolean) => {
    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
      trackEvent({
        event: 'contentPictures',
        eventCategory: 'B2B_CONTENT_PICTURES',
        eventAction: 'B2B_RIGHT_NAV',
        eventLabel: '',
      });
      if (event) {
        onClick(event);
      }
    };
    return isMobile ? <></> : (
      <FlexBox
        flexShrink={10}
        borderRadius={6}
        right={15}
        onClick={disabled ? undefined : onClickHandler}
        backgroundColor={disabled ? 'transparent' : colors.green}
        className="image-gallery-icon image-gallery-right-nav"
        boxShadow="unset"
        style={{ filter: 'unset' }}
        opacity={disabled ? '0.5' : 1}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          color={disabled ? 'transparent' : colors.white}
          size="3x"
          stroke="black"
        />
      </FlexBox>
    );
  };

  const renderPlayPauseButton = (onClick: any, isPlaying: boolean) => {
    const onClickHandler = () => {
      trackEvent({
        event: 'contentPictures',
        eventCategory: 'B2B_CONTENT_PICTURES',
        eventAction: 'B2B_PLAY_PAUSE_BUTTON',
        eventLabel: '',
      });
      onClick(onClick);
    };

    return (
      <FlexBox position="absolute" left={15} bottom={15}>
        <IconButton
          onClick={onClickHandler}
          icon={(<FontAwesomeIcon icon={isPlaying ? faPause : faPlay}/>)}
        />
      </FlexBox>
    );
  };

  const renderFullscreenButton = (onClick: any, isFullscreen: boolean) => {
    const onClickHandler = () => {
      trackEvent({
        event: 'contentPictures',
        eventCategory: 'B2B_CONTENT_PICTURES',
        eventAction: 'B2B_FULL_SCREEN_BUTTON',
        eventLabel: '',
      });
      onClick(onClick);
    };
    return (
      <FlexBox position="absolute" right={15} bottom={15}>
        <IconButton
          onClick={onClickHandler}
          icon={(<FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand}/>)}
        />
      </FlexBox>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    trackEvent({
      event: 'contentPictures',
      eventCategory: 'B2B_CONTENT_PICTURES',
      eventAction: 'B2B_IMAGE_SHOWN',
      eventLabel: '',
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [items]);

  return (
    <Styled.ImageSliderWrapper
      isPortal={isPortal}
      hasHeader={!!headerComponent}
      isMobile={isMobile}
      hasOperatorSelect={hasOperatorSelect}
    >
      {headerComponent && headerComponent}
      {!isLoading || isPortal ? (
        <ImageGallery
          ref={ref}
          items={items}
          startIndex={startIndex}
          renderItem={renderItem}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          renderPlayPauseButton={renderPlayPauseButton}
          renderFullscreenButton={renderFullscreenButton}
          showIndex
          lazyLoad
        />
      ) : (<ImageSliderSkeleton/>)}
    </Styled.ImageSliderWrapper>
  );
});

ImagesSlider.defaultProps = defaultProps;

export default ImagesSlider;
