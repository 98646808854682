const lineHeights: any = ['13px', '18px', '30px', '34px'];

lineHeights.caption = lineHeights[0];
lineHeights.body = lineHeights[1];
lineHeights.title = lineHeights[2];
lineHeights.heading = lineHeights[3];

export default {
  lineHeights,
};
