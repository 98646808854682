import styled from 'styled-components';

const OperatorLogo__Priority = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  position: absolute;
  left: -6px;
  top: -6px;
  background-color: ${({ theme }) => theme.colors.navy};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  font-size: 10px;
  z-index: 10;
  box-shadow: rgb(16 44 88 / 61%) 0 1px 4px 0px;
`;

const OperatorLogo__Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const OperatorLogo = styled.div<{ isSelected?: boolean, isPrioritized?: boolean, isClickable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  height: 30px;
  width: 48px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: ${({ isSelected }) => (!isSelected ? '0.5' : null)};
  transition: all 300ms ease 0s;

  ${({ isClickable }) => isClickable && `
    cursor: pointer;

    &:hover {
      box-shadow: rgb(199 209 215) 0px 0px 0px 4px;
    }
  `}

  ${OperatorLogo__Img} {
    filter: ${({ isSelected }) => (!isSelected ? 'grayscale(100%)' : null)};
  }
`;

export const Styled = {
  OperatorLogo,
  OperatorLogo__Priority,
  OperatorLogo__Img,
};
