import styled, { css } from 'styled-components';

const overlayBackground = css`
  background-color: rgb(0 0 0 / 25%);
`;

const ImageSlider = styled.div<{ height?: number, width?: number, onClick?: any }>`
  position: relative;
  width: ${({ width }) => `${width ? `${width}px` : '100%'}`};
  height: ${({ height }) => `${height ? `${height}px` : '100%'}`};

  ${({ onClick }) => onClick && `
    cursor: pointer;
  `}
`;

const ImageSlider__Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  ${overlayBackground}
`;

const ImageSlider__Item = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ImageSlider__Controls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const ImageSlider__Controls__Arrow = styled.div<{ direction: string, arrowStyle: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;

  bottom: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
  font-size: 14px;
  z-index: 10;

 ${({ arrowStyle, theme }) => (arrowStyle === 'light' ? `
    background: #fff;
    opacity: 1;
    box-shadow: ${theme.boxShadow[1]};
    border-radius: 100%;
    width: 36px;
    height: 36px;
    font-size: 14px;
    margin-top: -18px;
 ` : `
    width: 24px;
    height: 24px;
    font-size: 20px;
    color: #fff;
    margin-top: -12px;
 `)}

  ${({ direction }) => (direction === 'left' ? 'left: 10px;' : 'right: 10px;')};

  &:hover {
    opacity: 0.8;
  }
`;

export const Styled = {
  ImageSlider,
  ImageSlider__Overlay,
  ImageSlider__Item,
  ImageSlider__Controls,
  ImageSlider__Controls__Arrow,
};
