import { IDictionary } from '@ess/types';
import { has } from 'lodash-es';

const sourceLabel: IDictionary<string> = {
  S: 'static_price',
  O: 'online_price',
};

/**
 * Returns price source translation key if exists.
 * @param source
 */
const getPriceSource = (source: string) => (has(sourceLabel, source) ? sourceLabel[source] : source);

export default getPriceSource;
