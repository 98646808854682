import React, { useMemo } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { OptionTypeBase } from 'react-select';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusMinus } from '@fortawesome/pro-regular-svg-icons';

import { Styled } from './TripRange.styles';
import { toNumber } from 'lodash-es';

const options = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 7,
    label: 7,
  },
];

type TripRangeProps = {
  name: string
  label?: string
  placeholder?: string
  isLoading?: boolean
  disabled?: boolean
  handleChange?: (name: string, value: string | number) => void
}

const defaultProps = {
  label: '',
  isLoading: false,
  disabled: false,
  placeholder: undefined,
  handleChange: undefined,
};

const TripRange = ({
  name,
  handleChange,
}: TripRangeProps) => {
  const { t } = useTranslation();
  const { values: formikValues, setFieldValue } = useFormikContext<FormikValues>();

  /**
   * Option click handler.
   * @param option
   */
  const onClickHandler = (option: OptionTypeBase | null) => {
    const value = option !== null ? option.value : '';

    setFieldValue([name] as any, value);

    if (handleChange) {
      handleChange(name, value);
    }
  };

  return useMemo(() => (
    <Styled.TripRange>
      {options.map((option) => {
        const isSelected = toNumber(formikValues[name]) === option.value;

        return (
          <Styled.TripRange__Button
            key={option.label}
            isSelected={isSelected}
            onClick={() => onClickHandler(isSelected ? null : option)}
          >
            <FontAwesomeIcon icon={faPlusMinus} size="xs"/>
            <Styled.TripRange__Button__Text>
              {`${option.label} ${option.value === 1 ? t('day') : t('lbl_days')}`}
            </Styled.TripRange__Button__Text>
          </Styled.TripRange__Button>
        );
      })}
    </Styled.TripRange>
  ), [formikValues[name]]);
};

TripRange.defaultProps = defaultProps;

export default TripRange;
