import { useEffect, useState } from 'react';

const useRefElementSize = (ref:any):any => {
  const rc = ref.current;
  const computeheight = (element:Element) => {
    const { width, height } = element?.getBoundingClientRect() ?? { height: 0, width: 0 };
    return { width, height };
  };

  const [height, setHeight] = useState(rc ? computeheight(rc) : { height: 0, width: 0 });

  useEffect(() => {
    const rc = ref.current;

    if (!rc) {
      return () => false;
    }

    const requestID = window.requestAnimationFrame(() => setHeight(computeheight(rc)));

    const ro = new ResizeObserver((elem) => {
      window.requestAnimationFrame(() => setHeight(computeheight(elem[0].target)));
    });

    ro.observe(rc);

    return () => {
      cancelAnimationFrame(requestID);
      ro.unobserve(rc);
    };
  }, [rc]);
  return height;
};

export default useRefElementSize;
