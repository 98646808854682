import React, { useRef } from 'react';

import FlexBox from '@ess/ui/FlexBox';

import useRefElementSize from '@ess/hooks/useRefElementSize';

type FlexBoxHeightProps= {
    children:(height:number)=>any
}

const FlexBoxHeightChild = ({ children, forwardedRef }: FlexBoxHeightProps & { forwardedRef: any }) => {
  const { height, width } = useRefElementSize(forwardedRef);

  return (
    <div style={{
      height, width, position: 'absolute', overflow: 'hidden',
    }}
    >
      {children(height)}
    </div>
  );
};

const FlexBoxHeight = ({ children }: FlexBoxHeightProps) => {
  const ref = useRef(null);
  return (
    <FlexBox ref={ref} style={{ flexGrow: 1 }}>
      <FlexBoxHeightChild forwardedRef={ref}>{children}</FlexBoxHeightChild>
    </FlexBox>
  );
};

export default FlexBoxHeight;
