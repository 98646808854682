import React, {
  useContext, useEffect, useMemo, useState, memo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { IDictionary } from '@ess/types';

import { UNGROUPED_VIEW } from '@ess/constants/search';

import { useSelector } from '@ess/store/core';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

import { Button, ButtonGroup } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Switch from '@ess/ui/Switch';
import Alert from '@ess/ui/Alert';
import Text from '@ess/ui/Text';

import Offer from '@tourop/components/OfferList/Offer';

import AgencyAccessControl from '@tourop/components/AgencyAccessControl';
import Chart from './Chart';
import Table from './Table';

import getParsedData from './utils/getParsedData';

type OmnibusProps = {
  data: any
  showOffer?: boolean
  offerData?: any
}

enum OmnibusView {
  Chart = 'Chart',
  Table = 'Table',
  Analysis = 'Analysis',
}

const defaultProps = {
  showOffer: false,
  offerData: undefined,
};

const viewTypes: IDictionary<any> = {
  Chart: {
    label: 'lbl_chart',
    description: 'history_chart_description',
    type: OmnibusView.Chart,
    component: Chart,
  },
  Table: {
    label: 'lbl_table',
    description: 'history_table_description',
    type: OmnibusView.Table,
    component: Table,
  },
};

const Omnibus = ({ data, offerData, showOffer }: OmnibusProps) => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const { state: AppConfig } = useContext(AppConfigContext);
  const [isGrouped, setIsGrouped] = useState(true);
  const [viewType, setViewType] = useState(OmnibusView.Chart);
  const [selectedDot, setSelectedDot] = useState<string | null>(null);
  const parsedData = useMemo(() => getParsedData(data, isGrouped), [data, isGrouped]);
  const sfProtoHash = useSelector((state) => state.searchForm.protoHash);

  /**
   * Dot click handler.
   * @param selectedDate
   */
  const showPriceDetails = (selectedDate: string) => {
    setSelectedDot(selectedDate);

    if (selectedDate) {
      setViewType(OmnibusView.Table);

      trackEvent({
        event: 'chart',
        eventCategory: 'B2B_PRICE_HISTORY',
        eventAction: 'B2B_PRICE_DETAILS_CLICK',
      });
    }
  };

  /**
   * View type change handler.
   * @param type
   */
  const onViewTypeChange = (type: string) => {
    trackEvent({
      event: 'chart',
      eventCategory: 'B2B_PRICE_HISTORY',
      eventAction: 'B2B_CHANGE_VIEW',
      eventLabel: type,
    });

    setViewType(type as OmnibusView);
  };

  useEffect(() => {
    if (viewType !== OmnibusView.Table) {
      setSelectedDot(null);
      if (viewType === OmnibusView.Chart) {
        if (isGrouped && parsedData?.IsGrouped && parsedData?.Data?.length === 1) {
          setIsGrouped(false);
        }
      }
    } else {
      setIsGrouped(false);
    }
  }, [viewType]);

  return (
    <AgencyAccessControl moduleName="Omnibus">
      <FlexBox p="medium" flexDirection="column">
        {showOffer && offerData && (
        <FlexBox
          pb="medium"
          maxWidth="800px"
          width="100%"
        >
          <Offer
            item={offerData}
            rowIndex={0}
            details={false}
            showPrice={false}
            showControls={false}
            style={{
              marginBottom: 0,
            }}
            commonProps={{
              view: UNGROUPED_VIEW,
              type: AppConfig.type,
              sfProtoHash,
            }}
          />
        </FlexBox>
        )}
        <FlexBox
          flexDirection="column"
          width="100%"
        >
          <FlexBox
            width="100%"
            height="100%"
            borderRadius={6}
            position="relative"
            flexDirection="column"
            backgroundColor="white"
            style={{
              boxShadow: 'rgb(0 0 0 / 10%) 0 1px 4px 0px',
            }}
          >
            <FlexBox
              width="100%"
              justifyContent="space-between"
              alignItems="flex-start"
              pt="medium"
              px="medium"
            >
              <FlexBox width="30%" flexDirection="column">
                {parsedData?.Price ? Object.keys(parsedData.Price).map((type) => {
                  const { amount, currency } = parsedData.Price[type];

                  return (
                    <FlexBox key={type} mb="2px" width={320} justifyContent="space-between">
                      <Text mr="2px">{`${t(`lbl_omnibus_${type.toLowerCase()}_price`)}:`}</Text>
                      <Text fontWeight="bold" whiteSpace="nowrap">{`${Math.round(amount)} ${currency}`}</Text>
                    </FlexBox>
                  );
                }) : null}
              </FlexBox>
              <FlexBox width="40%" flexDirection="column" justifySelf="center" alignItems="center">
                {parsedData ? (
                  <>
                    <Text fontSize="title" pb="medium">
                      {t('lbl_price_change_history')}
                    </Text>
                    <ButtonGroup>
                      {Object.keys(viewTypes).map((type) => {
                        const view = viewTypes[type];

                        return view?.component ? (
                          <Button
                            key={view.type}
                            label={t(view.label)}
                            onClick={() => onViewTypeChange(view.type)}
                            variant={viewType === view.type ? 'primary' : 'secondary'}
                            size="small"
                          />
                        ) : null;
                      })}
                    </ButtonGroup>
                    <Alert severity="info" size="medium" mt="medium" showBorder={false}>{t(viewTypes[viewType].description)}</Alert>
                  </>
                ) : null}
              </FlexBox>
              <FlexBox width="30%" justifyContent="flex-end">
                {parsedData && (
                <FlexBox width="auto">
                  <Text mr="tiny">{`${t('lbl_grouped_by_price')}:`}</Text>
                  <Switch checked={isGrouped} onChange={(event) => setIsGrouped(event.target.checked)}/>
                </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox
              flexShrink={0}
              width="100%"
            >
              {parsedData && viewTypes[viewType]?.component ? React.createElement(viewTypes[viewType].component, {
                data: parsedData.Data,
                offerData,
                selectedDot,
                showPriceDetails,
              }) : (
                <FlexBox
                  width="100%"
                  height={650}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="darkGray">
                    {t('no_history_data')}
                  </Text>
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AgencyAccessControl>
  );
};

Omnibus.defaultProps = defaultProps;
Omnibus.displayName = 'Omnibus';

export default memo(Omnibus);
