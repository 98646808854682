import styled from 'styled-components';

const Summary = styled.div<{ stickyElementOffsetTop: number }>`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.space.medium};
  position: sticky;
  max-width: 450px;
  min-width: 300px;
  width: 100%;
  top:  ${({ stickyElementOffsetTop }) => `${stickyElementOffsetTop}px`};

  ${({ theme }) => `${theme.mediaQueries.lg} {
     max-width: 100%;
     padding-left: 0;
     padding-top: ${theme.space.medium};
  }`}
`;

const Summary__Room__Top = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: ${({ theme }) => theme.space.small};
  padding-right: 40px;
`;

const Summary__Room__Bottom = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.small};
`;

const Summary__Room__Description = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.small};
`;

const Summary__Room__Description__Edit = styled.div`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.space.small};
  color:  ${({ theme }) => theme.colors.textColor};

  &:hover {
    color:  ${({ theme }) => theme.colors.orange};
  }
`;

const Summary__Room = styled.div<{ editRoomMode?: boolean, isEditedRoom?: boolean, editDescription?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 10%) 0 1px 4px 0px;
  transition: all .15s ease-in;
  border-bottom: 1px solid #ddd;

  ${({ editDescription, theme }) => editDescription && `
    ${Summary__Room__Top} {
        padding-right: ${theme.space.small};
    }
  `}

  ${({ isEditedRoom, editRoomMode }) => editRoomMode && `
      opacity: ${isEditedRoom ? 1 : 0.3};

      ${isEditedRoom && `
        background-color: #ddf1ff;
        border: 1px solid #1a9fff;
        box-shadow: rgb(0 0 0 / 20%) 0px 10px 10px 0px;
        transform: scale(1.05);

        ${Summary__Room__Top} {
          border-color: #a6daff;
        }
      `}
  `}
`;

const Summary__Rooms = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Summary__Room}:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.small};
  }
`;

export const Styled = {
  Summary,
  Summary__Rooms,
  Summary__Room,
  Summary__Room__Top,
  Summary__Room__Bottom,
  Summary__Room__Description,
  Summary__Room__Description__Edit,
};
