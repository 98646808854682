import { useState, useEffect } from 'react';

/**
 * useKeyPress hook.
 * Returns true if given key was pressed.
 *
 * @param targetKey {string}
 * @param ref {ReactNode}
 * @returns keyPressed {boolean}
 */

const useKeyPress = (targetKey: string, ref: HTMLElement | null) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }: { key: string}) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }: { key: string}) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    if (ref) {
      ref.addEventListener('keydown', downHandler);
      ref.addEventListener('keyup', upHandler);

      return () => {
        ref.removeEventListener('keydown', downHandler);
        ref.removeEventListener('keyup', upHandler);
      };
    }

    return () => null;
  }, [ref]);

  return keyPressed;
};

export default useKeyPress;
