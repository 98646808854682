import React, { MouseEventHandler } from 'react';

import { Styled } from './ReplacedComponents.styles';

type NavButtonProps = {
  disabled?: boolean
  onClick?: MouseEventHandler
}

const defaultProps = {
  disabled: false,
  onClick: undefined,
};

const NextBtn = ({ disabled, onClick }: NavButtonProps) => (
  <Styled.NextBtn size="medium" icon="chevron-right" disabled={disabled} onClick={onClick}/>
);

const PrevBtn = ({ disabled, onClick }: NavButtonProps) => (
  <Styled.PrevBtn size="medium" icon="chevron-left" disabled={disabled} onClick={onClick}/>
);

NextBtn.defaultProps = defaultProps;
PrevBtn.defaultProps = defaultProps;

export {
  PrevBtn,
  NextBtn,
};
