import { createSlice } from '@reduxjs/toolkit';

import { IDictionary } from '@ess/types';

import getFieldsList from '@ess/utils/search/getFieldsList';

const regionName = 'Base.DestinationLocation';
const dayOfWeekName = 'Base.StartDateDayOfWeek';

const getFilterFieldsInitialValues = ({ fieldValues }: any) => {
  const { fieldList } = fieldValues;
  const initialValues: IDictionary<string> = {};

  fieldList.map((item: string) => {
    initialValues[item] = '';

    if (item === 'Base.StartDate') {
      initialValues[dayOfWeekName] = '';
    }
  });

  return initialValues;
};

const filtersFields = getFieldsList(
  {}, 'fieldValues', (item: string) => item !== regionName,
);

const initialFiltersValues = getFilterFieldsInitialValues(filtersFields);

const initialState = {
  fields: {},
  regions: {
    [regionName]: [],
  },
  operators: [],
  initialFetch: [],
  showFilters: true,
  referrer: undefined,
};

export const filtersForm = createSlice({
  name: 'filtersForm',
  initialState,
  reducers: {
    resetFilters: (state) => ({
      ...state,
      fields: initialFiltersValues,
      regions: {
        [regionName]: [],
      },
    }),
    setFilterValue: (state, action) => ({
      ...state,
      fields: {
        ...state.fields,
        [action.payload.name]: action.payload.value,
      },
    }),
    setOperatorsFilters: (state, action) => ({
      ...state,
      operators: action.payload,
    }),
    setRegionsFilters: (state, action) => ({
      ...state,
      regions: {
        ...state.regions,
        [regionName]: action.payload,
      },
    }),
    toggleFilters: (state) => ({
      ...state,
      showFilters: !state.showFilters,
    }),
    setReferrer: (state, action) => ({
      ...state,
      referrer: action.payload,
    }),
    expandFilters: (state, action) => ({
      ...state,
      showFilters: action.payload,
    }),
  },
});

export const {
  setRegionsFilters,
  setOperatorsFilters,
  resetFilters,
  toggleFilters,
  expandFilters,
  setFilterValue,
  setReferrer,
} = filtersForm.actions;
export default filtersForm.reducer;
