import {
  isEmpty,
  mergeWith,
  pick,
} from 'lodash-es';
import produce from 'immer';
import {
  IOffer,
  IOfferAccommodation,
  IOfferBase,
  IOfferTransport,
  Components,
} from '@ess/types';

import { offerMergeCustomizer } from '@ess/utils/offerData/offerMergeCustomizer';

import {
  conditionsSections,
  emptyIV5FieldStatus,
  IOnlineRequest,
  IV5EngineInternal,
  IV5FieldStatus,
  IV5OnlineOffer,
  IViewObject,
} from './interfaces';

export class OfferOnline implements IOffer {
  Base: IOfferBase;

  Accommodation: IOfferAccommodation;

  Transport: IOfferTransport;

  online: Components.Schemas.OfferOnline;

  parent: IViewObject;

  offer: OfferOnline;

  v5engine: IV5EngineInternal;

  mounted:boolean;

  hash:string;

  offerdata:any;

  constructor(data: IOffer | any) {
    this.offerdata = data.offer;
    this.parent = data.parent;
    this.v5engine = data.v5engine;
    this.offer = this;// for legacy code
    this.mounted = true;
    this.hash = this.offerdata.Base.OfferId;
    this.MergeWith(this.v5engine.getOfferOnlineData(this.parent, this.offerdata.Base.OfferId));
    this.Base = this.offerdata.Base;
    this.Transport = this.offerdata.Transport;
    this.Accommodation = this.offerdata.Accommodation;
    this.online = this.offerdata.Online;
  }

  /**
     * Get action type for specified fields.
     * @param fields List of fields
     */
  getActionsForFields(...fields: string[]) {
    return this.online ? this.online.actions.filter((a) => a.fieldList.some((f) => fields.includes(f))) : [];
  }

  /**
     * Check if field is gettable online
     * @param fields
     */
  isGettableOnline(...fields: string[]) {
    return this.getActionsForFields(...fields).length > 0;
  }

  mount(setOfferStatusUpdate:any) {
    this.v5engine.mountOffer(this, setOfferStatusUpdate);
  }

  unmount() {
    this.v5engine.unmountOffer(this);
  }

  /**
     * Call API function to get online field value
     * @param fields
     * @param force
     */

  sendOnlineRequest({ fields, force }: IOnlineRequest): IV5FieldStatus {
    const actions = this.getActionsForFields(...fields);
    if (actions.length === 1) {
      return this.v5engine.offerOnlineQueueRequest(this.parent, actions[0], this, force);
    }
    if (actions.length > 1) {
      const state = { ...emptyIV5FieldStatus, isReady: true };
      actions.forEach((v) => {
        const actionstate = this.v5engine.offerOnlineQueueRequest(this.parent, v, this);
        state.isLoading = actionstate.isLoading ? true : state.isLoading;
        state.isQueued = actionstate.isQueued ? true : state.isQueued;
        state.isError = actionstate.isError ? true : state.isError;
        state.isReady = !actionstate.isReady ? false : state.isReady;
      });
      return state;
    }
    return { ...emptyIV5FieldStatus };
  }

  async getWithOnlineResponse(fields: string[]): Promise<{offer:IV5OnlineOffer, status:IV5FieldStatus}> {
    const actions = this.getActionsForFields(...fields);
    return new Promise<{offer:IV5OnlineOffer, status:IV5FieldStatus}>(((resolve) => {
      this.v5engine.offerOnlineQueueRequest(this.parent, actions[0], this, true, false, (status:any) => {
        resolve({
          offer: new OfferOnline({ offer: this.offerdata, parent: this.parent, v5engine: this.v5engine }),
          status,
        });
      });
    }));
  }

  /**
     * Return status of field/Api call
     * @param fields
     */

  fieldStatus(...fields: string[]):IV5FieldStatus {
    const actions = this.getActionsForFields(...fields);
    if (actions.length === 1) {
      const state = this.v5engine.offerOnlineState(this.parent, actions[0], this, false);
      if (state !== null) {
        return state;
      }
    }
    if (actions.length > 1) {
      const state = { ...emptyIV5FieldStatus, isReady: true };
      let found = false;
      actions.forEach((v) => {
        const actionstate = this.v5engine.offerOnlineState(this.parent, v, this, false);
        if (actionstate === null) {
          return;
        }
        found = true;
        state.isLoading = actionstate.isLoading ? true : state.isLoading;
        state.isQueued = actionstate.isQueued ? true : state.isQueued;
        state.isError = actionstate.isError ? true : state.isError;
        state.isReady = !actionstate.isReady ? false : state.isReady;
      });
      if (found) {
        return state;
      }
    }
    return { ...emptyIV5FieldStatus };
  }

  getConditions(section:conditionsSections):any {
    return this.v5engine.getConditionsForViewObject(this.parent, section);
  }

  getActionFields(offerData: any, action: string) {
    const onlineAction = offerData.Online.actions.find((item: any) => item.action === action);
    const { fieldList = [] } = onlineAction;

    return fieldList.map((item: string) => item.replace('.*', ''));
  }

  MergeWith(onlineState:any) {
    let newhash = this.hash + this.parent.fieldList.join('');
    if (onlineState === null) {
      this.hash = newhash;
      return;
    }
    Object.entries(onlineState.actions).forEach(([key, value]:[any, any]) => {
      if (value.isReady) {
        newhash = newhash.concat('R');
      }
      if (value.isQueued) {
        newhash = newhash.concat('Q');
      }
      if (value.isLoading) {
        newhash = newhash.concat('L');
      }
      if (value.isError) {
        newhash = newhash.concat('E');
      }
      newhash = newhash.concat(key);
    });
    if (onlineState.hash !== newhash) {
      // eslint-disable-next-line no-param-reassign
      onlineState.hash = newhash;

      const offerData = (this.offerdata instanceof OfferOnline) ? this.offerdata.offerdata : this.offerdata;
      // eslint-disable-next-line no-param-reassign
      onlineState.offerdata = produce((offerData), (draft:any) => {
        Object.keys(onlineState.actions).map((action: string) => {
          const value = onlineState.actions[action];
          const actionFields = this.getActionFields(offerData, action);
          const { isReady, data } = value;

          if (!isReady) {
            return;
          }
          if (isEmpty(data)) {
            return;
          }

          const filteredData = pick(data, actionFields);

          mergeWith(draft, filteredData, offerMergeCustomizer);
        });
      });
    }
    this.hash = onlineState.hash;
    this.offerdata = onlineState.offerdata;
  }
}
