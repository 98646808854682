import styled from 'styled-components';

const Dropdown = styled.div<{ maxWidth?: string, maxHeight?: string, menuMaxWidth: number, menuMinWidth: number }>`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: auto;
    z-index: ${({ theme }) => 999999};
    min-width: ${({ menuMinWidth }) => `${menuMinWidth}px`};
    max-width: ${({ menuMaxWidth }) => `${menuMaxWidth}px`};
    padding: 5px 0;
    width: ${(props) => props.maxWidth};
    background-color: ${({ theme }) => theme.colors.white};
    max-height: ${(props) => props.maxHeight};
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
`;

const Dropdown__Item__Label = styled.div`
    padding: 0 10px;
`;

const Dropdown__Item__Icon = styled.div`
    display: flex;
    align-items:center;
    justify-content: center;
    flex-shrink: 0;
    line-height: 1;
    width: 36px;
`;

const Dropdown__Item = styled.div<{ disabled: boolean, hasIcons: boolean }>`
    display: flex;
    align-items: center;
    padding: 10px 0px;
    line-height: 1;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray : theme.colors.textColor)};

    ${({ disabled, theme }) => (!disabled && `
       &:hover {
        background-color: ${theme.colors.navy};
        color: ${theme.colors.white};
      }
    `)};

    ${({ hasIcons }) => hasIcons && `
        ${Dropdown__Item__Label} {
            padding-left: 0;
        }
    `}
`;

const Dropdown__Group = styled.div<{ isFirstGroup: boolean }>`
  ${({ isFirstGroup, theme }) => !isFirstGroup && `
     border-top: 1px solid ${theme.colors.lighterGray};
     margin-top: ${theme.space.tiny};
  `}
`;

const Dropdown__Group__Name = styled.div`
    padding: ${({ theme }) => theme.space.small};
    color: #99a1ab;
    font-size: 12px;
    white-space: nowrap;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`;

const Dropdown__Group__Items = styled.div`

`;
export const Styled = {
  Dropdown,
  Dropdown__Item,
  Dropdown__Item__Label,
  Dropdown__Item__Icon,
  Dropdown__Group,
  Dropdown__Group__Name,
  Dropdown__Group__Items,
};
