import { CHILD_CODE, INFANT_CODE } from '@ess/constants/participants';

export const EXCEPT_TYPE_CAMP: string = '_CAMP';

export const ageSettings = {
  [CHILD_CODE + EXCEPT_TYPE_CAMP]: {
    max: 26,
    min: 2,
  },
  [INFANT_CODE + EXCEPT_TYPE_CAMP]: {
    max: 2,
    min: 0,
  },
  [CHILD_CODE]: {
    max: 18,
    min: 0,
  },
  [INFANT_CODE]: {
    max: 2,
    min: 0,
  },
} as any;
