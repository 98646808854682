import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import React, { MouseEventHandler } from 'react';
import FlexBox from '../../FlexBox';
import Text from '../../Text';

type THotelMarker = {
    onClick?: MouseEventHandler<SVGSVGElement | HTMLDivElement>
    mainColor: string
    secondaryColor: string
    text: string
}

const defaultProps = {
  onClick: () => {},
};

const TextMarker = ({
  onClick, mainColor, secondaryColor, text,
}: THotelMarker) => (
  <FlexBox display="grid" style={{ gridColumn: '1fr', gridRow: '1fr' }}>
    <FontAwesomeIcon
      icon={faMapMarker}
      color={mainColor}
      size="3x"
      style={{ gridArea: '1 /1 / 1 / 1', cursor: 'pointer' }}
      onClick={onClick}
    />
    <FlexBox
      style={{ gridArea: '1 /1 / 1 / 1', cursor: 'pointer' }}
      justifyContent="center"
      width="min-content"
      height="min-content"
      m="4px auto"
    >
      <FlexBox onClick={onClick}>
        <Text
          color={secondaryColor}
          fontWeight="bold"
          textAlign="center"
          px="2px"
        >
          {text}
        </Text>
      </FlexBox>

    </FlexBox>
  </FlexBox>
);

TextMarker.defaultProps = defaultProps;

export default TextMarker;
