import styled from 'styled-components';

const TripRange__Button = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 26px;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.navy : theme.colors.navy)};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.navy : theme.colors.white)};
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.textColor)};
`;

const TripRange__Button__Text = styled.div`
  margin-left: ${({ theme }) => theme.space.tiny};
`;

const TripRange = styled.div`
  display: flex;
  flex-grow: 1;

  ${TripRange__Button}:not(:last-child) {
    margin-right: ${({ theme }) => theme.space.small};
  }
`;

export const Styled = {
  TripRange,
  TripRange__Button,
  TripRange__Button__Text,
};
