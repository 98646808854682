import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  has, isEmpty, isUndefined,
} from 'lodash-es';

import { UNGROUPED_VIEW } from '@ess/constants/search';

import { getTFG } from '@ess/utils/offerData/offerData';
import { offerHashGetterFunc } from '@ess/utils/protoHash/getOfferHashGetter';

import useContentWindow from '@ess/hooks/useContentWindow';
import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';
import { Sections } from '@ess/hooks/useOfferContentService';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import FlexBox from '@ess/ui/FlexBox';
import Loader from '@ess/ui/Loader';
import Text from '@ess/ui/Text';

import { TripAdvisorNote } from '@tourop/components/OfferElements';
import TransportDetails from '@tourop/components/OfferElements/TransportDetails';
import AgentAttributes from '@tourop/components/OfferElements/AgentAttributes';
import FeaturedOfferAttributes from '@tourop/components/OfferElements/OfferAttributes/FeaturedOfferAttributes';
import { HotelAttributesGroup } from '@tourop/components/OfferElements/HotelAttribute';
import TFGData from '@tourop/components/OfferElements/TFGData';

import OmnibusForOfferDetails from '@tourop/components/OfferList/Offer/OfferDetails/OmnibusForOfferDetails/OmnibusForOfferDetails';
import useOmnibusPrice from '@ess/hooks/useOmnibusPrice';
import { SwapBooking } from '@tourop/components/OfferElements/SwapBooking';

import { AlternativeDates } from '@tourop/components/OfferList/Offer/OfferDetails/AlternativeDates';
import { Styled } from '../Offer.styles';

import CampThemes from '../../../HotelDetails/CampThemes';

type OfferDetailsProps = {
  offerData: any
  transportOnly?: boolean
  isTooltip?: boolean
  swapBookingEnabled?: boolean
  onContentsChange?: () => void
  isMultiRoomMode?: boolean | undefined
  offerHashGetter: offerHashGetterFunc
  viewType: string
  searchType: string
}

const defaultProps = {
  offerLocation: 'ResultsPage',
  transportOnly: false,
  onContentsChange: undefined,
  isMultiRoomMode: false,
  isTooltip: false,
  swapBookingEnabled: true,
};

const OfferDetails = ({
  offerData,
  isTooltip,
  transportOnly,
  isMultiRoomMode,
  offerHashGetter,
  swapBookingEnabled,
  onContentsChange,
  viewType,
  searchType,
}: OfferDetailsProps) => {
  const { t } = useTranslation();
  const { Base, Accommodation } = offerData;
  const {
    Catalog, Price, Operator, Omnibus,
  } = Base;
  const omnibusPrice = useOmnibusPrice({ url: Omnibus?.URL as string, disabled: isMultiRoomMode });
  const transportDataStatus = typeof offerData?.fieldStatus === 'function' ? offerData.fieldStatus('Transport.*') : {};
  const tfg = getTFG(Price);
  const campThemes = Accommodation?.Camp?.Theme;
  const { state: { type: currentSearchType } } = useContext(AppConfigContext);
  const showCampThemes = currentSearchType === 'camp' && !isEmpty(campThemes);
  const { openContentWindow } = useContentWindow(searchType);
  const { trackEvent } = useGoogleAnalytics();

  const openHotelInfoWindow = (value: string): void => {
    openContentWindow({
      offerHash: offerHashGetter(),
      page: value,
    });
    trackEvent({
      event: 'HotelDetailsMenu',
      eventCategory: 'B2B_CLICK_EVENT',
      eventAction: 'B2B_HOTEL_DETAILS_MENU',
      eventLabel: value,
    });
  };

  const { state: SFContext } = useContext(AppConfigContext);
  const { configOper } = SFContext;
  const operatorConfig = has(configOper, Operator as string) ? configOper[Operator as string] : configOper?.default ?? {};
  const hasBookingForOfferChange = has(configOper, Operator as string) ? configOper[`${Operator}`].hasBookingForOfferChange : false;

  useEffect(() => {
    if (onContentsChange && omnibusPrice?.data) {
      onContentsChange();
    }
  }, [onContentsChange, omnibusPrice]);

  const accommodationArray = Accommodation && !isMultiRoomMode ? [
    {
      key: 'maintenance',
      label: 'lbl_maintenance',
      desc: `${Accommodation?.Service?.Name}${Accommodation?.Service?.Id ? ` (${Accommodation?.Service?.Id})` : ''}`,
    },
    {
      key: 'accommodation',
      label: 'lbl_accommodation',
      desc: `${Accommodation?.Room?.Name}${Accommodation?.Room?.Id ? ` (${Accommodation?.Room?.Id})` : ''}`,
    },
    {
      key: 'accommodationCount',
      label: 'hotel_places_header',
      desc: operatorConfig?.showHotelPlaces && Accommodation?.AvailableRoomsCount
        ? Accommodation?.AvailableRoomsCount
        : '',
    },
  ] : [];

  const detailsArray = [
    {
      key: 'tfg',
      label: 'lbl_tfg',
      desc: tfg !== undefined ? <TFGData price={Price.FirstPerson}/> : null,
    },
    {
      key: 'catalog',
      label: 'lbl_catalog',
      desc: Catalog ?? '',
    },
  ];

  const data = [
    ...accommodationArray,
    ...detailsArray,
  ];

  return (
    <Styled.Offer__Details transportOnly={transportOnly}>
      {transportOnly ? (
        <FlexBox p="small" width="100%">
          <TransportDetails isTooltip={isTooltip} offerData={offerData}/>
        </FlexBox>
      ) : (
        <>
          {transportDataStatus?.isLoading && (
            <FlexBox
              alignItems="center"
              justifyContent="center"
              backgroundColor="lightGray"
              height="30px"
            >
              <Loader type="dots" size="20px" color="gray"/>
              <Text fontSize="12px" ml="small" color="darkGray">{t('check_transport_wait')}</Text>
            </FlexBox>
          )}
          <Styled.Offer__Details__Content>
            <TransportDetails offerData={offerData}/>

            {data?.filter(({ desc }) => desc)?.length && (
              <>
                <Text fontSize="14px" fontWeight="bold" mb="small">
                  {Accommodation ? t('lbl_accomodation_details') : t('lbl_details_page')}
                </Text>
                {data.filter(({ desc }) => desc).map(({ key, label, desc }) => (
                  <Styled.Offer__Detail key={key}>
                    <Styled.Offer__Detail__Label>
                      {`${t(label as string)}:`}
                    </Styled.Offer__Detail__Label>
                    <Styled.Offer__Detail__Desc>
                      {desc}
                    </Styled.Offer__Detail__Desc>
                  </Styled.Offer__Detail>
                ))}
              </>
            )}

            {(viewType === UNGROUPED_VIEW) && Accommodation?.ExtTripAdvisor?.image && (
            <Styled.Offer__Detail>
              <Styled.Offer__Detail__Label>
                {t('lbl_tripad')}
              </Styled.Offer__Detail__Label>
              <Styled.Offer__Detail__Desc>
                <TripAdvisorNote data={Accommodation.ExtTripAdvisor} onClick={() => openHotelInfoWindow(Sections.TripAdvisor)} />
              </Styled.Offer__Detail__Desc>
            </Styled.Offer__Detail>
            )}
            {(viewType === UNGROUPED_VIEW) && showCampThemes && Accommodation?.Camp?.Theme && (
              <Styled.Offer__Detail >
                <Styled.Offer__Detail__Label>
                  {t('lbl_camp_themes')}
                </Styled.Offer__Detail__Label>
                <Styled.Offer__Detail__Desc>
                  <CampThemes campThemesList={Accommodation?.Camp?.Theme}/>
                </Styled.Offer__Detail__Desc>
              </Styled.Offer__Detail>
            )}
            {(viewType === UNGROUPED_VIEW) && Accommodation?.Attributes && (
            <Styled.Offer__Detail >
              <Styled.Offer__Detail__Label>
                {t('lbl_attributes')}
              </Styled.Offer__Detail__Label>
              <Styled.Offer__Detail__Desc>
                <HotelAttributesGroup attributes={
                  (!isUndefined(Accommodation) && !isUndefined(Accommodation.Attributes)) ? Accommodation.Attributes : []
                }
                />
              </Styled.Offer__Detail__Desc>
            </Styled.Offer__Detail>
            )}
            {(viewType === UNGROUPED_VIEW) && Accommodation?.ExtAgentAttributes && (
              <Styled.Offer__Detail>
                <Styled.Offer__Detail__Label>
                  {t('lbl_ext_agent_attribute')}
                </Styled.Offer__Detail__Label>
                <Styled.Offer__Detail__Desc>
                  <AgentAttributes
                    offerHash={offerHashGetter()}
                    attributes={Accommodation.ExtAgentAttributes}
                  />
                </Styled.Offer__Detail__Desc>
              </Styled.Offer__Detail>
            )}
            <FeaturedOfferAttributes offerData={offerData} title={t('lbl_offer_attributes')} />
            {!omnibusPrice.isLoading && !omnibusPrice.isError && omnibusPrice?.data ? (
              <FlexBox>
                <OmnibusForOfferDetails omnibus={omnibusPrice.data}/>
              </FlexBox>
            ) : null}
            {hasBookingForOfferChange && swapBookingEnabled && (
              <Styled.Offer__Detail key="swapBooking" paddingTop="10px">
                <SwapBooking
                  offerData={offerData}
                  offerHashGetter={offerHashGetter}
                  searchType={searchType}
                />
              </Styled.Offer__Detail>
            )}

            <AlternativeDates
              offerData={offerData}
              onChange={onContentsChange}
            />
          </Styled.Offer__Details__Content>
        </>
      )}
    </Styled.Offer__Details>
  );
};

OfferDetails.defaultProps = defaultProps;

export default OfferDetails;
