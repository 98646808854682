import styled from 'styled-components';
import { space } from 'styled-system';

const Loader = styled.svg<{ size: string, color: string }>`
    width: ${(props) => props.size};
    height: ${(props) => props.size}; 
    stroke: ${(props) => props.theme.colors[props.color]};
    fill: ${(props) => props.theme.colors[props.color]};
    ${space}
`;

export const Styled = {
  Loader,
};
