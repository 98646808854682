/**
 * Indicates if rooms are the same.
 * @param roomA
 * @param roomB
 */
const isEqualRooms = (roomA: any, roomB: any) => {
  const { Offer: roomAOffer } = roomA;
  const { Offer: roomBOffer } = roomB;

  const roomAId = `${roomAOffer.Accommodation.Room.Id}_${roomAOffer.Accommodation.Service.Id}_${roomAOffer.Base.Transfer}`;
  const roomBId = `${roomBOffer.Accommodation.Room.Id}_${roomBOffer.Accommodation.Service.Id}_${roomBOffer.Base.Transfer}`;

  return roomAId === roomBId;
};

export {
  isEqualRooms,
};
