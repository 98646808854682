import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  find,
  includes, range, toNumber, toString, uniq,
} from 'lodash-es';
import moment, { Moment } from 'moment';

import { IDictionary } from '@ess/types';

import { ageSettings } from '@tourop/config/searchForm/birthdate';

import { ADULT_CODE, CHILD_CODE, INFANT_CODE } from '@ess/constants/participants';
import { DATE_REQUEST_FORMAT } from '@ess/constants/api';

import useOnClickOutside from '@ess/hooks/useOnClickOutside';

import Field from '@ess/ui/Form/Field';
import { Col, Row } from '@ess/ui/FlexGrid';
import { Button } from '@ess/ui/Button';
import { Modal } from '@ess/ui/Modal/ModalsTypes';
import FlexBox from '@ess/ui/FlexBox';
import Select from '@ess/ui/Form/Select';
import Text from '@ess/ui/Text';
import TextInput from '@ess/ui/Form/TextInput';
import Alert from '@ess/ui/Alert';

type ParticipantsModalProps = {
  isOpen: boolean
  showRoomsCount?: boolean
  room: any
  participants: any
  onClose: any
  onApply: any
  selected: any
}

const participantsTranslations: IDictionary<string> = {
  Adult: ADULT_CODE,
  Child: CHILD_CODE,
  Infants: INFANT_CODE,
};

const participantsWithDates = ['Child', 'Infants'];

const getParticipantsCount = (pax: any) => {
  let total = 0;

  Object.keys(pax).map((item) => {
    if (item !== 'Infants') {
      total += pax[item].value;
    }
  });

  return total;
};

const isValidGroup = (group: any, offerPax: any) => {
  const error = {
    birthDates: [] as string[],
    pax: [] as any,
  };

  const datesValidation = (personId: string, dates: string[]) => {
    if (dates?.length) {
      dates.map((date: string, index: number) => {
        const fieldId = `${personId}_${index}`;

        if (!date) {
          error.birthDates.push((fieldId));
        } else {
          error.birthDates.filter((person) => person !== fieldId);
        }
      });
    }
  };

  group.map((item: any, roomIndex: number) => {
    const { pax } = item;
    const total = getParticipantsCount(pax);

    if (total > offerPax.Max || total < offerPax.Min) {
      error.pax.push({ roomIndex, offerPax });
    } else {
      error.pax.filter((paxItem: any) => paxItem.roomIndex !== roomIndex);
    }

    Object.keys(pax).filter((personType) => personType === 'Child').map((personType, index) => {
      const { dates } = pax[personType];
      const personId = `${roomIndex}_${personType}`;

      datesValidation(personId, dates);
    });
  });

  return error;
};

const defaultProps = {
  showRoomsCount: true,
};

const ParticipantsModal = ({
  isOpen,
  room,
  participants,
  showRoomsCount,
  selected,
  onClose,
  onApply,
}: ParticipantsModalProps) => {
  const { t } = useTranslation();
  const [roomsCount, setRoomsCount] = useState(1);
  const [group, setGroup] = useState<any>([]);
  const [modalElement, setModalElement] = useState<HTMLElement | null>(null);
  const [birthDatesError, setBirthDatesError] = useState<string[]>([]);
  const [paxError, setPaxError] = useState<string[]>([]);
  const [birthDatePickerOpen, setBirthDatePickerOpen] = useState(false);

  const defaultPax = useMemo(() => ({
    description: '',
    pax: {
      Adult: {
        value: room.Adult.Min,
      },
      Child: {
        value: room.Child.Min,
        dates: room.Child.Min
          ? Array.from({ length: room.Child.Min }).map((_) => (''))
          : [],
      },
    },
  }), [room]);

  const onBirthDatePickerFocusHandler = (isFocused: boolean) => {
    setBirthDatePickerOpen(isFocused);
  };

  /**
   * Returns room count select options.
   * @param remaining
   * @param offer
   */
  const getRoomCountOptions = (remaining: any, offer: any) => {
    const maxRooms = Math.floor(remaining.Adult / offer.Adult.Min);

    const rangeArray = range(1, maxRooms + 1, 1);
    const options = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rangeArray.length; i++) {
      const value = rangeArray[i];

      options.push({
        label: toString(value),
        value: toString(value),
      });
    }

    return options;
  };

  /**
   * Returns participants select options.
   * @param personType
   * @param currentValue
   * @param remaining
   * @param isSelected
   */
  const getParticipantsOptions = (personType: any, currentValue: any, remaining: any, isSelected: boolean) => {
    const rangeArray = range(room[personType].Min, room[personType].Max + 1, 1);
    const options = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rangeArray.length; i++) {
      const value = rangeArray[i];
      const isDisabled = !isSelected ? value > remaining[personType] : value > currentValue + remaining[personType];

      options.push({
        label: toString(value),
        value: toString(value),
        disabled: isDisabled,
      });
    }

    return options;
  };

  const onApplyHandler = useCallback(() => {
    const error = isValidGroup(group, room.Pax);

    if (error.birthDates.length || error.pax.length) {
      setBirthDatesError(error.birthDates);
      setPaxError(error.pax);
    } else {
      setBirthDatesError([]);
      setPaxError([]);
      onApply(room, group);
    }
  }, [group]);

  const onChangeHandler = (selectedOption: any, personType: string, roomIndex: number) => {
    setGroup((state: any) => (
      state.map((roomPax: any, i: number) => (i === roomIndex ? ({
        ...roomPax,
        pax: {
          ...roomPax.pax,
          [personType]: {
            ...roomPax.pax[personType],
            value: toNumber(selectedOption.value),
            dates: participantsWithDates.indexOf(personType) > -1
              ? Array.from({ length: toNumber(selectedOption.value) })
                .map((item, j) => roomPax.pax[personType].dates?.[j] || '') : [],
          },
        },
      }) : roomPax))
    ));
  };

  const onCloseHandler = () => {
    setBirthDatesError([]);
    setPaxError([]);
    onClose();
  };

  const onInputDateChange = useCallback((selectedDate: any, dateId: string, roomIndex: number) => {
    const isValidDate = moment(selectedDate, DATE_REQUEST_FORMAT).isValid();
    const fieldId = `${roomIndex}_${dateId}`;
    const [personType, personIndex] = dateId.split('_');

    if (isValidDate) {
      const birthdate = moment(selectedDate, DATE_REQUEST_FORMAT).format(DATE_REQUEST_FORMAT);

      setGroup((state: any) => (
        state.map((roomPax: any, i: number) => (i === roomIndex ? {
          ...roomPax,
          pax: {
            ...roomPax.pax,
            [personType]: {
              ...roomPax.pax[personType],
              dates: roomPax.pax[personType]?.dates.length
                ? roomPax.pax[personType].dates.map((date: string, j: number) => (j === toNumber(personIndex)
                  ? birthdate : date
                )) : [],
            },
          },

        } : roomPax))
      ));

      setBirthDatesError((state) => [...state].filter((item) => item !== fieldId));
    } else {
      setBirthDatesError((state) => uniq([...state, fieldId]));
    }
  }, [birthDatesError]);

  const descriptionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, roomIndex: number) => {
    setGroup((state: any) => (
      state.map((roomPax: any, i: number) => (i === roomIndex ? ({
        ...roomPax,
        description: event.target.value,
      }) : roomPax))
    ));
  };

  const onRoomCountChangeHandler = (selectedOption: any) => {
    const { value } = selectedOption;
    const newPax: any[] = [];

    setRoomsCount(value);

    Array.from({ length: toNumber(value) }).map((_, index) => {
      newPax.push(group[index] ?? defaultPax);
    });

    setBirthDatesError([]);
    setGroup(newPax);
  };

  const changeParticipantBirthDate = (selectedDate: Moment, person: string, roomIndex: number) => {
    const [personType, personIndex] = person.split('_');
    const birthdate = selectedDate.format(DATE_REQUEST_FORMAT);

    setBirthDatesError((state) => [...state].filter((item) => item !== `${roomIndex}_${person}`));

    setGroup((state: any) => (
      state.map((roomPax: any, i: number) => (i === roomIndex ? {
        ...roomPax,
        pax: {
          ...roomPax.pax,
          [personType]: {
            ...roomPax.pax[personType],
            dates: roomPax.pax[personType]?.dates.length
              ? roomPax.pax[personType].dates.map((date: string, j: number) => (j === toNumber(personIndex)
                ? birthdate : date
              )) : [],
          },
        },

      } : roomPax))
    ));
  };

  useOnClickOutside(modalElement, () => onCloseHandler(), [], !birthDatePickerOpen);

  useEffect(() => {
    const hasSelectedItems = selected.length > 0;
    const roomsCount = hasSelectedItems ? selected.length : 1;
    const group = hasSelectedItems ? selected.map((item: any) => ({ ...item.Group })) : [defaultPax];

    setRoomsCount(roomsCount);
    setGroup(group);
  }, [room, selected]);

  return (
    <Modal
      ref={setModalElement as any}
      title={room.Offer.Accommodation.Room.Name}
      isOpen={isOpen}
      onClose={onCloseHandler}
      controls={(
        <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
          <Button
            variant="secondary"
            label={t('lbl_cancel')}
            size="small"
            mr="small"
            width="90px"
            onClick={onCloseHandler}
          />
          <Button
            variant="primary"
            label={t('lbl_apply')}
            size="small"
            width="90px"
            onClick={onApplyHandler}
          />
        </FlexBox>
      )}
    >
      {showRoomsCount && (
        <FlexBox p="small" flexDirection="column">
          <FlexBox mb="2px">{`${t('rooms_count')}:`}</FlexBox>
          <FlexBox width="100%">
            <Select
              value={roomsCount}
              options={getRoomCountOptions(participants, room)}
              onChange={onRoomCountChangeHandler}
              isClearable={false}
            />
          </FlexBox>
        </FlexBox>
      )}
      <FlexBox width="100%" flexDirection="column">
        {group.map((item: any, roomIndex: number) => {
          const moreThanOneGroup = group.length > 1;
          const personIndex = roomIndex + 1;
          const invalidPaxError = find(paxError, (o: any) => o.roomIndex === roomIndex);

          return (
            <FlexBox
              key={`group_${roomIndex}`}
              width="100%"
              flexDirection="column"
              style={{
                borderTop: moreThanOneGroup ? '1px solid #d5d5d5' : 'none',
              }}
            >
              <FlexBox p="small" flexDirection="column">
                {moreThanOneGroup ? <Text fontWeight="bold" mb="tiny">{`${t('room')} ${personIndex}:`}</Text> : null}
                {invalidPaxError && (
                  <Alert severity="error" mb="medium">
                    {t('multiroom_pax_error', {
                      count: room.Pax.Max === room.Pax.Min ? room.Pax.Max : `${room.Pax.Min}-${room.Pax.Max}`,
                    })}
                  </Alert>
                )}
                <FlexBox flexDirection="column" mb="small">
                  <Text mb="tiny">{`${t('group_description')}:`}</Text>
                  <TextInput
                    value={item.description}
                    onChange={(event) => descriptionChangeHandler(event, roomIndex)}
                  />
                </FlexBox>

                <FlexBox width="100%">
                  {Object.keys(item.pax).map((personType: any, index: number) => {
                    const options = getParticipantsOptions(
                      personType, item.pax[personType].value, participants, selected.length > 0,
                    );
                    const isLastChild = Object.keys(item.pax).length - 1 === index;

                    return (
                      <FlexBox
                        key={`${personType}_${index}_${roomIndex}`}
                        flexGrow={1}
                        flexDirection="column"
                        mr={isLastChild ? null : 'small'}
                      >
                        <FlexBox
                          mb="2px"
                        >
                          {`${t(`lbl_${participantsTranslations[personType].toLowerCase()}`)}:`}
                        </FlexBox>
                        <FlexBox width="100%">
                          <Select
                            value={item.pax[personType].value}
                            isDisabled={!options.length || (options.length < 2 && toNumber(options[0].value) === 0)}
                            options={options}
                            onChange={(selectedOption) => onChangeHandler(selectedOption, personType, roomIndex)}
                            isClearable={false}
                          />
                        </FlexBox>
                      </FlexBox>
                    );
                  })}
                </FlexBox>
                {Object.keys(item.pax).map((personType: any) => {
                  if (includes(participantsWithDates, personType) && item.pax[personType].value) {
                    const personCode = personType === 'Child' ? CHILD_CODE : INFANT_CODE;
                    const minDate = moment().subtract(ageSettings[personCode].max, 'y');
                    const maxDate = moment().subtract(ageSettings[personCode].min, 'y');

                    return (
                      <FlexBox key={personType} mt="medium">
                        <Row gapX={5}>
                          {Array.from({ length: item.pax[personType].value })
                            .map((_, personIndex) => {
                              const dateId = `${personType}_${personIndex}`;
                              const label = t(`lbl_${participantsTranslations[personType].toLowerCase()}`);
                              const dateValue = item.pax[personType].dates[personIndex]
                                ? moment(item.pax[personType].dates[personIndex], DATE_REQUEST_FORMAT)
                                : null;

                              return (
                                <Col key={`${personType}_${personIndex}`} width="50%">
                                  <Text>{`${label} ${personIndex + 1}:`}</Text>
                                  <Field.BirthDatePicker
                                    dateId={dateId}
                                    errorDisplay="inline"
                                    error={includes(birthDatesError, `${roomIndex}_${dateId}`) && t('lbl_provide_birthdate')}
                                    personType={item === 'Child' ? CHILD_CODE : INFANT_CODE}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    dateValue={dateValue}
                                    dateInputValue={item.pax[personType].dates[personIndex]}
                                    onBlur={(selectedDate: Moment) => onInputDateChange(selectedDate, `${personType}_${personIndex}`, roomIndex)}
                                    onInputDateChange={(selectedDate: Moment) => onInputDateChange(selectedDate, `${personType}_${personIndex}`, roomIndex)}
                                    onCalendarChange={(selectedDate: Moment) => changeParticipantBirthDate(selectedDate, `${personType}_${personIndex}`, roomIndex)}
                                    datePlaceholder={t('lbl_birthdate')}
                                    onFocus={onBirthDatePickerFocusHandler}
                                  />
                                </Col>
                              );
                            })}
                        </Row>
                      </FlexBox>
                    );
                  }
                  return null;
                })}
              </FlexBox>
            </FlexBox>
          );
        })}
      </FlexBox>
    </Modal>
  );
};

ParticipantsModal.defaultProps = defaultProps;

export default ParticipantsModal;
