import React, {
  forwardRef, useEffect, useState, memo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FieldError } from '@ess/types';

import Text from '@ess/ui/Text';

import { Styled } from './TextArea.styles';

export type TextAreaProps = {
  name?: string
  value?: any
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onClick?: (event: React.PointerEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: React.FocusEvent) => void
  onFocus?: (event: React.FocusEvent) => void
  placeholder?: string
  error?: FieldError
  readOnly?: boolean
  maxLength?: number
  style?: React.CSSProperties
};

const defaultProps = {
  name: '',
  value: '',
  onChange: undefined,
  onBlur: undefined,
  onClick: undefined,
  onFocus: undefined,
  placeholder: '',
  error: '',
  readOnly: false,
  maxLength: undefined,
  style: undefined,
};

const TextInput = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
  name,
  value,
  onChange,
  onClick,
  onBlur,
  onFocus,
  placeholder,
  readOnly,
  error,
  maxLength,
  ...props
}, ref) => {
  const { t } = useTranslation();
  const [textAreaValue, setTextAreaValue] = useState<any>('');
  const hasError: any = error && error.length;

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setTextAreaValue(event.target.value);

    if (onChange) {
      onChange(event);
    }
  };

  const clickHandler = (event: React.PointerEvent<HTMLTextAreaElement>): void => {
    if (onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    setTextAreaValue(value || '');
  }, [value]);

  return (
    <Styled.TextArea__Wrapper>
      <Styled.TextArea
        ref={ref}
        hasError={hasError}
        onChange={changeHandler}
        onClick={clickHandler}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        value={textAreaValue}
        {...{ ...name ? { name, id: name } : {} }}
        {...{ ...placeholder ? { placeholder } : {} }}
        {...{ ...maxLength ? { maxLength } : {} }}
        {...props}
      />
      {hasError && (<Text fontSize="12px" my="2px" color="errorRed">{t(error as string)}</Text>)}
    </Styled.TextArea__Wrapper>

  );
});

TextInput.defaultProps = defaultProps;

export default memo(TextInput);
