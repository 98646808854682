import React from 'react';
import { useTranslation } from 'react-i18next';

import { IOption } from '@ess/types';

import Select from '@ess/ui/Form/Select';

type ServiceSelectProps = {
  onChange: (value: string) => void
  options: IOption[]
  value: string | null
}

const ServiceSelect = ({ onChange, options, value }: ServiceSelectProps) => {
  const { t } = useTranslation();

  /**
   * Select value change handler.
   * @param selected
   */
  const onChangeHandler = (selected: any) => {
    onChange(selected?.value);
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={onChangeHandler}
      isClearable
      placeholder={t('maintenance')}
    />
  );
};

export default ServiceSelect;
