import React from 'react';
import { components } from 'react-select';
import { isMobileOnly } from 'react-device-detect';

import Drawer from '../../../../Drawer';
import FlexBox from '../../../../FlexBox';

const Menu = ({
  children,
  ...props
}: any) => {

  const close = () => {
    if (props.selectProps.passRef.current) {
      props.selectProps.passRef.current.blur();
    }
  };

  return (
    isMobileOnly
      ? (
        <FlexBox onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        >
          <Drawer
            top={0}
            position="bottom"
            showOverlay
            title={props.selectProps.label}
            isOpen
            onClose={close}
            fixHeight
            clickOutsideIgnoreElements={[props.selectProps.passRef.current.select.controlRef]}
          >
            <components.MenuList {...props}>
              {children}
            </components.MenuList>
          </Drawer>
        </FlexBox>
      ) : (
        <components.Menu {...props}>
          {children}
        </components.Menu>
      )
  );
};

export default Menu;
