import { createGlobalStyle } from 'styled-components';

import CSSVariables from "./CSSVariables";
import Normalize from './Normalize';
import Tooltip from './Tooltip';
import Toastify from './Toastify';
import DatePicker from './DatePicker';

const GlobalStyle = createGlobalStyle`
  ${CSSVariables}
  ${Normalize}
  html {
    height: 100%;
      &.search-results-page {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        body {
          position: absolute;
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
        }
    }
  }

  body {
    font-family: ${({ theme }) => theme.fontFamily.base};
    font-size: ${({ theme }) => theme.fontSizes.body};
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.textColor};
    min-height: 100%;
    min-width: 320px;
    height: 100%;
    padding-bottom: 0 !important;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    line-height: 1.428571429;
    cursor: default;

    p {
      margin: 0;
      line-height: 1.5rem;
    }
  }

  .search-results-page body {
    overflow: hidden !important;
  }

  #react-root, #root {
    display: flex;
    flex-direction: column;
    height: 100%;

    input, select {
     height: auto;
    }
  }

  ${({ theme }) => `${theme.mediaQueries.md} {
     #main {
       height: calc(100% - 52px);
     }
  }`}

  /* Google maps autocomplete */

  .pac-container {
    z-index: ${({ theme }) => theme.zIndex.modal};
  }

  .clear-styles * {margin: 0; font-size: inherit !important; }

  .autosuggest input {width: 100% !important} /* https://github.com/JedWatson/react-input-autosize/issues/73 */

  .mx-custom-option:hover {
    background-color: #102c58 !important;
    color: #fff !important;
  }

  .mx-custom-option.mx-react-select__option--is-focused {
    background: none;
    color: #102c58;
  }

  .mx-custom-option.mx-react-select__option--is-selected {
    background-color: #f1f3f4;
    color: #102c58;
  }

  .mx-react-select__input input {
    height: auto;
  }

  ${Tooltip}
  ${Toastify}
  ${DatePicker}
`;

export { GlobalStyle };
