import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextInput from '@ess/ui/Form/TextInput';
import { AppConfigContext } from '@ess/context/AppConfigContext';

export interface IValues {
  amount: number,
  key: string
}

type FieldProps = {
  onClick: () => void
  onClear: () => void
  isClearable: boolean
  values: IValues[]
}

const Field = ({
  values,
  isClearable,
  onClear,
  onClick,
}: FieldProps) => {
  const { t } = useTranslation();
  const { state: AppConfig } = useContext(AppConfigContext);
  const { type } = AppConfig;
  const [value, setValue] = useState('');

  useEffect(() => {
    const correctValues = type === 'camp' ? values.filter((item:any) => item.key !== 'adult') : values;
    const parsedValue = correctValues.map((item) => {
      const label = t(`${item.key}_interval`, {
        postProcess: 'interval',
        count: item.amount,
      });
      return `${item.amount} ${label.toLowerCase()}`;
    });
    setValue(parsedValue.join(' • '));
  }, [values]);

  return (
    <TextInput
      value={value}
      isClearable={isClearable}
      onClear={onClear}
      readOnly
      unControlled
      onClick={onClick}
    />
  );
};

export default Field;
