import React, {
  CSSProperties,
  memo, useCallback, useContext, useEffect, useRef,
} from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import {
  has, includes, isArray,
} from 'lodash-es';

import {
  IOfferBase,
  IOfferBaseAvailability,
  IResponseListItem,
  TOfferPrice,
} from '@ess/types';

import { OWN_TRANSPORT } from '@ess/constants/transport';
import { UNGROUPED_VIEW } from '@ess/constants/search';
import { STATUS_DESCRIPTIONS, STATUS_TYPES } from '@ess/constants/status';

import { getTFG, getTransportType } from '@ess/utils/offerData/offerData';
import getOfferHashGetter from '@ess/utils/protoHash/getOfferHashGetter';
import showToast, { TOAST_ERROR, TOAST_SUCCESS } from '@ess/utils/form/ShowToast';
import objectToURLParams from '@ess/utils/objectToURLParams';

import { useV5OnlineOffer } from '@ess/v5-data-provider/components';
import { useSearchConditionsParticipants } from '@ess/v5-data-provider/mwsfunc/hooks';

import { Breakpoints } from '@ess/hooks/useBreakpoint';
import useAgentSettings from '@ess/hooks/useAgentSettings';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import FlexBox from '@ess/ui/FlexBox';
import Tooltip from '@ess/ui/Tooltip';
import Chip from '@ess/ui/Chip';
import Anchor from '@ess/ui/Anchor';

import MultiRoomPreview from '@tourop/components/OfferList/Offer/MultiRoomPreview';

import { OfferAttributesTags } from '@tourop/components/OfferElements/OfferAttributes';
import OfferStatus from '@tourop/components/OfferElements/OfferStatus';
import OperatorLogo from '@tourop/components/OfferElements/OperatorLogo';

import { OfferAttributesTagsFunc } from '@tourop/components/OfferElements/OfferAttributes/OfferAttributesTags';

import TransportIcon from '@tourop/components/OfferList/Offer/OfferElements/Transport';
import DateWithDuration from '@tourop/components/OfferList/Offer/OfferElements/DateWithDuration';
import ServiceMRFInformation from '@tourop/components/OfferList/Offer/OfferElements/ServiceMRFInformation';
import PriceElement from '@tourop/components/OfferList/Offer/OfferElements/Price';
import HotelCategory from '@tourop/components/OfferList/Offer/OfferElements/HotelCategory';
import TripAdvisorRating from '@tourop/components/OfferList/Offer/OfferElements/TripAdvisorRating';
import ServiceElement from '@tourop/components/OfferList/Offer/OfferElements/ServiceElement';
import { useSelector } from '@ess/store/core';
import OfferSkeleton from './Offer.skeleton';
import OfferDetails from './OfferDetails';
import OfferMenuSelection from './OfferMenuSelection';

import { Styled } from './Offer.styles';

export type OfferProps = {
  item: IResponseListItem
  isExpanded?: boolean
  isMultiRoomMode?: boolean
  onClick?: (offerID: string, rowIndex: number) => void
  onRoomsFetch?: (offerId: string, rowIndex: number, newRooms?: any) => void
  rooms?: any
  details?: boolean
  showPrice?: boolean
  showControls?: boolean
  rowIndex: number
  style?: CSSProperties
  commonProps?: {
    onContentsChange?:(rowIndex:number)=>void
    sfProtoHash?: any
    priceType?: string
    type?: any
    view?: string
    isMobile?: boolean
    breakpoint?: keyof typeof Breakpoints
    widthHigherThan1200?: boolean
  }
}

const defaultProps = {
  details: true,
  showPrice: true,
  showControls: true,
  isExpanded: false,
  isMultiRoomMode: false,
  commonProps: undefined,
  onRoomsFetch: undefined,
  rooms: null,
  style: {},
  onClick: undefined,
};

const Offer = ({
  item,
  details,
  showPrice,
  showControls,
  isExpanded,
  isMultiRoomMode,
  onClick,
  onRoomsFetch,
  rooms,
  rowIndex,
  commonProps,
  style,
} : OfferProps) => {
  const { t } = useTranslation();
  const onlineOffer = useV5OnlineOffer(item);
  const type = useSelector((state) => state.searchForm.type);
  const [sfrProtoHash] = useQueryParam('sfr', StringParam);
  const priceStatus = onlineOffer?.fieldStatus('Base.Price');
  const transportCheckStatus = onlineOffer?.fieldStatus('Transport.*');
  const { Base, Accommodation, Transport } = onlineOffer || item;
  const {
    Availability, Operator, OperatorDesc, Price: PriceData,
  } = Base as IOfferBase;
  const offerUngroupedView = !commonProps?.isMobile && commonProps?.view === UNGROUPED_VIEW;
  const transportType = getTransportType(Transport);
  const isOwnTransport = transportType === OWN_TRANSPORT;
  const tfg = getTFG(PriceData as TOfferPrice);
  const firstRender = useRef(true);
  const { state: SFContext } = useContext(AppConfigContext);
  const { configOper } = SFContext;
  const operatorConfig = has(configOper, Operator as string) ? configOper[Operator as string] : configOper.default;
  const participantList: any = useSearchConditionsParticipants();
  const offerHashGetter = getOfferHashGetter(item, participantList);
  const [agentSettings] = useAgentSettings();
  const multiRoomEmptyRooms = isMultiRoomMode && isArray(rooms?.data) && !rooms?.data?.length;
  const offerStatus = multiRoomEmptyRooms ? {
    base: 'notavailable',
  } : Availability;

  /**
   * Check offer status on click event.
   * @param event
   */
  const statusClickHandler = (event: React.MouseEvent): void => {
    event.stopPropagation();

    if (multiRoomEmptyRooms) {
      return;
    }

    const force = onlineOffer?.Base?.Availability?.base === 'unknown';

    if (priceStatus?.isReady && !force) {
      const base = onlineOffer?.Base?.Availability?.base ?? 'unknown';
      const message = onlineOffer?.Base?.Availability?.message;
      const statusType = STATUS_TYPES[base];
      const hasMessage = message ?? false;
      const statusDescription = hasMessage ? message : STATUS_DESCRIPTIONS[statusType];
      const notificationType = includes(['available', 'onrequest'], base) ? TOAST_SUCCESS : TOAST_ERROR;

      showToast(notificationType, statusDescription as string);
    }

    onlineOffer.sendOnlineRequest({ fields: ['Base.Price'], force });
  };

  const offerClickHandler = (event: React.MouseEvent) => {
    onlineOffer.sendOnlineRequest({ fields: [!isOwnTransport ? 'Transport.*' : '', 'Base.Price'] });
    if (onClick) {
      onClick(Base?.UniqueObjectId as string, rowIndex);
    }
  };

  const fetchRooms = (offerId: string) => {
    if (onRoomsFetch) {
      onRoomsFetch(offerId, rowIndex);
    }
  };

  const onUpdateRooms = useCallback((offerId: string, newRooms: any) => {
    if (onRoomsFetch) {
      onRoomsFetch(offerId, rowIndex, newRooms);
    }
  }, [onRoomsFetch]);

  const openOfferDetails = (event: React.MouseEvent) => {
    event.stopPropagation();
    const queryParams = {
      so: offerHashGetter(),
      sf: commonProps?.sfProtoHash,
      sfr: sfrProtoHash,
    };
    const target = agentSettings.openOfferInNewWindow ? '_blank' : '_self';
    const detailsUrl = `/${commonProps?.type}/details/?${objectToURLParams(queryParams)}`;
    window.open(detailsUrl, target);
  };

  const { state: AppConfig } = useContext(AppConfigContext);
  const { offerAttributesPriority } = AppConfig;
  const offerAttributesLimitedTagsList = OfferAttributesTagsFunc(t, onlineOffer, offerAttributesPriority, true);

  const serviceElement = Accommodation?.Service ? !isMultiRoomMode ? (
    <ServiceElement
      onlineOffer={onlineOffer}
      item={item}
      isMultiRoomMode={!!isMultiRoomMode}
    />
  ) : (
    <MultiRoomPreview
      data={rooms?.data}
      fetchRooms={fetchRooms}
      offerId={Base.OfferId as string}
      updateMultiRoomData={onUpdateRooms}
      isLoading={rooms?.isLoading}
    />
  ) : null;

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (commonProps?.onContentsChange) {
      commonProps?.onContentsChange(rowIndex);
    }
  }, [
    transportCheckStatus?.isLoading,
    priceStatus?.isLoading,
    priceStatus?.isQueued,
    onlineOffer?.hash,
    isExpanded,
    isMultiRoomMode,
    rooms,
  ]);

  useEffect(() => {
    if (isMultiRoomMode) {
      if (isExpanded && !rooms?.data && !rooms?.isLoading) {
        fetchRooms(Base?.OfferId as string);
      }
    }
  }, [Base?.OfferId, isExpanded]);

  return (
    <Styled.Offer
      isClickable={details}
      isMultiRoomMode={isMultiRoomMode}
      onClick={offerClickHandler}
      style={style}
    >
      <Styled.Offer__Inner>
        <Styled.Offer__Operator>
          <OperatorLogo
            name={OperatorDesc as string}
            code={Operator as string}
          />
        </Styled.Offer__Operator>
        <Styled.Offer__Main isGroupedView={!offerUngroupedView}>
          <Styled.Offer__Main__Top>
            <TransportIcon
              onlineOffer={onlineOffer}
              item={item}
              offerHashGetter={offerHashGetter}
            />
            <DateWithDuration
              item={item}
              onlineOffer={onlineOffer}
              commonProps={commonProps}
            />
            {!offerUngroupedView && showPrice && (
              <PriceElement
                rooms={rooms?.data}
                item={item}
                isMultiRoomMode={!!isMultiRoomMode}
                onlineOffer={onlineOffer}
                commonProps={commonProps}
              />
            )}
          </Styled.Offer__Main__Top>
          <Styled.Offer__Main__Bottom>
            <ServiceMRFInformation
              serviceElement={serviceElement}
              offerAttributesLimitedTagsList={offerAttributesLimitedTagsList}
              onlineOffer={onlineOffer}
              item={item}
              commonProps={commonProps}
              isMultiRoomMode={isMultiRoomMode}
            />
          </Styled.Offer__Main__Bottom>
        </Styled.Offer__Main>
        {(offerUngroupedView) && (
          <Styled.Offer__Main__Ungrouped>
            <Styled.Offer__Object>
              <Anchor onClick={openOfferDetails}>
                <Styled.Offer__Object__Name>
                  {Base?.XCode?.Name}
                </Styled.Offer__Object__Name>
              </Anchor>
              {serviceElement}
            </Styled.Offer__Object>

            <FlexBox ml="auto" flexShrink={0}>
              <FlexBox mr="small">
                <OfferAttributesTags
                  offerAttributes={offerAttributesLimitedTagsList}
                  maxVisibleAttributes={2}
                />
              </FlexBox>
              {Accommodation && (
              <>
                {Accommodation?.Category && Accommodation.Category > 0 ? (
                  <HotelCategory Accommodation={Accommodation}/>
                ) : (
                  <FlexBox
                    width="28px"
                    height="22px"
                    mr="small"
                  />
                )}
                {Accommodation?.ExtTripAdvisor?.rating ? (
                  <TripAdvisorRating Accommodation={Accommodation}/>
                ) : (
                  <FlexBox
                    width="24px"
                    height="22px"
                    mr="small"
                  />
                )}
              </>
              )}
              {offerUngroupedView && showPrice && (
                <PriceElement
                  rooms={rooms?.data}
                  item={item}
                  isMultiRoomMode={!!isMultiRoomMode}
                  onlineOffer={onlineOffer}
                  commonProps={commonProps}
                />
              )}
            </FlexBox>
          </Styled.Offer__Main__Ungrouped>
        )}
        {showControls && (
          <Styled.Offer__ControlsWrapper>
            {onlineOffer && (
            <OfferMenuSelection
              offerData={onlineOffer}
              selectedRooms={rooms?.data}
              offerHashGetter={offerHashGetter}
              isMultiRoomMode={isMultiRoomMode}
            />
            )}
            <Styled.Offer__Status>
              <OfferStatus
                status={offerStatus as IOfferBaseAvailability}
                isLoading={(rooms?.isLoading && isMultiRoomMode) || priceStatus?.isLoading}
                isQueued={priceStatus?.isQueued}
                onClick={statusClickHandler}
                isConfigSettingOnline={operatorConfig.isOnline}
              />
              {(tfg && (
              <Tooltip content={`${t('tfg_included')}`}>
                <Chip variant="primary" label={t('lbl_tfg')}/>
              </Tooltip>
              ))}
            </Styled.Offer__Status>
          </Styled.Offer__ControlsWrapper>
        )}
      </Styled.Offer__Inner>
      {isExpanded && details && (
        <OfferDetails
          searchType={type}
          offerData={onlineOffer}
          offerHashGetter={offerHashGetter}
          isMultiRoomMode={isMultiRoomMode}
          onContentsChange={() => commonProps?.onContentsChange && commonProps?.onContentsChange(rowIndex)}
          viewType={offerUngroupedView ? UNGROUPED_VIEW : ''}
        />
      )}
    </Styled.Offer>
  );
};

Offer.defaultProps = defaultProps;

export default memo(Offer);
export {
  OfferSkeleton,
};
