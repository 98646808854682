import { isEmpty, get, includes } from 'lodash-es';
import { IParticipantList } from '@ess/types';

const mxProto = get(window, 'mxProto', undefined);
const getProtoHash = get(mxProto, 'getProtoHash', () => {});
const SOffer = get(mxProto, 'SOffer', () => {});

export type GetOfferHashArgs = {
    offerId: string
    operator: string
    participantList?: IParticipantList
    changeBook?: {
        bookingNumber: string
    },
    extraHotel?: {
      code: string
      id: string
    },
    room?: number
}

/**
 * Returns offer protoHash.
 *
 * @param offerId
 * @param operator
 * @param changeBook
 * @param extraHotel
 * @param participantList
 */
const getOfferHash = ({
  offerId, operator, changeBook, participantList = [], extraHotel,
}: GetOfferHashArgs) => {
  const offerRoomPax = participantList.length ? {
    adt: participantList.filter(
      (v:any) => includes(['ADULT', 'SENIOR'], v.code),
    ).length,
    chdDates: participantList.filter((v:any) => v.code === 'CHILD').map(
      (v:any) => v.birthdate,
    ),
    infDates: participantList.filter((v:any) => v.code === 'INFANT').map(
      (v:any) => v.birthdate,
    ),
  } : {};

  const extraHotelData = extraHotel !== undefined && !isEmpty(extraHotel) ? {
    extraHotel,
  } : {};

  const changeBookData = changeBook !== undefined && !isEmpty(changeBook) ? {
    changeBook: {
      bnr: changeBook.bookingNumber,
    },
  } : {};

  const data = {
    operator,
    id: offerId,
    ...offerRoomPax,
    ...extraHotelData,
    ...changeBookData,
  }

  return mxProto !== undefined
    ? getProtoHash(SOffer, data)
    : '';
};

export default getOfferHash;
