import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import { promiseRequest } from '@ess/v5-data-provider/request';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Loader from '@ess/ui/Loader';

const DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss';

type ImportStatisticsProps = {
  operator: string
}

interface IStatisticsData {
  imports: any[]
  fileUpdateTime: string
}

interface IStatistics {
  isLoading: boolean
  isError: boolean
  data: IStatisticsData | null
}

const getParsedData = (data: any): IStatisticsData => {
  const { OperatorCode = {}, fileUpdateTime = '' } = data ?? {};
  const keys = Object.keys(OperatorCode);

  const dataObject: IStatisticsData = {
    imports: [],
    fileUpdateTime: fileUpdateTime ? moment(fileUpdateTime, DATE_FORMAT).format('DD.MM.YYYY HH:mm') : '',
  };

  if (keys.length && OperatorCode?.[keys[0]]) {
    dataObject.imports = OperatorCode?.[keys[0]];
  }

  return dataObject;
};

const ImportStatistics = ({ operator }: ImportStatisticsProps) => {
  const { t } = useTranslation();
  const { state: AppConfig } = useContext(AppConfigContext);
  const [statistics, setStatistics] = useState<IStatistics>({
    isLoading: false,
    isError: false,
    data: null,
  });

  const operatorName = AppConfig?.configOper[operator]?.name ?? operator;

  const fetchStatistics = useCallback((operator: string) => {
    if (statistics?.data || statistics.isLoading) {
      return;
    }

    setStatistics((state) => ({
      ...state,
      isLoading: true,
    }));

    (async () => {
      const postData = encodeURIComponent(JSON.stringify({
        Operator: operator,
        Language: 'PL',
      }));

      const response = await promiseRequest(`${CONTENT_SERVICE_URL}ImportStatistics/${postData}`, null);

      const data = getParsedData(response?.Sections?.ImportStatistics);

      setStatistics((state) => ({
        ...state,
        isLoading: false,
        data,
      }));
    })();
  }, [statistics]);

  useEffect(() => {
    if (operator) {
      fetchStatistics(operator);
    }
  }, [operator]);

  return (
    <FlexBox
      width="100%"
      flexDirection="column"
    >
      <FlexBox
        p="small"
        backgroundColor="#f1f1f1"
        style={{
          borderTop: '1px solid #ddd',
          borderLeft: '1px solid #ddd',
          borderRight: '1px solid #ddd',
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
        }}
      >
        <Text fontWeight="bold">
          {`${t('lbl_offers_import_statistics')}: ${operatorName}`}
        </Text>
      </FlexBox>

      {statistics.isLoading ? (
        <FlexBox
          width="100%"
          height="200px"
          alignItems="center"
          justifyContent="center"
          style={{
            borderBottom: '1px solid #ddd',
            borderLeft: '1px solid #ddd',
            borderRight: '1px solid #ddd',
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
          }}
        >
          <Loader type="oval" size="28px" color="navy"/>
        </FlexBox>
      ) : statistics?.data?.imports.length ? (
        <>
          <FlexBox
            width="100%"
            display="grid"
            overflow="hidden"
            style={{
              gridTemplateColumns: '1fr 1fr',
              borderTop: '1px solid #ddd',
              borderBottom: '1px solid #ddd',
              borderLeft: '1px solid #ddd',
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6,
            }}
          >
            <FlexBox
              p="small"
              backgroundColor="#f1f1f1"
              style={{
                borderRight: '1px solid #ddd',
              }}
            >
              <Text fontWeight="bold">
                {t('lbl_hour')}
              </Text>
            </FlexBox>
            <FlexBox
              p="small"
              backgroundColor="#f1f1f1"
              style={{
                borderRight: '1px solid #ddd',
              }}
            >
              <Text fontWeight="bold">
                {t('lbl_size')}
              </Text>
            </FlexBox>
            {statistics?.data?.imports?.map((item, index) => {
              const backgroundColor = index % 2 === 0 ? 'white' : '#f7f7f7';
              return (
                <>
                  <FlexBox
                    p="small"
                    alignItems="flex-start"
                    backgroundColor={backgroundColor}
                    style={{
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    <Text lineHeight="24px">
                      {moment(item.endts, DATE_FORMAT).format('HH:mm')}
                    </Text>
                  </FlexBox>
                  <FlexBox
                    p="small"
                    alignItems="flex-start"
                    backgroundColor={backgroundColor}
                    style={{
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    <Text lineHeight="24px">
                      {`${Math.round(item.size / 1024 ** 2)} MB`}
                    </Text>
                  </FlexBox>
                </>
              );
            })}
          </FlexBox>

          {statistics?.data?.fileUpdateTime && (
            <FlexBox width="100%" height={30} alignItems="center">
              <Text>
                {`${t('lbl_import_update_time')}: ${statistics.data.fileUpdateTime}`}
              </Text>
            </FlexBox>
          )}
        </>
      ) : (
        <FlexBox
          width="100%"
          height="200px"
          alignItems="center"
          justifyContent="center"
          style={{
            borderBottom: '1px solid #ddd',
            borderLeft: '1px solid #ddd',
            borderRight: '1px solid #ddd',
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
          }}
        >
          <Text color="darkGray">
            {t('lbl_section_data_error')}
          </Text>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default ImportStatistics;
