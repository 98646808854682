import React, { memo, CSSProperties } from 'react';
import { isString } from 'lodash-es';

import { Styled } from './Checkbox.styles';

export type CheckboxProps = {
  name?: any
  label?: any | React.ReactElement | undefined
  value?: any
  checked: boolean
  disabled?: boolean | undefined
  defaultChecked?: boolean
  spacing?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (event: React.MouseEvent<any>) => void
  style?: CSSProperties
  fillColor?: string
  color?: string
  transition?: boolean
  iconStyles?: CSSProperties
};

const defaultProps = {
  name: '',
  label: '',
  value: '',
  onClick: undefined,
  onChange: undefined,
  disabled: false,
  defaultChecked: false,
  spacing: '5px',
  style: {},
  fillColor: '',
  color: undefined,
  iconStyles: {},
  transition: true,
};

const Checkbox = ({
  name,
  label,
  value,
  onChange,
  onClick,
  checked,
  disabled,
  style,
  fillColor,
  color,
  iconStyles,
  transition,
  ...props
}: CheckboxProps) => {
  const clickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Styled.Checkbox
      disabled={disabled}
      style={style}
      transition={transition}
      {...props}
    >
      <Styled.CheckboxInput
        type="checkbox"
        name={name}
        value={value}
        onChange={changeHandler}
        onClick={clickHandler}
        checked={checked}
        disabled={disabled as boolean}
        fillColor={fillColor}
      />
      <Styled.CheckboxIcon
        width="32"
        height="32"
        viewBox="-4 -4 39 39"
        aria-hidden="true"
        focusable="false"
        disabled={disabled}
        {...props}
        transition={transition}
        style={iconStyles}
      >
        <rect
          width="35"
          height="35"
          x="-2"
          y="-2"
          stroke="currentColor"
          fill="none"
          strokeWidth="3"
          rx="6"
          ry="6"
        />
        <polyline
          points="4,14 12,23 28,5"
          stroke="transparent"
          strokeWidth="4"
          fill="none"
        />
      </Styled.CheckboxIcon>
      {label ? isString(label) ? (<span>{label}</span>) : (label) : null}
    </Styled.Checkbox>
  );
};

Checkbox.defaultProps = defaultProps;

export default memo(Checkbox);
