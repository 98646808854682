import {
  includes, isEmpty, has, floor, isArray,
} from 'lodash-es';

import { TOTAL_PRICE } from '@ess/constants/search';
import { OWN_TRANSPORT, TRANSPORT_ICONS, TRANSPORT_TYPES } from '@ess/constants/transport';

/**
 * Returns transport type.
 *
 * @param Transport {any}
 * @returns {string}
 */

export const getTransportType = (Transport) => (
  !isEmpty(Transport) ? TRANSPORT_TYPES[Object.keys(Transport)[0]] : OWN_TRANSPORT
);

/**
 * Returns departure code with transport icon.
 *
 * @param Transport
 * @returns {{icon: *, depCode: *}}
 */

export const getTransportDep = (Transport) => {
  const transportType = getTransportType(Transport);
  const { Out = null } = !isEmpty(Transport) ? Transport[Object.keys(Transport)[0]] : {};
  const depCode = Out !== null
    ? isArray(Out)
      ? Out[0].Departure.Code
      : ''
    : '';

  return {
    depCode,
    icon: TRANSPORT_ICONS[transportType],
  };
};

/**
 * Returns parsed Transport.
 *
 * @param Transport {object}
 * @returns {Out:IFlightPart[],Ret:IFlightPart[]}
 */

export const getTransportDesc = (Transport) => {
  const transportData = !isEmpty(Transport) ? Transport[Object.keys(Transport)[0]] : {};
  let { Out = null, Ret = null } = transportData;
  if (!isEmpty(Out) && !isArray(Out)) {
    Out = [Out];
  }
  if (!isEmpty(Ret) && !isArray(Ret)) {
    Ret = [Ret];
  }
  return {
    Out: !isEmpty(Out) ? Out.map((segment) => ({
      Departure: !includes([null, '-', ''], segment.Departure) ? segment.Departure : '',
      Destination: !includes([null, '-'], segment.Destination) ? segment.Destination : '',
      Luggage: segment.Luggage,
      FlightNo: segment.FlightNo,
      AvailableSeatsCount: segment.AvailableSeatsCount,
    })) : [],
    Ret: !isEmpty(Ret) ? Ret.map((segment) => ({
      Departure: !includes([null, '-', ''], segment.Departure) ? segment.Departure : '',
      Destination: !includes([null, '-'], segment.Destination) ? segment.Destination : '',
      Luggage: segment.Luggage,
      FlightNo: segment.FlightNo,
      AvailableSeatsCount: segment.AvailableSeatsCount,
    })) : [],
  };
};

/**
 * Returns offer airlines from Transport object.
 *
 * @param Transport {object}
 * @returns {{outAirline: (*|string|string), isSameAirline: boolean, retAirline: (*|string|string)}}
 */

export const getAirlines = (Transport) => {
  const transportData = !isEmpty(Transport) ? Transport[Object.keys(Transport)[0]] : {};
  const { Out, Ret } = transportData;

  const getValidAirline = (data) => {
    const hasAirline = !isEmpty(data) && has(data[0], 'Airline');
    const isValidAirline = hasAirline && !includes([null, '-', '..'], data[0].Airline.Name);

    return isValidAirline ? data[0].Airline.Name : '';
  };

  const outAirline = getValidAirline(Out);
  const retAirline = getValidAirline(Ret);
  const isSameAirline = outAirline && retAirline && outAirline === retAirline;

  return {
    isSameAirline,
    outAirline,
    retAirline,
  };
};

/**
 * Checks if Total Price has TFG/TFP included.
 *
 * @param Price TOfferPrice
 * @returns {*|boolean}
 */

export const getTFG = (Price) => {
  const { details } = has(Price, TOTAL_PRICE) ? Price[TOTAL_PRICE] : {};
  const hasPriceTFGInfo = has(details, 'TFGIncluded');

  return hasPriceTFGInfo ? details.TFGIncluded : false;
};

/**
 * Returns parsed slope / beach distance.
 *
 * @param distance {number | undefined}
 * @returns {{unit: (string), show: (boolean), value: (number)}}
 */

export const getDistance = (distance) => {
  if (!distance) {
    return {
      show: false,
    };
  }

  const show = distance > 0 && distance < 99999;
  const distanceInKilometers = distance > 1000;
  const unit = distanceInKilometers ? 'km' : 'm';
  const value = distanceInKilometers ? floor((distance / 1000), 2) : distance;

  return {
    show,
    unit,
    value,
  };
};
