import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import {
  CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';

import FlexBox from '@ess/ui/FlexBox';

import CustomTooltip from './CustomTooltip';
import CustomLabel from './CustomLabel';
import ScrollWrapper from './ScrollWrapper';

type ChartProps = {
  data: any
  showPriceDetails: (selectedDate: string) => void
}

const Chart = ({
  data,
  showPriceDetails,
}: ChartProps) => {
  const [scrollBarWrapperElement, setScrollBarWrapperElement] = useState<HTMLDivElement | null>(null);
  const [scrollBarElement, setScrollBarElement] = useState<any>(null);

  const length = data?.length ?? 1;
  const width = length > 25 ? length * 65 : '100%';

  /**
   * Mouse Wheel event handler.
   * @param event
   */
  const onMouseWheelHandler = (event: React.WheelEvent) => {
    if (scrollBarElement) {
      const {
        scrollHeight, scrollWidth, clientWidth, clientHeight, scrollTop,
      } = scrollBarElement.getValues();
      const blockWindowScroll = event.deltaY > 0 && (scrollHeight - clientHeight <= scrollTop + 1);

      if (scrollWidth > clientWidth && (blockWindowScroll || event.deltaY <= 0)) {
        event.preventDefault();
      }

      scrollBarElement.scrollLeft(scrollBarElement.getScrollLeft() + event.deltaY);
    }
  };

  /**
   * Label click handler.
   * @param index
   */
  const onLabelClickHandler = (index: number) => {
    const { Date } = data[index];

    showPriceDetails(Date);
  };

  /**
   * Dot click handler.
   * @param event
   * @param props
   */
  const onDotClickHandler = (event: any, props: any) => {
    const { Date } = props.payload;
    showPriceDetails(Date);
  };

  useEffect(() => {
    scrollBarWrapperElement?.addEventListener('wheel', (event: any) => onMouseWheelHandler(event), { passive: false });
    return () => {
      scrollBarWrapperElement?.removeEventListener('wheel', (event: any) => onMouseWheelHandler(event));
    };
  }, [scrollBarWrapperElement]);

  return (
    <ScrollWrapper ref={setScrollBarElement}>
      <FlexBox
        ref={setScrollBarWrapperElement}
        py="17px"
        width="100%"
        height={550}
        onWheel={onMouseWheelHandler}
      >
        <ResponsiveContainer
          key={nanoid()}
          width={width}
          height="100%"
          debounce={1}
        >
          <LineChart
            data={data}
            margin={{
              top: 0,
              left: 20,
              right: 20,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="0" vertical={false} opacity={0.4}/>
            <XAxis dataKey="Date" height={36}/>
            <YAxis
              hide
              label={undefined}
              domain={[
                (dataMin: number) => ((Math.round(dataMin / 50) * 50) - 200),
                (dataMax: number) => ((Math.round(dataMax / 50) * 50) + 400),
              ]}
              yAxisId="price"
              dataKey="Price"
              orientation="left"
            />
            <Tooltip
              content={<CustomTooltip/>}
              isAnimationActive={false}
            />
            <Line
              yAxisId="price"
              type="linear"
              dataKey="Price"
              stroke="red"
              label={<CustomLabel onClick={onLabelClickHandler}/>}
              animationDuration={300}
              activeDot={{ onClick: onDotClickHandler, style: { cursor: 'pointer' } }}
              dot={{
                strokeWidth: 1,
                r: 5,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </FlexBox>
    </ScrollWrapper>
  );
};

export default Chart;
