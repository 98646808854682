import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';
import { ProtoHashTypes } from '@ess/protohash';
import useBasket, { BASKET_ACTION_ADD, BASKET_ACTION_DELETE } from '@ess/hooks/useBasket';
import useProtoHash from '@ess/hooks/useProtoHash';
import useSearchType from '@ess/hooks/useSearchType';
import useAgentSettings from '@ess/hooks/useAgentSettings';

import { Button } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';
import { Styled } from './TooltipContent.styles';

type TooltipData = {
  name: string,
  value: any
}

type TooltipContentProps = {
  offer: any
  isSelected: boolean
  additionalTooltipData?: TooltipData[]
}

const defaultProps = {
  additionalTooltipData: [],
};

const TooltipContent = ({
  offer,
  isSelected,
  additionalTooltipData,
}: TooltipContentProps) => {
  const { t } = useTranslation();
  const [agentSettings] = useAgentSettings();
  const [currentSoHash] = useQueryParam('so', StringParam);
  const { decode, encode } = useProtoHash(ProtoHashTypes.Offer);
  const { basketRequest, isInBasket, isLoading } = useBasket();
  const { type } = useSearchType();
  const isAlreadyInBasket = offer?.Base?.OfferId ? isInBasket(offer) : false;
  const { trackEvent } = useGoogleAnalytics();

  const openOfferDetails = () => {
    trackEvent({
      event: 'chart',
      eventCategory: 'B2B_CHART',
      eventAction: 'B2B_OPEN_OFFER_DETAILS',
      eventLabel: '',
    });
    const newWindowMode = agentSettings.openOfferInNewWindow;
    const searchParams = new URLSearchParams(window.location.search);
    const currentOfferParams = decode(currentSoHash as string);
    const newHash = encode({
      ...currentOfferParams,
      operator: offer.Base.Operator,
      id: offer.Base.OfferId.split('|')[0],
    });
    searchParams.set('so', newHash as string);
    window.open(`/${type}/details/?${searchParams.toString()}`, newWindowMode ? '_blank' : '_self');
  };

  const addToBasket = () => {
    trackEvent({
      event: 'chart',
      eventCategory: 'B2B_CHART',
      eventAction: 'B2B_ADD_TO_BAKSET',
      eventLabel: '',
    });
    basketRequest({
      actionType: BASKET_ACTION_ADD,
      offer,
    });
  };

  const deleteFromBasket = () => {
    trackEvent({
      event: 'chart',
      eventCategory: 'B2B_CHART',
      eventAction: 'B2B_DELETE_FROM_BAKSET',
      eventLabel: '',
    });
    basketRequest({
      actionType: BASKET_ACTION_DELETE,
      offer: [offer],
    });
  };

  const tooltipData = [
    {
      name: 'lbl_filters_startdate',
      value: offer?.Base?.StartDate ? (
        <>
          <Text
            lineHeight="20px"
            mr="tiny"
          >
            {moment(offer?.Base?.StartDate, DATE_REQUEST_FORMAT).format(DATE_DISPLAY_FORMAT)}
          </Text>
        </>
      ) : null,
    },
    {
      name: 'lbl_totalprice',
      value: offer?.Base?.Price?.Total?.amount ? (
        <Styled.TooltipContent>
          <Text lineHeight="20px" mr="tiny" >
            {`${Math.round(Number(offer?.Base?.Price.Total.amount))} ${offer?.Base?.Price.Total.currency}`}
          </Text>
        </Styled.TooltipContent>
      ) : null,
    },
    {
      name: 'lbl_price',
      value: offer?.Base?.Price?.FirstPerson?.amount ? (
        <Styled.TooltipContent>
          <Text lineHeight="20px" mr="tiny">
            {`${Math.round(Number(offer?.Base?.Price.FirstPerson.amount))} ${offer?.Base?.Price.FirstPerson.currency}`}
          </Text>
        </Styled.TooltipContent>
      ) : null,
    },
  ];
  const data = [...tooltipData, ...additionalTooltipData as any[]];

  return (
    <FlexBox
      width="220px"
    >
      <FlexBox
        width="100%"
        flexDirection="column"
      >
        {data.map((item, index) => {
          const isLastItem = data.length - 1 === index;
          return (
            <FlexBox
              key={item.name}
              alignItems="center"
              mb={!isLastItem ? 'tiny' : null}
            >
              <FlexBox width="110px" mr="tiny">
                <Text color="white" fontWeight="bold">{`${t(item.name)}:`}</Text>
              </FlexBox>
              <FlexBox>
                <Text color="white">{item.value}</Text>
              </FlexBox>
            </FlexBox>
          );
        })}
        <Button
          mt="small"
          isLoading={isLoading}
          startIcon="cart-plus"
          label={t(isAlreadyInBasket ? 'lbl_menu_basketremove' : 'lbl_menu_basketadd')}
          width="100%"
          onClick={isAlreadyInBasket ? deleteFromBasket : addToBasket}
        />
        {!isSelected && (
          <Button
            mt="small"
            label={t('offer_details')}
            width="100%"
            startIcon="angle-double-right"
            onClick={openOfferDetails}
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};

TooltipContent.defaultProps = defaultProps;

export default TooltipContent;
