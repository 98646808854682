import React, { memo } from 'react';
import AsyncSelect from 'react-select/async';
import { isMobile } from 'react-device-detect';
import { OptionTypeBase, ValueType, InputActionMeta } from 'react-select';

import SelectCustomStyles from './SimpleSelect/SimpleSelect.styles';

import DropdownIndicator from './SimpleSelect/ReplacedComponents/DropdownIndicator';
import ClearIndicator from './SimpleSelect/ReplacedComponents/ClearIndicator';
import LoadingIndicator from './SimpleSelect/ReplacedComponents/LoadingIndicator';

export type AutosuggestProps = {
  name?: string
  value?: OptionTypeBase | null
  isClearable?: boolean
  isLoading?: boolean
  maxMenuHeight?: number
  openMenuOnFocus?: boolean
  noOptionsMessage: (obj: { inputValue: string; }) => string | null;
  menuShouldBlockScroll?: boolean
  onChange?: (selected: ValueType<OptionTypeBase, false>) => void // (value: ValueType<OptionType, IsMulti>, action: ActionMeta<OptionType>)
  onBlur: (e: React.FocusEvent) => void
  loadOptions: (query: string) => Promise<any> | undefined
  getOptionValue: (value: OptionTypeBase) => string
  getOptionLabel: (value: OptionTypeBase) => string
  inputValue?: string
  onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
};

const defaultProps = {
  name: '',
  value: '',
  inputValue: '',
  maxMenuHeight: 300,
  onChange: undefined,
  onInputChange: undefined,
  isClearable: true,
  isLoading: false,
  openMenuOnFocus: false,
  menuShouldBlockScroll: false,
};

const Autosuggest = ({
  name,
  value,
  onChange,
  isClearable,
  isLoading,
  menuShouldBlockScroll,
  openMenuOnFocus,
  maxMenuHeight,
  getOptionValue,
  ...props
}: AutosuggestProps) => {
  const changeHandler = (selected: ValueType<OptionTypeBase, false>) => {
    if (onChange) {
      onChange(selected);
    }
  };

  return (
    <AsyncSelect
      styles={SelectCustomStyles}
      name={name}
      value={value}
      onChange={changeHandler}
      isAutosuggest
      isClearable={isClearable}
      menuPlacement="auto"
      menuPosition="absolute"
      maxMenuHeight={isMobile ? 200 : maxMenuHeight}
      menuShouldBlockScroll={isMobile || menuShouldBlockScroll}
      getOptionValue={getOptionValue}
      components={{
        IndicatorSeparator: () => null,
        LoadingIndicator,
        ClearIndicator,
        DropdownIndicator,
      }}
      className="autosuggest"
      {...props}
    />
  );
};

Autosuggest.defaultProps = defaultProps;

export default memo(Autosuggest);
