import styled from 'styled-components';
import { rgba } from 'polished';

const TextInput = styled.input<{ hasError?: boolean, readOnly?: boolean, disabled?: boolean }>`
    width: 100%;
    height: 34px !important;
    padding: 0 6px !important;
    position: relative;
    color: ${({ theme, hasError }) => (!hasError ? theme.colors.textColor : theme.colors.errorRed)} !important;
    border-radius: ${({ theme }) => theme.radii['br-4']};
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-color: ${({ theme, hasError }) => (!hasError ? theme.colors.gray : theme.colors.errorRed)} !important;
    background-color: ${({ theme, hasError, disabled }) => (disabled ? '#d3d6da' : !hasError ? theme.colors.white : rgba(theme.colors.errorRed, 0.08))} !important;
    transition: all 0.2s ease;
    transition-property: box-shadow, border-color;
    cursor: ${({ readOnly, disabled }) => (disabled ? 'not-allowed' : readOnly ? 'pointer' : 'cursor')} !important;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus {
        border-color: transparent !important;
        box-shadow: 0 0 0px 2.5px ${({ theme, hasError }) => (!hasError ? '#2684ff' : theme.colors.errorRed)} !important;
        z-index: 10;
    }

    &::placeholder {
      font-weight: normal;
      color: ${({ theme, hasError }) => (!hasError ? theme.colors.gray : theme.colors.errorRed)} !important;
      opacity: 1;
    }
`;

const TextInput__Icon = styled.div<{ isClearIcon?: boolean, isStartIcon: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 34px;
    height: 100%;
    z-index: 12;
    font-size: ${({ isClearIcon }) => (isClearIcon ? '12px' : '15px')};
    pointer-events: ${({ isClearIcon }) => (isClearIcon ? 'all' : 'none')};
    color: ${({ theme }) => theme.colors.gray};

    ${({ isStartIcon }) => (isStartIcon ? 'left: 0;' : 'right: 0;')};
`;

const TextInput__Wrapper = styled.div<{ clearIconVisible?: boolean, startIcon?: boolean, endIcon?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${TextInput} {
      padding-right: ${({ clearIconVisible, endIcon }) => (clearIconVisible || endIcon ? '34px !important' : '6px')};
      padding-left:  ${({ startIcon }) => (startIcon ? '34px !important' : '0')};
    }
`;

const TextInput__Inner = styled.div<{ clearIconVisible?: boolean, startIcon?: boolean, endIcon?: boolean }>`
    display: flex;
    position: relative;
    width: 100%;
`;

export const Styled = {
  TextInput,
  TextInput__Icon,
  TextInput__Wrapper,
  TextInput__Inner,
};
