import React, { ChangeEvent, useEffect, useState } from 'react';
import { includes, toSafeInteger } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { IViewObject } from '@ess/v5-data-provider/interfaces';

import {
  BASE_URL,
  CHECK_BOOKING_SWAP_ACTION,
  OFFER_RESERVATION_ENDPOINT,
} from '@ess/constants/api';

import showToast, { TOAST_ERROR } from '@ess/utils/form/ShowToast';

import useOfferContentService, { Sections } from '@ess/hooks/useOfferContentService';

import { Button } from '@ess/ui/Button';
import Modal from '@ess/ui/Modal';
import FlexBox from '@ess/ui/FlexBox';
import TextInput from '@ess/ui/Form/TextInput';
import Text from '@ess/ui/Text';

type BookingSwapCheckResponsePrice = {
  type: string,
  description: string,
  amount: string,
}

type BookingSwapContent = {
  bookingNumber: string,
  messageFooter?: string,
  messageHeader: string,
  messagePrices: Array<BookingSwapCheckResponsePrice>
  onlineMessage: string
}

const swapModalInitialState = {
  messageFooter: '',
  bookingNumber: '',
  messageHeader: '',
  messagePrices: [],
  onlineMessage: '',
};

type SwapState = {
  isModalOpen: boolean
  isLoading: boolean
  content: BookingSwapContent
  inputValue: string
}

type SwapBookingProps = {
  offerData: any
  offerHashGetter: any
  searchType: string
}

const SwapBooking = ({ offerData, offerHashGetter, searchType }: SwapBookingProps) => {
  const { t } = useTranslation();
  const [swapBookingState, setSwapBookingState] = useState<SwapState>({
    isModalOpen: false,
    isLoading: false,
    content: swapModalInitialState,
    inputValue: '',
  });

  const {
    results, errors, setOffer, fetchSections, requestParams,
  } = useOfferContentService();

  /**
   * Check booking swap handler.
   * @param event
   */
  const checkBookingSwap = async (event: React.MouseEvent) => {
    event.stopPropagation();

    setSwapBookingState((state) => ({
      ...state,
      isLoading: true,
      content: swapModalInitialState,
    }));

    await fetchSections({
      sections: [Sections.Bookings],
      params: {
        Bookings: {
          Action: CHECK_BOOKING_SWAP_ACTION,
          BookingNumber: swapBookingState.inputValue,
        },
      },
    });

    setSwapBookingState((state) => ({
      ...state,
      isLoading: false,
    }));
  };

  /**
   * CLose modal handler.
   */
  const onClose = () => {
    setSwapBookingState((state) => ({
      ...state,
      isModalOpen: false,
    }));
  };

  /**
   * Confirm booking swap handler.
   * @param event
   */
  const confirmBookingSwap = (event: React.MouseEvent) => {
    event.stopPropagation();
    const newProtoHash = offerHashGetter({
      changeBook: {
        bookingNumber: swapBookingState.content.bookingNumber,
      },
    });

    const queryParams = `so=${newProtoHash}`;

    setSwapBookingState((state) => ({
      ...state,
      isModalOpen: false,
    }));

    window.open(`${BASE_URL}${searchType}/${OFFER_RESERVATION_ENDPOINT}${queryParams}`, '_blank');
  };

  /**
   * Input change handler.
   * @param event
   */
  const swapInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    setSwapBookingState((state) => ({
      ...state,
      inputValue: event.target.value,
    }));
  };

  useEffect(() => {
    if (!includes(requestParams.Sections, Sections.Bookings)) {
      return;
    }

    try {
      if (results?.Sections?.Bookings && !errors.Bookings) {
        setSwapBookingState((state) => ({
          ...state,
          isModalOpen: true,
        }));

        const content = results.Sections.Bookings;

        content.messageFooter = t('swap_booking_footer_message');
        content.messageHeader = t('swap_booking_header_message');
        content.bookingNumber = swapBookingState.inputValue;

        setSwapBookingState((state) => ({
          ...state,
          content,
        }));
      } else {
        showToast(TOAST_ERROR, t('booking_swap_not_possible'));
      }
    } catch (error) {
      showToast(TOAST_ERROR, t('booking_swap_not_possible'));
      Sentry.captureException(error);
    } finally {
      setSwapBookingState((state) => ({
        ...state,
        isLoading: false,
      }));
    }
  }, [results]);

  useEffect(() => {
    setOffer({
      parent: {} as IViewObject, sortKeyValue: ['asc'], offer: offerData,
    });
  }, [offerData?.Base?.OfferId]);

  return (
    <>
      <Modal
        isOpen={swapBookingState.isModalOpen}
        onClose={onClose}
        title={t('swap_booking_title')}
        controls={(
          <FlexBox width="100%" px="small" justifyContent="flex-end" alignItems="center">
            <Button
              mr="small"
              variant="secondary"
              label={t('lbl_cancel')}
              size="small"
              width="80px"
              onClick={onClose}
            />
            <Button
              onClick={confirmBookingSwap}
              label={t('swap_booking_confirm')}
              variant="primary"
              size="small"
            />
          </FlexBox>
        )}
      >
        {swapBookingState.content.messageHeader && (
          <FlexBox p="small" width="100%" justifyContent="space-between" alignItems="center">
            <Text fontWeight="bold">
              {swapBookingState.content.messageHeader}
            </Text>
          </FlexBox>
        )}
        <FlexBox p="small" width="100%" justifyContent="space-between" alignItems="center">
          <Text>
            {`${swapBookingState.content.onlineMessage}:`}
          </Text>
        </FlexBox>
        {swapBookingState.content.messagePrices && (
          <FlexBox width="100%" justifyContent="space-between" alignItems="center" flexDirection="column">
            {swapBookingState.content.messagePrices.map(({ type, description, amount }) => (
              <FlexBox
                p="small"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                order={toSafeInteger(amount)}
                backgroundColor={type === 'total' ? 'lightgray' : null}
              >
                <Text textAlign="center">
                  {description}
                </Text>
                <Text>
                  {amount}
                </Text>
              </FlexBox>
            ))}
          </FlexBox>
        )}
        {swapBookingState.content.messageFooter && (
          <FlexBox
            p="small"
            backgroundColor="lightBlue"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="0.8em" textAlign="justify">
              {swapBookingState.content.messageFooter}
            </Text>
          </FlexBox>
        )}
      </Modal>

      <FlexBox width="100%" alignItems="center">
        <FlexBox mr="small" flexGrow={1}>
          <TextInput
            name="swapBooking"
            placeholder={t('lbl_put_booking_number')}
            onChange={swapInputChange}
            onClick={(_e) => _e.stopPropagation()}
          />
        </FlexBox>

        <FlexBox width="210px">
          <Button
            width="100%"
            onClick={(_e: React.MouseEvent) => checkBookingSwap(_e)}
            label={t('swap_booking')}
            disabled={!swapBookingState.inputValue}
            isLoading={swapBookingState.isLoading}
          />
        </FlexBox>
      </FlexBox>
    </>
  );
};

export {
  SwapBooking,
};
