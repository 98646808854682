import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { isNumber } from 'lodash-es';

import useKeyPress from '@ess/hooks/useKeyPress';
import useLockBodyScroll from '@ess/hooks/useLockBodyScroll';

import { SectionBoxHeader } from '@ess/ui/SectionBox';
import { Col, Row } from '@ess/ui/FlexGrid';
import Text from '@ess/ui/Text';
import Anchor from '@ess/ui/Anchor';
import FlexBox from '@ess/ui/FlexBox';
import Image from '@ess/ui/Image';

import { colors } from '@tourop/config/theme/colors';

import Index from '../ImagesSlider';

type GalleryItems = ReadonlyArray<ReactImageGalleryItem & { largeThumb: string }>;

type ImagesGridProps = {
  items: GalleryItems
  maxItems?: number | boolean
  hasGallery?: boolean
  headerComponent?: React.ReactElement
  hotelName?: string
}

const defaultProps = {
  maxItems: 5,
  hasGallery: false,
  headerComponent: undefined,
  hotelName: undefined,
};

const ImagesGrid = ({
  items,
  maxItems,
  hasGallery,
  headerComponent,
  hotelName,
}: ImagesGridProps) => {
  const { t } = useTranslation();
  const { setLocked } = useLockBodyScroll();
  const [openGallery, setOpenGallery] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [picturesToShow, setPicturesToShow] = useState<GalleryItems>([]);
  const escapePress = useKeyPress('Escape', document.querySelector('body'));

  /**
   * Show more / fewer images click handler.
   */
  const showMoreImagesClickHandler = () => {
    setPicturesToShow(
      isNumber(maxItems) && picturesToShow.length !== maxItems ? items?.slice(0, maxItems) : items,
    );
  };

  /**
   * Grid item click handler.
   * @param index
   */
  const imageClickHandler = (index: number) => {
    if (hasGallery) {
      setStartIndex(index);
      setOpenGallery(!openGallery);
    }
  };

  useEffect(() => {
    setPicturesToShow(isNumber(maxItems) ? items?.slice(0, maxItems) : items);
  }, [items]);

  useEffect(() => {
    setLocked(openGallery);
  }, [openGallery]);

  useEffect(() => {
    if (escapePress) {
      setOpenGallery(false);
    }
  }, [escapePress]);

  let HeaderComponent = headerComponent;
  if (headerComponent === undefined) {
    HeaderComponent = (
      <SectionBoxHeader title={(
        <FlexBox
          height="50px"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="blue"
        >
          <Text fontSize="title" mr="tiny">{hotelName}</Text>
          <FlexBox
            fontSize="title"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={() => { setOpenGallery(false); }}
          >
            <Text fontSize="title" as="span" mr="tiny">{t('close')}</Text>
            <FontAwesomeIcon
              icon={faTimes}
              color={colors.black}
              size="1x"
            />
          </FlexBox>
        </FlexBox>
      )}
      />
    );
  }

  return (
    <>
      <FlexBox width="100%">
        <Row gapX={5} gapY={5} width="100%" flexWrap="wrap">
          {picturesToShow.map((item: any, index: number) => (
            <Col
              key={`image_${index}`}
              width={{
                lg: 2.4 / 12,
                md: 4 / 12,
                sm: 6 / 12,
                xs: 1,
                xxs: 1,
                print: 3 / 12,
              }}
            >
              <FlexBox borderRadius={6} overflow="hidden">
                <Image
                  src={item.largeThumb}
                  width="100%"
                  height="160px"
                  cursor={hasGallery ? 'pointer' : 'default'}
                  onClick={() => imageClickHandler(index)}
                />
              </FlexBox>
            </Col>
          ))}
        </Row>
      </FlexBox>
      {maxItems && (items.length > maxItems) && (
        <FlexBox mt="medium" justifyContent="flex-end">
          <Anchor onClick={showMoreImagesClickHandler}>
            {t(picturesToShow.length === maxItems ? 'showMorePictures' : 'showLessPictures')}
          </Anchor>
        </FlexBox>
      )}
      {hasGallery && openGallery && createPortal(
        <Index
          items={items}
          startIndex={startIndex}
          headerComponent={HeaderComponent}
          isPortal
        />,
        document.querySelector('body') as HTMLElement,
      )}
    </>
  );
};

ImagesGrid.defaultProps = defaultProps;

export default ImagesGrid;
