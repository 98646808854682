import moment, { Moment } from 'moment';
import { includes, isUndefined } from 'lodash-es';
import { DATE_DISPLAY_FORMAT, DATE_DISPLAY_SHORT_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';

/**
 * Test if date is before minDate. Returns minDate if tests true, date otherwise.
 */
const validateMinDate = (date: Moment, minDate: Moment): Moment => (
  date.isBefore(minDate) ? minDate : date
);

/**
 * Test if date is after maxDate. Returns maxDate if tests true, date otherwise.
 */
const validateMaxDate = (date: Moment, maxDate: Moment): Moment => (
  date.isAfter(maxDate) ? maxDate : date
);

/**
 * Check if date generated from incomplete date string is valid
 *
 * @param date {Moment}
 *
 * @returns {Moment | null} Moment if date is valid, null otherwise
 */
const checkIncompleteDate = (date: Moment): Moment | null => {
  if (date !== null && moment(date).isValid()) {
    if (date.isBefore(moment())) {
      date.add(1, 'year');
    }

    return date;
  }

  return null;
};

/**
 * Returns date by given age.
 */
const ageToDate = (age: string, ageBaseDate: Moment): Moment => ageBaseDate.clone().subtract(age, 'years');

type manualProvidedDateValidatorProps = {
  date: string,
  allowSetAge?: boolean,
  minDate?: Moment,
  maxDate?: Moment,
  ageBaseDate?: Moment,
}
/**
 * Returns parsed date, provided manually.
 */
const manualProvidedDateValidator = ({
  date,
  allowSetAge = false,
  minDate = undefined,
  maxDate = undefined,
  ageBaseDate = moment(),
}: manualProvidedDateValidatorProps) => {
  if (!date) {
    return '';
  }

  let parsedDate = null;

  if (date.length === 4 && !includes(date, '.') && !allowSetAge) {
    parsedDate = checkIncompleteDate(moment(date, 'DDMM'));
  } if (date.length === 5 && includes(date, '.') && !allowSetAge) {
    parsedDate = checkIncompleteDate(moment(date, DATE_DISPLAY_SHORT_FORMAT));
  } else if (date.length === 6 && !includes(date, '.')) {
    parsedDate = moment(date, 'DDMMYY');
  } else if (date.length === 8 && !includes(date, '.')) {
    parsedDate = moment(date, 'DDMMYYYY');
  } else if (date.length === 10 && includes(date, '.')) {
    parsedDate = moment(date, DATE_DISPLAY_FORMAT);
  } else if (date.length > 0 && date.length <= 2 && allowSetAge) {
    parsedDate = ageToDate(date, ageBaseDate);
  }

  if (parsedDate === null || !moment(parsedDate).isValid()) {
    return date;
  }

  if (!isUndefined(minDate)) {
    parsedDate = validateMinDate(parsedDate, minDate);
  }

  if (!isUndefined(maxDate)) {
    parsedDate = validateMaxDate(parsedDate, maxDate);
  }

  return parsedDate.format(DATE_REQUEST_FORMAT);
};

export default manualProvidedDateValidator;
