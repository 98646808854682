import React from 'react';
import { Text } from 'recharts';

const CustomLabel = (props: any) => {
  const {
    viewBox, angle, text,
  } = props;

  const padding = angle > 0 ? 20 : -20;

  return (
    <Text
      x={viewBox.x + (viewBox.width / 2) + padding}
      y={viewBox.y + (viewBox.height / 2)}
      verticalAnchor="end"
      textAnchor="middle"
      fontSize="15px"
      angle={angle}
    >
      {text}
    </Text>
  );
};

export default CustomLabel;
