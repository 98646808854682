import React from 'react';
import { has } from 'lodash-es';
import { SpaceProps } from 'styled-system';

import { IDictionary } from '@ess/types';

import OvalLoader from './OvalLoder';
import DotsLoader from './DotsLoader';

const DEFAULT_LOADER = 'oval';
const LOADER_TYPES: IDictionary<(props: any) => React.ReactComponentElement<any>> = {
  oval: (props) => <OvalLoader {...props}/>,
  dots: (props) => <DotsLoader {...props}/>,
};

type LoaderTypesEnum = 'oval' | 'dots';

type LoaderProps = {
  type?: LoaderTypesEnum,
  color?: string,
  size?: string;
} & SpaceProps;

const defaultProps = {
  size: '20px',
  color: 'white',
  type: DEFAULT_LOADER as LoaderTypesEnum,
};

const Loader = ({ type, ...props }: LoaderProps) => (
  <>
    {type && has(LOADER_TYPES, type)
      ? (LOADER_TYPES[type](props))
      : (LOADER_TYPES[DEFAULT_LOADER](props))}
  </>
);

Loader.defaultProps = defaultProps;

export default Loader;
