import {
  find, isEmpty, isArray, toNumber, upperFirst, isString,
} from 'lodash-es';
import * as Sentry from '@sentry/react';

import { CHILD_CODE } from '@ess/constants/participants';
import { DEFAULT_BIRTH_DATES } from '@tourop/components/MultiRoom/constants';
import { FIRST_PERSON_PRICE, TOTAL_PRICE } from '@ess/constants/search';
import { DATE_REQUEST_FORMAT } from '@ess/constants/api';

import { getFieldsOfParticipantsWithDate } from '@ess/utils/form/getParticipantsWithDate';
import arrayToObject from '@ess/utils/arrayToObject';
import getAgentAttributesXCodes from '@ess/utils/form/getAgentAttributesXCodes';
import pastDateValidation from '@ess/utils/form/pastDateValidation';


const agentSettings = localStorage.getItem('AgentSettings')
  ? JSON.parse(`${localStorage.getItem('AgentSettings')}`)
  : undefined;

const getValues = (fields, property) => arrayToObject(fields, 'name', property);

const getOptions = (options, property) => {
  const optionsValues = options.map(({ name, mapValue, [property]: isChecked }) => {
    const valueBool = isChecked ? 1 : 0;
    const value = mapValue.length ? mapValue[valueBool] : valueBool;

    return {
      name,
      value,
    };
  });

  return arrayToObject(optionsValues, 'name', 'value');
};

const getDateRange = (field, property) => {
  const { name, fieldData } = field;
  const { startDateFrom, startDateTo } = fieldData.dateRange.subField;
  const startDateValue = pastDateValidation({ date: startDateFrom[property] });
  const endDateValue = pastDateValidation({ date: startDateTo[property], relativeDate: startDateValue.date.moment });
  const parsedEndDate = endDateValue.isPastDate
    ? startDateValue.date.moment.add(1, 'week').format(DATE_REQUEST_FORMAT)
    : endDateValue.date.string;

  return {
    [`${name}.${upperFirst(startDateFrom.name)}`]: startDateValue.date.string,
    [`${name}.${upperFirst(startDateTo.name)}`]: parsedEndDate,
  };
};

const getPriceValues = (field, property, SBContext) => {
  const [priceType, priceRange] = field;
  const { name: priceTypeFieldName, [property]: priceTypeValue } = priceType;
  const { name: priceRangeFieldName, fieldData } = priceRange;
  const { priceRangeMin, priceRangeMax } = fieldData.priceRange.subField;
  const { [property]: max, source } = priceRangeMax;
  const { [property]: min } = priceRangeMin;
  const customPriceTypeValue = property === 'initialValue' ? agentSettings?.priceType ?? priceTypeValue : priceTypeValue;
  const priceTypeName = customPriceTypeValue ? TOTAL_PRICE : FIRST_PERSON_PRICE;
  const [sourcePersonPrice, sourceTotalPrice] = source.split(',');
  try {
    const { num: { min: SBMin, max: SBMax } } = SBContext[customPriceTypeValue ? sourceTotalPrice : sourcePersonPrice];
    return {
      [priceTypeFieldName]: customPriceTypeValue ? 1 : 0,
      [priceRangeFieldName]: {
        [priceTypeName]: {
          Min: min || SBMin,
          Max: max || SBMax,
        },
      },
    };
  } catch (error) {
    const scope = new Sentry.Scope();
    scope.setContext('SBContext', SBContext);
    Sentry.captureException(new Error('Log for sentry issue MERLINREACT-FC'), () => scope);
  }

  return null;
};

const getDistances = (distances, property, distanceSliders) => {
  const distanceValues = distances.map((item) => {
    const { name, [property]: max } = item;
    const { max: configMax, min } = distanceSliders[name === 'Accommodation.DistanceToSlope' ? 'slope' : 'beach'];

    return {
      name,
      value: {
        min,
        max: max || configMax,
      },
    };
  });

  return arrayToObject(distanceValues, 'name', 'value');
};

/**
 * Sets default birthdate if empty.
 * @param value
 */
const datesValidation = (value) => ({
  ...value,
  rooms: value.rooms.map((room, roomIndex) => (roomIndex === 0 ? {
      ...room,
      [CHILD_CODE]: {
        ...room[CHILD_CODE],
        dates: Array.from({ length: room[CHILD_CODE]?.value ?? 0 }).map((item, j) => {
          const birthDate = room[CHILD_CODE].dates?.[j]
              ? room[CHILD_CODE].dates[j]
              : DEFAULT_BIRTH_DATES[CHILD_CODE];

          return birthDate;
        }),
      },
    } : room),
  ),
});


const getRoomsValues = (field, property) => {
  const { [property]: value, name } = field;
  const { rooms } = datesValidation(value);



  return {
    ...getFieldsOfParticipantsWithDate(rooms),
    [name]: {
      ...value,
      rooms,
      multiRoom: {
        enabled: false,
        mode: 'auto',
        roomsCount: 1
      }
    },
  };
};

const getRatingValues = (field, property) => {
  const { [property]: value, name } = field;

  return {
    [name]: {
      min: value * 1,
      max: 5,
    },
  };
};

const getCategoryValues = (field, property) => {
  const { [property]: value, name } = field;

  return {
    [name]: {
      min: value * 1,
      max: 5.5,
    },
  };
};

const getCampAttributes = (field, property) => {
  const { name, [property]: value } = field;
  const arrayValue = isArray(value) ? value : value ? value.split(',') : [];
  const parsedValue = arrayValue.map((item) => toNumber(item));

  return {
    [name]: parsedValue,
  };
};

const getAgentAttributesValues = (field, property, dictionary) => {
  const { name, [property]: value } = field;
  const xCodes = !getAgentAttributesXCodes(value, dictionary);

  return {
    'Custom.AgentAttributes.XCodes': xCodes,
    [name]: value,
  };
};

const getTransport = (field, property) => {
  const { name, [property]: value } = field;
  const transportValue = isString(value) ? [value] : value;

  return { [name]: transportValue.filter((transport) => transport !== 'transport.*') }
}

const getFieldsValuesFromConfig = ({
  SBContext,
  SFContext,
  property,
}) => {
  const { config, distanceSliders, dictionary } = SFContext;
  const {
    fields, attributes, options, price, distances, themes,
  } = config ?? {};

  const filteredFields = fields?.filter((field) => field.name !== 'Custom.StartDateRange');

  const agentAttributesField = find(fields, { name: 'Custom.AgentAttributes' });
  const categoryField = find(fields, { name: 'Accommodation.Category' });
  const participantsField = find(fields, { name: 'Base.ParticipantsList' });
  const dateRangeField = find(fields, { name: 'Custom.StartDateRange' });
  const ratingField = find(fields, { name: 'Accommodation.Rating' });
  const campAttributesField = find(themes, { name: 'Accommodation.Camp.Theme' });
  const transportField = find(fields, { name: 'Base.ComponentsCombinations.Transport' });

  const customFieldsValuesGetters = {
    ...participantsField ? getRoomsValues(participantsField, property) : {},
    ...ratingField ? getRatingValues(ratingField, property) : {},
    ...categoryField ? getCategoryValues(categoryField, property) : {},
    ...dateRangeField ? getDateRange(dateRangeField, property) : {},
    ...transportField ? getTransport(transportField, property) : {},
    ...agentAttributesField ? getAgentAttributesValues(
      agentAttributesField, property, dictionary['Custom.AgentAttributes'],
    ) : {},
    ...campAttributesField ? getCampAttributes(campAttributesField, property) : {},
  };

  return {
    ...!isEmpty(filteredFields) ? getValues(filteredFields, property) : {},
    ...!isEmpty(attributes) ? getValues(attributes, property) : {},
    ...!isEmpty(options) ? getOptions(options, property) : {},
    ...!isEmpty(distances) && !isEmpty(distanceSliders) ? getDistances(distances, property, distanceSliders) : {},
    ...!isEmpty(price) && !isEmpty(SBContext) ? getPriceValues(price, property, SBContext) : {},
    ...customFieldsValuesGetters,
  };
};

export default getFieldsValuesFromConfig;
