import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import Tooltip from '../../Tooltip';
import Box from '../../Box';

type OnlineWarnTooltpProps = {
    condition: boolean
    tooltipcontent: () => any
    children: any
}

const OnlineWarnCondition = ({ condition, tooltipcontent, children }: OnlineWarnTooltpProps) => {
  const { t } = useTranslation();
  if (!condition) {
    return children ?? <></>;
  }
  return (
    <Tooltip content={(
      <>
        {t('value_before_online_check')}
        <br/>
        {tooltipcontent()}
      </>
        )}
    >
      <Box>
        {children}
        <span>
          <FontAwesomeIcon color="orange" icon={faExclamationTriangle}/>
        </span>
      </Box>
    </Tooltip>
  );
};
export default OnlineWarnCondition;
