import {
  has, sortBy,
} from 'lodash-es';

import { getParticipantsHash } from '@ess/utils/protoHash/getOfferId';
import { getUniqueObjectId } from '@ess/utils/offerData/getUniqueObjectId';

import { getOfferByPax } from '@tourop/components/MultiRoom/utils/getOfferByPax';

import { IRoom, ISelectedRoom } from '../types';

/**
 * Returns recommended rooms based on DefaultPax.
 * @param data
 * @param description
 */
const getRecommendedRooms = (data: IRoom[], description?: '') => {
  if (!data?.length) {
    return [];
  }

  const roomWithDefaultPax = data.filter((room) => room.Offers.find((offer) => offer.DefaultPax.length));

  let rooms: ISelectedRoom[] = [];
  let roomCount = 0;

  if (roomWithDefaultPax.length) {
    roomWithDefaultPax.map((room) => {
      room.Offers.map((offer) => {
        if (offer) {
          const {
            DefaultPax,
            Offer,
          } = offer;

          if (DefaultPax) {
            DefaultPax.map((group: any, index: number) => {
              const { Base } = Offer;
              const {
                Operator,
                UniqueObjectId,
              } = Base || {};

              const offerByPax = getOfferByPax(offer, group);

              const paxHash = getParticipantsHash({
                adultsCount: group.Adult.value,
                childDates: group.Child.dates ?? [],
                infantDates: [],
              });

              const offerId = offerByPax?.OfferId || `${UniqueObjectId}|${Operator}|${paxHash}`;
              const uniqueObjectId = getUniqueObjectId(offerId);

              rooms.push({
                ...offer,
                RoomId: `${index}_${uniqueObjectId}`,
                Offer: {
                  ...Offer,
                  Base: {
                    ...Offer.Base,
                    OfferId: offerId,
                    UniqueObjectId: uniqueObjectId,
                  },
                },
                Group: {
                  ...has(group, 'GroupId') ? { id: group.GroupId } : {},
                  description: description ? `${description} ${roomCount + 1}` : '',
                  pax: {
                    Adult: group.Adult,
                    Child: group.Child,
                  },
                },
              });

              roomCount += 1;
            });

            if (rooms.length && has(rooms[0].Group, 'id')) {
              rooms = sortBy(rooms, (item) => item.Group.id);
            }
          }
        }
      });
    });
  }

  return rooms;
};

export {
  getRecommendedRooms,
};
