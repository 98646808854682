import React, {
  memo, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment, MomentInput } from 'moment';

import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';

import Field from '@ess/ui/Form/Field';
import { ageSettings } from '@tourop/config/searchForm/birthdate';

export interface IRoomPax {
  max: number,
  min: number,
  value: number,
  dates?: [string, string]
}
export interface IRoom {
  [key: string] : IRoomPax,
}

type BirthDatePickerProps = {
  appendTo?: Element | null
  onFocus?: () => void
  onChange?: (date: string, personIndex: number, dateOnly?: boolean) => void
  date: string
  error?: string
  minDate?: any
  maxDate?: any
  mobileWithDrawer:any
  datePlaceholder: string
  personType: string
  roomIndex: number
  personIndex: number
  ref:any
}

const pickerDefaultProps = {
  onFocus: undefined,
  onChange: undefined,
  appendTo: null,
  error: '',
  minDate: null,
  maxDate: null,
};

const BirthDatePicker = ({
  date,
  onFocus,
  onChange,
  error,
  appendTo,
  mobileWithDrawer,
  datePlaceholder,
  personType,
  roomIndex,
  minDate,
  maxDate,
  personIndex,
  ref,
}: BirthDatePickerProps) => {
  const [birthdateValue, setBirthdateValue] = useState<MomentInput>(null);
  const fieldName = `Custom.Birthdates.${roomIndex}.${personType}.${personIndex}`;

  const changeParticipantBirthDate = (selectedDate: Moment) => {
    const isValidDate = selectedDate !== null ? selectedDate.isValid() : false;
    const formattedDate = isValidDate ? selectedDate.format(DATE_REQUEST_FORMAT) : '';

    if (formattedDate !== '') {
      sessionStorage.setItem(fieldName, formattedDate);
    } else {
      sessionStorage.removeItem(fieldName);
    }

    if (onChange) {
      onChange(formattedDate, personIndex);
    }
  };

  const onInputBlur = (birthdateFieldName: string, value: string) => {
    const date = moment(value, [DATE_REQUEST_FORMAT, DATE_DISPLAY_FORMAT], true);
    if (onChange) {
      onChange((date && date.isValid()) ? value : '', personIndex);
    }
  };

  const onInputDateChange = (birthdateFieldName: string, value: string) => {
    const date = moment(value, [DATE_REQUEST_FORMAT, DATE_DISPLAY_FORMAT], true);
    const isValidDate = value && value.length === 10 && date.isValid();
    if (isValidDate) {
      sessionStorage.setItem(fieldName, value);
    } else {
      sessionStorage.removeItem(fieldName);
    }

    if (onChange && isValidDate) {
      onChange(value, personIndex, value.length !== 10);
    }
  };

  useEffect(() => {
    let value = moment(date, [DATE_REQUEST_FORMAT, DATE_DISPLAY_FORMAT], true);
    let isValidDate = date && date.length === 10 && value.isValid();

    if (!isValidDate && sessionStorage.getItem(fieldName)) {
      value = moment(sessionStorage.getItem(fieldName), [DATE_REQUEST_FORMAT, DATE_DISPLAY_FORMAT], true);
      isValidDate = value.isValid();
    }

    setBirthdateValue(isValidDate ? value : null);

    if (onChange) {
      onChange(isValidDate ? value.format(DATE_REQUEST_FORMAT) : '', personIndex);
    }
  }, [date]);

  return (
    <Field.BirthDatePicker
      ref={ref}
      onFocus={onFocus}
      mobileWithDrawer={mobileWithDrawer}
      dateId={fieldName}
      name={fieldName}
      dateValue={birthdateValue}
      dateInputValue={date}
      datePlaceholder={datePlaceholder}
      error={error}
      errorDisplay="inline"
      personType={personType}
      appendTo={appendTo}
      maxDate={moment().subtract(ageSettings[personType].max, 'years')}
      minDate={moment()}
      onCalendarChange={(selectedDate: Moment) => changeParticipantBirthDate(selectedDate)}
      onInputDateChange={(value: string) => onInputDateChange(fieldName, value)}
      onBlur={(value: string) => onInputBlur(fieldName, value)}
    />
  );
};

BirthDatePicker.defaultProps = pickerDefaultProps;

type BirthDatesType = {
  value: any
  onFocus?: any
  onChange?: any
  appendTo?: Element | null
  mobileWithDrawer?: any
  error?: string
  personIndex: number
  roomIndex: number
  personType: string
  ref:any
}

const defaultProps = {
  error: '',
  onFocus: undefined,
  onChange: undefined,
  mobileWithDrawer: undefined,
  appendTo: null,
};

const BirthDates = ({
  value,
  onFocus,
  error,
  appendTo,
  mobileWithDrawer,
  onChange,
  personIndex,
  roomIndex,
  personType,
  ref,
}: BirthDatesType) => {
  const { t } = useTranslation();

  return (
    <BirthDatePicker
      ref={ref}
      date={value}
      onFocus={onFocus}
      onChange={onChange}
      error={error}
      mobileWithDrawer={mobileWithDrawer}
      personIndex={personIndex}
      roomIndex={roomIndex}
      personType={personType}
      appendTo={appendTo}
      datePlaceholder={t('lbl_birthdate')}
    />
  );
};

BirthDates.defaultProps = defaultProps;

export default memo(BirthDates);
