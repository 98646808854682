export const colors: any = {
  white: '#ffffff',
  gray: '#d5d5d5',
  darkGray: '#777777',
  lightGray: '#f1f3f4',
  lighterGray: '#e1e3e6',
  navy: '#102c58',
  brand: '#1f4494',
  blue: '#1a9fff',
  lightBlue: '#C9E9FF',
  green: '#689b0e',
  orange: '#f5642d',
  yellow: '#F6C52D',
  red: '#990000',
  errorRed: '#c00',
};

colors.white = 'var(--white-color)';
colors.primary = 'var(--primary-color)';
colors.secondary = 'var(--secondary-color)';
colors.textColor = 'var(--text-color)';
colors.textSecondaryColor = 'var(--text-secondary-color)';
colors.textAccentColor = 'var(--text-accent-color)';
colors.background = 'var(--background-color)';
colors.buttonBackground = 'var(--button-background-color)';
colors.sectionHeaderBackground = 'var(--section-header-background-color)';
colors.textSectionHeader = 'var(--text-section-header-color)';
colors.totalPrice = 'var(--text-total-price-color)';
colors.personPrice = 'var(--text-person-price-color)';
colors.price = 'var(--text-price-color)';
colors.separator = 'var(--separator-color)';
colors.backgroundSecondary = 'var(--separator-color)';
export default {
  colors,
};
