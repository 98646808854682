import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toNumber } from 'lodash-es';

import useFavourites from '@ess/hooks/useFavourites';

/**
 * Returns decoded favourites persisted in search params.
 * @param favourites
 */
const decodeFavourites = (favourites: string | undefined | null): number[] => {
  let values: number[] = [];

  if (favourites) {
    values = atob(favourites).split(',').map((item) => toNumber(item));
  }

  return values;
};

/*
  Returns encoded favourites.
 */
const persistFavourites = (data: number[]) => btoa(data.join(','));

type FavouritesContainerProps = {
  children: React.ReactElement
}

const FavouritesContainer = ({ children }: FavouritesContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { favourites, set } = useFavourites<number>('LiveRoom', false);
  const isPersistedFavouritesReady = useRef<boolean>(false);

  useEffect(() => {
    const values = persistFavourites(favourites);
    setSearchParams(values ? `f=${values}` : undefined);
  }, [favourites]);

  useEffect(() => {
    const persistedFavourites = searchParams.get('f');

    if (!isPersistedFavouritesReady.current && persistedFavourites) {
      set(decodeFavourites(persistedFavourites));
      isPersistedFavouritesReady.current = true;
    }
  }, [searchParams]);

  return children;
};

export {
  FavouritesContainer,
};
