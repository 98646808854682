import React from 'react';
import { useTranslation } from 'react-i18next';
import { has, toNumber } from 'lodash-es';
import moment from 'moment';

import { IPriceDetailsParticipant } from '@ess/types';

import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';

import { getPriceDifference } from '@ess/utils/offerData/priceDifference';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

type PriceDetailsProps = {
  offerData: any
  useColors?: boolean
  status?: any
  bookingId?: string
}
const PriceDetailsDefaultProps = {
  useColors: true,
  status: undefined,
  bookingId: undefined,
};

const PriceDetails = ({
  offerData, useColors, status, bookingId,
}: PriceDetailsProps) => {
  const { t } = useTranslation();
  const { Base } = offerData;
  const { Availability, OriginalPrice, Price } = Base;
  const message = Availability?.message;
  const priceObject = OriginalPrice && (bookingId ? OriginalPrice.Total?.currency !== Price?.Total?.currency : OriginalPrice.FirstPerson?.currency !== Price?.FirstPerson?.currency) ? OriginalPrice : Price;
  const isPriceChange = has(priceObject?.Total, 'amount_old');
  const availability = Availability?.base;
  const showErrorMessage = (): boolean => !(availability === 'available' || availability === 'onrequest');
  // eslint-disable-next-line no-unsafe-optional-chaining

  const { isReady = false, isLoading = false, isError = false } = status ?? offerData?.fieldStatus('Base.Price') ?? {};

  const { difference = 0 } = isPriceChange ? getPriceDifference(
    priceObject?.Total?.amount as string,
    // @ts-ignore
    priceObject?.Total?.amount_old as string,
    priceObject?.Total?.currency,
  ) : {};

  const priceDifference = toNumber(difference);

  if (isLoading) {
    return (
      <>
        {t('offer_status_loading')}
      </>
    );
  }

  if (isError) {
    return (
      <FlexBox p={useColors ? 'small' : ''} width="100%" justifyContent="space-between" alignItems="center">
        {message ?? t('offer_status_error')}
      </FlexBox>
    );
  }

  if (!isReady) {
    return (
      <FlexBox p={useColors ? 'small' : ''} width="100%" justifyContent="space-between" alignItems="center">
        {t('offer_status_please_check')}
      </FlexBox>
    );
  }

  if (!priceObject?.Details) {
    return (
      <FlexBox p={useColors ? 'small' : ''} width="100%" justifyContent="space-between" alignItems="center">
        {message ?? t('lbl_offer_status_unknown')}
      </FlexBox>
    );
  }
  if (showErrorMessage()) {
    return (
      <>
        <FlexBox p={useColors ? 'small' : ''} width="100%" justifyContent="space-between" alignItems="center">
          {message}
        </FlexBox>
      </>
    );
  }

  return priceObject.Details ? (
    <FlexBox flexDirection="column" width={useColors ? '100%' : '300px'}>
      <FlexBox
        width="100%"
        flexDirection="column"
      >
        {(priceObject.Details.Participants ?? []).map((person: IPriceDetailsParticipant, k: number) => (
          <FlexBox
            p="small"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            key={k}
          >
            <FlexBox>
              <Text>
                {t(`lbl_${person.code.toLowerCase()}`)}
              </Text>
              {person.code !== 'ADULT' && (
                <Text pl="tiny" fontWeight="bold" as="span">
                  {moment(person.birthdate, DATE_REQUEST_FORMAT).format(`(${DATE_DISPLAY_FORMAT})`)}
                </Text>
              )}
            </FlexBox>
            <FlexBox>
              <Text pr="tiny">
                {person.amount}
              </Text>
              <Text>
                {person.currency}
              </Text>
            </FlexBox>
          </FlexBox>
        ))}
        <FlexBox
          p="small"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="bold">{t('lbl_totalprice')}</Text>
          <FlexBox>
            <Text fontWeight="bold" pr="tiny">
              {priceObject?.Total?.amount}
            </Text>
            <Text fontWeight="bold">
              {priceObject.Total?.currency}
            </Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isPriceChange && priceDifference !== 0 && (
        <FlexBox flexDirection="column">
          <FlexBox
            p="small"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="lightGray"
          >
            <Text color="textSecondaryColor">
              {t('lbl_price_difference')}
            </Text>
            <FlexBox>
              <Text color="textSecondaryColor" pr="tiny">
                {priceDifference > 0 ? `+${difference}` : difference}
              </Text>
              <Text color="textSecondaryColor">
                {priceObject.Total?.currency}
              </Text>
            </FlexBox>
          </FlexBox>
          <FlexBox
            p="small"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="lightGray"
          >
            <Text color="textSecondaryColor">
              {t('lbl_calc_before_price')}
            </Text>
            <FlexBox>
              <Text color="textSecondaryColor" pr="tiny">
                {
                  // @ts-ignore
                  priceObject.Total?.amount_old
                }
              </Text>
              <Text color="textSecondaryColor">
                {has(priceObject.Total, 'currency_old')
                  // @ts-ignore:
                  ? priceObject.Total?.currency_old
                  : priceObject.Total?.currency}
              </Text>
            </FlexBox>
          </FlexBox>

        </FlexBox>
      )}
    </FlexBox>
  ) : null;
};

PriceDetails.defaultProps = PriceDetailsDefaultProps;

export default PriceDetails;
