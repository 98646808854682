import styled from 'styled-components';
import Text from '@ess/ui/Text';

const OfferAttributes = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 5px;
  }
`;

const Featured = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.tiny};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => `${theme.mediaQueries.sm} {
    align-items: flex-start;
    flex-direction: column;
  }`}
`;

const Featured__Title = styled(Text)`
  font-size: 14px;
  padding: ${({ theme }) => theme.space.small} 0;
  letter-spacing: 0.5px;
  font-weight: bold;
`;

const Featured__Label = styled(Text)`
  flex: 0 0 120px;
  font-size: 12px;
  font-weight: bold;

  ${({ theme }) => `${theme.mediaQueries.sm} {
    flex-basis: auto;
  }`}
`;

const Featured__Desc = styled(Text)`
 flex-grow: 1;
 font-size: 12px;
`;

export const Styled = {
  OfferAttributes,
  Featured,
  Featured__Title,
  Featured__Label,
  Featured__Desc,
};
