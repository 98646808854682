import {
  get, toNumber, subtract, round,
} from 'lodash-es';

import { IOfferListItem, IV5OnlineOffer } from '@ess/v5-data-provider/interfaces';

import { FIRST_PERSON_PRICE, TOTAL_PRICE } from '@ess/constants/search';

type Offer = IV5OnlineOffer | IOfferListItem;

type GetPriceDifferenceArgs = {
  currentOffer: Offer
  alternativeOffer: Offer
  preferredPriceType?: string
}

export interface IPriceDifference {
  priceType: string
  difference: string
  currency: string
}

/**
 * Returns price difference between two offer objects.
 * @param currentOffer
 * @param alternativeOffer
 * @param preferredPriceType
 */
const getOffersPriceDifference = ({
  currentOffer,
  alternativeOffer,
  preferredPriceType = 'Total',
}: GetPriceDifferenceArgs): IPriceDifference => {
  const currentPrice = get(currentOffer, 'Base.Price');
  const alternativePrice = get(alternativeOffer, 'Base.Price');
  const priceType = currentPrice[preferredPriceType]?.amount
    ? preferredPriceType : preferredPriceType === TOTAL_PRICE
      ? FIRST_PERSON_PRICE
      : TOTAL_PRICE;

  const { difference } = getPriceDifference(alternativePrice[priceType].amount, currentPrice[priceType].amount);

  return {
    priceType,
    difference,
    currency: alternativePrice[priceType].currency,
  };
};

/**
 * Returns price difference between two amounts.
 * @param priceA
 * @param priceB
 * @param currency
 * @param precision
 */
const getPriceDifference = (
  priceA: string,
  priceB: string,
  currency?: string | undefined,
  precision?: number,
) => {
  const difference = subtract(toNumber(priceA), toNumber(priceB));

  return {
    difference: round(difference) !== 0 ? round(difference, precision ?? 2).toFixed(precision ?? 2) : '',
    currency,
  };
};

export {
  getOffersPriceDifference,
  getPriceDifference,
};
