import { find, isEqual } from 'lodash-es';

import { IMultiRoomOffer, IPax } from '@tourop/components/MultiRoom/types';

/**
 * Indicates if pax exists in OfferIdByPax.
 * @param offer
 * @param pax
 */
const getOfferByPax = (offer: IMultiRoomOffer, pax: IPax) => find(offer.OfferIdByPax, (item) => {
  const { OfferId, Price, ...rest } = item;

  return isEqual(rest, pax);
});

export {
  getOfferByPax,
};
