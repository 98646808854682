import React from 'react';
import { useTranslation } from 'react-i18next';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

const NoAccess = () => {
  const { t } = useTranslation();

  return (
    <FlexBox
      height={200}
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <FlexBox flexDirection="column">
        <Text fontSize="title" mb="small">{t('no_access_title')}</Text>
        <Text>{t('no_access_message')}</Text>
      </FlexBox>
    </FlexBox>
  );
};

export default NoAccess;
