import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { includes, isNumber } from 'lodash-es';
import moment from 'moment';

import { OptionTypeBase } from 'react-select';

import { CHILD_BY_INDEX_DATE, DEFAULT_BIRTH_DATES, PARTICIPANTS_WITH_DATES } from '@tourop/components/MultiRoom/constants';

import { ageSettings } from '@tourop/config/searchForm/birthdate';

import { Button } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Select from '@ess/ui/Form/Select';

import Menu from '@ess/ui/Form/Select/SimpleSelect/ReplacedComponents/Menu';
import BirthDatesForParticipantsPicker, { IRoom } from '@tourop/components/ParticipantsPicker/BirthDatesForParticipantsPicker';

type BirthDateMode = 'select' | 'calendar';

type CounterProps = {
  onChangeForCalendar?: any
  personType: string
  onChange: any
  onFocus?: any
  value: any
  groupIndex: number
  count: number
  maxValue: number
  minValue?: number
  onBirthDateChange?: any
  enableBirthDate?: boolean
  birthDateMode?: BirthDateMode
  appendCalendarTo?: Element | null
  calendarValue?: any
  setDefaultBirthDate?: boolean
}

const defaultProps = {
  onChangeForCalendar: undefined,
  enableBirthDate: false,
  birthDateMode: 'calendar' as BirthDateMode,
  appendCalendarTo: null,
  minValue: 0,
  calendarValue: '',
  KidAgeToLow: [],
  KidAgeExtend: [],
  onFocus: undefined,
  setDefaultBirthDate: false,
  onBirthDateChange: undefined,
};

const Counter = ({
  onChangeForCalendar,
  setDefaultBirthDate,
  value,
  count,
  maxValue,
  minValue,
  groupIndex,
  personType,
  onChange,
  onFocus,
  onBirthDateChange,
  birthDateMode,
  enableBirthDate,
  appendCalendarTo,
  calendarValue,
}: CounterProps) => {
  const { t } = useTranslation();
  const [ageSelectOptions, setAgeSelectOptions] = useState<any>([]);
  // setting value in formik
  const setParticipants = (value: number, personType: string, defaultDate: boolean) => {
    onChange((state: any) => ({
      ...state,
      rooms: state.rooms.map(
        (room: any, i: number) => (i === groupIndex ? {
          ...room,
          [personType]: {
            ...room[personType],
            value,
            dates: PARTICIPANTS_WITH_DATES.includes(personType)
              ? Array.from({ length: value }).map((item, j) => {
                const fieldName = `Custom.Birthdates.${groupIndex}.${personType}.${j}`;
                const defaultBirthDate = CHILD_BY_INDEX_DATE[j] ? CHILD_BY_INDEX_DATE[j] : DEFAULT_BIRTH_DATES[personType];
                const date = setDefaultBirthDate && defaultDate ? defaultBirthDate : '';
                const birthDate = room[personType]?.dates?.[j] ? room[personType].dates[j] : date;

                if (onBirthDateChange) {
                  onBirthDateChange(fieldName, birthDate);
                }

                return birthDate;
              }) : [],
          },
        } : room),
      ),
    }),
    );
  };

  const setRoomsValue = (selectedDate: string | null, personIndex: number, dateOnly = false) => {
    const fieldName = `Custom.Birthdates.${groupIndex}.${personType}.${personIndex}`;

    if (onBirthDateChange) {
      onBirthDateChange(fieldName, selectedDate);
    }

    if (!dateOnly) {
      const newValue = {
        ...value,
        rooms: value.rooms.map(
          (room: IRoom, i: number) => (i === groupIndex ? {
            ...room,
            [personType]: {
              ...room[personType],
              dates: value.rooms[groupIndex][personType].dates.length
                ? value.rooms[groupIndex][personType].dates.map((date: string, j: number) => (
                  j === personIndex ? selectedDate : date || '')) : selectedDate ? [selectedDate] : [''],
            },
          } : room),
        ),
      };
      onChange(newValue);
    }
  };

  const getSelectValue = (date: string) => {
    const value = moment().diff(moment(date, 'YYYY-MM-DD'), 'years');
    return value && isNumber(value) ? value.toString() : null;
  };

  const increaseHandler = () => {
    setParticipants(count + 1, personType, true);
  };

  const decreaseHandler = () => {
    setParticipants(count - 1, personType, true);
  };

  useEffect(() => {
    const ageOptions = [];

    if (includes(PARTICIPANTS_WITH_DATES, personType)) {
      for (let i = ageSettings[personType].min + 1; i < ageSettings[personType].max + 1; i++) {
        ageOptions.push(
          {
            label: i.toString(),
            value: i.toString(),
          },
        );
      }

      setAgeSelectOptions(ageOptions);
    }
  }, []);

  return (
    <FlexBox flexDirection="column" width="100%">
      <FlexBox
        style={{
          width: '100%', position: 'relative', alignItems: 'center',
        }}
      >
        <div>
          <div style={{
            fontSize: '14px', fontWeight: 'bold', whiteSpace: 'nowrap', height: '18px',
          }}
          >
            {t(`sb_field_${personType.toLowerCase()}`)}
          </div>
        </div>
        <FlexBox style={{
          marginLeft: 'auto', alignItems: 'center', position: 'relative',
        }}
        >
          <FlexBox style={{ zIndex: '1000' }} alignItems="center">
            <Button
              size={isMobileOnly ? 'large' : 'medium'}
              label={<FontAwesomeIcon icon={faMinus}/>}
              onClick={decreaseHandler}
              disabled={count <= (minValue ?? 0)}
            />
            <div style={{
              width: '40px',
              margin: '0 2px',
              fontSize: '13px',
              textAlign: 'center',
              alignItems: 'center',
            }}
            >
              {count}
            </div>
            <Button
              size={isMobileOnly ? 'large' : 'medium'}
              label={<FontAwesomeIcon style={{ margin: 'auto' }} icon={faPlus}/>}
              onClick={increaseHandler}
              disabled={!maxValue || count === maxValue}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {enableBirthDate && (
        <FlexBox>
          {includes(PARTICIPANTS_WITH_DATES, personType) && count > 0 && (
            <FlexBox width="100%" flexDirection="column" pt="small">
              {Array.from({ length: count }).map((item, personIndex) => (
                <FlexBox
                  key={personIndex}
                  flexDirection="column"
                  pb="small"
                >
                  <FlexBox alignItems="flex-start">
                    <FlexBox height="34px" alignItems="center">
                      <Text mr="tiny">{`${personIndex + 1}.`}</Text>
                    </FlexBox>
                    {birthDateMode === 'select' ? (
                      <Select
                        maxMenuHeight={500}
                        isClearable
                        isSearchable={false}
                        options={ageSelectOptions}
                        placeholder={t('lbl_insert_age')}
                        value={getSelectValue(calendarValue[personIndex])}
                        onChange={(selected: OptionTypeBase) => {
                          const value = selected?.value
                            ? moment().subtract(selected.value, 'y').format('YYYY-MM-DD')
                            : null;
                          setRoomsValue(value, personIndex);
                        }}
                        menuShouldScrollIntoView
                        {...{
                          ...isMobileOnly ? {
                            components: {
                              Menu,
                              IndicatorSeparator: () => null,
                            },
                          } : {},
                        }}
                        menuPortalTarget={document.body as HTMLBodyElement}
                      />
                    ) : (
                      <BirthDatesForParticipantsPicker
                        value={calendarValue[personIndex]}
                        onFocus={onFocus}
                        appendTo={appendCalendarTo}
                        mobileWithDrawer={false}
                        personIndex={personIndex}
                        roomIndex={groupIndex}
                        personType={personType}
                        onChange={setRoomsValue as any}
                      />
                    )}
                  </FlexBox>
                </FlexBox>
              ))}
            </FlexBox>
          )}
        </FlexBox>
      )}
    </FlexBox>
  );
};

Counter.defaultProps = defaultProps;

export default Counter;
