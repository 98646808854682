import React from 'react';

import { Styled } from './Button.styles';

type ButtonGroupProps = {
  children: React.ReactNode[]
}

const ButtonGroup = ({ children, ...props }: ButtonGroupProps) => (
  <Styled.ButtonGroup {...props}>
    {children}
  </Styled.ButtonGroup>
);

export default ButtonGroup;
