import styled from 'styled-components';
import { space } from 'styled-system';

const Radio = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: auto !important;
  margin: 0;
  line-height: 16px;
  user-select: none;
  ${space}

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const RadioLabel = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.textColor};
`;

const RadioIconOutline = styled.circle`
  stroke: ${({ theme }) => theme.colors.gray};
`;

const RadioIconDot = styled.circle`
  fill: transparent;
`;

const RadioIcon = styled.svg`
  flex-shrink: 0;
  align-self: center;
  width: 18px;
  height: 18px;

  * {
    transition: all 0.15s linear;
  }
`;

const RadioInput = styled.input`
  position: absolute;
  margin: 0 !important;
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + svg {
    ${RadioIconOutline} {
      stroke: ${({ theme }) => theme.colors.textColor};
    }

    ${RadioIconDot} {
      fill: ${({ theme }) => theme.colors.textColor};
    }
  }
}
`;

export const Styled = {
  Radio,
  RadioInput,
  RadioLabel,
  RadioIcon,
  RadioIconOutline,
  RadioIconDot,
};
