import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartArrowDown,
  faChartLine,
  faCartPlus,
  faEllipsisV,
  faLaptop,
  faPeopleRoof,
  faR,
  faUsers,
  faAngleDoubleRight,
  faCopy,
} from '@fortawesome/pro-solid-svg-icons';
import { has, includes } from 'lodash-es';
import { isFirefox, isIOS, isSafari } from 'react-device-detect';
import * as Sentry from '@sentry/react';
import clipboardCopy from 'clipboard-copy';
import moment from 'moment';

import { IDictionary, IFlightPart } from '@ess/types';

import {
  BASE_URL,
  MERLINX_MASK_ENDPOINT,
  OFFER_RESERVATION_ENDPOINT,
  TRANSPORT_DATE_DISPLAY_FORMAT,
} from '@ess/constants/api';

import { TRANSPORT_BUS, TRANSPORT_FLIGHT, OWN_TRANSPORT } from '@ess/constants/transport';
import { CONTENT_WINDOW_OPTIONS } from '@ess/constants/contentWindow';
import { AVAILABLE_STATUSES } from '@ess/constants/status';
import { COMBINED_OFFERS } from '@ess/constants/offer';

import { ISelectedRoom } from '@tourop/components/MultiRoom/types';

import { IV5OnlineOffer } from '@ess/v5-data-provider/interfaces';
import { useSelector } from '@ess/store/core';

import objectToURLParams from '@ess/utils/objectToURLParams';
import showToast, { TOAST_ERROR, TOAST_SUCCESS } from '@ess/utils/form/ShowToast';
import { offerHashGetterFunc } from '@ess/utils/protoHash/getOfferHashGetter';
import { getTransportType, getTransportDesc, getAirlines } from '@ess/utils/offerData/offerData';

import useBasket, { BASKET_ACTION_ADD, BASKET_ACTION_DELETE } from '@ess/hooks/useBasket';
import useOfferContentService from '@ess/hooks/useOfferContentService';
import useAgentSettings from '@ess/hooks/useAgentSettings';
import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';
import useFreePlaces from '@ess/hooks/useFreePlaces';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { Button, IconButton } from '@ess/ui/Button';
import Modal, { WaitModal } from '@ess/ui/Modal';
import Dropdown from '@ess/ui/Dropdown';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Box from '@ess/ui/Box';
import Loader from '@ess/ui/Loader';

import BasketControls from '@tourop/components/MultiRoom/components/Summary/BasketControls';
import AgencyAccessControl from '@tourop/components/AgencyAccessControl';
import loadable from '@loadable/component';
import { useReactBasket } from '@basket/hooks/useReactBasket';

const AvailabilityModal = loadable(() => import('@tourop/components/OfferElements/AvailabilityModal'));
const OfferCalculationModal = loadable(() => import('@tourop/components/OfferElements/OfferCalculationModal'));

const MultiRoom = loadable(() => import('@tourop/components/MultiRoom'), {
  fallback: (
    <FlexBox height={200} alignItems="center" justifyContent="center">
      <Loader size="32px" color="gray"/>
    </FlexBox>
  ),
});

const Omnibus = loadable(() => import('@tourop/components/ContentSections/Omnibus'), {
  fallback: (
    <FlexBox height={200} alignItems="center" justifyContent="center">
      <Loader size="32px" color="gray"/>
    </FlexBox>
  ),
});

type OfferMenuSelectionProps = {
  offerData: IV5OnlineOffer
  offerHashGetter: offerHashGetterFunc
  isMultiRoomMode?: boolean
  selectedRooms?: ISelectedRoom[]
}

type NewWindowTarget = '_blank' | '_self';

type TOfferNotAvailableModal = {
  open: boolean
  url: string,
  target: NewWindowTarget,
  error: string
}

const offerUnavailableDefaultState = {
  open: false,
  url: '',
  target: '_self' as NewWindowTarget,
  error: '',
};

const OFFER_ADD_TO_BASKET: string = '1';
const OFFER_DELETE_FROM_BASKET: string = '2';
const OFFER_DETAIL_PRICE_CALCULATION: string = '3';
const OFFER_AVAILABILITY_INFO: string = '4';
const OFFER_MERLIN_MASK: string = '5';
const OFFER_GOTO_OFFER_DETAILS: string = '6';
const OFFER_GOTO_RESERVATION_FORMULAR: string = '7';
const OFFER_MULTI_ROOM_MODE = '8';
const OFFER_PRICE_HISTORY: string = '9';
const OFFER_COPY: string = '10';

const BOOKING_FORMULAR: number = 1;
const DETAIL_FORMULAR: number = 2;

const defaultProps = {
  isMultiRoomMode: false,
  selectedRooms: [],
};

const OfferMenuSelection = ({
  offerData,
  offerHashGetter,
  isMultiRoomMode,
  selectedRooms,
}: OfferMenuSelectionProps) => {
  const { t } = useTranslation();
  const {
    results, setOffer, fetchSections, requestParams, isLoading,
  } = useOfferContentService();
  const { trackEvent } = useGoogleAnalytics();
  const [agentSettings] = useAgentSettings();
  const { state: SFContext } = useContext(AppConfigContext);
  const {
    basketRequest,
    isInBasket,
    isLoading: basketIsLoading,
  } = useBasket();
  const {
    isOfferInBasket,
  } = useReactBasket();
  const sfProtoHash = useSelector((state) => state.searchForm.protoHash);
  const [sfrProtoHash] = useQueryParam('sfr', StringParam);
  const [isMultiRoomOpen, setIsMultiRoomOpen] = useState(false);
  const [isPriceHistoryOpen, setIsPriceHistoryOpen] = useState(false);
  const [multiRoomAddToBasketIsOpen, setMultiRoomAddToBasketIsOpen] = useState(false);
  const [offerNotAvailableModalData, setOfferNotAvailableModalData] = useState<TOfferNotAvailableModal>(
    offerUnavailableDefaultState,
  );
  const [showPleaseWaitState, setShowPleaseWait] = useState(false);
  const [showPriceDetailsOnReady, setShowPriceDetailsOnReady] = useState(false);
  const [isDropDownMenuOpen, setDropDownMenu] = useState(false);
  const [priceDetailsState, setPriceDetailsState] = useState({
    isLoading: false,
    showModal: false,
    data: {},
  });
  const places = useFreePlaces(offerData);
  const [placesShowModal, setPlacesShowModal] = useState(false);
  const { Base, Transport, Accommodation } = offerData;
  const { Operator, Price, Catalog } = Base;
  const {
    configOper, type, isBasketOn, expedientPermissions,
  } = SFContext;

  const transportType = getTransportType(Transport);
  const operatorConfig = has(configOper, Operator as string) ? configOper[Operator as string] : configOper.default;
  const priceStatus = offerData.fieldStatus('Base.Price');
  const isFlight = transportType === TRANSPORT_FLIGHT;
  const isBus = transportType === TRANSPORT_BUS;
  const returnTitle = 'offer_not_available';
  const returnButtonTitle = 'go_to_unavailable_offer';
  const returnDescription = 'offerNotAvailableModalData.error';

  /**
   * Closes MultiRoom add to basket modal.
   */
  const closeMultiRoomAddToBasketModal = () => {
    setMultiRoomAddToBasketIsOpen(false);
  };

  /**
   * Closes MultiRoom.
   */
  const closeMultiRoomModal = () => {
    setIsMultiRoomOpen(false);
  };

  /**
   * Opens MultiRoom.
   */
  const showMultiRoomModal = () => {
    setIsMultiRoomOpen(true);
  };

  /**
   * Opens Price History.
   */
  const showPriceHistoryModal = () => {
    setIsPriceHistoryOpen(true);
  };

  /**
   * Closes Price History.
   */
  const closePriceHistoryModal = () => {
    setIsPriceHistoryOpen(false);
  };

  /**
   * Opens window with merlin mask.
   */
  const openMerlinMask = () => {
    const url = `${BASE_URL}/${type}/${MERLINX_MASK_ENDPOINT}${offerHashGetter()}`;
    const params = objectToURLParams({
      CONTENT_WINDOW_OPTIONS,
      width: 725,
      height: 510,
    }, ',');

    window.open(url, 'merlinMask', params);
  };
  /**
   * Checks offer status and redirects to next step.
   * @param pageType
   */
  const goToOfferDetails = (pageType: number): void => {
    const newWindowMode = agentSettings.openOfferInNewWindow;
    const target = newWindowMode ? '_blank' : '_self';
    const queryParams = objectToURLParams({
      so: offerHashGetter(),
      sf: sfProtoHash,
      sfr: sfrProtoHash,
    });

    const pageUrlMap: IDictionary<string> = {
      [BOOKING_FORMULAR]: `${BASE_URL}${type}/${OFFER_RESERVATION_ENDPOINT}${queryParams}`,
      [DETAIL_FORMULAR]: `${BASE_URL}${type}/details/?${queryParams}`,
    };
    if (newWindowMode && (isIOS || isFirefox || isSafari)) {
      window.open(pageUrlMap[pageType], target);
    } else {
      (async () => {
        try {
          setShowPleaseWait(true);
          const { offer, status } = await offerData.getWithOnlineResponse(['Base.Availability']);
          if (status.isError) {
            showToast(TOAST_ERROR, t('unknown_error_try_again_later'));
          } else if (!includes(AVAILABLE_STATUSES, offer.Base?.Availability?.base)) {
            if (pageType === BOOKING_FORMULAR) {
              showToast(TOAST_ERROR, t('offer_not_available'));
            } else {
              setOfferNotAvailableModalData({
                open: true,
                url: pageUrlMap[pageType],
                target,
                error: offer.Base?.Availability?.message ?? '',
              });
            }
            setShowPleaseWait(false);
          } else {
            window.open(pageUrlMap[pageType], target);
          }
        } catch (error) {
          showToast(TOAST_ERROR, t('unknown_error_try_again_later'));
          setShowPleaseWait(false);
          Sentry.captureException(error);
        } finally {
          setShowPleaseWait(false);
        }
      })();
    }
  };

  /**
   * Indicates if price details are ready.
   */
  const offerDetailPriceCalculation = (): void => {
    const { isLoading } = offerData.sendOnlineRequest({ fields: ['Base.Price'] });
    if (isLoading) {
      setShowPriceDetailsOnReady(true);
      setPriceDetailsState((state) => ({
        ...state,
        showModal: true,
        isLoading: true,
      }));
    } else {
      setPriceDetailsState((state) => ({
        ...state,
        showModal: true,
      }));
    }
  };

  const closeAvailabilityModal = () => {
    setPlacesShowModal(false);
  };

  const closeOfferCalculationModal = () => {
    setPriceDetailsState((state) => ({
      ...state,
      showModal: false,
      isLoading: false,
    }));
  };

  const offerAvailabilityInfo = (): void => {
    places.fetch();
    setPlacesShowModal(true);
  };

  const deleteBasketItem = (): void => {
    basketRequest({
      actionType: BASKET_ACTION_DELETE,
      offer: [offerData],
    });
  };

  const addBasketItem = (): void => {
    if (includes(COMBINED_OFFERS, offerData?.Accommodation?.Type?.Id)) {
      showToast(TOAST_ERROR, t('basket_choose_hotel_message'));
    } else {
      basketRequest({
        actionType: BASKET_ACTION_ADD,
        offer: offerData,
      });
    }
  };

  const actionBasket = (actionType: string): void => {
    if (actionType === BASKET_ACTION_ADD) { addBasketItem(); }
    if (actionType === BASKET_ACTION_DELETE) { deleteBasketItem(); }
  };

  const checkIfInBasket = () => {
    if (window.GlobalVariables.isBasketGoOn) {
      return isOfferInBasket(offerData);
    }
    return isInBasket(offerData);
  };

  const getTransportData = (segment: IFlightPart, type: string, k: number, isDateChanged: boolean) => {
    let transportData = '';
    if (k === 0) {
      transportData += t(type + (isFlight ? '_flight' : '_bus'));
      transportData += `: ${moment(segment?.Departure?.Date).format(TRANSPORT_DATE_DISPLAY_FORMAT)}`;
    }
    transportData += `\n\t   ${segment.Departure?.Time} ${segment.Departure?.Code} ${segment.Departure?.Name}`;
    transportData += `\t ---->  ${segment.Destination?.Time} ${segment.Destination?.Code} ${segment.Destination?.Name}`;
    if (isDateChanged) {
      transportData += ' (+1)';
    }
    if (isFlight) {
      const { outAirline = false, retAirline = false } = getAirlines(offerData.Transport);
      transportData += `\n\t   ${t('lbl_line')}: ${outAirline}`;
    }
    return transportData;
  };

  const getTransportDetails = (segments: IFlightPart[], type: string) => {
    let transportDetails: string = '';
    segments.map((segment: IFlightPart, k: number) => {
      transportDetails += `\t ${getTransportData(segment, type, k, (k === 0 ? false : segments[k - 1].Departure?.Date !== segment.Destination?.Date))}`;
      if (segments.length === k + 1) {
        if (segments[0].Luggage) {
          transportDetails += `\n\t ${t('lbl_luggage')}:`;
          (['Hand', 'Registered'] as Array<'Hand' | 'Registered'>)
            .map((luggageType, l: number) => {
              const luggageSection = segments[0].Luggage as any;
              const typeOfLuggageFull = t(`lbl_${luggageType.toLowerCase()}_luggage`);
              const luggageDesc = luggageSection[luggageType];
              if (luggageDesc) {
                transportDetails += ` ${typeOfLuggageFull} ${luggageDesc}${(l === 0) ? '\n\t  ' : ''}`;
              }
            });
        }
        if (segments[0].AvailableSeatsCount !== undefined) {
          transportDetails += `\n\t ${t('check_avail_seats')}: ${segments[0].AvailableSeatsCount}`;
        }
      }
    });
    return transportDetails;
  };

  const getAccommodationDeails = () => {
    const accomodationData = [
      {
        label: 'lbl_maintenance',
        desc: `${Accommodation?.Service?.Name}${Accommodation?.Service?.Id ? ` (${Accommodation?.Service?.Id})` : ''}`,
      },
      {
        label: 'lbl_accommodation',
        desc: `${Accommodation?.Room?.Name}${Accommodation?.Room?.Id ? ` (${Accommodation?.Room?.Id})` : ''}`,
      },
      {
        label: 'hotel_places_header',
        desc: operatorConfig.showHotelPlaces && Accommodation?.AvailableRoomsCount
          ? Accommodation?.AvailableRoomsCount : '',
      },
      {
        label: 'lbl_tfg',
        desc: Price?.FirstPerson?.details?.TFGIncluded ? t('tfg_included') : t('tfg_not_included'),
      },
      {
        label: 'lbl_catalog',
        desc: Catalog ?? '',
      },
    ];
    let accomodationDetails = `${t('lbl_accomodation_details')}:`;
    accomodationData.forEach((item) => {
      if (item.desc) {
        accomodationDetails += `\n\t${t(item.label)}: ${item.desc}`;
      }
    });

    return accomodationDetails;
  };

  const getCopyText = () => {
    const { Out: transportOutSegments = [], Ret: transportRetSegments = [] } = transportType !== OWN_TRANSPORT
      ? getTransportDesc(Transport) : {};
    let copyText = isFlight ? t('lbl_flight_details') : isBus ? t('lbl_bus_details') : '';
    if (isFlight || isBus) {
      copyText += ':\n';
      copyText += getTransportDetails(transportOutSegments, 'departure');
      copyText += '\n\n';
      copyText += getTransportDetails(transportRetSegments, 'destination');
      copyText += '\n\n';
    }
    // accomodation details
    copyText += getAccommodationDeails();
    return copyText;
  };

  /**
   * function offerMenuItemClick : manage all the clicks from the offermenus
   */
  const offerMenuItemClick = (selectedOption: string): void => {
    switch (selectedOption) {
      case OFFER_MULTI_ROOM_MODE:
        showMultiRoomModal();
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'SHOW_MULTIROOM_MODAL',
        });
        break;
      case OFFER_ADD_TO_BASKET:
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'OFFER_ADD_TO_BASKET',
        });

        if (isMultiRoomMode) {
          setMultiRoomAddToBasketIsOpen(true);
        } else {
          actionBasket(BASKET_ACTION_ADD);
        }

        break;
      case OFFER_DELETE_FROM_BASKET:
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'OFFER_DELETE_FROM_BASKET',
        });
        actionBasket(BASKET_ACTION_DELETE);
        break;
      case OFFER_AVAILABILITY_INFO:
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'OFFER_AVAILABILITY_INFO',
        });
        offerAvailabilityInfo();
        break;
      case OFFER_DETAIL_PRICE_CALCULATION:
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'OFFER_DETAIL_PRICE_CALCULATION',
        });
        offerDetailPriceCalculation();
        break;
      case OFFER_MERLIN_MASK:
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'OFFER_MERLINX_MASK',
        });
        openMerlinMask();
        break;
      case OFFER_GOTO_OFFER_DETAILS:
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'OFFER_GOTO_OFFER_DETAILS',
        });
        goToOfferDetails(DETAIL_FORMULAR);
        break;
      case OFFER_GOTO_RESERVATION_FORMULAR:
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'OFFER_GOTO_RESERVATION_FORMULAR',
        });
        goToOfferDetails(BOOKING_FORMULAR);
        break;
      case OFFER_PRICE_HISTORY:
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'OFFER_PRICE_HISTORY',
        });
        showPriceHistoryModal();
        break;
      case OFFER_COPY:
        trackEvent({
          event: 'offerActions',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_OFFER_ACTIONS',
          eventLabel: 'OFFER_COPY',
        });
        clipboardCopy(getCopyText()).then(
          (() => showToast(TOAST_SUCCESS, t('copied_to_clipboard_msg'))),
          (() => showToast(TOAST_ERROR, t('copy_to_clipboard_failed_msg'))),
        );
        break;
      default:
        break;
    }
  };
  // search for the Operator default settings, need to show / or notshow menuitems
  const menuItems = [];
  const showPlaces = (
    (isFlight && operatorConfig.showFlightPlaces)
    || (isBus && operatorConfig.showBusPlaces)
    || operatorConfig.showHotelPlaces
  );
  if (isDropDownMenuOpen) { // checking if the click on the drop down is done
    if (isBasketOn) {
      if (!checkIfInBasket() || isMultiRoomMode) {
        menuItems.push({
          label: (t('lbl_menu_basketadd')),
          value: OFFER_ADD_TO_BASKET,
          icon: (<FontAwesomeIcon icon={faCartPlus}/>),
          disabled: basketIsLoading || (isMultiRoomMode && !selectedRooms?.length),
        });
      } else {
        menuItems.push(
          {
            label: (t('lbl_menu_basketremove')),
            value: OFFER_DELETE_FROM_BASKET,
            icon: (<FontAwesomeIcon icon={faCartArrowDown}/>),
            disabled: basketIsLoading,
          },
        );
      }
    }

    if (isMultiRoomMode) {
      menuItems.push({
        label: (t('multiroom')),
        value: OFFER_MULTI_ROOM_MODE,
        icon: (<FontAwesomeIcon icon={faPeopleRoof}/>),
      });
    } else {
      if (operatorConfig.isOnline === true) {
        menuItems.push({
          label: (t('lbl_menu_pricelist')),
          value: OFFER_DETAIL_PRICE_CALCULATION,
          icon: (<FontAwesomeIcon icon={faUsers}/>),
        });

        if (showPlaces) {
          menuItems.push({
            label: (t('lbl_menu_places')),
            icon: 'sitting',
            value: OFFER_AVAILABILITY_INFO,
          });
        }
      }

      if (operatorConfig.hasMask === true) {
        menuItems.push({
          label: (t('lbl_menu_maskmerlin')),
          icon: (<FontAwesomeIcon icon={faLaptop}/>),
          value: OFFER_MERLIN_MASK,
        });
      }
    }

    const hasBookingPermission = (
      operatorConfig.isBookable
      && operatorConfig.hasQuickBook
      && operatorConfig.isOnline
      && (!expedientPermissions || expedientPermissions?.makeBooking)
    );

    menuItems.push({
      label: (t('lbl_menu_details')),
      icon: (<FontAwesomeIcon icon={faAngleDoubleRight}/>),
      value: OFFER_GOTO_OFFER_DETAILS,
    });

    menuItems.push({
      label: (t('lbl_menu_quickbook')),
      value: OFFER_GOTO_RESERVATION_FORMULAR,
      icon: (<FontAwesomeIcon icon={faR}/>),
      disabled: !hasBookingPermission || isMultiRoomMode,
    });

    if (!isMultiRoomMode) {
      menuItems.push({
        label: (t('lbl_price_change_history')),
        icon: (<FontAwesomeIcon icon={faChartLine}/>),
        value: OFFER_PRICE_HISTORY,
      });
    }

    menuItems.push({
      label: (t('lbl_copy')),
      value: OFFER_COPY,
      icon: (<FontAwesomeIcon icon={faCopy}/>),
    });
  }

  useEffect(() => {
    if (!showPriceDetailsOnReady) {
      return;
    }
    if (priceStatus.isError) {
      showToast(TOAST_ERROR, t('offer_not_available'));
    }
    if (priceStatus.isReady) {
      setShowPriceDetailsOnReady(false);
      setPriceDetailsState((state) => ({
        ...state,
        showModal: true,
        isLoading: false,
      }));
    }
  }, [showPriceDetailsOnReady, priceStatus.isLoading]);

  useEffect(() => {
    setOffer(offerData as any);
  }, [offerData]);

  useEffect(() => {
    if (isMultiRoomOpen) {
      fetchSections({ sections: ['MultiRoomFinder'] });
    }
  }, [isMultiRoomOpen]);

  useEffect(() => {
    if (isPriceHistoryOpen) {
      fetchSections({ sections: ['Omnibus'], combine: false });
    }
  }, [isPriceHistoryOpen]);

  return (
    <>
      <Modal
        isOpen={multiRoomAddToBasketIsOpen}
        onClose={closeMultiRoomAddToBasketModal}
        title={`${t('multiroom')}: ${t('add_to_basket')}`}
      >
        <Box p="small">
          <BasketControls
            selectedRooms={selectedRooms as any}
            onAddToBasket={closeMultiRoomAddToBasketModal}
            allParticipantsSettled
            isModal
          />
        </Box>
      </Modal>
      <AvailabilityModal
        isOpen={placesShowModal}
        isLoading={places.status.isLoading}
        offerAjaxAnswer={places}
        showFlightPlaces={operatorConfig.showFlightPlaces}
        showHotelPlaces={operatorConfig.showHotelPlaces}
        onClose={closeAvailabilityModal}
      />
      <Modal
        title={t('lbl_price_change_history')}
        isOpen={isPriceHistoryOpen}
        width={1600}
        maxHeight="90%"
        onClose={closePriceHistoryModal}
        controls={(
          <FlexBox
            px="small"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              variant="secondary"
              label={t('lbl_close')}
              width={100}
              size="medium"
              onClick={closePriceHistoryModal}
            />
          </FlexBox>
        )}
      >
        <AgencyAccessControl moduleName="Omnibus">
          {includes(isLoading, 'Omnibus') ? (
            <FlexBox height={200} alignItems="center" justifyContent="center">
              <Loader size="32px" color="gray"/>
            </FlexBox>
          ) : (
            <Omnibus
              data={results?.Sections?.Omnibus ?? undefined}
              offerData={offerData}
              showOffer
            />
          )}
        </AgencyAccessControl>
      </Modal>
      <Modal
        title={t('multiroom')}
        isOpen={isMultiRoomOpen}
        width={1600}
        maxHeight="90%"
        onClose={closeMultiRoomModal}
        controls={(
          <FlexBox
            px="small"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              variant="secondary"
              label={t('lbl_close')}
              width={100}
              size="medium"
              onClick={closeMultiRoomModal}
            />
          </FlexBox>
        )}
      >
        {!results?.Sections.MultiRoomFinder ? (
          <FlexBox height={200} alignItems="center" justifyContent="center">
            <Loader size="32px" color="gray"/>
          </FlexBox>
        ) : (
          <MultiRoom
            data={results?.Sections.MultiRoomFinder ?? undefined}
            rooms={selectedRooms}
            fetchAsync={fetchSections as any}
            stickyElementOffsetTop={0}
            requestParams={requestParams}
          />
        )}

      </Modal>
      <Modal
        isOpen={offerNotAvailableModalData.open}
        title={t(returnTitle)}
        onClose={() => {
          setOfferNotAvailableModalData(offerUnavailableDefaultState);
        }}
        controls={(
          <FlexBox
            px="small"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Button
              mr="small"
              variant="primary"
              label={t(returnButtonTitle)}
              size="medium"
              pl="20px"
              pr="20px"
              onClick={() => {
                window.open(offerNotAvailableModalData.url, offerNotAvailableModalData.target);
                setOfferNotAvailableModalData(offerUnavailableDefaultState);
              }}
            />
          </FlexBox>
        )}
      >
        <Box p="small">
          <FlexBox alignItems="center" style={{ justifyContent: 'center', flexDirection: 'column', gap: '10px' }}>
            <Text>{t(returnDescription)}</Text>
            <Text style={{ fontWeight: 'bold' }}>
              {offerNotAvailableModalData.error}
            </Text>
          </FlexBox>
        </Box>
      </Modal>
      <OfferCalculationModal
        offerData={offerData}
        isLoading={priceDetailsState.isLoading}
        isOpen={priceDetailsState.showModal}
        onClose={closeOfferCalculationModal}
      />
      <Dropdown
        onItemClick={offerMenuItemClick}
        options={menuItems}
        menuMinWidth={170}
        onOpen={() => setDropDownMenu(true)}
        onClose={() => setDropDownMenu(false)}
        hoverOpensDropdown={agentSettings.dropdownMouseover}
        target={(
          <IconButton
            icon={(<FontAwesomeIcon icon={faEllipsisV}/>)}
            radius="rounded"
            size="small"
          />
        )}
      />
      <WaitModal
        message={t('check_offer_avail_wait')}
        isOpen={showPleaseWaitState}
      />
    </>
  );
};

OfferMenuSelection.defaultProps = defaultProps;

export default OfferMenuSelection;
