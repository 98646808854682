import React from 'react';

import Skeleton from '@ess/ui/Skeleton';
import FlexBox from '@ess/ui/FlexBox';

const ImageSliderSkeleton = () => (
  <FlexBox flexDirection="column">
    <Skeleton bg="#e1e3e6" height="720px" width="100%" />
    <FlexBox width="100%" p="medium" height="92px" overflow="hidden">
      {Array.from({ length: 20 }).map((_, index) => (
        <FlexBox key={index} width="100px" flexShrink={0} mr={10}>
          <Skeleton borderRadius="6px" height="100%" width="100%" />
        </FlexBox>
      ))}
    </FlexBox>
  </FlexBox>
);

export default ImageSliderSkeleton;
