import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';
import CheckBox from '@ess/ui/Form/Checkbox';

const CustomLegend = (props: any) => {
  const { t } = useTranslation();
  const { onClick, payload, translationsMap } = props;
  return (
    <FlexBox
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      {payload.length > 1 ? payload.map((item: any, index: number) => {
        const isLastItem = payload.length - 1 === index;
        const label = t(translationsMap[item.dataKey]);

        return (
          <FlexBox
            key={item.dataKey}
            mr={!isLastItem ? 'medium' : null}
          >
            <CheckBox
              checked={!item.inactive}
              fillColor={item.color}
              label={<Text fontWeight="bold">{label}</Text>}
              onClick={() => onClick({ dataKey: item.dataKey })}
            />
          </FlexBox>
        );
      }) : (
        <Text fontWeight="bold">{t(translationsMap[payload[0].dataKey])}</Text>
      )}
    </FlexBox>
  );
};

export default CustomLegend;
