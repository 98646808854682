import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-color: #f6f6f6;
  justify-content: flex-end;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const Chart__ExpandButton = styled.div`
  margin: 15px 15px 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.green};
  text-decoration: underline;

  span {
    margin-right: 5px;
  }
`;

const Chart = styled.div<{ height?: number, isExpandable?: boolean }>`
  height: ${({ height }) => `${height}px`};
  width: 100%;
  overflow-x: hidden;
  padding: 15px 30px 15px;
  position: relative;
`;

const ChartBar__Value = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex-grow: 1;
`;

const ChartBar__Value__Inner = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.navy};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-weight: bold;
  justify-content: center;
  align-items: flex-end;
  padding: 5px;
  border-radius: ${({ theme }) => theme.radii.default};
  width: 100%;
`;

const ChartBar__Date = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 5px;
  height: 40px;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.navy};
  border-radius: ${({ theme }) => theme.radii.default};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.caption};
  white-space: pre;
`;

const ChartBar = styled.div<{ selected?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 60px;

  ${({ selected, theme }) => (selected ? `
    ${ChartBar__Value__Inner} {
       background-color: ${theme.colors.primary};
    }

    ${ChartBar__Date} {
       border-color: ${theme.colors.primary};
    }
  ` : `
    &:hover {
       ${ChartBar__Value__Inner} {
         background-color: #3b62a2;
       }

       ${ChartBar__Date} {
         border-color: #3b62a2;
       }
      }
  `)}
`;

export const Styled = {
  Chart,
  ChartBar,
  Chart__ExpandButton,
  ChartBar__Value,
  ChartBar__Value__Inner,
  ChartBar__Date,
  Container,
};
