import { useEffect, useState } from 'react';

import { IDictionary } from '@ess/types';

import ProtoHash, { ProtoHashTypes } from '@ess/protohash';

/**
 * useProtoHash hook.
 * Creates proto hash from given payload.
 * @param type
 * @param compressionLevel
 */

const useProtoHash = (type: ProtoHashTypes, compressionLevel = 9) => {
  const [protoHashPayload, setProtoHashPayload] = useState<any>(null);
  const [protoHash, setProtoHash] = useState<string | null>(null);

  const ProtoInstance = new ProtoHash(type, compressionLevel);

  /**
   * Returns encoded message hash.
   * @param payload
   */
  const encode = (payload: any): string | null => ProtoInstance.encode(payload);

  /**
   * Returns decoded message.
   * @param hash
   * @param withDefaults
   */
  const decode = (hash: string, withDefaults?: boolean): IDictionary<any> | null => ProtoInstance.decode(hash, withDefaults);

  useEffect(() => {
    if (protoHashPayload !== null) {
      setProtoHash(encode(protoHashPayload));
    }
  }, [protoHashPayload]);

  return {
    protoHash,
    decode,
    encode,
    setProtoHashPayload,
  };
};

export default useProtoHash;
