import React from 'react';

import Icon from '@ess/ui/Icon';
import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';

import { Styled } from './HotelAttribute.styles';

type HotelAttributeProps = {
  iconName: string
  isHighlighted: boolean
  descriptionMode: string | undefined
  label: string
};

const HotelAttribute = ({
  iconName,
  isHighlighted,
  descriptionMode,
  label,
}: HotelAttributeProps) => {
  const isTooltipMode = descriptionMode && descriptionMode === 'tooltip';

  const AttributeElement = (
    <Styled.HotelAttribute
      isHighlighted={isHighlighted}
      withLabel={!isTooltipMode}
    >
      <Icon size="inherit" iconName={iconName} />
      {!isTooltipMode && (<Text fontSize="12px" ml="tiny">{label}</Text>)}
    </Styled.HotelAttribute>
  );

  return (
    <>
      {isTooltipMode ? (
        <Tooltip content={label}>
          {AttributeElement}
        </Tooltip>
      ) : (
        AttributeElement
      )}
    </>
  );
};

export {
  HotelAttribute,
};
