import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { toNumber } from 'lodash-es';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import showToast, { TOAST_ERROR, TOAST_SUCCESS } from '@ess/utils/form/ShowToast';

import { Sections } from '@ess/hooks/useOfferContentService';

import { promiseRequest } from '@ess/v5-data-provider/request';

import { ISelectedRoom } from '@tourop/components/MultiRoom/types';

import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';
import useSearchType from '@ess/hooks/useSearchType';
import useBasket from '@ess/hooks/useBasket';

import { Button } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Checkbox from '@ess/ui/Form/Checkbox';
import TextInput from '@ess/ui/Form/TextInput';
import { BASKET_STORAGE_SECTION } from '@basket/constants';

type BasketControlsProps = {
  selectedRooms: ISelectedRoom[]
  swapRoomMode?: boolean
  isModal?: boolean
  allParticipantsSettled?: any
  onAddToBasket?: () => void
}

const defaultProps = {
  swapRoomMode: false,
  isModal: false,
  allParticipantsSettled: true,
  onAddToBasket: undefined,
};

const BasketControls = ({
  selectedRooms,
  swapRoomMode,
  isModal,
  allParticipantsSettled,
  onAddToBasket,
}: BasketControlsProps) => {
  const { t } = useTranslation();
  const { type } = useSearchType();
  const { basketId } = useBasket();
  const { trackEvent } = useGoogleAnalytics();
  const [sfProtoHash] = useQueryParam('sf', StringParam);
  const [createNewBasket, setCreateNewBasket] = useState(false);
  const [newBasketNameError, setNewBasketNameError] = useState(false);
  const [newBasketName, setNewBasketName] = useState('');
  const [isBasketLoading, setIsBasketLoading] = useState(false);

  /**
   * New basket name handler.
   * @param event
   */
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewBasketName(event.target.value);
    setNewBasketNameError(!event.target.value);
  };

  /**
   * Add rooms to basket.
   */
  const addToBasketHandler = () => {
    setNewBasketNameError(!newBasketName);

    if (createNewBasket && !newBasketName) {
      return;
    }

    if (onAddToBasket) {
      onAddToBasket();
    }

    (async () => {
      try {
        setIsBasketLoading(true);
        const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
        let currentBasketId = basketId;

        if (createNewBasket) {
          if (window.GlobalVariables.isBasketGoOn) {
            const response = await window[BASKET_STORAGE_SECTION].createBasket(newBasketName);
            currentBasketId = response.id;
          } else {
            const newBasketRequest = await promiseRequest(`${CONTENT_SERVICE_URL}${Sections.Basket}`, {
              Language: 'PL',
              Currency: 'PLN',
              Basket: {
                Action: 'new',
                Name: newBasketName,
              },
            });

            currentBasketId = newBasketRequest?.Sections?.Basket?.Id;
          }
        }

        if (window.GlobalVariables.isBasketGoOn) {
          window[BASKET_STORAGE_SECTION].addItem({
            ItemType: 'merlin',
            Item: selectedRooms.map((room, index: number) => ({
              Hash: room.Offer.Base?.OfferId,
              Name: `${alphabet[index]}:${room.Group.description ?? `${t('room')} ${index + 1}`}`,
              Group: `${index + 1}`,
            })),
            Search: {
              Form: type,
              Hash: sfProtoHash,
            },
          }, {
            success: t('rooms_added_to_basket_success'),
            fail: t('rooms_added_to_basket_fail'),
          });
        } else {
          await promiseRequest(`${CONTENT_SERVICE_URL}${Sections.Basket}`, {
            Language: 'PL',
            Currency: 'PLN',
            Basket: {
              Action: 'add',
              Id: toNumber(currentBasketId),
              SearchType: type,
              SearchHash: sfProtoHash,
              Offers: selectedRooms.map((room, index: number) => ({
                OfferId: room.Offer.Base?.OfferId,
                Name: `${alphabet[index]}:${room.Group.description ?? `${t('room')} ${index + 1}`}`,
              })),
            },
          }, 3);

          showToast(TOAST_SUCCESS, t('rooms_added_to_basket_success'));

          if (typeof window.MXSmallBasket !== 'undefined' && currentBasketId) {
            window.MXSmallBasket.reload(currentBasketId);
          }
        }

        trackEvent({
          event: 'multiroom',
          eventCategory: 'B2B_CLICK_EVENT',
          eventAction: 'B2B_ADD_ROOMS_TO_BASKET',
          eventLabel: newBasketName,
        });
      } catch (e) {
        console.log(e);
        showToast(TOAST_ERROR, t('rooms_added_to_basket_fail'));
      } finally {
        setIsBasketLoading(false);
      }
    })();
  };

  /**
   * New basket checkbox handler.
   * @param event
   */
  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setCreateNewBasket(isChecked);

    if (!isChecked) {
      setNewBasketName('');
    }
  };

  return (
    <>
      <FlexBox pb={isModal ? 'small' : 'medium'} flexDirection="column">
        <Checkbox label={t('lbl_create_new_basket')} checked={createNewBasket} onChange={onCheckboxChange}/>
        {createNewBasket && (
          <FlexBox pt={isModal ? 'small' : 'medium'}>
            <TextInput
              value={newBasketName}
              onChange={onInputChange}
              onClear={() => setNewBasketName('')}
              placeholder={t('new_basket_name')}
              isClearable
              {...{ ...newBasketNameError ? { error: t('lbl_provide_basket_name') } : {} }}
            />
          </FlexBox>
        )}
      </FlexBox>
      <Button
        width="100%"
        disabled={!allParticipantsSettled || swapRoomMode}
        startIcon={(<FontAwesomeIcon icon={faCartPlus}/>)}
        label={t('add_to_basket')}
        isLoading={isBasketLoading}
        onClick={addToBasketHandler}
      />
    </>
  );
};

BasketControls.defaultProps = defaultProps;

export default BasketControls;
