import { getTransportDesc } from '@ess/utils/offerData/offerData';
import { IFlightPart, IOffer } from '@ess/types';

/**
 * Returns free places.
 * @param offer
 */
const getFreePlaces = (offer: IOffer) => {
  let transport: any = [];

  const {
    Out: transportOutSegments = [],
    Ret: transportRetSegments = [],
  } = getTransportDesc(offer.Transport as any);

  transport = [...transportOutSegments, ...transportRetSegments].map((v: IFlightPart) => ({
      Free: v.AvailableSeatsCount ?? '?',
      Code: `${v.Departure?.Code ?? ''} - ${v.Destination?.Code}` ?? '',
    }),
  );

  const hotel = [{
    Free: offer?.Accommodation?.AvailableRoomsCount ?? '?',
    Code: `${offer?.Accommodation?.Room?.Id ?? ''} - ${offer?.Accommodation?.Room?.Name ?? ''}`,
  }];

  return {
    transport,
    hotel,
    offerStatus: offer?.Base?.Availability?.base ?? 'unknown',
    statusMessage: offer?.Base?.Availability?.message ?? '',
  }
}

export {
  getFreePlaces,
}
