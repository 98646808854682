import React from 'react';
import {
  SpaceProps, LayoutProps, BorderProps, FlexboxProps,
} from 'styled-system';

import { Styled } from './Skeleton.styles';

type StyledSystemProps = SpaceProps & LayoutProps & BorderProps & FlexboxProps;

type SkeletonProps = {
  children?: React.ReactNode,
  height?: string,
  width?: string,
  bg?: string,
  borderRadius?: string,
} & StyledSystemProps;

const defaultProps = {
  children: '',
  height: '20px',
  width: '100px',
  borderRadius: '3px',
  bg: 'lightGray',
};

const Skeleton = ({
  children, bg, ...props
}: SkeletonProps) => (
  <Styled.Skeleton
    bgColor={bg}
    {...props}
  >
    {children && children}
  </Styled.Skeleton>
);

Skeleton.defaultProps = defaultProps;

export default Skeleton;
