import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faPlane, faUmbrellaBeach } from '@fortawesome/pro-solid-svg-icons';
import React, { MouseEventHandler } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import FlexBox from '../../FlexBox';

type TStackedMarker = {
    onClick?: MouseEventHandler<SVGSVGElement | HTMLDivElement>
    mainColor: string
    secondaryColor: string
    secondaryIcon: {iconName: string} & IconProp
}

const defaultProps = {
  onClick: () => {},
};

const iconFixProps: {[key: string]: {mt?: string, ml?: string, mb?: string, mr?: string}} = {
  'umbrella-beach': { mt: '2px', ml: '-2px' },
  plane: { mt: '2px', ml: '1px' },
};

const StackedMarker = ({
  onClick, mainColor, secondaryColor, secondaryIcon,
}: TStackedMarker) => (
  <FlexBox display="grid" style={{ gridColumn: '1fr', gridRow: '1fr' }}>
    <FontAwesomeIcon
      icon={faMapMarker}
      color={mainColor}
      size="3x"
      onClick={onClick}
      style={{ gridArea: '1 / 1 / 1 / 1', cursor: 'pointer' }}
    />
    <FlexBox
      style={{ gridArea: '1 / 1 / 1 / 1', transform: 'scale(0.6)', cursor: 'pointer' }}
      justifyContent="center"
      width="min-content"
      height="min-content"
      {...iconFixProps[secondaryIcon.iconName]}
    >
      <FlexBox onClick={onClick}>
        <FontAwesomeIcon
          icon={secondaryIcon}
          color={secondaryColor}
          size="2x"
        />
      </FlexBox>
    </FlexBox>
  </FlexBox>
);

StackedMarker.defaultProps = defaultProps;

export default StackedMarker;
