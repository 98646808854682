import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  has, includes, isNumber,
} from 'lodash-es';

import { IDictionary } from '@ess/types';

import { getUniqueObjectId } from '@ess/utils/offerData/getUniqueObjectId';
import { getParticipantsHash } from '@ess/utils/protoHash/getOfferId';
import showToast, { TOAST_SUCCESS } from '@ess/utils/form/ShowToast';

import useStorage from '@ess/hooks/useStorage';
import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Loader from '@ess/ui/Loader';

import ParticipantsNewVersion from '@tourop/components/ParticipantsPicker';

import { getOfferByPax } from './utils/getOfferByPax';
import { getRoomList } from './utils/getRoomList';
import { getRoomsMaintenance } from './utils/getRoomsMaintenance';
import { getRecommendedRooms } from './utils/getRecommendedRooms';
import getTotalPrice from './utils/getTotalPrice';

import RoomsGrid from './components/RoomsGrid';
import Summary from './components/Summary';
import ServiceSelect from './components/ServiceSelect';

import { IMultiRoomResponse, IRoom, ISelectedRoom } from './types';

type MultiRoomProps = {
  data: IMultiRoomResponse
  rooms?: ISelectedRoom[]
  fetchAsync: ({ sections, params }: { sections: string[], params: any }) => void
  requestParams: any
  stickyElementOffsetTop?: number
}

const getDefaultRooms = (request: any = null) => {
  const isGroup = request && has(request, 'ParticipantGroups');
  const rooms: any = [];

  if (request) {
    if (isGroup) {
      request?.ParticipantGroups.map((item: any) => {
        rooms.push({
          ADULT: {
            value: item.Adult.value,
          },
          CHILD: {
            value: item.Child.value,
            dates: item.Child.dates,
          },
        });
      });
    } else {
      rooms.push({
        ADULT: {
          value: request.Adult.value,
        },
        CHILD: {
          value: request.Child.value,
          dates: request.Child.dates,
        },
      });
    }
  }

  if (rooms.length) {
    return { rooms };
  }

  return {
    rooms: [
      {
        ADULT: {
          value: 2,
        },
        CHILD: {
          value: 0,
          dates: [],
        },
      },
    ],
  };
};

const defaultProps = {
  stickyElementOffsetTop: 135,
  rooms: [],
};

const MultiRoom = ({
  data,
  rooms,
  fetchAsync,
  requestParams,
  stickyElementOffsetTop,
}: MultiRoomProps) => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const [totalPrice, setTotalPrice] = useState('');
  const [initialSelectedRooms, setInitialSelectedRooms] = useState(rooms ?? []);
  const [availableRooms, setAvailableRooms] = useState<IRoom[]>([]);
  const [selectedRooms, setSelectedRooms] = useState<ISelectedRoom[]>([]);
  const [participants, setParticipants] = useState<any>({ all: {}, remain: {} });
  const [isLoading, setIsLoading] = useState(false);
  const [editRoom, setEditRoom] = useState<any>(null);
  const [services, setServices] = useState([]);
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const [participantsStorage, setParticipantsStorage] = useStorage<IDictionary<any>>(
    'sessionStorage', 'MultiRoomParticipants', {},
  );

  const { MultiFinder } = requestParams;
  const [participantsValue, setParticipantsValue] = useState<any>(getDefaultRooms(MultiFinder));
  const isManualMode = MultiFinder?.ParticipantGroups;

  const onParticipantsChange = (value: any) => {
    setParticipantsValue(value);
  };

  const swapRoomHandler = useCallback((oldRoom: any, newRoom: any) => {
    const { Offer } = newRoom;
    const { Base } = Offer;
    const { UniqueObjectId, Operator } = Base;
    const { Group, RoomId } = oldRoom;

    const paxHash = getParticipantsHash({
      adultsCount: Group.pax.Adult.value,
      childDates: Group.pax?.Child?.dates ?? [],
      infantDates: [],
    });

    const offerByPax = getOfferByPax(newRoom, Group.pax);
    const offerId = offerByPax?.OfferId || `${UniqueObjectId}|${Operator}|${paxHash}`;
    const uniqueObjectId = getUniqueObjectId(offerId);

    setSelectedRooms((state) => state.map((item, index) => (item.RoomId === RoomId ? {
      ...item,
      Offer: {
        ...newRoom.Offer,
        Base: {
          ...newRoom.Offer.Base,
          OfferId: offerId,
          UniqueObjectId: uniqueObjectId,
          Availability: undefined,
        },
      },
      RoomId: `${index}_${uniqueObjectId}`,
      Group,
    } : item)));

    setEditRoom(null);
    showToast(TOAST_SUCCESS, t('room_changed', { group: oldRoom.Group.description }));
  }, []);

  /**
   * Remove room handler.
   * @param room
   */
  const removeRoomHandler = (room: ISelectedRoom) => {
    setSelectedRooms(
      (state) => (room.RoomId
        ? [...state].filter((item) => item.RoomId !== room.RoomId)
        : [...state].splice(-1)),
    );
  };

  const changeRoomHandler = useCallback((room: any, resetStatus = true) => {
    const {
      Offer,
      RoomId,
      Group,
    } = room;

    const { Base } = Offer;
    const { UniqueObjectId, Operator } = Base || {};

    const paxHash = getParticipantsHash({
      adultsCount: Group.pax?.Adult.value,
      childDates: Group.pax?.Child?.dates ?? [],
      infantDates: [],
    });

    const offerByPax = getOfferByPax(Offer, Group.pax);
    const offerId = offerByPax?.OfferId || `${UniqueObjectId}|${Operator}|${paxHash}`;
    const uniqueObjectId = getUniqueObjectId(offerId);

    setSelectedRooms((state) => state.map((item) => (item.RoomId === RoomId ? {
      ...item,
      Group,
      Offer: {
        ...item.Offer,
        Base: {
          ...item.Offer.Base,
          ...resetStatus ? { Availability: undefined } : {},
          OfferId: offerId,
          UniqueObjectId: uniqueObjectId,
        },
      },
    } : item)));
  }, []);

  /**
   * Select room`s handler.
   * @param rooms
   */
  const selectRoom = useCallback((rooms: ISelectedRoom[]) => {
    const roomsP = {
      roomId: '',
      selected: [] as any[],
    };

    rooms.map((item) => {
      const {
        Offer,
        RoomId,
        Group,
      } = item;

      const { Base } = Offer;
      const { UniqueObjectId, Operator } = Base || {};

      const paxHash = getParticipantsHash({
        adultsCount: Group.pax.Adult.value,
        childDates: Group.pax?.Child?.dates ?? [],
        infantDates: [],
      });

      const offerByPax = getOfferByPax(item as any, Group.pax);
      const offerId = offerByPax?.OfferId || `${UniqueObjectId}|${Operator}|${paxHash}`;
      const uniqueObjectId = getUniqueObjectId(offerId);

      if (!Group.description) {
        Group.description = `${t('room')} ${selectedRooms.length + 1}`;
      }

      roomsP.roomId = RoomId.split('_')[1];
      roomsP.selected.push({
        ...item,
        Group,
        Offer: {
          ...item.Offer,
          Base: {
            ...item.Offer.Base,
            Availability: undefined,
            OfferId: offerId,
            UniqueObjectId: uniqueObjectId,
          },
        },
      });
    });

    setSelectedRooms((state) => {
      let stateCopy = [...state];

      stateCopy = stateCopy.filter((item) => !includes(item.RoomId, roomsP.roomId));
      stateCopy = [...stateCopy, ...roomsP.selected];

      return stateCopy;
    });
  }, [selectedRooms.length]);

  /**
   * Set room status handler.
   * @param roomId
   * @param status
   */
  const setRoomStatus = ({ roomId, status } : { roomId: string, status: any}) => {
    setSelectedRooms((state) => state.map((selectedRoom: any) => (selectedRoom.RoomId === roomId ? {
      ...selectedRoom,
      Offer: {
        ...selectedRoom.Offer,
        Base: {
          ...selectedRoom.Offer.Base,
          Availability: status?.Base?.Availability ? {
            ...status.Base.Availability,
          } : status,
          Price: {
            ...selectedRoom.Offer.Base.Price,
            ...status?.Base?.Price ? {
              FirstPerson: {
                ...selectedRoom.Offer.Base.Price.FirstPerson,
                amount: status.Base.Price.FirstPerson.amount,
              },
              Total: {
                ...selectedRoom.Offer.Base.Price.Total,
                amount: status.Base.Price.Total.amount,
              },
            } : {},
          },
        },
      },
    } : selectedRoom)));
  };

  /**
   * Pax onApply event handler.
   * @param value
   * @param roomsCount
   * @param groupMode
   */
  const onApplyHandler = (value: any, groupMode: boolean, roomsCount: number) => {
    const { rooms } = value;

    setInitialSelectedRooms([]);

    const storage = {
      Participants:
        groupMode ? rooms.map((item: any) => ({
          Adult: {
            value: item.ADULT.value,
          },
          Child: {
            value: item.CHILD.value,
            dates: item.CHILD.dates ?? [],
          },
        })) : {
          Adult: {
            value: rooms[0].ADULT.value,
          },
          Child: {
            value: rooms[0].CHILD.value,
            dates: rooms[0].CHILD.dates ?? [],
          },
        },
    };

    const request = groupMode ? {
      ParticipantGroups: rooms.map((item: any) => ({
        Adult: {
          value: item.ADULT.value,
        },
        Child: {
          value: item.CHILD.value,
          dates: item.CHILD.dates ?? [],
        },
      })),
    } : {
      Adult: {
        value: rooms[0].ADULT.value,
      },
      Child: {
        value: rooms[0].CHILD.value,
        dates: rooms[0].CHILD.dates ?? [],
      },
    };

    setParticipantsStorage((state: any) => ({
      ...storage,
      ...roomsCount > 1 && !groupMode ? { RoomCnt: roomsCount } : {},
      ...state?.XServiceId ? { XServiceId: state.XServiceId } : {},
    }));

    fetchRooms({
      MultiFinder: {
        ...!groupMode && roomsCount > 1 ? { RoomCnt: roomsCount } : {},
        ...participantsStorage?.XServiceId ? { XServiceId: participantsStorage.XServiceId } : {},
        ...request,
      },
    });

    trackEvent({
      event: 'multiroom',
      eventCategory: 'B2B_CLICK_EVENT',
      eventAction: 'B2B_MULTIROOM_CHANGE_PARTICIPANTS',
    });
  };

  const onServiceChangeHandler = (value: string) => {
    setParticipantsStorage((state: any) => ({
      ...state,
      XServiceId: value,
    }));

    fetchRooms({
      MultiFinder: {
        ...MultiFinder,
        XServiceId: value,
      },
    });
  };

  const fetchRooms = async (params: any = {}) => {
    setIsLoading(true);

    await fetchAsync({
      sections: ['MultiRoomFinder'],
      params,
    });

    setEditRoom(null);
    setIsLoading(false);
  };

  const onRemoveGroupHandler = (groupId: number) => {
    setGroupNames((state) => {
      const copy = [...state];

      copy.splice(groupId, 1);

      return copy;
    });
  };

  useEffect(() => {
    const participants = {
      Adult: participantsValue.rooms.reduce((acc: any, current: any) => acc + current.ADULT.value, 0),
      Child: participantsValue.rooms.reduce((acc: any, current: any) => acc + current.CHILD.value, 0),
    };

    setParticipants((state: any) => ({
      ...state,
      all: participants,
    }));
  }, [participantsValue]);

  useEffect(() => {
    const totalPrice = getTotalPrice(selectedRooms);
    const groupNames: string[] = [...new Array(participantsValue.rooms.length)].map(() => '');
    let restPax = participants.all;
    let selectedPax: any = {
      Adult: 0,
      Child: 0,
    };

    if (selectedRooms.length) {
      selectedRooms.map((room) => {
        let roomTotalPax = 0;

        if (isNumber(room?.Group?.id)) {
          groupNames.splice(room.Group.id, room.Group.id, room.Group.description);
        }

        Object.keys(room.Group.pax).map((personType) => {
          // @ts-ignore
          roomTotalPax += room.Group.pax[personType].value;
          restPax = {
            ...restPax,
            // @ts-ignore
            [personType]: restPax[personType] - room.Group.pax[personType].value,
          };

          selectedPax = {
            ...selectedPax,
            // @ts-ignore
            [personType]: selectedPax[personType] + room.Group.pax[personType].value,
          };
        });
      });
    }

    setGroupNames(groupNames);
    setTotalPrice(`${totalPrice.amount} ${totalPrice.currency}`);
    setParticipants((state: any) => ({
      ...state,
      remain: restPax,
      selected: selectedPax,
    }));
  }, [selectedRooms]);

  useEffect(() => {
    if (initialSelectedRooms.length) {
      setSelectedRooms(initialSelectedRooms);
      setInitialSelectedRooms([]);
    }
  }, [initialSelectedRooms]);

  useEffect(() => {
    if (rooms) {
      setInitialSelectedRooms(rooms);
    }
  }, [rooms]);

  useEffect(() => {
    const maintenance = getRoomsMaintenance(data);
    const roomList = getRoomList(data);

    let defaultRooms = getRecommendedRooms(roomList, t('room'));

    if (!services.length) {
      setServices(maintenance);
    }

    if (!initialSelectedRooms.length) {
      defaultRooms = defaultRooms.map((room, roomIndex) => {
        const isGroupMode = room.Group?.id ?? false;
        const roomId = isGroupMode ? room.Group.id as number : roomIndex;
        const description = groupNames.length && groupNames[roomId] ? groupNames[roomId] : `${t('room')} ${roomId + 1}`;

        return {
          ...room,
          Group: {
            ...room.Group,
            description,
          },
        };
      });

      setSelectedRooms(defaultRooms);
    }

    setAvailableRooms(roomList);
  }, [data]);

  return (
    <FlexBox
      flexDirection="column"
      width="100%"
    >
      <FlexBox
        p="medium"
        flexDirection={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <FlexBox
          mb={{
            xs: 'small',
            sm: '0px',
          }}
          width={{
            xs: '100%',
            sm: 325,
          }}
        >
          <ParticipantsNewVersion
            defaultValue={getDefaultRooms()}
            onChange={onParticipantsChange}
            onApply={onApplyHandler}
            roomsCountEnabled
            manualModeEnabled
            isClearable
            groupNames={groupNames}
            onRemoveGroup={onRemoveGroupHandler}
            setDefaultBirthDate
            ageSelectionEnabled
            roomsCount={isManualMode ? MultiFinder.ParticipantGroups.length : MultiFinder?.RoomCnt || 1}
            initialMode={isManualMode ? 'manual' : 'auto'}
            value={participantsValue}
          />
        </FlexBox>
        <FlexBox
          ml={{
            xs: '0px',
            sm: 'medium',
          }}
          width={{
            xs: '100%',
            sm: 250,
          }}
        >
          <ServiceSelect
            options={services}
            onChange={onServiceChangeHandler}
            value={participantsStorage?.XServiceId ?? null}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        p="medium"
        backgroundColor="#E9EBEC"
        flexDirection={{
          _: 'column', lg: 'row',
        }}
      >
        {isLoading ? (
          <FlexBox height={200} width="100%" alignItems="center" justifyContent="center">
            <Loader color="gray" size="32px"/>
          </FlexBox>
        ) : (
          <>
            <FlexBox flexGrow={1} flexDirection="column">
              <FlexBox
                width="100%"
                backgroundColor="white"
                style={{
                  boxShadow: 'rgb(0 0 0 / 10%) 0 1px 4px 0px',
                }}
              >
                {availableRooms.length > 0 ? (
                  <RoomsGrid
                    rooms={availableRooms}
                    selectedRooms={selectedRooms}
                    participants={participants.all}
                    swapRoom={swapRoomHandler}
                    editRoom={editRoom}
                    setRoom={selectRoom}
                    stickyElementOffsetTop={stickyElementOffsetTop ?? 0}
                  />
                ) : (
                  <FlexBox height={200} width="100%" alignItems="center" justifyContent="center">
                    <Text color="darkGray">{t('multiroom_no_rooms')}</Text>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <Summary
              selectedRooms={selectedRooms}
              onStatusChange={setRoomStatus}
              participants={participants}
              totalPrice={totalPrice}
              removeRoomHandler={removeRoomHandler}
              roomsCount={participantsStorage?.RoomCnt ?? 0}
              changeRoom={changeRoomHandler}
              setEditRoom={setEditRoom}
              editRoom={editRoom}
              errorMessage={data?.Message}
              stickyElementOffsetTop={stickyElementOffsetTop ?? 0}
            />
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};

MultiRoom.defaultProps = defaultProps;
MultiRoom.displayName = 'MultiRoomFinder';

export default MultiRoom;
