import React from 'react';
import { has, isString } from 'lodash-es';

import Icon from '@ess/ui/Icon';

import { Styled } from '../Dropdown.styles';


export type DropdownItem = {
  label: string
  value: string | number
  disabled?: boolean
  icon?: React.ReactNode
}

type ItemProps = {
  data: DropdownItem
  onClick: ({ event, item } : { event: React.MouseEvent, item: DropdownItem}) => void
  hasIcons: boolean
}

const Item = ({ onClick, data, hasIcons }: ItemProps) => {
  const disabled = has(data, 'disabled') ? data.disabled ?? false : false;

  /**
   * Item click handler.
   * @param event
   */
  const onClickHandler = (event: React.MouseEvent) => {
    onClick({ event, item: data });
  }

  return (
    <Styled.Dropdown__Item
      key={data.value}
      onClick={onClickHandler}
      hasIcons={hasIcons}
      disabled={disabled}
    >
      {(hasIcons || data.icon) && (
        <Styled.Dropdown__Item__Icon>
          {isString(data.icon) ? (
            <Icon iconName={data.icon}/>
          ) : data.icon}
        </Styled.Dropdown__Item__Icon>
      )}
      <Styled.Dropdown__Item__Label>
        {data.label}
      </Styled.Dropdown__Item__Label>
    </Styled.Dropdown__Item>
  )
};

export default Item;
