import React, { CSSProperties } from 'react';
import { SpaceProps } from 'styled-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTriangleExclamation,
  faCircleExclamation,
  faCircleCheck,
  faCircleInfo,
} from '@fortawesome/pro-regular-svg-icons';

import { Styled } from './Alert.styles';

type SeverityEnum = 'error' | 'warning' | 'info' | 'success';
type SizeEnum = 'small' | 'medium' | 'large';

type AlertProps = {
  children: string | React.ReactNode
  severity: SeverityEnum
  size?: SizeEnum
  showBorder?: boolean
  showIcon?: boolean
  styles?: CSSProperties,
} & SpaceProps;

const defaultProps = {
  showIcon: true,
  showBorder: true,
  size: 'medium' as SizeEnum,
}

const severityIcons = {
  error: faCircleExclamation,
  warning: faTriangleExclamation,
  success: faCircleCheck,
  info: faCircleInfo,
}

const Alert = ({
  children,
  showIcon,
  ...props
}: AlertProps) => {
  return (
    <Styled.Alert {...props} style={props.styles}>
      {showIcon && (
        <Styled.Alert__Icon>
          <FontAwesomeIcon icon={severityIcons[props.severity]}/>
        </Styled.Alert__Icon>
      )}
      <Styled.Alert__Message>
      {children}
      </Styled.Alert__Message>
    </Styled.Alert>
  )
}

Alert.defaultProps = defaultProps;

export default Alert;
