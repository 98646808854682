import React, { useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, some } from 'lodash-es';

import { IRoom, ISelectedRoom } from '@tourop/components/MultiRoom/types';

import { isEqualRooms } from '@tourop/components/MultiRoom/utils/isEqualRooms';

import Room from './Room';

import { Styled } from './RoomsGrid.styles';

type RoomsGridProps = {
  rooms: IRoom[]
  selectedRooms: any
  swapRoom: any
  editRoom: ISelectedRoom
  participants: any
  stickyElementOffsetTop: number
  setRoom: any
}

const headers = [
  {
    labelKey: 'lbl_room_type',
    align: 'flex-start',
  },
  {
    labelKey: 'lbl_for',
    align: 'center',
  },
  {
    labelKey: 'lbl_room_description',
    align: 'flex-start',
  },
  {
    labelKey: 'lbl_mrf_price',
    align: 'center',
  },
  {
    labelKey: undefined,
  },
];

const RoomsGrid = ({
  rooms,
  selectedRooms,
  participants,
  stickyElementOffsetTop,
  swapRoom,
  editRoom,
  setRoom,
}: RoomsGridProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (editRoom) {
      const swappableElements = document.querySelectorAll('[data-swappable="true"]');

      if (swappableElements.length) {
        window.scrollTo({
          top: (swappableElements[0] as HTMLElement).offsetTop - 183,
          behavior: 'smooth',
        });
      }
    }
  }, [editRoom]);

  return (
    <Styled.RoomsGrid>
      <Styled.RoomsGrid__Header__Item
        stickyElementOffsetTop={stickyElementOffsetTop}
        textAlign={headers[0].align}
      >
        {headers[0].labelKey && t(headers[0].labelKey)}
      </Styled.RoomsGrid__Header__Item>

      <Styled.RoomsGrid__Header stickyElementOffsetTop={stickyElementOffsetTop}>
        {headers.slice(1).map((item) => (
          <Styled.RoomsGrid__Header__Item
            key={item.labelKey}
            textAlign={item.align}
            stickyElementOffsetTop={stickyElementOffsetTop}
          >
            {item.labelKey && t(item.labelKey)}
          </Styled.RoomsGrid__Header__Item>
        ))}
      </Styled.RoomsGrid__Header>
      {rooms.map((room) => {
        const { Min: MinPax, Max: MaxPax } = room.Offers[0].Pax;
        const availPax: any = MinPax === MaxPax ? MaxPax : `${MinPax} - ${MaxPax}`;

        const isHighlighted = () => {
          if (editRoom) {
            const editedRoomPax = editRoom?.Group?.pax;
            const paxCount = editedRoomPax.Adult.value + editedRoomPax.Child.value;

            return some(room.Offers, (item) => paxCount >= item.Pax.Min && paxCount <= item.Pax.Max);
          }
          return false;
        };

        return (
          <React.Fragment key={room.RoomCode}>
            <Styled.RoomsGrid__RoomType
              highlight={isHighlighted()}
              data-swappable={isHighlighted() ? 'true' : 'false'}
            >
              <Styled.RoomsGrid__RoomType__Name>
                {room.RoomDescription}
              </Styled.RoomsGrid__RoomType__Name>

              <Styled.RoomsGrid__RoomType__Pax>
                {MaxPax === 1
                  ? t('multiroom_avail_pax_single')
                  : t('multiroom_avail_pax', {
                    count: availPax,
                  })}
              </Styled.RoomsGrid__RoomType__Pax>
            </Styled.RoomsGrid__RoomType>
            <Styled.RoomsGrid__Rooms>
              {room.Offers.map((item) => {
                const selectedRoom = filter(
                  selectedRooms, (o) => isEqualRooms(o, item),
                );

                return (
                  <Room
                    key={item.Offer?.Base?.UniqueObjectId}
                    editRoom={editRoom}
                    swapRoom={swapRoom}
                    selected={selectedRoom}
                    participants={participants}
                    roomName={room.RoomDescription}
                    setRoom={setRoom}
                    data={item}
                  />
                );
              })}
            </Styled.RoomsGrid__Rooms>
          </React.Fragment>
        );
      })}
    </Styled.RoomsGrid>
  );
};

export default memo(RoomsGrid);
