import React from 'react';

import FlexBox from '@ess/ui/FlexBox';

import { Header } from './Header';
import { Footer } from './Footer';

type PageProps = {
  children: React.ReactNode
  isLoading?: boolean
}

const defaultProps = {
  isLoading: false,
};

const Page = ({ children, isLoading }: PageProps) => (
  <FlexBox
    width="100%"
    height="100%"
    minWidth={320}
    flexDirection="column"
    overflowY="auto"
    style={{
      background: 'var(--background-color)',
    }}
  >
    <Header/>
    <FlexBox
      p="large"
      flexDirection="column"
      width="100%"
      flexGrow={1}
    >
      {children}
    </FlexBox>
    {!isLoading && <Footer/>}
  </FlexBox>
);

Page.defaultProps = defaultProps;

export {
  Page,
};
