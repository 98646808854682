import styled from 'styled-components';
import { color } from 'styled-system';

import Text from '@ess/ui/Text';

const Price__Amount = styled(Text)`
  margin: 0;
  font-weight: bold;
`;

const Price__Arrow = styled.div`
  margin-left: 1px;
`;

const Price__Currency = styled(Text)`
  margin: 0;
  font-weight: bold;
  padding-left: ${({ theme }) => theme.space.tiny};
`;

const Price = styled.div<{color?: string}>`
    display: flex;
    align-items: center;
    line-height: 14px;

    ${Price__Amount} {
      ${color}
    }
`;

export const Styled = {
  Price,
  Price__Amount,
  Price__Currency,
  Price__Arrow,
};
