import { createStore } from 'zustand';

import flattenObject from '@ess/utils/flattenObject';

import { AffiliateConfigService, BasketService } from '@liveroom/services';

import { setCssVariables } from '@liveroom/utils/setCssVariables';

import { Store } from './types';

const createLiveRoomStore = (initProps?: Partial<Store>) => {
  const DEFAULT_PROPS = {
    cssVariablesContainer: '',
    offers: {
      isLoading: true,
      isReady: false,
      description: '',
      error: '',
      items: [],
    },
    config: {
      agency: null,
      theme: null,
      settings: null,
    },
  };

  return createStore<Store>((set, getState) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    setOffers: (offers: any) => set({ offers }),
    fetchConfig: async (affiliateId: string) => {
      if (affiliateId) {
        const container = getState().cssVariablesContainer;
        const affiliateConfig = new AffiliateConfigService(affiliateId);
        const data = await affiliateConfig.fetch();

        setCssVariables({ params: data.theme.colors, suffix: 'color', container });
        setCssVariables({ params: flattenObject(data.theme.border), container });

        set({
          config: data,
        });
      }
    },
    fetchOffers: async ({ params, config }) => {
      const basket = new BasketService(config);
      const data = await basket.fetch(params);

      set((state) => ({
        offers: {
          ...state.offers,
          items: data?.details?.items ?? [],
          description: data?.details?.description ?? '',
          isLoading: false,
        },
      }));
    },
  }));
};

export {
  createLiveRoomStore,
};
