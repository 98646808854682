import React from 'react';
import { useParams } from 'react-router-dom';

import { UrlParams } from '@liveroom/types';

import Text from '@ess/ui/Text';

import { Page } from '@liveroom/layout';
import { OffersContainer } from '@liveroom/containers';

type LiveRoomProps = {
  params?: any
  isFavouritesEnabled?: boolean
}

const defaultProps = {
  params: undefined,
  isFavouritesEnabled: true,
};

const LiveRoom = ({ params, isFavouritesEnabled }: LiveRoomProps) => {
  const routeParams = useParams<UrlParams>();

  return (
    <Page>
      <OffersContainer
        params={params ?? routeParams}
        isFavouritesEnabled={isFavouritesEnabled}
        autoRefreshEnabled
      />
    </Page>
  );
};

LiveRoom.defaultProps = defaultProps;

export {
  LiveRoom,
};
