import { rgba } from 'polished';

const severityColors = {
  error: 'rgb(158 57 55)',
  warning: '#945b0b',
  success: 'rgb(30, 70, 32)',
  info: 'rgb(1, 67, 97)',
};

const variants = {
  error: {
    color: severityColors.error,
    backgroundColor: 'rgb(253, 237, 237)',
    borderColor: rgba(severityColors.error, 0.4),
  },
  warning: {
    color: severityColors.warning,
    backgroundColor: 'rgb(255, 244, 229)',
    borderColor: rgba(severityColors.warning, 0.4),
  },
  success: {
    color: severityColors.success,
    backgroundColor: 'rgb(237, 247, 237)',
    borderColor: rgba(severityColors.success, 0.4),
  },
  info: {
    color: severityColors.info,
    backgroundColor: 'rgb(229, 246, 253)',
    borderColor: rgba(severityColors.info, 0.4),
  },
};

const sizes = {
  small: {
    fontSize: '11px',
    padding: '4px 8px',
  },
  medium: {
    fontSize: '12px',
    padding: '6px 10px',
  },
  large: {
    fontSize: '14px',
    padding: '8px 12px',
  },
};

const alert = {
  variants,
  sizes,
};

export default {
  alert,
};
