import { css } from 'styled-components';

const CSSVariables = css`
  :root {
    /* Colors */

    --white-color: #fff;
    --primary-color: #689b0e;
    --text-color: #102c58;
    --text-secondary-color: #838383;
    --background-color: #fff;
    --separator-color: #e1e3e6;
    --text-price-color: #006400;
    --text-total-price-color: #006400;
    --text-person-price-color: #990000;
    --text-section-header-color: #fff;
    --section-header-background-color: #1a9fff;

    --text-accent-color: var(--text-price-color);
    --button-background-color: var(--primary-color);

    /* Border */

    --default-border-radius: 8px;
    --modal-border-radius: 6px;
    --button-border-radius: 6px;
    --form-element-border-radius: 4px;
  }
`;

export default CSSVariables;
