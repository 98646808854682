import { Components } from '@ess/types';

export type OnlineAction=Components.Schemas.OfferOnlineAction
export type IConditions = Components.Schemas.Baseconditions;
export type IOffer = Components.Schemas.Offer;
export type IOfferAccommodation = Components.Schemas.OfferAccommodation;
export type IOfferAccommodationExtended = Components.Schemas.OfferAccommodationExtended;
export type IOfferBase = Components.Schemas.OfferBase;
export type IOfferTransport = Components.Schemas.OfferTransport;
export type IGroupedListResponseItem = Components.Schemas.GroupedListResponseItem;
export type IExtCovidInfo = Components.Schemas.Extcovidinfo;
export type IExtCovidInfoItem = Components.Schemas.ExtcovidinfoBlock;
export type IExtMonthlyWeather = Components.Schemas.ExtMonthlyWeather;
export type IExtAgentAttributes = Components.Schemas.OfferAccommodationExtAgentAttributes

export type postSearchProps = {
    postData: any
    onSuccess: (data:any)=>void
    onError: (e:any)=>void
};

export type conditionsSections = 'search' | 'filter' | 'selectedObject' | 'groupKey'

export type priorityOrder = {
  field: string
  mapping: any[]
} | null

export enum conditionsDepth {
    Search = 0,
    Filtered = 1,
    GroupKey = 2,
    SlectedObject = 3,
}

export enum View {
    UnfilteredFieldValues,
    FieldValues,
    RegionList,
    SkiRegionList,
    GroupedList,
    OfferList,
    OfferListFieldValues,
    OfferDetails,
    AccommodationSupplementary,
    _LENGTH
}

export const getViewByName = (name: string): View | undefined => ({
  unfilteredFieldValues: View.UnfilteredFieldValues,
  fieldValues: View.FieldValues,
  regionList: View.RegionList,
  skiRegionList: View.SkiRegionList,
  groupedList: View.GroupedList,
  offerList: View.OfferList,
  offerListFieldValues: View.OfferListFieldValues,
  offerDetails: View.OfferDetails,
  accommodationSupplementary: View.AccommodationSupplementary,
}[name]);

export type paramsSelector = (e: any) => any

export interface IV5engine {
    // Set Conditions.Search
    SetSearchParams(params: any): void

    UpdateSearchParams(params: (draft: IConditions) => void): void

    // Set Conditions.Filter without Base.DestinationLocation
    SetFilterParams(params: any): void

    // Set Conditions.Filter.Base.DestinationLocation
    SetRegions(params: any): void

    // Set GroupBy.Key
    SetGroupType(params: any): void

    // Set GroupBy.Value
    SetSelectedObject(params: any): boolean

    // Commit changes
    Commit(onReady?: (data: IV5DataSet, alreadyDone: boolean) => void | undefined, view?: View | undefined,
      noReset?:boolean, deleteCacheAfter?: number|undefined): void

    IsSelectedObject(): boolean

    Revert():void

    SetOrder(view: View, order: string[]): void

    SetPriorityOrder(view: View, orderPriority: priorityOrder): void

    // Clear all params
    Reset(): void

    // Temporary solution for the filters fetcher
    // TODO: proper handling of filters inside the  v5 provider
    postSearch(postData:any): Promise<any>
}

export interface IV5FieldStatus {
    /**
     * indicating that engine will return new value asap
     * field is queued or currently processed
     */
    isLoading: boolean
    /**
     * additional flag to isLoading=true, indicating that there is no place in a request pool.
     * Request will start as soon as a free slot will be available.
     * In a case of a network error, all Loading requests will have a queued flag set
     */
    isQueued: boolean
    /**
     * indicating that something went wrong with request (except network problem)
     * it will require manual retry
     * in case of global error during loading (network) : isQueued flag will be set to true and isError will be set to false
     * you can check V5engine.isNetworkAvailable for "global" status of network availability
     */
    isError: boolean

    /**
     * Data available
     */
    isReady: boolean

    data: any

    retryCount: number

    action: string

    // Internal list of the callbacks to trigger the update (do not use directly)
    callbacks: any[]
}

export const emptyIV5FieldStatus = {
  isLoading: false,
  isQueued: false,
  isError: false,
  isReady: false,
  retryCount: 3,
  callbacks: [],
} as unknown as IV5FieldStatus;

export interface IOnlineRequest {
    force?: boolean
    fields: string[]
}

export interface IV5OnlineOffer {
    Base: IOfferBase
    Transport: IOfferTransport
    Accommodation: IOfferAccommodationExtended

    /**
     * @return true if any of listed fields have action to get online data from operator
     */
    isGettableOnline(...fields: string[]): boolean

    /**
     * @param fields List of fields
     * @return online action status
     */
    fieldStatus(...fields: string[]): IV5FieldStatus

    /**
     * Send online request and update all offer fields returned from action.
     * For e.g. asking for price will update availability as well
     * @param force
     * @param fields
     */
    sendOnlineRequest({ force, fields }: IOnlineRequest): IV5FieldStatus

    /**
     * Send online request and update all offer fields returned from action.
     * For e.g. asking for price will update availability as well
     * @param force
     * @param fields
     */
    getWithOnlineResponse(fields: string[]): Promise<{offer:IV5OnlineOffer, status:IV5FieldStatus}>

    /**
     * Get conditions used to fetch offer
     */
    getConditions<T extends conditionsSections>(section:T):(T extends 'filters'|'search'?IConditions:any)

    /*
       hash used to detect changes in offer status
     */
    hash:string
}

// end of public interfaces

/** @internal */
export interface IV5OnlineOfferInternal extends IV5OnlineOffer {
    mounted: boolean
}

/** @internal */
export interface IV5EngineInternal {
    mountOffer(a: any, b: any): void;

    getOfferOnlineData(vo:IViewObject, offer:any): any

    unmountOffer(offer:any): any;

    offerOnlineQueueRequest(vo:IViewObject, action:OnlineAction, offer:IOffer, force?:boolean, requeue?:boolean, callback?:any)
      : IV5FieldStatus

    offerOnlineState(vo:IViewObject, action:OnlineAction, offer:any, create:boolean):(IV5FieldStatus | null)

    getConditionsForViewObject(data:IViewObject, section:conditionsSections):any

}

/** @internal */
export interface IViewOfferMounted {
    onlineOffer: IV5OnlineOfferInternal
    statusUpdater: any
}

export interface IViewObjectMountable {
    vo: IViewObject
    currentStatus: IV5DataSet
    setViewState: any
    paramOptions: IParamOptions
    parent: any
}

export interface IV5DataSetX<T> {
    isInitialized: boolean
    // Request completed, with success or error (isLoading->isReady)
    isReady: boolean
    // List loading, true during order, filter change
    isLoading: boolean
    // isError error response from server
    isError: boolean
    // hasResults = isReady and count(results)>0
    hasResults: boolean

    // unique number of each data set, doesn't change on loadMore
    resultSetId: number
    hasMoreAtEnd: boolean
    hasMoreAtBegin: boolean
    loadMoreAtEnd: () => void
    loadMoreAtBegin: () => void
    isLoadingMore: boolean
    isLoadingMoreAtEnd: boolean
    isLoadingMoreAtBegin: boolean
    items: (T)
    get: () => void
}

export type IOfferListItem = Components.Schemas.OfferListResponseItem & {parent:IViewObject}
export type IGroupedListItem = Components.Schemas.GroupedListResponseItem & {parent:IViewObject}
export type IResponseListItem = IGroupedListItem|IOfferListItem

export type IV5DataSet = IV5DataSetX<IOfferListItem[] | IGroupedListItem[]>;
export type IV5DataOfferList = IV5DataSetX<IOfferListItem[]>
export type IV5DataGroupedList = IV5DataSetX<IGroupedListItem[]>

export enum InternalDataStatus {
    Idle,
    FreshDataRequested,
    ProcessingRequest,
    LoadMoreRequested,
    Done
}

export enum NetworkElems {
    Connection,
    Server,
    Google,
    Service,
    Query
}

export interface IElementStatus {
    status: 'idle' | 'checking' | 'ok' | 'error'
    errorMessage?: string
}

export interface INetworkStatus {
    statuses: {
        Connection?: IElementStatus
        Server?: IElementStatus
        Google?: IElementStatus
        Service?: IElementStatus
        Query?: IElementStatus
        [index: string]: (IElementStatus | undefined)
    }
    retryButton?: () => void
    errorMessage?: string
    isOnline: boolean
    reset?: () => void
}

export interface IViewObjects {
    viewobjects: IViewObject[]
}

export interface IParamOptions {
    uncommitted?:{
        filter?:boolean
        search?:boolean
        regions?:boolean
        selectedObject?:boolean
        [index:string]:boolean|undefined
    }
    ignoreObject?: boolean
    ignoreFilters?: boolean
    useObjectForFilters?:boolean
    useObjectForGroup?:boolean
    ignoreGeo?:boolean
    regionRequest?:boolean
    applyparams?:{
        filter?:any
        search?: any
        selectedObject?:any
        groupType?:any
    },
    orderPriorityOffers?: priorityOrder
    offerDetailsId?:string
}

export interface IViewObject {
    state: IV5DataSet
    ajaxActive: boolean
    queued: boolean
    fieldList: string[]
    order: string[]
    orderPriority: priorityOrder
    items: any
    orgItems: any
    parent: any
    type: View
    loadMoreRequested: boolean
    previousPageBookmark: string
    mounted: boolean
    dataStatus: InternalDataStatus
}
