import React, { useContext } from 'react';
import { has, isEmpty } from 'lodash-es';

import { TTransportBus, TTransportFlight } from '@ess/types';
import { IV5OnlineOffer } from '@ess/v5-data-provider/interfaces';

import { OWN_TRANSPORT } from '@ess/constants/transport';

import { getAirlines, getTransportDesc, getTransportType } from '@ess/utils/offerData/offerData';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import FlexBox from '@ess/ui/FlexBox';

import TransportItemNew from '@tourop/components/OfferList/Offer/OfferDetails/TransportItemNew';

type TransportProps = {
  offerData: IV5OnlineOffer
  isTooltip?: boolean,
  showTitle?: boolean
}

const defaultProps = {
  isTooltip: false,
  showTitle: false,
};

const TransportDetails = ({
  offerData,
  isTooltip,
  showTitle,
}: TransportProps) => {
  const { state: SFContext } = useContext(AppConfigContext);
  const { Base, Transport } = offerData;
  const { configOper } = SFContext;
  const transportType = getTransportType(Transport);

  const isFlight = (transport: TTransportFlight | TTransportBus | undefined,
  ): transport is TTransportFlight => transport !== undefined && (
    transport as TTransportFlight
  ).Flight !== undefined;

  const { outAirline = false, retAirline = false } = isFlight(Transport) ? getAirlines(Transport) : {};
  const { Out: transportOutSegments = [], Ret: transportRetSegments = [] } = transportType !== OWN_TRANSPORT
    ? getTransportDesc(Transport) : {};

  const operatorConfig = has(configOper, Base.Operator as string) ? configOper[Base.Operator as string] : configOper?.default ?? {};

  const transportData = [
    {
      key: 'out',
      desc: !isEmpty(transportOutSegments) ? (
        <TransportItemNew
          transportType={transportType}
          segments={transportOutSegments}
          type="departure"
          operatorConfig={operatorConfig}
          airline={outAirline}
          isTooltip={isTooltip}
        />
      ) : '',
    },
    {
      key: 'ret',
      desc: !isEmpty(transportRetSegments) ? (
        <TransportItemNew
          transportType={transportType}
          segments={transportRetSegments}
          type="destination"
          operatorConfig={operatorConfig}
          airline={retAirline}
          isTooltip={isTooltip}
        />
      ) : '',
    },
  ];

  return (
    <FlexBox flexDirection="column" width="100%">
      {transportData
        .filter(({ desc }) => desc)
        .map(({ key, desc }) => (
          <FlexBox
            key={key}
            width="100%"
            flexDirection={!isTooltip ? { sm: 'row', xs: 'column', print: 'row' } : 'row'}
            alignItems={!isTooltip ? { sm: 'center', _: 'flex-start', print: 'center' } : 'center'}
          >
            <FlexBox width="100%" flexGrow={1}>
              {desc}
            </FlexBox>
          </FlexBox>
        ))}
    </FlexBox>
  );
};

TransportDetails.defaultProps = defaultProps;

export default TransportDetails;
