import styled from 'styled-components';

const SectionBox__Header = styled.div<{ nested?: boolean, icon?: any }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 45px;
    background: ${({ theme, nested }) => (nested ? theme.colors.lightBlue : theme.colors.sectionHeaderBackground)};
    color: ${({ theme, nested }) => (nested ? theme.colors.blue : theme.colors.textSectionHeader)};
    padding-right: ${({ theme }) => theme.space.medium};
    padding-left: ${({ theme, icon }) => (!icon ? theme.space.medium : null)};
`;

const SectionBox__Header__Icon = styled.div`
    display: flex;
    justify-content: center;
    width: 50px;
    font-size: ${({ theme }) => theme.fontSizes.title};
    line-height: ${({ theme }) => theme.lineHeights.title};
`;

const SectionBox__Header__Title = styled.div`
    white-space: nowrap;
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes.title};
    line-height: ${({ theme }) => theme.lineHeights.title};
`;

const SectionBox__Content = styled.div`
    background-color: ${({ theme }) => theme.colors.lightGray};
`;

const SectionBox = styled.div<{ isExpandable: boolean | undefined, isExpanded: boolean | undefined, nested?: boolean, variant: any }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${({ isExpandable, isExpanded, nested }) => `
      ${SectionBox__Header} {
        cursor: ${isExpandable ? 'pointer' : 'default'};
        user-select: ${isExpandable ? 'none' : 'default'};
      }
    `}

    ${({ variant, theme }) => variant === 'light' && `
      border: 1px solid ${theme.colors.separator};
      border-radius: ${theme.radii.default};

      ${SectionBox__Header} {
        background-color: transparent;
        color: ${theme.colors.textColor};
        padding: 10px 15px;
        font-size: 16px;
      }

      ${SectionBox__Content} {
        background-color: transparent;
        border-top: 1px solid ${theme.colors.separator};
      }
    `}
`;

export const Styled = {
  SectionBox,
  SectionBox__Header,
  SectionBox__Header__Title,
  SectionBox__Header__Icon,
  SectionBox__Content,
};
