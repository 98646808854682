import Tooltip from '@ess/ui/Tooltip';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

type TripAdvisorRatingProps = {
  Accommodation:any
  Rating?: number| undefined
}

const TripAdvisorRating = (
  {
    Rating,
    Accommodation,
  }:TripAdvisorRatingProps,
) => {
  const { t } = useTranslation();
  return (
    <Tooltip content={t('tripadvisor_note')}>
      <FlexBox
        width="24px"
        height="22px"
        borderRadius={6}
        mr="small"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize="11px" fontWeight="bold">
          {Accommodation?.ExtTripAdvisor?.rating ? Accommodation?.ExtTripAdvisor?.rating : Rating }
        </Text>
      </FlexBox>
    </Tooltip>
  );
};
export default TripAdvisorRating;
