import React, {
  createContext, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  get, includes, isArray, isEmpty, merge, mergeWith, pick, toNumber,
} from 'lodash-es';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import axios from 'axios';
import moment from 'moment';

import {
  CHECK_BOOKING_STATUS_ENDPOINT,
  CHECK_ONLINE_ENDPOINT,
  CONTENT_SERVICE_URL,
  FORM_CHECK_ONLINE_ENDPOINT,
  ONLINE_SERVICE_TIMEOUT,
  SEARCH_API,
} from '@ess/constants/api';

import ProtoHash, { ProtoHashTypes } from '@ess/protohash';

import { promiseRequest } from '@ess/v5-data-provider/request';

import { withBeforeInitialization } from '@ess/hoc';

import { offerMergeCustomizer } from '@ess/utils/offerData/offerMergeCustomizer';
import { isDatePassed } from '@ess/utils/offerData/isDatePassed';
import showToast, { TOAST_ERROR, TOAST_INFO, TOAST_SUCCESS } from '@ess/utils/form/ShowToast';

import useStorage from '@ess/hooks/useStorage';

import { IbeLink } from '@basket/components/IbeLink';
import { LiveRoom } from '@basket/components/LiveRoom';

import { IOffer } from '@ess/types';
import {
  BasketActionLevel,
  BasketActions,
  BasketClient,
  BasketItem,
  BasketPosition,
  BasketState,
  BasketView,
  Bookmark,
} from './types';

import {
  ALLOWED_STATUS_UPDATE,
  BASKET_API_URL,
  BASKET_STORAGE_SECTION,
  BASKETS_LIST,
  CSV_EXPORT_OPTIONS,
  STATUS_MAP,
  TRANSLATION_KEYS,
} from './constants';

const beforeInit = (props: any) => {
  const basket = getBasketStorage();

  if (basket) {
    if (basket.position) {
      delete (basket.position);
    }

    window?.localStorage?.setItem(BASKET_STORAGE_SECTION, JSON.stringify(basket));
  }

  return {
    ...props,
    initialState: {
      position: null,
      lastAgentSettingsPosition: basket?.lastAgentSettingsPosition ?? null,
    },
  };
};

export const offersView = (localStorage.getItem('AgentSettings')
  ? JSON.parse(`${localStorage.getItem('AgentSettings')}`)?.basketOfferView
  : undefined);

export const getBasketStorage = (): any => (localStorage.getItem(BASKET_STORAGE_SECTION)
  ? JSON.parse(`${localStorage.getItem(BASKET_STORAGE_SECTION)}`)
  : undefined);

export const defaultState: BasketState = {
  current: {
    id: getBasketStorage()?.basket?.id ?? null,
    name: getBasketStorage()?.basket?.name ?? '',
    hash: '',
  },
  list: {
    isLoading: false,
    isError: false,
    isReady: false,
    data: [],
  },
  items: {
    isLoading: false,
    isError: false,
    isReady: false,
    data: [],
  },
  view: BasketView.Basket,
  offerView: offersView ?? 'list',
  count: 0,
  isDisabledClose: false,
  actions: {},
  affiliates: {
    list: [],
    selected: '',
  },
  bookmarksUpdated: false,
  description: '',
  liveRoom: '',
  selectedItems: [],
  allSelected: false,
  bookmarks: getBasketStorage()?.bookmarks ?? [],
  position: getBasketStorage()?.position ?? null,
  lastAgentSettingsPosition: getBasketStorage()?.lastAgentSettingsPosition ?? null,
  isPublish: false,
  isBlocked: false,
  isOpen: getBasketStorage()?.isOpen ?? false,
  isDragging: false,
  isBusy: false,
  IbeDefaultHost: '',
};

type BasketProviderProps = {
  children: React.ReactNode
  initialState?: Partial<BasketState>
  isEnabled?: boolean
  config?: {
    language: string
    currency: string
    overwriteBasket: boolean
  }
}

const defaultProps = {
  initialState: undefined,
  isEnabled: true,
  config: {
    language: 'PL',
    currency: 'PLN',
    overwriteBasket: false,
  },
};

const basketProtoInstance = new ProtoHash(ProtoHashTypes.Basket);

const onlineInitialState = {
  isLoading: false,
  isReady: false,
  isError: false,
};

const BasketContext = createContext<{
  basket: BasketState
  isOfferInBasket:(offerData: any) => void
  toggleBasket: () => void
  getBasketsList: () => void
  liveRoom: (basketId: number | null) => void
  createDynamicIbeLink: (basketId: number | null) => void
  createStaticIbeLink: (basketId: number | null) => void
  changeBasket: (basketId: number | null, name?: string) => void
  setIsDragging: (isDragging: boolean) => void
  setPosition: (position: BasketPosition) => void
  setLastAgentSettingsPosition: (position: BasketPosition) => void
  changeItemName: (rowId: number, name: string) => void
  changeBasketName: (id: number | null, name: string) => void
  addItem: (offerId: string, customMessage: any) => void
  addBookmark: (value: any) => void
  deleteBookmark: (value: any) => void
  createBasket: (name: string) => void
  getBasketItems: (basketId: number, force?: boolean) => void
  setItems: (items: BasketItem[]) => void
  selectItem: (rowId: number) => void
  selectAllItems: () => void
  deleteItem: (rowId: number[]) => void
  deleteBasket: (basketId: number | number[] | null) => void
  publishBasket: (basketId: number, showMessage?: boolean, description?: string) => void
  unPublishBasket: (basketId: number, showMessage?: boolean) => void
  blockBasket: (basketId: number | null) => void
  unBlockBasket: (basketId: number | null) => void
  swapItems: (rowId: number, rowIdSwap: number) => void
  cloneOffer: (rowId: number, basketId: number) => void
  checkOnline: (rowId: number, force?: boolean, showMessage?: boolean) => void
  autoCheckOnline: () => void
  changeView: (view: BasketView, basket?: any) => void
  copyToCSV: () => void
  getExternalUrl: (endpoint: 'compareoffers' | 'printpanel', basketId: number | null, selectedItems: number[] | undefined) => void
  basketClient: (params: any) => Promise<any> | any
  setOfferView: (view: string) => void
  setFullWidthBodyExt: (state: boolean) => void
  fullWidthBody: boolean
  getShortLink: (rowId: number[], defaultUrl: string) => void
  checkBookingStatus: (rowId: number, bookingId: string, operator: string) => void
    }>({
      basket: defaultState,
      cloneOffer: () => false,
      setOfferView: () => false,
      basketClient: () => false,
      createDynamicIbeLink: () => false,
      createStaticIbeLink: () => false,
      liveRoom: () => false,
      getBasketItems: () => false,
      isOfferInBasket: () => false,
      setIsDragging: () => false,
      toggleBasket: () => false,
      setPosition: () => false,
      setLastAgentSettingsPosition: () => false,
      getBasketsList: () => false,
      changeBasket: () => false,
      addItem: () => false,
      addBookmark: () => false,
      deleteBookmark: () => false,
      createBasket: () => false,
      selectItem: () => false,
      selectAllItems: () => false,
      deleteItem: () => false,
      deleteBasket: () => false,
      blockBasket: () => false,
      unBlockBasket: () => false,
      publishBasket: () => false,
      unPublishBasket: () => false,
      getExternalUrl: () => false,
      swapItems: () => false,
      setItems: () => false,
      copyToCSV: () => false,
      checkOnline: () => false,
      autoCheckOnline: () => false,
      changeItemName: () => false,
      changeBasketName: () => false,
      changeView: () => false,
      setFullWidthBodyExt: () => false,
      fullWidthBody: false,
      getShortLink: () => false,
      checkBookingStatus: () => false,
    });

const BasketProvider = ({
  children,
  initialState,
  isEnabled,
  config,
}: BasketProviderProps) => {
  const { t } = useTranslation();
  const [basketModal, setBasketModal] = useState({
    isOpen: false,
    hash: '',
    type: '',
  });
  const [state, setState] = useState<BasketState>({
    ...initialState ? { ...merge(defaultState, initialState) } : { ...defaultState },
  });

  const providerConfig = !isEmpty(config) ? merge(defaultProps.config, config) : defaultProps.config;

  const [fullWidthBody, setFullWidthBody] = useState<boolean>(false);

  const [basketStorage, setBasketStorage] = useStorage<any>(
    'localStorage', BASKET_STORAGE_SECTION, {
      basket: {
        id: null,
        name: '',
      },
      bookmarks: [],
      favouriteBasketsList: [],
      lastAgentSettingsPosition: null,
    },
  );

  const autoStatusCheck = useRef<any>({
    index: 0,
    offers: [],
    timeout: null,
  });

  const setOfferView = (view: string) => {
    setState((state) => ({
      ...state,
      offerView: view,
    }));
  };

  const changeView = (view: BasketView, basket: { id: number, name: string } | null) => {
    setState((state) => ({
      ...state,
      view,
    }));

    if (basket?.id) {
      changeBasket(basket.id, basket.name);
    }
  };

  /**
   * Change current basket handler.
   */
  const changeBasket = useCallback((id: number | null, name = '') => {
    const isSameId = id === state.current.id;

    setState((state) => ({
      ...state,
      ...!isSameId ? {
        items: {
          ...state.items,
          data: [],
          isReady: false,
        },
      } : {},
      current: {
        ...state.current,
        id,
        name,
      },
    }));

    setBasketStorage((state: any) => ({
      ...state,
      basket: {
        id,
        name,
      },
    }));

    if (id && !isSameId) {
      clearAutoCheckOnline();
      getBasketItems(id);
      addBookmark({ label: name, value: id });
    }
  }, [state.current.id]);

  /**
   * Toggle (open / close) basket handler.
   */
  const toggleBasket = useCallback(() => {
    setState((state) => ({
      ...state,
      isOpen: !state.isOpen,
    }));

    setBasketStorage((state: any) => ({
      ...state,
      isOpen: !state.isOpen,
    }));
  }, []);

  /**
   * Indicates if basket is dragging..
   */
  const setIsDragging = useCallback((isDragging: boolean) => {
    setState((state) => ({
      ...state,
      isDragging,
    }));
  }, []);

  /**
   * Basket position handler.
   */
  const setPosition = useCallback((position: BasketPosition) => {
    setState((state) => ({
      ...state,
      position,
    }));

    setBasketStorage((state: any) => ({
      ...state,
      position,
    }));
  }, []);

  const setLastAgentSettingsPosition = useCallback((lastAgentSettingsPosition: BasketPosition) => {
    setState((state) => ({
      ...state,
      lastAgentSettingsPosition,
    }));

    setBasketStorage((state: any) => ({
      ...state,
      lastAgentSettingsPosition,
    }));
  }, []);

  /**
   * Set basket items.
   */
  const setItems = useCallback((items: BasketItem[]) => {
    setState((state) => ({
      ...state,
      items: {
        ...state.items,
        data: items,
      },
    }));
  }, []);

  const publishBasket = async (basketId: number, showMessage = true, description = '') => {
    let response = null;

    try {
      const client = await basketClient({
        action: BasketActions.Publish,
        level: BasketActionLevel.Basket,
        conditions: {
          basketId,
          ...description ? {
            description,
          } : {},
        },
      });

      response = {
        ...client,
        basketId,
      };

      if (client?.status === 'OK') {
        if (showMessage) {
          showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.publishBasket.success));
        }

        setState((state) => ({
          ...state,
          isPublish: true,
        }));
      } else if (showMessage) {
        const errorMessage = basketError(client, TRANSLATION_KEYS.publishBasket.fail);
        showToast(TOAST_ERROR, t(errorMessage));
      }
    } catch (error) {
      console.log(error);
    }

    return response;
  };

  const unPublishBasket = async (basketId: number, showMessage = true) => {
    let response = null;

    try {
      const client = await basketClient({
        action: BasketActions.UnPublish,
        level: BasketActionLevel.Basket,
        conditions: {
          basketId,
        },
      });

      response = {
        ...client,
        basketId,
      };

      if (client?.status === 'OK') {
        if (showMessage) {
          showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.unPublishBasket.success));
        }

        setState((state) => ({
          ...state,
          isPublish: false,
        }));
      } else if (showMessage) {
        const errorMessage = basketError(client, TRANSLATION_KEYS.unPublishBasket.fail);
        showToast(TOAST_ERROR, t(errorMessage));
      }
    } catch (error) {
      console.log(error);
    }

    return response;
  };

  /**
   * Swap items handler.
   */
  const swapItems = useCallback((rowId: number, rowIdSwap: number) => {
    (async () => {
      try {
        const client = await basketClient({
          action: BasketActions.Swap,
          level: BasketActionLevel.Item,
          conditions: {
            language: providerConfig?.language,
            currency: providerConfig?.currency,
            basketId: state.current.id,
            rowId,
            rowIdSwap,
          },
        });

        if (client?.status === 'OK') {
          showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.swap.success));
        } else {
          const errorMessage = basketError(client, TRANSLATION_KEYS.swap.fail);
          showToast(TOAST_ERROR, t(errorMessage));
        }
      } catch (error) {
        console.log({
          error,
        });

        showToast(TOAST_ERROR, t(TRANSLATION_KEYS.swap.fail));
      }
    })();
  }, [state.current.id]);

  /**
   * Get Basket Actions handler.
   */
  const getBasketActions = () => {
    (async () => {
      const data = encodeURIComponent(JSON.stringify({
        language: providerConfig?.language,
      }));

      const parseActions = (actions: any) => {
        const allowedActions: Record<string, string[]> = {};

        Object.keys(actions).map((group) => {
          const items = actions[group]?.List ?? [];
          const groupName = group.toLowerCase();
          items.map((item: any) => {
            if (!(groupName in allowedActions)) {
              allowedActions[groupName] = [];
            }
            allowedActions[group.toLowerCase()].push(item.Action);
          });
        });

        return allowedActions;
      };

      const request = await promiseRequest(`${CONTENT_SERVICE_URL}BasketActions/${data}`, null);
      const actions = request?.Sections?.BasketActions;
      const basketActions = actions?.BasketActions?.Basket?.List;
      const IbeDefaultHost = actions?.IbeDefaultHost;

      if (actions) {
        const ibeLink = basketActions.find((item: any) => item.Action === 'shareBasket');

        setState((state) => ({
          ...state,
          liveRoom: '',
          IbeDefaultHost: IbeDefaultHost ?? '',
          actions: parseActions(actions?.BasketActions ?? {}),
          affiliates: {
            ...state.affiliates,
            list: ibeLink?.Affiliates ?? [],
            selected: ibeLink?.Url ?? '',
          },
        }));
      }
    })();
  };

  /**
   * Baskets list handler.
   */
  const getBasketsList = useCallback(async (id?: number[]) => {
    const isRequestWithId = id?.length;
    let client = null;
    try {
      setState((state) => ({
        ...state,
        list: {
          ...state.list,
          isLoading: true,
        },
      }));

      client = await basketClient({
        method: 'get',
        action: BasketActions.List,
        level: BasketActionLevel.Basket,
        conditions: {
          language: providerConfig?.language,
          currency: providerConfig?.currency,
          ...BASKETS_LIST,
          ...isRequestWithId ? {
            basketId: id,
          } : {},
        },
      });

      if (client?.list?.basket && !isRequestWithId) {
        const parsedData = client.list.basket.map((item: any) => ({
          label: item.name,
          value: item.id,
          isBlocked: !!item.blocked,
          itemsCount: item.count,
        }));

        setState((state) => ({
          ...state,
          list: {
            ...state.list,
            data: parsedData,
          },
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        list: {
          ...state.list,
          isError: true,
        },
      }));
    } finally {
      setState((state) => ({
        ...state,
        list: {
          ...state.list,
          isReady: true,
          isLoading: false,
        },
      }));
    }

    return client;
  }, [state.list.isReady]);

  /**
   * Basket items count handler.
   */
  const getItemsCount = useCallback(() => {
    if (!state.current.id) {
      return;
    }

    (async () => {
      try {
        const client = await basketClient({
          method: 'get',
          action: BasketActions.Count,
          level: BasketActionLevel.Item,
          conditions: {
            basketId: state.current.id,
          },
        });

        if (client?.details) {
          setState((state) => ({
            ...state,
            count: client.details.count,
          }));
        }
      } catch (error) {
        console.log({
          error,
        });
      }
    })();
  }, [state.current.id]);

  /**
   * Basket items (details) handler.
   * @param basketId
   */
  const getBasketItems = useCallback((basketId: number | null, force: boolean = false) => {
    if ((state.items.isReady && !force) || !basketId) {
      return;
    }

    (async () => {
      try {
        setState((state) => ({
          ...state,
          items: {
            ...state.items,
            isLoading: true,
          },
        }));

        const client = await basketClient({
          method: 'get',
          action: BasketActions.Details,
          level: BasketActionLevel.Basket,
          conditions: {
            agencyShare: 1,
            language: providerConfig?.language,
            currency: providerConfig?.currency,
            basketId,
          },
        });

        if (client?.details) {
          const {
            items, hashId, count, blocked = false, public: isPublish = false, description = '',
          } = client.details;
          const selectedItems = items?.map((item: any) => item.rowid) ?? [];

          autoStatusCheck.current.offers = selectedItems;
          autoStatusCheck.current.index = 0;

          setState((state) => ({
            ...state,
            count,
            items: {
              ...state.items,
              data: items?.map((item: BasketItem) => ({
                ...item,
                offer: {
                  ...item.offer,
                },
                online: onlineInitialState,
              })) ?? [],
            },
            current: {
              ...state.current,
              hash: hashId,
            },
            description,
            isPublish,
            isBlocked: blocked,
            selectedItems,
            allSelected: true,
          }));
        }
      } catch (error) {
        console.log({
          error,
        });
        setState((state) => ({
          ...state,
          count: 0,
          items: {
            ...state.items,
            isError: true,
          },
        }));
      } finally {
        setState((state) => ({
          ...state,
          items: {
            ...state.items,
            isReady: true,
            isLoading: false,
          },
        }));
      }
    })();
  }, [state.items.isReady]);

  /**
   * Change status handler.
   * @param rowId
   * @param status
   */
  const updateStatus = useCallback((rowId: number, offerBase: any, status: string) => {
    (async () => {
      try {
        const { Price } = offerBase;

        if (!ALLOWED_STATUS_UPDATE.includes(status)) {
          return;
        }

        await basketClient({
          action: BasketActions.Change,
          level: BasketActionLevel.Item,
          conditions: {
            basketId: state.current.id,
            rowId,
            status,
            online: {
              currency: Price.Total.currency,
              priceTotal: toNumber(Price.Total.amount),
              ...Price?.Details?.Participants?.length ? {
                pricePersons: Price.Details.Participants.map((item: any) => toNumber(item.amount)),
              } : {},
            },
          },
        });
      } catch (error) {
        console.log({
          error,
        });
      }
    })();
  }, [state.current.id]);

  /**
   * Sets error status.
   * @param status
   * @param rowId
   * @param message
   */
  const setStatusError = (status: string, rowId: number, message?: string) => {
    setState((state) => ({
      ...state,
      items: {
        ...state.items,
        data: state.items.data.map((item) => (item.rowid === rowId ? ({
          ...item,
          offer: {
            ...item.offer,
            Base: {
              ...item.offer.Base,
              Availability: {
                ...item.offer.Base?.Availability ?? {},
                ...message ? { message } : {},
                base: status as any,
              },
            },
          },
          online: {
            ...item.online,
            isLoading: false,
            isError: false,
            isReady: true,
          },
        }) : item)),
      },
    }));
  };

  const checkOnlineWithServices = async (offer: IOffer) => {
    let data = {};

    try {
      const cancelTokenSource = axios.CancelToken.source();
      const AdditionalServices = offer.AdditionalServices;

      const request = promiseRequest(
        `${SEARCH_API}${FORM_CHECK_ONLINE_ENDPOINT}?Base.OfferId=${offer.Base?.OfferId}`,
        { AdditionalServices }, 0, cancelTokenSource, ONLINE_SERVICE_TIMEOUT,
      );

      const response = await request;

      data = response?.result?.offer;
    } catch (error) {
      console.log({
        error,
      });
    }

    return data;
  };

  const offerReplaceCheck = async (rowId: number) => {
    let data: any = {};

    try {
      const request = await basketClient({
        action: BasketActions.ReplaceCheck,
        level: BasketActionLevel.Item,
        conditions: {
          language: providerConfig?.language,
          currency: providerConfig?.currency,
          basketId: state.current.id,
          rowId,
        },
      });

      data = request?.item?.offer;

      if (!isEmpty(data)) {
        setState((state) => ({
          ...state,
          items: {
            ...state.items,
            data: state.items.data.map((item) => (item.rowid === rowId ? ({
              ...item,
              offer: data,
              online: {
                ...item.online,
                isLoading: true,
                isError: false,
                isReady: true,
              },
            }) : item)),
          },
        }));
      }
    } catch (error) {
      console.log({
        error,
      });
    }

    return data;
  };

  const checkOnlineRequest = async (offer: IOffer) => {
    let data = {};

    try {
      const actions = offer?.Online?.actions;
      const cancelTokenSource = axios.CancelToken.source();

      if (!actions) {
        return;
      }

      const request = Promise.allSettled(actions.map((item) => {
        const requestData = {
          actions: [item.action],
          offerIds: [offer?.Base?.OfferId],
          includeTFG: offer?.Base?.Price?.Total?.details?.TFGIncluded ?? false,
        };

        return promiseRequest(
          `${SEARCH_API}${CHECK_ONLINE_ENDPOINT}`,
          requestData, 0, cancelTokenSource, ONLINE_SERVICE_TIMEOUT,
        );
      }));

      const response = await request;
      const fullFilled = response.filter((item) => item.status === 'fulfilled');

      fullFilled?.map((responseItem: any) => {
        const { action, offer } = responseItem.value.results[0];
        const { fieldList = [] } = actions.find((item) => item.action === action) ?? {};
        const fields = fieldList.map((item) => item.replace('.*', ''));

        const filteredData = pick(offer, fields);

        data = {
          ...data,
          ...filteredData,
        };
      });
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line consistent-return
    return data;
  };

  /**
   * Check offer status.
   * @param rowId
   */
  const checkOnline = useCallback(async (rowId: number, force = false, showMessage = true, replaceCheck = true) => {
    let data: any = {};
    const { offer = undefined, online } = state.items.data.find((item) => item.rowid === rowId) ?? {};
    const actions = offer?.Online?.actions;
    const isOfferExpired = isDatePassed(offer?.Base?.StartDate ?? '');

    if (!offer || !actions || isOfferExpired) {
      if (isOfferExpired) {
        setStatusError('notavailable', rowId, t('lbl_offer_expired_message'));
      }
      return;
    }

    const hasAdditionalServices = offer?.AdditionalServices;

    if (online?.isReady && !force) {
      if (showMessage) {
        const messageType = ['onrequest', 'available'].includes(offer?.Base?.Availability?.base ?? '')
          ? TOAST_SUCCESS
          : TOAST_ERROR;

        showToast(messageType, offer?.Base?.Availability?.message as string);
      }
      return;
    }

    try {
      setState((state) => ({
        ...state,
        items: {
          ...state.items,
          data: state.items.data.map((item) => (item.rowid === rowId ? ({
            ...item,
            online: {
              ...item.online,
              isLoading: true,
              isReady: false,
              isError: false,
            },
          }) : item)),
        },
      }));

      data = hasAdditionalServices
        ? await checkOnlineWithServices(offer)
        : await checkOnlineRequest(offer);

      const onlineOffer = mergeWith(offer, { ...data ?? {} }, offerMergeCustomizer);

      if (onlineOffer?.Base?.Availability?.base) {
        const newStatus = STATUS_MAP[onlineOffer?.Base?.Availability?.base];

        if (['XX', '?'].includes(newStatus) && replaceCheck) {
          const replacedOffer = await offerReplaceCheck(rowId);

          if (!isEmpty(replacedOffer)) {
            const replacedOfferOnline = await checkOnline(rowId, true, false, false);

            if (['available', 'onrequest'].includes(replacedOfferOnline?.Base.Availability.base)) {
              showToast(TOAST_INFO, t('lbl_offer_replaced'));
            }
            return;
          }
        }

        updateStatus(rowId, onlineOffer?.Base, newStatus);
      }

      setState((state) => ({
        ...state,
        items: {
          ...state.items,
          data: state.items.data.map((item) => (item.rowid === rowId ? ({
            ...item,
            offer: onlineOffer,
            online: {
              ...item.online,
              isLoading: false,
              isError: false,
              isReady: true,
            },
          }) : item)),
        },
      }));
    } catch (error) {
      console.log({
        error,
      });
      setStatusError('unknown', rowId, t('lbl_offer_status_unknown'));
    }

    return data;
  }, [state.items]);

  /**
   * Check offer status.
   * @param rowId
   * @param bookingId
   * @param operator
   */
  const checkBookingStatus = useCallback(async (rowId: number, bookingId: string, operator: string) => {
    const { offer = undefined, online } = state.items.data.find((item) => item.rowid === rowId) ?? {};

    try {
      setState((state) => ({
        ...state,
        items: {
          ...state.items,
          data: state.items.data.map((item) => (item.rowid === rowId ? ({
            ...item,
            online: {
              ...item.online,
              isLoading: true,
              isReady: false,
              isError: false,
            },
          }) : item)),
        },
      }));
      const cancelTokenSource = axios.CancelToken.source();

      const response = await promiseRequest(
        `${SEARCH_API}${CHECK_BOOKING_STATUS_ENDPOINT}?Booking.Id=${bookingId}&Booking.Operator=${operator}`,
        null, 0, cancelTokenSource, ONLINE_SERVICE_TIMEOUT,
      );

      if (response?.result?.booking) {
        const { Price, Status } = response.result.booking;

        updateStatus(rowId, { Price }, Status);

        setState((state) => ({
          ...state,
          items: {
            ...state.items,
            data: state.items.data.map((item) => (item.rowid === rowId ? ({
              ...item,
              booking: {
                id: bookingId,
                status: response.result.booking.Status,
              },
              offer: {
                ...item.offer,
                Base: {
                  ...item.offer.Base,
                  Price: response.result.booking?.Price ? {
                    ...item.offer.Base?.Price,
                    Details: response.result.booking?.Price?.Details,
                    FirstPerson: {
                      ...item.offer.Base?.Price?.FirstPerson,
                      amount: response.result.booking?.Price?.FirstPerson?.amount ?? 0,
                      currency: response.result.booking?.Price?.FirstPerson?.currency ?? '',
                    },
                    Total: {
                      ...item.offer.Base?.Price?.Total,
                      amount: response.result.booking?.Price?.Total?.amount ?? 0,
                      currency: response.result.booking?.Price?.Total?.currency ?? '',
                    },
                  } : item.offer.Base?.Price,
                },
              },
              online: {
                ...item.online,
                isLoading: false,
                isError: false,
                isReady: !!response.result.booking?.Price,
              },
            }) : item)),
          },
        }));
      }
    } catch (error) {
      console.log({
        error,
      });
    }
  }, [state.items]);

  /**
   * Clears offers status check.
   */
  const clearAutoCheckOnline = useCallback(() => {
    clearTimeout(autoStatusCheck.current.timeout);
    autoStatusCheck.current = {
      index: 0,
      offers: state.selectedItems,
      timeout: null,
    };
  }, [state.selectedItems]);

  /**
   * Checks selected offers status.
   */
  const autoCheckOnline = useCallback(() => {
    const { index, offers } = autoStatusCheck.current;

    clearTimeout(autoStatusCheck.current.timeout);

    autoStatusCheck.current.timeout = null;

    autoStatusCheck.current.timeout = setTimeout(() => {
      const nextIndex = index + 1;

      if (offers[index] !== undefined) {
        const rowId = offers[index];

        (async () => {
          await checkOnline(rowId, true);

          autoStatusCheck.current.index = nextIndex;

          if (nextIndex < offers.length) {
            autoCheckOnline();
          } else {
            clearAutoCheckOnline();
          }
        })();
      }
    }, 500);
  }, [checkOnline]);

  /**
   * Change basket name handler.
   */
  const changeBasketName = useCallback(async (id: number | null, name: string) => {
    let response = null;

    if (!id) {
      return;
    }

    try {
      setState((state) => ({
        ...state,
        isBusy: true,
      }));

      const client = await basketClient({
        action: BasketActions.ChangeName,
        level: BasketActionLevel.Basket,
        conditions: {
          agencyShare: 1,
          language: providerConfig?.language,
          currency: providerConfig?.currency,
          basketId: id,
          name,
        },
      });

      response = {
        ...client,
        basketId: id,
        name,
      };

      if (client?.status === 'OK') {
        showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.changeBasketName.success));

        setBasketStorage((state: any) => ({
          ...state,
          basket: {
            ...state.basket,
            name,
          },
          bookmarks: state.bookmarks.map((bookmark: any) => (bookmark.value === id ? ({
            ...bookmark,
            label: name,
          }) : bookmark)),
        }));

        setState((state) => ({
          ...state,
          bookmarks: state.bookmarks.map((bookmark) => (bookmark.value === id ? ({
            ...bookmark,
            label: name,
          }) : bookmark)),
          list: {
            ...state.list,
            data: state.list.data?.map((item) => (item.value === id ? ({
              ...item,
              label: name,
            }) : item)),
          },
        }));
      } else {
        const errorMessage = basketError(client, TRANSLATION_KEYS.changeBasketName.fail);
        showToast(TOAST_ERROR, t(errorMessage));
      }
    } catch (error) {
      console.log({
        error,
      });
      showToast(TOAST_ERROR, t(TRANSLATION_KEYS.changeBasketName.fail));
    } finally {
      setState((state) => ({
        ...state,
        isBusy: false,
      }));
    }

    return response;
  }, []);

  const cloneOffer = useCallback((rowId: number, basketId: number) => {
    (async () => {
      setState((state) => ({
        ...state,
        isBusy: true,
      }));

      try {
        const client = await basketClient({
          action: BasketActions?.CloneOffer,
          level: BasketActionLevel.Item,
          conditions: {
            basketId,
            rowId,
          },
        });

        if (client?.status === 'OK') {
          getBasketItems(basketId);
          showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.cloneOffer.success));
        } else {
          const errorMessage = basketError(client, TRANSLATION_KEYS.cloneOffer.fail);
          showToast(TOAST_ERROR, t(errorMessage));
        }
      } catch (error) {
        showToast(TOAST_ERROR, t(TRANSLATION_KEYS.cloneOffer.fail));
      } finally {
        setState((state) => ({
          ...state,
          isBusy: false,
        }));
      }
    })();
  }, []);

  /**
   * Change item name handler.
   */
  const changeItemName = useCallback((rowId: number, name: string) => {
    (async () => {
      try {
        setState((state) => ({
          ...state,
          isBusy: true,
        }));

        const client = await basketClient({
          action: BasketActions.ChangeName,
          level: BasketActionLevel.Item,
          conditions: {
            agencyShare: 1,
            language: providerConfig?.language,
            currency: providerConfig?.currency,
            basketId: state.current.id,
            rowId,
            name,
          },
        });

        if (client?.status === 'OK') {
          showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.changeItemName.success));
          setState((state) => {
            const basketItems = state.items.data;
            const selectedItem = basketItems.filter((item: any) => item.rowid === rowId)[0];
            selectedItem.nameCustom = name;
            return ({
              ...state,
            });
          },
          );
        } else {
          const errorMessage = basketError(client, TRANSLATION_KEYS.changeItemName.fail);
          showToast(TOAST_ERROR, t(errorMessage));
        }
      } catch (error) {
        console.log({
          error,
        });
        showToast(TOAST_ERROR, t(TRANSLATION_KEYS.changeItemName.fail));
      } finally {
        setState((state) => ({
          ...state,
          isBusy: false,
        }));
      }
    })();
  }, [state.current.id]);

  /**
   * Add item to basket handler.
   */
  const addItem = useCallback((request: any, customMessage: any) => {
    if (!state.current.id) {
      return;
    }

    (async () => {
      try {
        setState((state) => ({
          ...state,
          isBusy: true,
        }));

        const client = await basketClient({
          action: BasketActions.Add,
          level: BasketActionLevel.Item,
          conditions: {
            agencyShare: 1,
            language: providerConfig?.language,
            currency: providerConfig?.currency,
            basketId: state.current.id,
            replaceItems: !!providerConfig?.overwriteBasket,
            ...request,
          },
        });

        if (client?.status === 'OK') {
          showToast(TOAST_SUCCESS, customMessage?.success ?? t(TRANSLATION_KEYS.addItem.success));
          setState((state) => ({
            ...state,
            items: {
              ...state.items,
              isReady: false,
            },
          }));
          getBasketItems(state.current.id);
          getItemsCount();
        } else {
          const errorMessage = basketError(client, TRANSLATION_KEYS.addItem.fail);
          showToast(TOAST_ERROR, t(errorMessage));
        }
      } catch (error) {
        console.log({
          error,
        });
        showToast(TOAST_ERROR, customMessage?.fail ?? t(TRANSLATION_KEYS.addItem.fail));
      } finally {
        setState((state) => ({
          ...state,
          isBusy: false,
        }));
      }
    })();
  }, [state.current.id, providerConfig?.overwriteBasket]);

  /**
   * Delete item/s from basket handler.
   */
  const deleteItem = useCallback((rowId: number[]) => {
    (async () => {
      try {
        if (!rowId.length) {
          showToast(TOAST_ERROR, t('lbl_basket_no_offers_checked'));
          return;
        }

        setState((state) => ({
          ...state,
          isBusy: true,
        }));

        const client = await basketClient({
          action: BasketActions.Delete,
          level: BasketActionLevel.Item,
          conditions: {
            agencyShare: 1,
            language: providerConfig?.language,
            currency: providerConfig?.currency,
            basketId: state.current.id,
            rowId,
          },
        });

        if (client?.status === 'OK') {
          showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.deleteItem.success));

          setState((state) => ({
            ...state,
            items: {
              ...state.items,
              data: [...state.items.data].filter((item) => !rowId.includes(item.rowid)),
              isReady: true,
            },
            selectedItems: [...state.selectedItems].filter((item) => !rowId.includes(item)),
          }));

          getItemsCount();
        } else {
          const errorMessage = basketError(client, TRANSLATION_KEYS.deleteItem.fail);
          showToast(TOAST_ERROR, t(errorMessage));
        }
      } catch (error) {
        console.log({
          error,
        });
        showToast(TOAST_ERROR, t(TRANSLATION_KEYS.deleteItem.fail));
      } finally {
        setState((state) => ({
          ...state,
          isBusy: false,
        }));
      }
    })();
  }, [state.current.id]);

  /**
   * Get ShortLink for social network.
   */
  const getShortLink = useCallback((rowId: number[], defaultUrl) => {
    (async () => {
      try {
        const client = await basketClient({
          action: BasketActions.ShortLink,
          level: BasketActionLevel.Item,
          conditions: {
            agencyShare: 1,
            language: providerConfig?.language,
            currency: providerConfig?.currency,
            basketId: state.current.id,
            rowId,
            Affiliate: defaultUrl,
          },
        });

        if (client?.status === 'OK' && client?.details) {
          const itemDetails = client?.details.items.find((item: any) => item.rowid === rowId[0]);

          setState((state) => {
            const item = state.items.data.find((item) => item.rowid === rowId[0]);
            if (item && itemDetails?.shortLinkUrl) {
              const data = state.items.data.map((item) => {
                const retValue = { ...item };
                if (retValue.rowid === rowId[0]) {
                  retValue.AffiliateUrl = itemDetails?.shortLinkUrl ?? '';
                }
                return retValue;
              });
              return {
                ...state,
                items: {
                  ...state.items,
                  data,
                },
              };
            }
            return {
              ...state,
            };
          });
        }
      } catch (error) {
        console.log({
          error,
        });
      }
    })();
  }, [state.current.id]);

  /**
   * Select item handler.
   * @param rowId
   */
  const selectItem = useCallback((rowId: number) => {
    setState((state) => {
      const copy = [...state.selectedItems];
      const isSelected = copy.includes(rowId);

      const newSelectedItems = isSelected ? copy.filter((item) => item !== rowId) : [...copy, rowId];

      return {
        ...state,
        selectedItems: newSelectedItems,
        allSelected: state.items.data.length === newSelectedItems.length,
      };
    });
  }, [state.selectedItems]);

  /**
   * Select all items handler.
   */
  const selectAllItems = () => {
    setState((state) => ({
      ...state,
      selectedItems: state.allSelected ? [] : state.items.data.map((item) => item.rowid),
      allSelected: !state.allSelected,
    }));
  };

  /**
   * Add bookmark handler.
   * @param basket
   */
  const addBookmark = useCallback((basket: { label: string, value: number }) => {
    setState((state: any) => {
      const newBookmarks = [...state.bookmarks];
      if (isEmpty(newBookmarks?.filter((item: any) => item.value === basket.value))) {
        if (newBookmarks.length === 5) {
          newBookmarks.shift();
          newBookmarks.push(basket);
        } else {
          newBookmarks.push(basket);
        }
      }

      setBasketStorage((state: any) => ({
        ...state,
        bookmarks: newBookmarks,
      }));

      return {
        ...state,
        bookmarks: newBookmarks,
      };
    });
  }, []);

  /**
   * Delete bookmark handler.
   * @param value
   */
  const deleteBookmark = useCallback((value: number | number[]) => {
    setState((mainState: any) => {
      const isCurrentBasket = isArray(value)
        ? includes(value, mainState.current.id)
        : mainState.current.id === value;

      const newBookmarks = [...mainState.bookmarks].filter((item: any) => (isArray(value) ? !value.includes(item.value) : item.value !== value));

      setBasketStorage((state: any) => ({
        ...state,
        bookmarks: newBookmarks,
        ...isCurrentBasket || mainState.current.id === 0 ? {
          basket: {
            id: null,
            name: '',
          },
        } : {},
      }));

      return {
        ...mainState,
        bookmarks: newBookmarks,
        ...isCurrentBasket ? {
          current: {
            id: null,
            name: '',
          },
          items: {
            ...mainState.items,
            isReady: false,
            data: [],
          },
        } : {},
      };
    });
  }, []);

  /**
   * Create new basket handler.
   * @param name
   */
  const createBasket = useCallback(async (name: string) => {
    const newBasketName = name || `${t('lbl_basket_default_name')} - ${moment().format('DD.MM.YYYY HH:mm')}`;

    let response = null;

    try {
      setState((state) => ({
        ...state,
        isBusy: true,
      }));

      const client = await basketClient({
        action: BasketActions.Add,
        level: BasketActionLevel.Basket,
        conditions: {
          name: newBasketName,
        },
      });

      response = {
        ...client,
        name: newBasketName,
      };

      if (client?.status === 'OK') {
        showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.addBasket.success));

        setState((state) => {
          const newBasketsList = [{
            label: newBasketName,
            value: client.details.id as number,
            itemsCount: 0,
          }, ...state.list.data];

          return {
            ...state,
            current: {
              ...state.current,
              id: client.details.id,
              name: newBasketName,
              hash: client.details.hashId,
            },
            count: 0,
            list: {
              ...state.list,
              data: newBasketsList,
            },
            items: {
              ...state.items,
              data: [],
              isReady: true,
            },
          };
        });

        setBasketStorage((state: any) => ({
          ...state,
          basket: {
            ...state.basket,
            id: client.details.id,
            name: newBasketName,
          },
        }));

        addBookmark({ label: newBasketName, value: client.details.id });
      } else {
        const errorMessage = basketError(client, TRANSLATION_KEYS.addBasket.fail);
        showToast(TOAST_ERROR, t(errorMessage));
      }
    } catch (error) {
      console.log({
        error,
      });
      showToast(TOAST_ERROR, t(TRANSLATION_KEYS.addBasket.fail));
    } finally {
      setState((state) => ({
        ...state,
        isBusy: false,
      }));
    }

    return response;
  }, []);

  /**
   * Delete basket handler.
   */
  const deleteBasket = useCallback(async (id: number | number[] | null) => {
    if (!id) {
      return;
    }

    let response = null;

    try {
      setState((state) => ({
        ...state,
        isBusy: true,
      }));

      const client = await basketClient({
        action: BasketActions.Delete,
        level: BasketActionLevel.Basket,
        conditions: {
          ...isArray(id) ? {
            basketIdList: id,
          } : {
            basketId: id,
          },
        },
      });

      response = {
        ...client,
        basketId: id,
      };

      if (client?.status === 'OK') {
        showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.deleteBasket.success));

        setState((state) => ({
          ...state,
          current: {
            ...state.current,
            id: 0,
            name: '',
            hash: '',
          },
          count: 0,
          selectedItems: [],
          allSelected: false,
          items: {
            ...state.items,
            data: [],
            isReady: true,
          },
        }));

        deleteBookmark(id);
      } else {
        const errorMessage = basketError(client, TRANSLATION_KEYS.deleteBasket.fail);
        showToast(TOAST_ERROR, t(errorMessage));
      }
    } catch (error) {
      showToast(TOAST_ERROR, t(TRANSLATION_KEYS.deleteBasket.fail));
    } finally {
      setState((state) => ({
        ...state,
        isBusy: false,
      }));
    }

    return response;
  }, []);

  /**
   * Block basket handler.
   */
  const blockBasket = useCallback(async (id: number | null) => {
    if (!id) {
      return;
    }

    let response = null;

    try {
      setState((state) => ({
        ...state,
        isBusy: true,
      }));

      const client = await basketClient({
        action: BasketActions.Block,
        level: BasketActionLevel.Basket,
        conditions: {
          basketId: id,
        },
      });

      response = {
        ...client,
        basketId: id,
      };

      if (client?.status === 'OK') {
        showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.blockBasket.success));

        setState((state) => ({
          ...state,
          isBlocked: true,
        }));
      } else {
        const errorMessage = basketError(client, TRANSLATION_KEYS.blockBasket.fail);
        showToast(TOAST_ERROR, t(errorMessage));
      }
    } catch (error) {
      showToast(TOAST_ERROR, t(TRANSLATION_KEYS.blockBasket.fail));
    } finally {
      setState((state) => ({
        ...state,
        isBusy: false,
      }));
    }

    return response;
  }, []);

  /**
   * Unblock basket handler.
   */
  const unBlockBasket = useCallback(async (id: number | null) => {
    if (!id) {
      return;
    }

    let response = null;

    try {
      setState((state) => ({
        ...state,
        isBusy: true,
      }));

      const client = await basketClient({
        action: BasketActions.Unblock,
        level: BasketActionLevel.Basket,
        conditions: {
          basketId: id,
        },
      });

      response = {
        ...client,
        basketId: id,
      };

      if (client?.status === 'OK') {
        showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.unblockBasket.success));

        setState((state) => ({
          ...state,
          isBlocked: false,
        }));
      } else {
        const errorMessage = basketError(client, TRANSLATION_KEYS.unblockBasket.fail);
        showToast(TOAST_ERROR, t(errorMessage));
      }
    } catch (error) {
      showToast(TOAST_ERROR, t(TRANSLATION_KEYS.unblockBasket.fail));
    } finally {
      setState((state) => ({
        ...state,
        isBusy: false,
      }));
    }

    return response;
  }, []);

  /**
   * Creates static ibeLink.
   */
  const createStaticIbeLink = useCallback((id: number | null) => {
    if (!id) {
      return;
    }

    (async () => {
      try {
        setState((state) => ({
          ...state,
          isBusy: true,
        }));

        const client = await basketClient({
          action: BasketActions.IbeLinkStatic,
          level: BasketActionLevel.Basket,
          conditions: {
            agencyShare: 1,
            basketId: id,
            rowId: state.selectedItems,
          },
        });

        if (client?.status === 'OK') {
          const { id, name, hashId } = client.details;

          changeBasket(id, name);
          setBasketModal((state) => ({
            ...state,
            isOpen: true,
            hash: hashId,
            type: 'ibeLink',
          }));
          showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS.ibeLinkStaticMessage.success));
        } else {
          const errorMessage = basketError(client, TRANSLATION_KEYS.ibeLinkStaticMessage.fail);
          showToast(TOAST_ERROR, t(errorMessage));
        }
      } catch (error) {
        showToast(TOAST_ERROR, t(TRANSLATION_KEYS.ibeLinkStaticMessage.fail));
      } finally {
        setState((state) => ({
          ...state,
          isBusy: false,
        }));
      }
    })();
  }, [state.selectedItems]);

  /**
   * Creates dynamic ibeLink.
   */
  const createDynamicIbeLink = useCallback((id: number | null) => {
    if (!id) {
      return;
    }

    setBasketModal({
      isOpen: true,
      hash: state.current.hash,
      type: 'ibeLink',
    });
  }, [state.current]);

  /**
   * Creates liveroom url.
   */
  const liveRoom = useCallback((id: number | null) => {
    if (!id) {
      return;
    }

    (async () => {
      try {
        setState((state) => ({
          ...state,
          isBusy: true,
        }));

        const client = await basketClient({
          action: BasketActions.LiveRoom,
          level: BasketActionLevel.Basket,
          conditions: {
            agencyShare: 1,
            basketId: id,
          },
        });

        if (client?.status === 'OK') {
          setBasketModal({
            isOpen: true,
            hash: state.current.hash,
            type: 'liveRoom',
          });
        } else {
          const errorMessage = basketError(client, TRANSLATION_KEYS.liveRoom.fail);
          showToast(TOAST_ERROR, t(errorMessage));
        }
      } catch (error) {
        console.log({
          error,
        });
        showToast(TOAST_ERROR, t(TRANSLATION_KEYS.liveRoom.fail));
      } finally {
        setState((state) => ({
          ...state,
          isBusy: false,
        }));
      }
    })();
  }, [state.current]);

  /**
   * External url request (printPanel | compareOffers).
   * @param endpoint
   */
  const getExternalUrl = useCallback((endpoint: 'compareoffers' | 'printpanel', basketId: number | null, selectedItems: number[] | undefined) => {
    if (!basketId) {
      showToast(TOAST_ERROR, t('lbl_no_selected_basket'));
      return;
    }

    if (!selectedItems?.length) {
      showToast(TOAST_ERROR, t('lbl_basket_no_offers_checked'));
      return;
    }

    const hash = basketProtoInstance.encode({
      bid: basketId,
      checked: selectedItems,
    });

    const url = `${window.location.origin}/basket/${endpoint}/${basketId}.html?bf=${hash}`;

    window.open(url, '_blank');
  }, []);

  /**
   * Copy items to CSV handler.
   */
  const copyToCSV = useCallback(() => {
    try {
      const csvConfig = mkConfig(CSV_EXPORT_OPTIONS);

      const items = state.items.data
        .filter((item) => state.selectedItems.includes(item.rowid))
        .map(({ offer }) => {
          const transport: any = offer?.Transport;
          const transportType: any = !isEmpty(transport) ? Object.keys(transport)[0] : '';
          return {
            operator: get(offer, 'Base.Operator', ''),
            operatorName: get(offer, 'Base.OperatorDesc', ''),
            departureCode: !isEmpty(transport) ? get(transport[transportType].Out[0], 'Departure.Code', '') : '',
            departureName: !isEmpty(transport) ? get(transport[transportType].Out[0], 'Departure.Name', '') : '',
            departureDate: !isEmpty(transport) ? get(transport[transportType].Out[0], 'Departure.Date', '') : '',
            departureTime: !isEmpty(transport) ? get(transport[transportType].Out[0], 'Departure.Time', '') : '',
            destinationCode: !isEmpty(transport) ? get(transport[transportType].Ret[0], 'Destination.Code', '') : '',
            destinationName: !isEmpty(transport) ? get(transport[transportType].Ret[0], 'Destination.Name', '') : '',
            destinationDate: !isEmpty(transport) ? get(transport[transportType].Ret[0], 'Destination.Date', '') : '',
            destinationTime: !isEmpty(transport) ? get(transport[transportType].Ret[0], 'Destination.Time', '') : '',
            duration: `${get(offer, 'Base.Duration')} ${t('lbl_days')}`,
            nights: `${get(offer, 'Base.NightsBeforeReturn')} ${t('lbl_nights')}`,
            country: get(offer, 'Base.XCountry.Name', ''),
            region: get(offer, 'Base.XRegion.Name', ''),
            hotel: get(offer, 'Accommodation.XCode.Name', ''),
            category: get(offer, 'Accommodation.Category'),
            rating: get(offer, 'Accommodation.ExtTripAdvisor.rating', ''),
            service: get(offer, 'Accommodation.Service.Name', ''),
            room: get(offer, 'Accommodation.Room.Name', ''),
            price: get(offer, 'Base.Price.FirstPerson.amount'),
            currency: get(offer, 'Base.Price.FirstPerson.currency'),
            offerId: get(offer, 'Base.UniqueObjectId'),
          };
        });

      const csv = generateCsv(csvConfig)(items);

      download(csvConfig)(csv);
      showToast(TOAST_SUCCESS, t('lbl_csv_download_success'));
    } catch (error) {
      console.log(error);
      showToast(TOAST_ERROR, t('lbl_csv_download_fail'));
    }
  }, [state.items, state.selectedItems]);

  /**
   * Basket service errors handler.
   * @param error
   * @param defaultMessage
   */
  const basketError = (error: any, defaultMessage: string) => {
    if (error?.errorCode && error.errorCode in TRANSLATION_KEYS) {
      return TRANSLATION_KEYS[error.errorCode];
    }

    return defaultMessage;
  };
  /**
   * Basket request Handler.
   */
  const basketClient = async ({
    action,
    level,
    conditions,
    method = 'post',
    formData = undefined,
    timeout = undefined,
  }: BasketClient): Promise<any> => {
    const isPost = method === 'post';
    const headers = formData ? {
      'Content-Type': 'multipart/form-data',
    } : undefined;

    const params: any = {
      request: {
        method: action,
        level,
        conditions,
      },
    };

    const data = isPost ? formData ?? params : null;

    const url = !isPost
      ? `${BASKET_API_URL}/${action}/${encodeURIComponent(JSON.stringify(params))}`
      : `${BASKET_API_URL}/${action}`;

    return promiseRequest(url, data, 3, undefined, timeout, headers);
  };

  const isOfferInBasket = (offerData: any) => {
    const offerId = offerData?.Base?.OfferId;
    const similarList = state?.items?.data?.filter((item: any) => item?.offer?.Base?.OfferId === offerId);
    return similarList.length !== 0;
  };

  /**
   * Update bookmarks.
   * @param bookmarks
   */
  const updateBookmarksLabels = (bookmarks: Bookmark[]) => {
    (async () => {
      const request = await getBasketsList(bookmarks.map((item: any) => item.value));
      const basketList = request?.list?.basket;

      if (basketList?.length) {
        const newBookmarks = basketList.map((item: any) => ({
          label: item.name,
          value: item.id,
          itemsCount: item.count,
        }));

        setState((state) => {
          const validBookMarks = newBookmarks ?? state.bookmarks;
          return ({
            ...state,
            bookmarksUpdated: true,
            bookmarks: validBookMarks,
          });
        });
        setBasketStorage((state: any) => ({
          ...state,
          bookmarksUpdated: true,
          bookmarks: newBookmarks,
        }));
      }
    })();
  };

  /**
   * Updates basket items count.
   * @param count
   */
  const updateItemsCount = (count: number) => {
    const basketIconElement: any = document.querySelectorAll('.js-basket-react-icon .offer-circle');
    const basketIconQuantity = basketIconElement.length ? basketIconElement[0].children[0] : null;

    if (basketIconQuantity) {
      basketIconElement[0].style.display = count ? 'block' : 'none';
      basketIconQuantity.innerHTML = count;
    }
  };

  const setFullWidthBodyExt = (state: boolean) => {
    setFullWidthBody(() => state);
  };

  const basket = {
    ...state,
    favouriteBaskets: basketStorage?.favouriteBasketsList,
  };
  const value = useMemo(() => ({
    basket,
    isOfferInBasket,
    getBasketItems,
    toggleBasket,
    setIsDragging,
    setPosition,
    setLastAgentSettingsPosition,
    getBasketsList,
    changeBasket,
    addItem,
    setOfferView,
    changeItemName,
    changeBasketName,
    deleteItem,
    addBookmark,
    deleteBookmark,
    createBasket,
    deleteBasket,
    blockBasket,
    unBlockBasket,
    publishBasket,
    unPublishBasket,
    getExternalUrl,
    copyToCSV,
    selectItem,
    selectAllItems,
    changeView,
    basketClient,
    swapItems,
    setItems,
    checkOnline,
    autoCheckOnline,
    clearAutoCheckOnline,
    liveRoom,
    createStaticIbeLink,
    createDynamicIbeLink,
    fullWidthBody,
    cloneOffer,
    setFullWidthBodyExt,
    getShortLink,
    checkBookingStatus,
  }), [state, fullWidthBody, providerConfig?.overwriteBasket]);

  useEffect(() => {
    updateItemsCount(value.basket.count);
  }, [value.basket.count]);

  useEffect(() => {
    clearAutoCheckOnline();
  }, [value.basket.current.id]);

  useEffect(() => {
    autoStatusCheck.current.offers = value.basket.selectedItems;
    autoStatusCheck.current.index = 0;
  }, [value.basket.selectedItems]);

  useEffect(() => {
    window.BasketReact = value;

    const shouldFetchItems = !value.basket.items.isReady && !value.basket.items.isLoading;

    if (value.basket.isOpen && shouldFetchItems && value.basket.current.id) {
      getBasketItems(value.basket.current.id);
    }
  }, [value]);

  useEffect(() => {
    if (isEnabled) {
      getItemsCount();
      getBasketActions();
    }
  }, [isEnabled]);

  useEffect(() => {
    const bookmarks = getBasketStorage()?.bookmarks;

    if (value.basket.isOpen && !basket?.bookmarksUpdated && bookmarks?.length) {
      updateBookmarksLabels(bookmarks);
    }
  }, [value.basket.isOpen, value.basket.current.id]);

  return (
    <>
      {basketModal.isOpen && basketModal.type === 'ibeLink' && (
        <IbeLink
          affiliates={state.affiliates.list}
          defaultAffiliate={state.affiliates.selected}
          hashId={basketModal.hash}
          onClose={() => {
            setBasketModal({
              isOpen: false,
              hash: '',
              type: '',
            });
          }}
        />
      )}
      {basketModal.isOpen && basketModal.type === 'liveRoom' && (
        <LiveRoom
          url={state.liveRoom}
          language={providerConfig?.language ?? ''}
          hashId={basketModal.hash}
          onClose={() => {
            setBasketModal({
              isOpen: false,
              hash: '',
              type: '',
            });
          }}
        />
      )}
      <BasketContext.Provider value={value}>
        {children}
      </BasketContext.Provider>
    </>

  );
};

BasketProvider.defaultProps = defaultProps;
BasketProvider.displayName = 'BasketProvider';

export default withBeforeInitialization(BasketProvider, beforeInit);

export {
  BasketContext,
};
