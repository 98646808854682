import { IOffer } from '@ess/types';

export enum BasketActionLevel {
  List = 'list',
  Item = 'item',
  Basket = 'basket',
}

export enum BasketView {
  Basket = 'Basket',
  BasketList = 'BasketList',
}

export enum BasketActions {
  Add = 'add',
  AddMedia = 'addmedia',
  CloneOffer = 'cloneoffer',
  Upload = 'upload',
  List = 'List',
  Details = 'details',
  Delete = 'delete',
  Count = 'count',
  Swap = 'swap',
  Block = 'block',
  Unblock = 'unblock',
  SearchByName = 'searchByName',
  Change = 'change',
  ReplaceCheck = 'ReplaceCheck',
  ChangeName = 'changeName',
  LiveRoom = 'liveroom',
  IbeLinkStatic = 'ibelinkcopy',
  ConsultantsList = 'consultantslist',
  Publish = 'setpublic',
  UnPublish = 'setprivate',
  ShortLink = 'shortlink',
}

export enum BasketPosition {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom'
}

export enum ShareMethod {
  LiveRoom = 'liveRoom',
  IbeLink = 'ibeLink',
}

export interface FilterConditions {
  name?: string
  dateFrom?: string
  dateTo?: string
  // TODO GET LOGICS FOR CONSLTER
  consulter?: any
}

export enum OrderTypes {
  id = 'id',
  idDescending = 'id-',
  consultant = 'consultant',
  consultantDescending = 'consultant-',
  name = 'name',
  nameDescending = 'name-',
  source = 'source',
  sourceDescending = 'source-',
  description = 'description',
  descriptionDescending = 'description-',
  modifyDate = 'modifytime',
  modifyDateDescending = 'modifytime-'

}

export type FilterConditionsRequest = 'More' | 'New'

export interface FiltersType {
  filters?: FilterConditions,
  orderBy?: OrderTypes
}

interface DataState<T> {
  isLoading: boolean
  isReady: boolean
  isError: boolean
  data: T
}

export interface Bookmark {
  label: string
  value: number
}

export interface BasketClient {
  method?: 'post' | 'get'
  formData?: FormData
  timeout?: number
  action: BasketActions
  level: BasketActionLevel
  conditions: any
}

export interface BasketItem {
  id: number
  offerType: string
  rowid: number
  online: DataState<any>
  offer: IOffer
  mediaLinkUrl?: string
  nameCustom?: string
  booking?: {
    id: string
    status: string
  }
  search?: {
    form: string
    hash: string
  }
  AffiliateUrl: string
}

export interface BasketState {
  current: {
    id: number | null
    hash: string
    name: string
  }
  bookmarksUpdated: boolean
  liveRoom: string
  count: number
  isDisabledClose: boolean
  list: DataState<any[]>
  items: DataState<BasketItem[]>
  actions: any
  affiliates: {
    list: any[]
    selected: string
  }
  description: string
  offerView: string
  selectedItems: number[]
  allSelected: boolean
  bookmarks: Bookmark[]
  view: BasketView
  position: BasketPosition
  lastAgentSettingsPosition: BasketPosition | null
  isPublish: boolean
  isBlocked: boolean
  isOpen: boolean
  isDragging: boolean
  isBusy: boolean
  IbeDefaultHost: string
}
