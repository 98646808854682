import styled from 'styled-components';
import { isNumber } from 'lodash-es';

const Card = styled.div<{ width?: any, height?: any, minHeight?: any }>`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii.default};
  background-color: white;
  box-shadow: ${({ theme }) => theme.boxShadow[1]};
  width: ${({ width }) => `${width ? isNumber(width) ? `${width}px` : width : '100%'}`};
  height: ${({ height }) => `${height ? isNumber(height) ? `${height}px` : height : '100%'}`};
  overflow: hidden;

  ${({ minHeight }) => minHeight && `
    min-height: ${isNumber(minHeight) ? `${minHeight}px` : minHeight};
  `};
`;

export const Styled = {
  Card,
};
