import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { UrlParams } from '@liveroom/types';

import { useLiveRoomStore } from '@liveroom/hooks';

import { OfferList } from '@liveroom/components/OfferList';

type OffersContainerProps = {
  params?: any
  isFavouritesEnabled?: boolean
  autoRefreshEnabled?: boolean
}

const defaultProps = {
  params: undefined,
  isFavouritesEnabled: true,
  autoRefreshEnabled: false,
};

const OffersContainer = ({ params, autoRefreshEnabled, isFavouritesEnabled }: OffersContainerProps) => {
  const routeParams = useParams<UrlParams>();
  const { fetchOffers, offers } = useLiveRoomStore((state) => ({
    fetchOffers: state.fetchOffers,
    offers: state.offers,
  }));

  useEffect(() => {
    fetchOffers({
      params: params ?? routeParams,
      config: {
        autoRefreshEnabled: !!autoRefreshEnabled,
      },
      isFirstRequest: true,
    });
  }, []);

  return (
    <OfferList
      offers={offers}
      isFavouritesEnabled={isFavouritesEnabled}
    />
  );
};

OffersContainer.defaultProps = defaultProps;

export {
  OffersContainer,
};
