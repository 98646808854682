import React, { CSSProperties, forwardRef } from 'react';

import { Styled } from './Card.styles';

type CardProps = {
  children: React.ReactNode
  onClick?: (event?: React.MouseEvent) => void
  width?: any
  height?: any
  minHeight?: any
  style?: CSSProperties
}

const defaultProps = {
  onClick: undefined,
  width: undefined,
  height: 'auto',
  minHeight: undefined,
  style: {},
};

const Card = forwardRef<HTMLDivElement, CardProps>(({
  children,
  width,
  height,
  minHeight,
  onClick,
  style,
}, ref) => (
  <Styled.Card
    ref={ref}
    height={height}
    minHeight={minHeight}
    width={width}
    onClick={onClick}
    style={style}
  >
    {children}
  </Styled.Card>
));

Card.defaultProps = defaultProps;

export {
  Card,
};
