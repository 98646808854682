import { includes } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import showToast from '@ess/utils/form/ShowToast';

import useStorage from '@ess/hooks/useStorage';

/**
 * useFavourites hook.
 * Manages favourite items storage.
 */
const useFavourites = <T>(name: string, showNotifications = true) => {
  const { t } = useTranslation();
  const [favourites, setFavourites] = useStorage<T[]>('localStorage', `Favourite${name}`, []);

  /**
   * Clear favourites.
   */
  const clear = () => {
    setFavourites([]);

    if (showNotifications) {
      showToast(1, t('lbl_favourites_cleared'));
    }
  };

  /**
   * Add/Remove item from favourites.
   * @param item
   */
  const add = (item: any) => {
    setFavourites((state) => {
      const isInFavourites = includes(state, item);

      if (!isInFavourites) {
        if (showNotifications) {
          showToast(1, t('lbl_added_to_favourites'));
        }
        return [...state, item];
      }
      return state;
    });
  };

  /**
   * Remove item from favourites.
   * @param item
   */
  const remove = (item: any) => {
    setFavourites((state: any) => {
      const isInFavourites = includes(state, item);

      if (isInFavourites) {
        if (showNotifications) {
          showToast(1, t('lbl_removed_from_favourites'));
        }
        return [...state].filter((favorite) => favorite !== item);
      }
      return state;
    });
  };

  return {
    add,
    remove,
    clear,
    set: setFavourites,
    favourites,
  };
};

export default useFavourites;
