import React, { CSSProperties, useContext } from 'react';
import { isEmpty } from 'lodash-es';

import Tooltip from '@ess/ui/Tooltip';
import Text from '@ess/ui/Text';

import { OfferAttributesTags } from '@tourop/components/OfferElements/OfferAttributes';

import { OfferAttributesTagsFunc } from '@tourop/components/OfferElements/OfferAttributes/OfferAttributesTags';
import { AppConfigContext } from '@ess/context/AppConfigContext';

import { useTranslation } from 'react-i18next';
import { Styled } from './PriceCell.styles';
import TooltipContent from '../../TooltipContent';

type PriceCellProps = {
  offer: any
  style: CSSProperties
  isSelected: boolean
}

const PriceCell = ({
  offer,
  style,
  isSelected,
}: PriceCellProps) => {
  const { t } = useTranslation();
  const { state: AppConfig } = useContext(AppConfigContext);
  const { offerAttributesPriority } = AppConfig;

  return (
    <Tooltip
      content={(
        <TooltipContent
          offer={offer}
          isSelected={isSelected}
        />
    )}
      appendTo={document.getElementById('operator-chart') as HTMLElement}
      contentPadding={10}
      offset={[10, 0]}
      disabled={!offer}
      interactive
    >
      <Styled.PriceCell
        isSelected={isSelected}
        isDisabled={!offer}
        style={style}
      >
        <Text>
          {offer ? `${Math.round(Number(offer.Base.Price.Total.amount))} ${offer.Base.Price.Total.currency}` : '-'}
        </Text>
        {!isEmpty(offer) && (
        <OfferAttributesTags
          size="medium"
          offerAttributes={OfferAttributesTagsFunc(t, offer, offerAttributesPriority, true)}
          maxVisibleAttributes={1}
          isHighlighted={isSelected}
        />
        )}
      </Styled.PriceCell>
    </Tooltip>
  );
};

export default PriceCell;
