import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  get, toNumber, capitalize,
} from 'lodash-es';
import moment from 'moment';

import { IDictionary } from '@ess/types';

import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

import FlexBox from '@ess/ui/FlexBox';

import CustomLegend from './CustomLegend';
import CustomTooltip from './CustomTooltip';
import CustomLabel from './CustomLabel';

type LabelItem = {
  key: string,
  color: string,
  yAxisId: string,
  hide: boolean,
}

const TRANSLATION_KEYS: IDictionary<string> = {
  Price: 'lbl_totalprice',
  TempAir: 'lbl_air_temp',
  TempWater: 'lbl_water_temp',
};

const CHART_LABELS: IDictionary<LabelItem> = {
  TempAir: {
    key: 'TempAir',
    color: 'orange',
    yAxisId: 'temperature',
    hide: false,
  },
  TempWater: {
    key: 'TempWater',
    color: '#42a5f5',
    yAxisId: 'temperature',
    hide: false,
  },
  Price: {
    key: 'Price',
    color: 'red',
    yAxisId: 'price',
    hide: false,
  },
};

type ChartProps = {
  data: any
}

/**
 * Returns formatted date label.
 * @param date
 */
const getChartDate = (date: string) => {
  const momentDate = moment(date, 'MM.YYYY', true);
  const monthName = momentDate.isValid()
    ? capitalize(momentDate.format('MMMM'))
    : null;

  return monthName || date;
};

/**
 * Returns parsed chart data.
 * @param data
 */
const getChartData = (data: any) => data.Offers.map((item: any, index: number) => ({
  ...item,
  Price: toNumber(item?.Offer?.Base?.Price?.Total?.amount),
  Currency: item?.Offer?.Base?.Price?.Total?.currency,
  Date: getChartDate(data.Dates[index]),
}));

/**
 * Returns chart labels, checks if data exists.
 * @param data
 */
const getChartLabels = (data: any) => {
  const labels: LabelItem[] = [];

  Object.keys(CHART_LABELS).map((key) => {
    const hasData = data.some((item: any) => (key === 'Price' ? get(item.Offer, 'Base.Price.Total.amount') : item[key]));

    if (hasData) {
      labels.push(CHART_LABELS[key]);
    }
  });

  return labels;
};

const Chart = ({ data }: ChartProps) => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const [chartLabels, setChartLabels] = useState<LabelItem[]>([]);
  const [hasTemperature, setHasTemperature] = useState(true);
  const [chartProps, setChartProps] = useState(CHART_LABELS);
  const chartData = useMemo(() => getChartData(data), [data]);

  /**
   * Legend click handler.
   * @param event
   */
  const legendOnClickHandler = (event: any) => {
    trackEvent({
      event: 'chart',
      eventCategory: 'B2B_CHART',
      eventAction: 'B2B_LEGEND_ON_CLICK_HANDLER',
      eventLabel: '',
    });
    setChartProps({
      ...chartProps,
      [event.dataKey]: {
        ...chartProps[event.dataKey],
        hide: !chartProps[event.dataKey].hide,
      },
    });
  };

  useEffect(() => {
    const hasAirTemperature = data.Offers.some((item: any) => item?.TempAir);
    const hasWaterTemperature = data.Offers.some((item: any) => item?.TempWater);

    setChartLabels(getChartLabels(data.Offers));
    setHasTemperature(hasAirTemperature || hasWaterTemperature);
  }, [data]);

  return (
    <FlexBox
      p="medium"
      width="100%"
      height="100%"
    >
      <FlexBox
        width="100%"
        height="100%"
        borderRadius={6}
        position="relative"
        flexDirection="column"
        backgroundColor="white"
        style={{
          boxShadow: 'rgb(0 0 0 / 10%) 0 1px 4px 0px',
        }}
      >
        <FlexBox
          id="price-chart"
          p="medium"
          flexShrink={0}
          width="100%"
          height="650px"
        >
          <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="Date" height={36}/>
              <YAxis
                label={(
                  <CustomLabel
                    angle={-90}
                    text={t('lbl_totalprice')}
                    verticalAnchor="start"
                  />
                )}
                yAxisId="price"
                dataKey="Price"
                orientation="left"
              />
              {hasTemperature && (
                <YAxis
                  label={(
                    <CustomLabel
                      angle={90}
                      text={t('lbl_temperature')}
                      verticalAnchor="start"
                    />
                  )}
                  domain={[0, 'dataMax + 1']}
                  yAxisId="temperature"
                  dataKey="TempAir"
                  orientation="right"
                />
              )}
              <Tooltip
                cursor={{ fill: '#ebf8ff' }}
                coordinate={{ y: 0 }}
                content={<CustomTooltip translationsMap={TRANSLATION_KEYS} />}
                wrapperStyle={{
                  position: 'absolute',
                  zIndex: 9999,
                  pointerEvents: 'all',
                }}
              />
              <Legend
                height={36}
                verticalAlign="top"
                onClick={legendOnClickHandler}
                content={<CustomLegend translationsMap={TRANSLATION_KEYS}/>}
              />
              {chartLabels.map((label) => (
                <Bar
                  key={label.key}
                  dataKey={label.key}
                  yAxisId={label.yAxisId}
                  fill={label.color}
                  maxBarSize={150}
                  hide={chartProps[label.key].hide}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default Chart;
