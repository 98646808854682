import React, {
  forwardRef, memo, useEffect, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

import { Styled } from './SectionBox.styles';

type SectionBoxProps = {
  id?: string
  title: string | React.ReactElement
  icon?: React.ReactNode | string
  children: React.ReactNode
  isExpandable?: boolean
  expanded?: boolean
  innerRef?: React.Ref<HTMLDivElement>
  name?: string,
  nested?: boolean
  variant?: string
  scrollToSectionOnOpen?: boolean
  scrollOffsetTop?: number
  scrollDelay?: number
  onOpen?: (name?: string) => void
  onClose?: (name?: string) => void
  onClick?: (name?: string) => void
};

type SectionBoxHeaderProps = Pick<SectionBoxProps, 'title' | 'icon' | 'onClick' | 'isExpandable' | 'expanded' | 'nested'>

const defaultProps = {
  name: '',
  variant: 'regular',
  id: undefined,
  icon: undefined,
  expanded: true,
  isExpandable: false,
  nested: false,
  innerRef: undefined,
  onOpen: undefined,
  onClose: undefined,
  onClick: undefined,
  scrollToSectionOnOpen: false,
  scrollOffsetTop: 0,
  scrollDelay: 0,
};

const SectionBoxHeader = forwardRef<HTMLDivElement, SectionBoxHeaderProps>(({
  title,
  icon,
  expanded,
  isExpandable,
  nested,
  onClick,
}, ref) => (
  <Styled.SectionBox__Header
    ref={ref}
    nested={nested}
    icon={icon}
    onClick={onClick as any}
  >
    {icon && (
      <Styled.SectionBox__Header__Icon>
        {icon}
      </Styled.SectionBox__Header__Icon>
    )}
    {typeof title === 'string' ? (
      <Styled.SectionBox__Header__Title>
        {title}
      </Styled.SectionBox__Header__Title>
    ) : title}
    {isExpandable && <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} size="sm"/>}
  </Styled.SectionBox__Header>
));

const SectionBox = forwardRef<HTMLDivElement, SectionBoxProps>(({
  id,
  title,
  icon,
  name,
  expanded,
  children,
  variant,
  isExpandable,
  scrollToSectionOnOpen,
  scrollOffsetTop,
  scrollDelay,
  nested,
  onOpen,
  onClose,
  onClick,
  innerRef,
}, ref) => {
  const [sectionElement, setSectionElement] = useState<HTMLDivElement | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(!!expanded);

  /**
   * On section open handler.
   */
  const onOpenHandler = () => {
    if (onOpen) {
      onOpen(name);
    }

    if (scrollToSectionOnOpen) {
      setTimeout(() => {
        // @ts-ignore TODO proper typing for ref.current @ts-ignore
        const sectionContent = ref && ref.current ? ref.current : sectionElement;
        const offset = scrollOffsetTop ?? 0;

        if (sectionContent) {
          window.scrollTo({
            top: (sectionContent as HTMLElement).offsetTop - offset,
            behavior: 'smooth',
          });
        }
      }, scrollDelay);
    }
  };

  /**
   * On section close handler.
   */
  const onCloseHandler = () => {
    if (onClose) {
      onClose(name);
    }
  };

  /**
   * On section click handler.
   */
  const onClickHandler = () => {
    if (isExpandable) {
      const isOpened = !isExpanded;
      setIsExpanded(isOpened);

      if (isOpened) {
        onOpenHandler();
      } else {
        onCloseHandler();
      }
    }

    if (onClick) {
      onClick(name);
    }
  };

  useEffect(() => {
    setIsExpanded(!!expanded);
    if (expanded) {
      onOpenHandler();
    }
  }, [expanded]);

  return (
    <Styled.SectionBox
      {... { ...id ? { id } : {} }}
      aria-expanded={isExpanded}
      ref={ref || setSectionElement}
      isExpanded={isExpanded}
      isExpandable={isExpandable}
      nested={nested}
      variant={variant}
    >
      <SectionBoxHeader
        ref={innerRef}
        title={title}
        icon={icon}
        nested={nested}
        isExpandable={isExpandable}
        expanded={isExpanded}
        onClick={onClickHandler}
      />
      {(!isExpandable || isExpanded) && (
        <Styled.SectionBox__Content>
          {children}
        </Styled.SectionBox__Content>
      )}
    </Styled.SectionBox>
  );
});

SectionBox.defaultProps = defaultProps;
export {
  SectionBoxHeader,
};
export default memo(SectionBox);
