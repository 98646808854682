import { pick } from 'lodash-es';

import { IDictionary } from '@ess/types';

type getUrlSearchParamsArgs = {
  queryString?: string
  params: string[]
}

/**
 * Returns url search params by given keys array.
 * @param queryString
 * @param params
 */
const getUrlSearchParams = ({
  queryString = window.location.search,
  params,
}: getUrlSearchParamsArgs): IDictionary<string> => {
  let paramsObject: IDictionary<string> = {};

  new URLSearchParams(queryString).forEach((value, key) => {
    try {
      paramsObject[key] = decodeURIComponent(value)?.replace(/ /g,"+");
    } catch {
      console.log("Invalid url: ", value);
    }
  });

  if (params && params.length) {
    paramsObject = pick(paramsObject, params);
  }

  return paramsObject;
};

export default getUrlSearchParams;
