import FlexBox from '@ess/ui/FlexBox';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  includes, isEmpty, isEqual, isUndefined,
} from 'lodash-es';
import { useFormikContext } from 'formik';
import { AppConfigContext } from '@ess/context/AppConfigContext';

import { IDictionary } from '@ess/types';

import { theme } from '@tourop/config/theme';
import { ADULT_CODE, CHILD_CODE } from '@ess/constants/participants';

import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import useEventListener from '@ess/hooks/useEventListener';

import { Button } from '@ess/ui/Button';
import Switch from '@ess/ui/Switch';
import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';
import Drawer from '@ess/ui/Drawer';
import Box from '@ess/ui/Box';

import Modal from '@ess/ui/Modal/ModalsTypes/Modal';
import Counter from '@tourop/components/ParticipantsPicker/Counter';
import Anchor from '@ess/ui/Anchor';
import Field from '@tourop/components/ParticipantsPicker/Field';

import AgencyAccessControl from '@tourop/components/AgencyAccessControl';
import moment from 'moment';
import { DATE_REQUEST_FORMAT } from '@ess/constants/api';
import showToast, { TOAST_INFO } from '@ess/utils/form/ShowToast';

export type ModeEnum = 'auto' | 'manual';

/**
 * Returns total participants.
 * @param value
 */
const getTotalParticipants = (value : any) => {
  let totalParticipants = 0;
  const { rooms } = value;

  rooms.map((room: any) => {
    const personTypes = Object.keys(room)
      .filter((item) => includes([ADULT_CODE, CHILD_CODE], item));

    personTypes.map((personType) => {
      totalParticipants += (room[personType]?.value ?? 0);
    });
  });

  return totalParticipants;
};

type ParticipantsNewVersionProps = {
  isCamp?: boolean | undefined
  defaultValue:any
  label?:string
  onApply: any
  onChange: any
  onModeChange?: (mode: ModeEnum) => void
  onAgeSelectModeChange?: (mode: any) => void
  onClear?: () => void
  isClearable?: boolean
  setDefaultBirthDate?: boolean
  appendTo?: Element | null
  headerElement?: React.ReactElement | null
  roomsCountEnabled?: boolean
  roomsCount?: number
  personTypes?: string[]
  minMaxValue?: any
  onBirthDateChange?: (fieldName: string, value: string) => void
  onRemoveGroup?: (groupIndex: number) => void
  manualModeEnabled?: boolean
  sortChildren?: boolean
  ageSelectionEnabled?: boolean
  groupNames?: string[]
  initialMode?: ModeEnum
  value:any
}

const defaultProps = {
  isCamp: false,
  label: undefined,
  isClearable: false,
  ageSelectionEnabled: true,
  onBirthDateChange: undefined,
  onAgeSelectModeChange: undefined,
  onRemoveGroup: undefined,
  onModeChange: undefined,
  onClear: undefined,
  appendTo: null,
  headerElement: null,
  minMaxValue: undefined,
  roomsCountEnabled: false,
  manualModeEnabled: false,
  sortChildren: false,
  setDefaultBirthDate: false,
  groupNames: [],
  personTypes: ['ADULT', 'CHILD'],
  initialMode: 'auto' as ModeEnum,
  roomsCount: 1,
};

const MAX_ROOMS = 15;
const MIN__ROOMS = 1;

const ParticipantsNewVersion = ({
  isCamp,
  defaultValue,
  setDefaultBirthDate,
  label,
  sortChildren,
  value,
  onChange,
  onClear,
  onRemoveGroup,
  onBirthDateChange,
  onAgeSelectModeChange,
  ageSelectionEnabled,
  manualModeEnabled,
  groupNames,
  minMaxValue,
  initialMode,
  personTypes,
  isClearable,
  roomsCountEnabled,
  headerElement,
  roomsCount,
  appendTo,
  onModeChange,
  onApply,
}:ParticipantsNewVersionProps) => {
  const { t } = useTranslation();
  const { errors } = useFormikContext() ?? {};
  const [fieldValue, setFieldValue] = useState<any>([]);
  const [manualMode, setManualMode] = useState(initialMode === 'manual');
  const [showDropdown, setShowDropdown] = useState(false);
  const [ageSelectMode, setageSelectMode] = useState(false);
  const targetElement = useRef();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [currentValue, setCurrentValue] = useState(value);
  const mobile = useMediaQuery({ minWidth: 0, maxWidth: 768 });
  const [roomsCountValue, setRoomsCountValue] = useState(initialMode === 'manual' ? 1 : roomsCount);
  const { styles, attributes } = usePopperPositioning({
    targetElementRef: targetElement,
    popperElementRef: popperElement,
    zIndex: theme.zIndex.modal,
    applyMaxSizeEnabled: true,
  });
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const { state: AppConfig } = useContext(AppConfigContext);
  const { type } = AppConfig;

  const closeOnScroll = () => {
    if (targetElement?.current) {
      const { top } = (targetElement?.current as Element)?.getBoundingClientRect() ?? {};
      if (showDropdown && (((top ?? 0) < 100) || ((top ?? 0) > (window.innerHeight - 50)))) {
        setShowDropdown(false);
      }
    }
  };

  useEventListener('scroll', closeOnScroll);

  const isFieldClearable = (
    isClearable && (
      getTotalParticipants(currentValue) !== getTotalParticipants(defaultValue)
      || !!(roomsCountValue && roomsCountValue > 1)
    )
  );

  const getMinMax = (isManualMode: boolean): IDictionary<any> => ({
    ADULT: {
      min: minMaxValue?.ADULT ? minMaxValue.ADULT.min : 1,
      max: !manualModeEnabled ? minMaxValue?.ADULT ? minMaxValue.ADULT.max : 20 : isManualMode ? 8 : 20,
    },
    CHILD: {
      min: minMaxValue?.CHILD ? minMaxValue.CHILD.min : 0,
      max: !manualModeEnabled ? minMaxValue?.CHILD ? minMaxValue.CHILD.max : 12 : isManualMode ? 4 : 12,
    },
  });

  const onApplyHandler = () => {
    const current = currentValue;

    if (onApply) {
      if (sortChildren) {
        const { dates, value } = current.rooms[0].CHILD;

        if (value > 0) {
          const sortedDates = [...dates].sort((a, b) => (
            moment(a, DATE_REQUEST_FORMAT).isAfter(moment(b, DATE_REQUEST_FORMAT)) ? -1 : 1),
          );

          if (!isEqual(sortedDates, dates)) {
            showToast(TOAST_INFO, t('lbl_sorted_children_message'));
            current.rooms[0].CHILD.dates = sortedDates;
          }
        }
      }

      onApply(current, manualMode, roomsCountValue);
    }

    setShowDropdown(false);
  };

  const increaseGroupCount = () => {
    setCurrentValue((state: any) => {
      const copy: any = { ...state };
      copy.rooms = [...state.rooms, defaultValue.rooms[0]];
      return { ...copy };
    });
  };

  const removeGroup = (groupIndex: number) => {
    setCurrentValue((state: any) => {
      const copy: any = { ...state };

      copy.rooms = [...state.rooms].filter((_, index) => index !== groupIndex);

      if (onRemoveGroup) {
        onRemoveGroup(groupIndex);
      }

      return { ...copy };
    });
  };

  const onClearHandler = () => {
    if (onClear) {
      onClear();
    } else {
      setCurrentValue(defaultValue);
      setRoomsCountValue(1);
    }
  };

  const showDropdownHandler = () => {
    setShowDropdown(!showDropdown);
  };

  const FuncageSelectMode = () => {
    if (ageSelectMode) {
      setageSelectMode(false);
    } else {
      setageSelectMode(true);
    }

    if (onAgeSelectModeChange) {
      onAgeSelectModeChange(!ageSelectMode);
    }
  };

  const toolTipIsVisible = () => {
    if (!isEmpty(errors) && !showDropdown) {
      return true;
    }
    return false;
  };

  const onModeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    if (onModeChange) {
      onModeChange(isChecked ? 'manual' : 'auto');
    }

    setCurrentValue((state: any) => {
      let copy = { ...state };

      if (!isChecked) {
        copy = {
          ...copy,
          rooms: [copy.rooms[0]],
        };
      }

      return copy;
    });

    if (isChecked) {
      setRoomsCountValue(1);
    }

    setManualMode(isChecked);
  };

  const getPersonTypes = () => {
    const correctValues = type === 'camp' ? personTypes && personTypes.filter((item:any) => item !== 'ADULT') : personTypes;
    return isUndefined(correctValues) ? [] : correctValues as any;
  };

  useOnClickOutside(popperElement, () => setShowDropdown(false), [], !calendarIsOpen);

  useEffect(() => {
    const firstRoomAdultValue = currentValue.rooms[0].ADULT.value;
    const iaAutoMode = manualModeEnabled && !manualMode;

    let roomsCount = roomsCountValue || 1;

    if (iaAutoMode && roomsCount > 1 && roomsCount > firstRoomAdultValue) {
      roomsCount = firstRoomAdultValue;
    }

    if (onChange) {
      onChange(currentValue, manualMode, roomsCount);
    }
  }, [currentValue, roomsCountValue]);

  useEffect(() => {
    setRoomsCountValue(roomsCount);
  }, [roomsCount]);

  useEffect(() => {
    const values = [];
    const isManualMode = manualMode && manualModeEnabled;

    personTypes?.map((personType: string) => {
      values.push({
        amount: isManualMode
          ? currentValue.rooms.reduce((acc: any, curr: any) => acc + curr[personType].value, 0)
          : (currentValue.rooms[0][personType]?.value ?? 0) > 0 ? currentValue.rooms[0][personType]?.value : 0,
        key: personType.toLowerCase(),
      });
    });

    if ((roomsCountEnabled && !!roomsCountValue && roomsCountValue > 1) || isManualMode) {
      values.push({
        amount: isManualMode
          ? currentValue.rooms.length
          : roomsCountValue,
        key: 'room',
      });
    }

    setFieldValue(values);
  }, [currentValue, manualMode, roomsCountValue, manualModeEnabled]);

  useEffect(() => {
    if (!isEqual(value, currentValue)) {
      setCurrentValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isCamp) {
      setShowDropdown(true);
    }
  }, [isCamp]);

  const controls = (
    <FlexBox alignItems="center" width="100%" p="small">
      <Button
        size={isMobileOnly ? 'large' : 'medium'}
        width="100%"
        label={t('lbl_apply')}
        onClick={onApplyHandler}
      />
    </FlexBox>
  );

  const dropDown = (
    <FlexBox
      style={{ userSelect: 'none' }}
      height={calendarIsOpen && isMobileOnly ? '350px' : 'auto'}
      flexDirection="column"
    >
      {headerElement && headerElement}
      <AgencyAccessControl moduleName="MultiRoomFinder" enabled={manualModeEnabled}>
        <>
          {manualModeEnabled && (
          <FlexBox p="small" width="100%">
            <FlexBox flexGrow={1}>
              <Text>
                {t('manual_allocation')}
              </Text>
            </FlexBox>
            <Switch
              checked={manualMode}
              onChange={onModeChangeHandler}
            />
          </FlexBox>
          )}

          {ageSelectionEnabled && (
          <FlexBox p="small" width="100%">
            <FlexBox flexGrow={1}>
              <Text>
                {t(ageSelectMode ? 'lbl_switch_to_calendar' : 'lbl_switch_to_selector')}
              </Text>
            </FlexBox>
            <Switch checked={ageSelectMode} onChange={FuncageSelectMode}/>
          </FlexBox>
          )}

          <FlexBox
            height={calendarIsOpen && isMobileOnly ? '1px' : 'auto'}
            overflow={calendarIsOpen && isMobileOnly ? 'hidden' : 'visible'}
            flexDirection="column"
            p={!manualModeEnabled || !manualMode ? 'small' : null}
          >
            {manualModeEnabled && manualMode ? (
              <>
                {currentValue?.rooms.map((item: any, groupIndex: number) => {
                  const allowedPersonTypes = Object.keys(item).filter((pType) => includes(personTypes, pType));

                  return (
                    <FlexBox
                      key={`group_${groupIndex}`}
                      flexDirection="column"
                      p="small"
                      style={{
                        borderTop: currentValue?.rooms.length > 1 && groupIndex !== 0 ? '1px solid #ddd' : '',
                      }}
                    >
                      <FlexBox height={24} mb="small" alignItems="center" justifyContent="space-between">
                        {groupNames?.length && groupNames[groupIndex] ? (
                          <Text>{groupNames[groupIndex]}</Text>
                        ) : (
                          <Text>{`${t('room')} ${groupIndex + 1}`}</Text>
                        )}
                        {currentValue?.rooms.length > 1 && (
                        <Button
                          size="tiny"
                          variant="secondary"
                          label={t('remove')}
                          onClick={() => removeGroup(groupIndex)}
                        />
                        )}
                      </FlexBox>

                      {allowedPersonTypes.map((personType, index) => {
                        const value = item[personType];
                        const isLastChild = allowedPersonTypes.length - 1 === index;

                        return (
                          <FlexBox
                            key={personType}
                            width="100%"
                            mb={!isLastChild ? 'medium' : null}
                          >
                            <Counter
                              groupIndex={groupIndex}
                              onFocus={setCalendarIsOpen}
                              enableBirthDate={ageSelectionEnabled}
                              calendarValue={value.dates}
                              birthDateMode={ageSelectMode ? 'select' : 'calendar'}
                              value={currentValue}
                              count={(value.value) > 0 ? value.value : 0}
                              minValue={getMinMax(manualMode)[personType].min}
                              maxValue={getMinMax(manualMode)[personType].max}
                              personType={personType}
                              appendCalendarTo={appendTo}
                              setDefaultBirthDate={setDefaultBirthDate}
                              onBirthDateChange={onBirthDateChange}
                              onChangeForCalendar={onChange}
                              onChange={setCurrentValue}
                            />
                          </FlexBox>
                        );
                      })}
                    </FlexBox>
                  );
                })}
                <FlexBox mb="small" px="small" justifyContent="flex-end">
                  <Anchor
                    endIcon={(<FontAwesomeIcon icon={faPlus}/>)}
                    onClick={increaseGroupCount}
                    disabled={currentValue.rooms.length === MAX_ROOMS}
                  >
                    {t('add_room')}
                  </Anchor>
                </FlexBox>
              </>
            ) : (
              <>
                {getPersonTypes().map((personType: string, index: number) => {
                  const isLastChild = Object.keys(getPersonTypes()).length - 1 === index;
                  const count = (currentValue?.rooms?.[0]?.[personType]?.value ?? 0) > 0
                    ? currentValue?.rooms?.[0]?.[personType]?.value ?? 0 : 0;

                  return (
                    <FlexBox
                      key={personType}
                      width="100%"
                      mb={!isLastChild ? 'medium' : null}
                    >
                      <Counter
                        key={personType}
                        groupIndex={0}
                        onFocus={setCalendarIsOpen}
                        enableBirthDate={ageSelectionEnabled}
                        setDefaultBirthDate={setDefaultBirthDate as boolean}
                        calendarValue={currentValue?.rooms[0][personType]?.dates ?? []}
                        birthDateMode={ageSelectMode ? 'select' : 'calendar'}
                        value={currentValue}
                        count={count}
                        minValue={getMinMax(manualMode)[personType].min}
                        maxValue={getMinMax(manualMode)[personType].max}
                        personType={personType}
                        appendCalendarTo={appendTo}
                        onBirthDateChange={onBirthDateChange}
                        onChange={setCurrentValue}
                      />
                    </FlexBox>
                  );
                })}
              </>
            )}

            {roomsCountEnabled && !(manualModeEnabled && manualMode) && (
            <FlexBox
              style={{
                width: 'auto', position: 'relative', alignItems: 'center', marginTop: '15px',
              }}
            >
              <div>
                <div style={{
                  fontSize: '14px', fontWeight: 'bold', whiteSpace: 'nowrap', height: '18px',
                }}
                >
                  {t('rooms_count')}
                </div>
              </div>

              <FlexBox ml="auto" alignItems="center" position="relative" >
                <FlexBox style={{ zIndex: '1000' }} alignItems="center">
                  <Button
                    size={isMobileOnly ? 'large' : 'medium'}
                    label={<FontAwesomeIcon icon={faMinus}/>}
                    onClick={() => setRoomsCountValue((state) => (state || 0) - 1)}
                    disabled={roomsCountValue === MIN__ROOMS}
                  />
                  <div style={{
                    width: '40px',
                    margin: '0 2px',
                    fontSize: '13px',
                    textAlign: 'center',
                    alignItems: 'center',
                    color: !roomsCountValue ? '#d5d5d5' : 'inherit',
                  }}
                  >
                    {roomsCountValue === 1 ? 'auto' : roomsCountValue || MIN__ROOMS}
                  </div>
                  <Button
                    size={isMobileOnly ? 'large' : 'medium'}
                    label={<FontAwesomeIcon style={{ margin: 'auto' }} icon={faPlus}/>}
                    onClick={() => setRoomsCountValue((state) => (state || 0) + 1)}
                    disabled={roomsCountValue === MAX_ROOMS || roomsCountValue === currentValue.rooms[0].ADULT.value}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            )}
          </FlexBox>
        </>
      </AgencyAccessControl>
    </FlexBox>
  );

  return (
    <div
      ref={targetElement as any}
      style={{ width: '100%' }}
    >
      {label && (<Text mb="2px">{label}</Text>)}
      <Tooltip
        offset={[0, 10]}
        visible={toolTipIsVisible()}
        content={t('provide_participants_age_error')}
        zIndex={99991}
        showOnMobile
        theme="red"
      >
        <FlexBox>
          <Field
            onClick={showDropdownHandler}
            values={fieldValue}
            isClearable={!!isFieldClearable}
            onClear={onClearHandler}
          />
        </FlexBox>
      </Tooltip>

      {isMobileOnly ? (
        <Drawer
          title={t('lbl_participants')}
          position="bottom"
          isOpen={showDropdown}
          showOverlay
          onClose={() => setShowDropdown(false)}
          controls={controls}
        >
          <Box width="100%">
            {dropDown}
          </Box>
        </Drawer>
      ) : (
        <>
          {showDropdown && (
          <Modal
            ref={setPopperElement as any}
            width="fit-content"
            showOverlay={mobile}
            theme="white"
            isOpen
            showCloseIcon={false}
            controls={controls}
            appendTo={appendTo}
            onClose={() => setShowDropdown(false)}
            positionedByPopper={!mobile}
            {...!mobile ? { ...attributes.popper, style: styles.popper } : {}}
          >
            {dropDown}
          </Modal>
          )}
        </>
      )}
    </div>
  );
};

ParticipantsNewVersion.defaultProps = defaultProps;

export default ParticipantsNewVersion;
