import React, {
  useEffect, useRef, useState, memo, useMemo,
} from 'react';
import { List, CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import { includes, cloneDeep } from 'lodash-es';
import { ListRowProps } from 'react-virtualized/dist/es/List';
import { IGroupedOptions } from '@ess/types';
import useBreakpoint from '@ess/hooks/useBreakpoint';
import { OptionTypeBase } from 'react-select';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import useWindowSize from '@ess/hooks/useWindowSize';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { TOptions } from '../AdvancedSelect';
import Text from '../../../../Text';
import { FontAwesomeIconOnHover, FlexboxWithHover } from '../AdvancedSelect.styles';
import FlexBox from '../../../../FlexBox';
import Checkbox from '../../../Checkbox';
import { Col, Row } from '../../../../FlexGrid';

type MenuGridSectionProps = {
  name: string
  groupedOptions: TOptions
  checkedOptions: string[]
  maxModalContentHeight: number
  searchActive?: boolean,
  hasFavorites?: boolean,
  onFavoritesClick: (option: OptionTypeBase, event: React.MouseEvent) => void,
  columns: number
  rows: {
    xxs: number,
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
  },
  gapX: number
  gapY: number
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
  theme?: string
  favorites?: TOptions
}

const defaultProps = {
  theme: 'default',
  searchActive: false,
  hasFavorites: true,
  favorites: [],
};

const MenuGridSection = ({
  name,
  groupedOptions,
  columns,
  rows,
  gapX,
  gapY,
  changeHandler,
  checkedOptions,
  maxModalContentHeight,
  searchActive,
  hasFavorites,
  onFavoritesClick,
  theme,
  favorites = [],
}: MenuGridSectionProps) => {
  const { t } = useTranslation();
  const listRef = useRef<List>(null);
  const breakpoint = useBreakpoint();
  const [listHeight, setListHeight] = useState<number>(0);
  const [rowsCount, setRowsCount] = useState<number>(groupedOptions.length);
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 76,
  });

  const inFavorites = (option: OptionTypeBase) => Boolean(
    hasFavorites && favorites.filter((item: OptionTypeBase) => item.value === option.value).length,
  );

  const getColumn = (searchActive: boolean, rows: any, length: number) => {
    const cols = cloneDeep(rows);
    if ((!searchActive) || length > 35) {
      return cols;
    }
    cols.lg = cols.xl = cols.md = (Math.floor(length / 8) + 1);
    return cols;
  };

  const { height } = useWindowSize();

  const cellRender = ({
    key, index, style, parent,
  }: ListRowProps) => {
    const searchActive = !(groupedOptions[0].options || undefined);
    const item = searchActive ? groupedOptions : groupedOptions[index];
    let columnInfo = cloneDeep(rows);
    if (name === 'Base.Operator') {
      columnInfo = getColumn(searchActive, rows, groupedOptions?.length ?? 1);
    }
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <FlexBox key={index} flexDirection="column" style={style}>
          {!searchActive && favorites.length > 0 && (
            <FlexBox
              bg={theme === 'default' ? 'lighterGray' : ''}
              p="small"
              height="38px"
            >
              <Text fontWeight="bold">{item.label}</Text>
            </FlexBox>
          )}
          <FlexBox width="100%" p="small">
            <Row gapX={gapX} gapY={gapY} flexWrap="wrap">
              {(searchActive ? item : (item as IGroupedOptions).options)
                .map((item: OptionTypeBase) => {
                  const itemInFavorites = inFavorites(item);
                  return (
                    <Col
                      key={item.value}
                      width={{
                        xxs: (columns / rows.xs) / columns,
                        xs: (columns / rows.xs) / columns,
                        sm: (columns / rows.sm) / columns,
                        md: (columns / columnInfo.md) / columns,
                        lg: (columns / columnInfo.lg) / columns,
                        xl: (columns / columnInfo.xl) / columns,
                      }}
                    >
                      <FlexboxWithHover>
                        {hasFavorites
                          && (
                          <FlexBox
                            as="span"
                            onClick={(e) => onFavoritesClick && onFavoritesClick(item, e)}
                            pr="5px"
                            width="20px"
                            flexShrink="0"
                            style={{ cursor: 'pointer' }}
                          >
                            {isMobile || itemInFavorites
                              ? <FontAwesomeIcon icon={itemInFavorites ? faSolidStar : faStar}/>
                              : (
                                <FontAwesomeIconOnHover
                                  onClick={(e: React.MouseEvent) => onFavoritesClick && onFavoritesClick(item, e)}
                                  icon={faStar}
                                />
                              )}
                          </FlexBox>
                          )}
                        <FlexBox
                          width={`calc(100% - ${isMobile ? '16px' : searchActive ? '32px' : '16px'})`}
                          flexShrink="0"
                          flexGrow="0"
                        >
                          <Checkbox
                            spacing="10px"
                            onChange={changeHandler}
                            checked={includes(checkedOptions, item.value)}
                            name={item.value}
                            label={item.label}
                            style={{ width: '100%' }}
                            disabled={item.disabled}
                          />
                        </FlexBox>
                      </FlexboxWithHover>
                    </Col>
                  );
                })}
            </Row>
          </FlexBox>
        </FlexBox>
      </CellMeasurer>
    );
  };

  const ItemsVirtualizedList = useMemo(() => (
    <List
      ref={listRef}
      width={1}
      height={isMobileOnly ? height - 150 : 400}
      deferredMeasurementCache={cache}
      rowHeight={cache.rowHeight}
      rowRenderer={cellRender}
      rowCount={searchActive ? 1 : groupedOptions.length}
      favorites={favorites}
      containerStyle={{
        width: '100%',
        maxWidth: '100%',
      }}
      style={{
        width: '100%',
      }}
    />
  ), [groupedOptions, checkedOptions, listHeight, searchActive]);

  useEffect(() => {
    let height = 0;
    groupedOptions.forEach((group) => {
      if (group.options && group.options.length > 0) {
        const headerHeight = 38;

        const itemsRowsCount = group.options.length / rows[breakpoint];

        height += headerHeight + (Math.ceil(itemsRowsCount) * 29) + 7.8;
      }
    });
    setListHeight((height > maxModalContentHeight ? maxModalContentHeight : height));
  }, [maxModalContentHeight, breakpoint, rows]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.forceUpdateGrid();
      listRef.current.recomputeRowHeights();
    }
  }, [listRef, maxModalContentHeight, rows]);

  useEffect(() => {
    setRowsCount(groupedOptions.length);
  }, [groupedOptions]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.scrollToPosition(0);
    }
  }, [rowsCount]);

  return groupedOptions.length
    ? ItemsVirtualizedList
    : (
      <FlexBox
        color="gray"
        width="100%"
        justifyContent="center"
        p="10px"
        height={isMobileOnly ? height - 150 : listHeight - 20}
      >
        <span>{t('no_results')}</span>
      </FlexBox>
    );
};

MenuGridSection.defaultProps = defaultProps;

export default memo(MenuGridSection);
