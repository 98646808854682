import styled from 'styled-components';

const Field = styled.div<{ label: string }>`
    display: flex;
    flex-direction: ${(props) => (props.label ? 'column' : 'row')};
    width: 100%;
`;

const Field__Label = styled.label`
    font-size: ${({ theme }) => theme.fontSizes.paragraph};
    color: ${({ theme }) => theme.colors.textColor};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 2px;
`;

const Field__Element = styled.div`
    display: flex;
    width: 100%;

    & > div {
      width: 100%;
    }
`;

export const Styled = {
  Field,
  Field__Label,
  Field__Element,
};
