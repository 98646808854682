import styled from 'styled-components';
import { colors } from '@tourop/config/theme/colors';

const PriceCell = styled.div<{ isSelected: boolean, isDisabled: boolean }>`
    flex: 1 0 120px;
    display: flex;
  flex-direction: column;
    height: 70px;
    align-items: center;
    justify-content: center;
    background-color: ${({ isSelected }) => (isSelected ? '#388e3c' : 'white')};
    color: ${({ isSelected }) => (isSelected ? 'white' : colors.textColor)};

    ${({ isDisabled, isSelected }) => !isDisabled && !isSelected && `
      cursor: pointer;
      &:hover {
        background-color: #ebf8ff;
      }
    `}
`;

export const Styled = {
  PriceCell,
};
