import React, {
  createContext, useMemo, useReducer,
} from 'react';

import {
  IDictionary,
  ISearchType,
  SearchTypesEnum,
  TopOffersSortType,
} from '@ess/types';

import reducer from './reducer';

export type IAppConfigState = {
  offerAttributesPriority: any
  availableModules: IDictionary<boolean>
  type: SearchTypesEnum
  searchFormList: ISearchType[]
  loginAgency: string
  language: string
  country: string
  showAllAttributes: boolean
  isLoading: boolean
  isProto: boolean
  topOffersSortBy: TopOffersSortType
  updater: any
  searchFormProto: string | null
  googleApiKey: string
  distanceSliders: any
  dictionary: IDictionary<any>
  quickSearchFields: string[]
  minPriceChangeShow: IDictionary<number>
  highlighted: any
  currency: string
  configOper: any
  adminSettings: any
  maxBasketItems: number
  isBasketOn: boolean
  expedientPermissions: {
    makeBooking: boolean
  },
  agency: {
    consultantCode: string,
    agencyNumber: string
  },
  config: {
    fields: any
    options: any
    attributes: any
    distances: any
    themes: any
    price: any
  },
}

type SearchFormProviderProps = {
  children: React.ReactNode,
  initialState: IAppConfigState
}

const AppConfigContext = createContext<{
  state: IAppConfigState;
  dispatch: React.Dispatch<any>;
}>({
  state: {} as IAppConfigState,
  dispatch: () => null,
});

const AppConfigProvider = ({ children, initialState }: SearchFormProviderProps) => {
  const [state, dispatch] = useReducer<React.Reducer<IAppConfigState, object>>(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  );
};

export default AppConfigProvider;
export {
  AppConfigContext,
};
