import React, { useContext } from 'react';
import { has, includes } from 'lodash-es';

import { IDictionary } from '@ess/types';

import { ATTRIBUTES_NAMES_MAP } from '@ess/constants/attributes';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { HotelAttribute } from './HotelAttribute';

import { Styled } from './HotelAttribute.styles';

type DescriptionMode = 'tooltip' | 'label';

export type HotelAttributesGroupProps = {
  attributes: string[]
  descriptionMode?: DescriptionMode
  selectedAttributes?: string[]
};

export const hotelAttributeFromNewToOld = (item: string): string => (
  has(ATTRIBUTES_NAMES_MAP, item) ? ATTRIBUTES_NAMES_MAP[item] : item
);

export const hotelAttributesTooltipDescription = (item: string, dictionary: IDictionary<{ text: string }>) => (
  has(dictionary, item) ? dictionary[item].text : item
);

const defaultProps = {
  descriptionMode: 'tooltip' as DescriptionMode,
  selectedAttributes: [],
};

const HotelAttributesGroup = ({
  attributes,
  descriptionMode,
  selectedAttributes,
}: HotelAttributesGroupProps) => {
  const { state: SFContext } = useContext(AppConfigContext);
  const { dictionary } = SFContext;

  const attributesDictionary = dictionary['Accommodation.Attributes'];

  return (
    <Styled.HotelAttributesGroup withLabel={descriptionMode !== 'tooltip'}>
      {attributes.map((item) => {
        const isHighlighted = includes(selectedAttributes, item);
        const label = hotelAttributesTooltipDescription(item, attributesDictionary);
        const iconName = hotelAttributeFromNewToOld(item);

        return (
          <HotelAttribute
            key={item}
            iconName={iconName}
            descriptionMode={descriptionMode}
            isHighlighted={isHighlighted}
            label={label}
          />
        );
      })}
    </Styled.HotelAttributesGroup>
  );
};

HotelAttributesGroup.defaultProps = defaultProps;

export { HotelAttributesGroup };
