import React from 'react';
import { toNumber } from 'lodash-es';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/pro-solid-svg-icons';

import { Styled } from './HotelStars.styles';

type HotelStarsProps = {
  category: number
  color?: string
  size?: SizeProp
};

const defaultProps = {
  size: '1x',
  color: '#337ab7',
};

const HotelStars = ({ category, color, size }: HotelStarsProps) => {
  const parsedCategory = category.toFixed(1).split('.');
  const [fullStars, halfStar] = parsedCategory;

  if (!category) {
    return <></>;
  }

  return (
    <Styled.HotelStars>
      {Array.from({ length: toNumber(fullStars) }).map((item, index) => (
        <FontAwesomeIcon key={index} icon={faStar} color={color} size={size}/>
      ))}
      {toNumber(halfStar) > 0 && <FontAwesomeIcon key="halfStar" icon={faStarHalf} color={color} size={size}/>}
    </Styled.HotelStars>
  );
};

HotelStars.defaultProps = defaultProps;

export default HotelStars;
