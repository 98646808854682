import React, {
  forwardRef, useEffect, useState, CSSProperties, useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { includes } from 'lodash-es';

import { IDictionary } from '@ess/types';

import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import useBreakpoint from '@ess/hooks/useBreakpoint';

import useLockBodyScroll from '@ess/hooks/useLockBodyScroll';
import DrawerOverlay from './DrawerOverlay';

import { Styled } from './Drawer.styles';

export type DrawerPositionEnum = 'left' | 'right' | 'top' | 'bottom';

export type DrawerProps = {
  isPortal?: boolean
  id?: any
  position?: DrawerPositionEnum
  controls?: React.ReactElement | undefined
  children: React.ReactNode
  onClose?: () => void
  title?: React.ReactElement | string
  appendTo?: Element | null
  clickOutsideIgnoreElements?: any[]
  maxWidth?: number
  isOpen?: boolean
  isBodyScrollLocked?: boolean
  top?: number
  showOverlay?: boolean
  animationEnabled?: boolean
  fixHeight?: boolean
  contentStyles?: CSSProperties
  containerStyles?: CSSProperties
  zIndex?: number
};

const defaultProps = {
  isPortal: true,
  id: undefined,
  title: undefined,
  onClose: undefined,
  controls: undefined,
  top: 0,
  animationEnabled: true,
  showOverlay: true,
  isOpen: false,
  isBodyScrollLocked: false,
  position: 'left' as DrawerPositionEnum,
  clickOutsideIgnoreElements: [],
  fixHeight: false,
  contentStyles: {},
  containerStyles: {},
  maxWidth: undefined,
  zIndex: undefined,
  appendTo: null,
};

const positionsMap: IDictionary<any> = {
  right: {
    initial: {
      x: '100%',
    },
    animate: {
      x: 0,
    },
    exit: {
      x: '100%',
    },
  },
  left: {
    initial: {
      x: '-100%',
    },
    animate: {
      x: 0,
    },
    exit: {
      x: '-100%',
    },
  },
  top: {
    initial: {
      y: '-100%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '-100%',
    },
  },
  bottom: {
    initial: {
      y: '100%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '100%',
    },
  },
};

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(({
  isPortal,
  id,
  children,
  title,
  onClose,
  top,
  showOverlay,
  isOpen,
  isBodyScrollLocked,
  position,
  appendTo,
  controls,
  clickOutsideIgnoreElements,
  fixHeight,
  contentStyles,
  containerStyles,
  animationEnabled,
  maxWidth,
  zIndex,
  ...props
}, ref) => {
  const { setLocked } = useLockBodyScroll();
  const [rootElement, setRootElement] = useState<Element | null>(null);
  const [drawerElement, setDrawerElement] = useState<HTMLDivElement | null>(null);
  const [fixedHeight, setFixedHeight] = useState<string>('auto');
  const breakpoint = useBreakpoint();

  const styles = useMemo(() => ({
    [position as string]: '0',
    ...maxWidth ? { maxWidth: `${maxWidth}px` } : {},
    ...includes(['bottom', 'top'], position) ? {
      width: '100%',
      height: fixedHeight,
      maxHeight: '100%',
      left: 0,
      right: 0,
      margin: '0 auto',
    } : {
      width: 'auto',
      height: includes(['xxs', 'xs'], breakpoint) || !top ? '100%' : `calc(100% - ${top}px)`,
      top: includes(['xxs', 'xs'], breakpoint) || !top ? '0px' : `${top}px`,
    },
  }), [position, top]);

  const closeHandler = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();

    if (onClose) {
      onClose();
    }
  };

  useOnClickOutside(
    drawerElement, closeHandler, clickOutsideIgnoreElements?.length ? clickOutsideIgnoreElements : [],
  );

  useEffect(() => {
    setRootElement(appendTo ?? document.querySelector('.drawer-root') as Element);
  }, [appendTo]);

  useEffect(() => {
    if (fixHeight && fixedHeight === 'auto' && drawerElement) {
      setFixedHeight(`${drawerElement.clientHeight}px`);
    }
  }, [drawerElement, fixHeight]);

  useEffect(() => {
    setLocked(!!isOpen && !!isBodyScrollLocked);
  }, [isOpen, isBodyScrollLocked]);

  const content = (
    <AnimatePresence>
      {isOpen && (
      <>
        <Styled.Drawer
          isPortal={isPortal}
          id={id}
          key="drawer"
          ref={ref || setDrawerElement}
          {...{
            ...animationEnabled ? {
              animate: positionsMap[position as string].animate,
              initial: positionsMap[position as string].initial,
              exit: positionsMap[position as string].exit,
              transition: { type: 'spring', bounce: 0, duration: 0.3 },
            } : {},
          }}
          zIndex={zIndex}
          style={{
            ...styles,
            ...containerStyles,
          }}
        >
          {title && (
          <Styled.Drawer__Header>
            <Styled.Drawer__Header__Title>
              {title}
            </Styled.Drawer__Header__Title>
            <Styled.Drawer__Header__CloseBtn onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} color="inherit" size="lg"/>
            </Styled.Drawer__Header__CloseBtn>
          </Styled.Drawer__Header>
          )}

          <Styled.Drawer__Content style={contentStyles}>
            {children}
          </Styled.Drawer__Content>

          {controls && (
          <Styled.Drawer__Controls>
            {controls}
          </Styled.Drawer__Controls>
          )}
        </Styled.Drawer>
        {showOverlay && (<DrawerOverlay zIndex={zIndex} onClick={onClose}/>)}
      </>
      )}
    </AnimatePresence>
  );

  return isPortal ? (
    <>
      {rootElement !== null && createPortal(
        <>
           {content}
        </>,
        rootElement,
      )}
    </>
  ) : content;
});

Drawer.defaultProps = defaultProps;

export default Drawer;
