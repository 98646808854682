import React, { CSSProperties } from 'react';

import { Styled } from './Image.styles';

type ImageProps = {
  src: string,
  alt?: string,
  width?: (number | string),
  height?: number | string,
  objectFit?: 'cover' | 'contain'
  onClick?: (event: React.MouseEvent) => void
  cursor?: string
  style?: CSSProperties
};

const defaultProps = {
  objectFit: undefined,
  alt: '',
  height: 0,
  width: 0,
  onClick: () => {},
  cursor: 'default',
  style: {},
};

const Image = ({
  src, alt, width = 0, height = 0, objectFit = 'cover', cursor = 'default', ...props
}: ImageProps) => (
  <Styled.Image
    src={src}
    alt={alt}
    width={width}
    height={height}
    objectFit={objectFit}
    cursor={cursor}
    {...props}
  />
);

Image.defaultProps = defaultProps;

export default Image;
