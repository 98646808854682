import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FIRST_PERSON_PRICE, TOTAL_PRICE } from '@ess/constants/search';

const initialState = {
  type: '',
  protoHash: '',
  priceType: '',
  formErrors: {},
  searchValues: {} as any,
  sortBy: null,
  showAllAttributes: false,
  showMap: false,
  selectedAgentProfile: null,
};

export const searchFormSlice = createSlice({
  name: 'searchForm',
  initialState,
  reducers: {
    expandAttributes: (state, action) => ({
      ...state,
      showAllAttributes: action.payload,
    }),
    setSearchValues: (state, action) => ({
      ...state,
      searchValues: action.payload,
      priceType: action.payload['Custom.FullPrice'] ? TOTAL_PRICE : FIRST_PERSON_PRICE,
    }),
    replaceGeoWithCountry: (state, action) => {
      if (!(state.searchValues['Base.DestinationLocation.Geo.Polygon'] ?? false)) {
        return state;
      }
      const newsearchval = { ...state.searchValues };
      delete newsearchval['Base.DestinationLocation.Geo.Polygon'];
      newsearchval['Base.DestinationLocation.Country'] = action.payload;
      newsearchval['Base.DestinationLocation.Region'] = [];
      return {
        ...state,
        searchValues: newsearchval,
      };
    },
    setSelectedAgentProfile: (state, action) => ({
      ...state,
      selectedAgentProfile: action.payload,
    }),
    setFormErrors: (state, action) => ({
      ...state,
      formErrors: {
        ...action.payload,
      },
    }),
    resetForm: (state) => ({
      ...state,
      formErrors: {},
    }),
    setSortBy: (state, action) => ({
      ...state,
      sortBy: action.payload,
    }),
    setFormProto: (state, action) => ({
      ...state,
      protoHash: action.payload,
    }),
  },
  extraReducers: {},
});

export const {
  setFormErrors,
  setSearchValues,
  replaceGeoWithCountry,
  setFormProto,
  expandAttributes,
  resetForm,
  setSortBy,
  setSelectedAgentProfile,
} = searchFormSlice.actions;

export default searchFormSlice.reducer;
