import { toString } from 'lodash-es';

import { OptionTypeBase } from 'react-select';

/**
 * Returns selected option object from options array.
 * Used in Select Component.
 *
 * @param options {array}
 * @param propValue {string|number}
 * @param propName {string}
 * @returns {object}
 */

const findSelectOption = (options: OptionTypeBase[] = [], propValue: string, propName: string = 'value') => (
  options.find((option) => toString(option[propName]) === toString(propValue))
);

export default findSelectOption;
