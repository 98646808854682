import React, {
  useState, useEffect, memo, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { includes, isEmpty, toString } from 'lodash-es';

import { IMultiRoomOffer, ISelectedRoom } from '@tourop/components/MultiRoom/types';

import { getPriceDifference } from '@ess/utils/offerData/priceDifference';

import { OfferAttributesTags } from '@tourop/components/OfferElements/OfferAttributes';

import { Button } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Price from '@ess/ui/Price';
import Tooltip from '@ess/ui/Tooltip';

import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

import { isEqualRooms } from '@tourop/components/MultiRoom/utils/isEqualRooms';

import { getOfferByPax } from '@tourop/components/MultiRoom/utils/getOfferByPax';

import { AppConfigContext } from '@ess/context/AppConfigContext';
import { OfferAttributesTagsFunc } from '@tourop/components/OfferElements/OfferAttributes/OfferAttributesTags';
import { Styled } from '../RoomsGrid.styles';
import ParticipantsModal from '../../ParticipantsModal';

type RoomProps = {
  participants: any
  editRoom: ISelectedRoom | undefined
  roomName: string
  data: IMultiRoomOffer
  swapRoom: any
  selected: any
  setRoom: any
}

const getRoomPrice = (offer: any, pax: any) => {
  const offerByPax = getOfferByPax(offer, pax);

  return {
    ...offer.Offer.Base?.Price?.FirstPerson,
    ...offerByPax ? {
      amount: toString(offerByPax.Price),
    } : {},
  };
};

const Room = ({
  data,
  participants,
  selected,
  setRoom,
  swapRoom,
  editRoom,
}: RoomProps) => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const { Offer } = data;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSwappable, setIsSwappable] = useState(false);
  const [priceDifference, setPriceDifference] = useState<string>('');
  const isSelected = selected.length > 0;
  const highlight = editRoom && isSwappable;
  const isEditedRoom = highlight && isEqualRooms(editRoom, data);
  const { state: AppConfig } = useContext(AppConfigContext);
  const { offerAttributesPriority } = AppConfig;
  const offerAttributesLimitedTagsList = OfferAttributesTagsFunc(t, Offer, offerAttributesPriority, true);

  const buttonLabel = !editRoom && isSelected
    ? 'lbl_selected'
    : editRoom
      ? isEqualRooms(editRoom, data)
        ? 'lbl_selected'
        : 'lbl_change'
      : 'lbl_choose';

  const onClickHandler = () => {
    setIsModalOpen(true);
  };

  const onSwapHandler = () => {
    swapRoom(editRoom, data);

    trackEvent({
      event: 'multiroom',
      eventCategory: 'B2B_CLICK_EVENT',
      eventAction: 'B2B_MULTIROOM_ROOM_CHANGE',
    });
  };

  const selectRoom = (offer: any, group: any) => {
    setIsModalOpen(false);

    setRoom(group.map((item: any, index: number) => ({
      ...data,
      RoomId: `${index}_${Offer.Base?.UniqueObjectId}`,
      Group: item,
    })));

    trackEvent({
      event: 'multiroom',
      eventCategory: 'B2B_CLICK_EVENT',
      eventAction: 'B2B_MULTIROOM_ROOM_SELECT',
    });
  };

  useEffect(() => {
    if (editRoom) {
      const { Min, Max } = data.Pax;
      const editRoomOffer = editRoom.Offer;
      const editedRoomPax = editRoom?.Group?.pax;
      const paxCount = editedRoomPax.Adult.value + editedRoomPax.Child.value;

      const { difference, currency } = getPriceDifference(
        getRoomPrice(data, editedRoomPax).amount as string,
        editRoomOffer.Base?.Price?.FirstPerson?.amount as string,
        editRoomOffer.Base?.Price?.FirstPerson?.currency,
        0,
      );

      const parsedDifference = !isEmpty(difference)
        ? `${!includes(difference, '-') ? '+' : ''}${difference} ${currency}`
        : '';

      setPriceDifference(parsedDifference);
      setIsSwappable(paxCount >= Min && paxCount <= Max);
    } else {
      setIsSwappable(false);
    }
  }, [editRoom]);

  return (
    <>
      <ParticipantsModal
        room={data}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onApply={selectRoom}
        participants={participants}
        selected={selected}
      />
      <Styled.RoomsGrid__Rooms__Item highlight={highlight}>
        <FlexBox alignItems="baseline">
          {Array.from({ length: data.Pax.Max }).map((_, index) => {
            const isLastChild = data.Pax.Max - 1 === index;

            return (
              <FlexBox key={index} {...{ ...!isLastChild ? { mr: '3px' } : {} }}>
                <FontAwesomeIcon icon={faUser} size="1x"/>
              </FlexBox>
            );
          })}
        </FlexBox>
      </Styled.RoomsGrid__Rooms__Item>
      <Styled.RoomsGrid__Rooms__Item highlight={highlight} justifyContent="flex-start">
        <FlexBox flexDirection="column">
          {Offer.Accommodation?.Service?.Name && (
          <FlexBox flexDirection="column">
            <Text fontWeight="bold">{`${t('lbl_maintenance')}:`}</Text>
            <Text>{Offer.Accommodation.Service.Name}</Text>
          </FlexBox>
          )}
          {offerAttributesLimitedTagsList.length !== 0 && (
          <FlexBox flexDirection="column">
            <OfferAttributesTags
              maxVisibleAttributes={1}
              offerAttributes={offerAttributesLimitedTagsList}
            />
          </FlexBox>
          )}
        </FlexBox>
      </Styled.RoomsGrid__Rooms__Item>
      <Styled.RoomsGrid__Rooms__Item highlight={highlight}>
        <FlexBox flexDirection="column">
          <FlexBox flexDirection="row" alignItems="center">
            <Price
              price={getRoomPrice(data, editRoom?.Group.pax)}
              showCurrency
              color="textColor"
            />
            <Tooltip content={t('price_info')}>
              <FontAwesomeIcon style={{ paddingLeft: '5px' }} icon={faInfoCircle} size="1x" />
            </Tooltip>
          </FlexBox>
          {(editRoom && !isEditedRoom && isSwappable && priceDifference) && (
          <Text
            mt="2px"
            fontWeight="bold"
            color={!includes(priceDifference, '-') ? 'red' : 'green'}
          >
            {priceDifference}
          </Text>
          )}
        </FlexBox>

      </Styled.RoomsGrid__Rooms__Item>
      <Styled.RoomsGrid__Rooms__Item highlight={highlight}>
        {isEditedRoom ? (
          <Text fontWeight="bold">{t('lbl_selected')}</Text>
        ) : (
          <Button
            size="small"
            label={t(buttonLabel)}
            disabled={editRoom && (isEqualRooms(editRoom, data) || !isSwappable)}
            variant={editRoom ? 'primary' : isSelected ? 'secondary' : 'primary'}
            onClick={editRoom ? onSwapHandler : onClickHandler}
          />
        )}

      </Styled.RoomsGrid__Rooms__Item>
    </>
  );
};

export default memo(Room);
