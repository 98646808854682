import styled, { css } from 'styled-components';
import { lighten, linearGradient } from 'polished';
import {
  space, layout, border, flexbox,
} from 'styled-system';

const PulseAnimation = css`
  background-size: 400% 400%;
  animation: pulse 2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%
    }
    100% {
      background-position: -135% 0%
    }
  }
`;

const Skeleton = styled.div<{bgColor: string | undefined}>`
  ${space}
  ${border}
  ${layout}
  ${flexbox}
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ bgColor }) => bgColor && linearGradient({
    colorStops: [`${bgColor} 0%`, `${lighten(0.060, bgColor)} 90%`, `${bgColor} 100%`],
    toDirection: 'to right',
    fallback: bgColor,
  })}
  ${PulseAnimation}
`;

export const Styled = {
  Skeleton,
};
