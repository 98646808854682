import React, {
  ChangeEvent, createRef, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  cloneDeep, find, findIndex, includes,
} from 'lodash-es';

import { colors } from '@tourop/config/theme/colors';

import {
  AGENT_ATTRIBUTES_SAVE_ATTRIBUTES_ACTION,
  AGENT_ATTRIBUTES_SAVE_DESC_ACTION,
  FIXED_HEADER_HEIGHT,
} from '@ess/constants/api';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { Sections } from '@ess/hooks/useOfferContentService';
import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';

import { Button } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Checkbox from '@ess/ui/Form/Checkbox';
import TextArea from '@ess/ui/Form/TextArea';
import Box from '@ess/ui/Box';
import Text from '@ess/ui/Text';
import useBreakpoint from '@ess/hooks/useBreakpoint';

const MAX_CHARS = 1500;

type TAttributes = {
  name: string,
  id: number,
  checked: boolean
}

type TDescription = {
  id: number,
  expedient: string,
  desc: string,
  visOthExp: boolean
}

type Props = {
  data: {
    ListHotel: {
      attr: Array<TAttributes>,
      desc: Array<TDescription>
    },
  }
  fetchAsync?: ({ sections, params }: { sections: string[], params: any }) => void
}

const defaultProps = {
  fetchAsync: undefined,
};

const AgentAttributes = ({ data, fetchAsync }: Props) => {
  const { ListHotel: { attr: attributes, desc: descriptions } } = data;
  const [changedAttributes, setChangedAttributes] = useState<Array<TAttributes>>(attributes);
  const [description, setDescription] = useState<string>('');
  const [editAttributes, setEditAttributes] = useState<boolean>(false);
  const [hasAttributes, setHasAttributes] = useState<boolean | undefined>(undefined);
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [hasOwnDescription, setHasOwnDescription] = useState<boolean | undefined>(undefined);
  const [hasOthersDescriptions, setHasOthersDescriptions] = useState<boolean | undefined>(undefined);
  const [charsCount, setCharsCount] = useState<number>(MAX_CHARS);
  const [isOtherExpVis, setIsOtherExpVis] = useState<boolean>(false);
  const { t } = useTranslation();
  const ref = createRef<HTMLDivElement>();
  const { state: { agency: { consultantCode: expedient } } } = useContext(AppConfigContext);
  const { trackEvent } = useGoogleAnalytics();
  const isMobile = includes(['xxs', 'xs', 'sm'], useBreakpoint());

  const sendToGoogleAnalytics = (eventActionString: string) => {
    trackEvent({
      event: 'agentAttributes',
      eventCategory: 'B2B_AGENTS_ATTRIBUTES',
      eventAction: eventActionString,
      eventLabel: '',
    });
  };

  useEffect(() => {
    if (editAttributes) {
      window.scrollTo({
        top: ref && ref.current ? (ref.current).offsetTop - (FIXED_HEADER_HEIGHT + 10) : 0,
        behavior: 'smooth',
      });
    }
  }, [editAttributes]);

  const attributeChangeHandler = (event: ChangeEvent) => {
    if (!changedAttributes) {
      return;
    }
    const { checked, name } = event.target as HTMLInputElement;
    const newChangedData = [...changedAttributes];
    const index = findIndex(newChangedData, (item) => item.id === Number(name));

    if (index > -1) {
      newChangedData[index].checked = checked;
      setChangedAttributes(newChangedData);
    }
  };

  const saveAttributesHandler = () => {
    if (!fetchAsync) {
      return;
    }
    sendToGoogleAnalytics('B2B_SAVE_ATTRIBUTES');

    const deleted: Array<number> = [];
    const added: Array<number> = [];
    attributes.map((value, index) => {
      if (value.checked && !changedAttributes![index].checked) {
        deleted.push(value.id);
      }
      if (!value.checked && changedAttributes![index].checked) {
        added.push(value.id);
      }
    });
    fetchAsync({
      sections: [Sections.AgentAttributes],
      params: {
        AgentAttributes: {
          Action: AGENT_ATTRIBUTES_SAVE_ATTRIBUTES_ACTION,
          AddId: added.join(','),
          DeleteId: deleted.join(','),
        },
      },
    });
    setEditAttributes(false);
  };

  const saveDescriptionHandler = () => {
    if (!fetchAsync) {
      return;
    }
    fetchAsync({
      sections: [Sections.AgentAttributes],
      params: {
        AgentAttributes: {
          Action: AGENT_ATTRIBUTES_SAVE_DESC_ACTION,
          Desc: description,
          VisOthExp: isOtherExpVis,
          VisOtherAgents: false,
        },
      },
    });
    sendToGoogleAnalytics('B2B_SAVE_DESCRIPTION');
    setEditDescription(false);
  };

  const cancelEditAttributesHandler = () => {
    sendToGoogleAnalytics('B2B_CANCEL_EDIT_ATTRIBUTES');
    setChangedAttributes(cloneDeep(attributes));
    setEditAttributes(false);
  };

  const cancelEditDescriptionHandler = () => {
    sendToGoogleAnalytics('B2B_SAVE_DESCRIPTION');
    setEditDescription(false);
  };

  useEffect(() => {
    setHasAttributes(find(attributes, { checked: true }) !== undefined);
    if (descriptions && descriptions.length > 0) {
      const oDesc = find(descriptions, { expedient });

      if (!oDesc || descriptions.length > 1) {
        setHasOthersDescriptions(true);
      }

      if (oDesc) {
        setHasOwnDescription((oDesc.desc.length > 0) || false);
        setDescription(oDesc.desc || '');
        setIsOtherExpVis(oDesc.visOthExp);
        return;
      }
    }

    setHasOwnDescription(false);
    setDescription('');
    setIsOtherExpVis(false);
  }, [data]);

  const editAttributesHandler = () => {
    setChangedAttributes(cloneDeep(attributes));
    setEditAttributes(true);
  };

  const descriptionChangeHandler = (event: ChangeEvent) => {
    sendToGoogleAnalytics('B2B_EDIT_DESCRIPTION');
    setDescription((event.target as HTMLInputElement).value);
  };

  const otherExpVisibleDescriptionHandler = (event: ChangeEvent) => {
    const { checked } = event.target as HTMLInputElement;
    setIsOtherExpVis(checked);
  };

  useEffect(() => {
    setCharsCount(MAX_CHARS - description.length);
  }, [description]);
  return (
    <FlexBox flexDirection="column" width="100%" ref={ref}>
      <FlexBox p="small" backgroundColor={colors.lighterGray} fontSize="title">
        {hasAttributes === false ? t('no_attributes_selected') : t('selected_attributes')}
      </FlexBox>
      {(hasAttributes === true || editAttributes) && (
        <FlexBox flexDirection="column" width="100%" p="small">
          {changedAttributes.map((attribute) => (
            <>
              {(editAttributes || attribute.checked) && (
                <FlexBox>
                  {editAttributes && (
                    <FlexBox mr="10px">
                      <Checkbox
                        name={attribute.id}
                        label=""
                        checked={attribute.checked}
                        onChange={attributeChangeHandler}
                      />
                    </FlexBox>
                  )}
                  <Box as="span">{`#${attribute.name}`}</Box>
                </FlexBox>
              )}
            </>
          ))}
        </FlexBox>
      )}
      {editAttributes && (
        <FlexBox p="small" width="100%">
          <Button
            mr="small"
            variant="primary"
            label={t('lbl_cancel')}
            size="medium"
            onClick={cancelEditAttributesHandler}
          />
          <Button
            mr="small"
            variant="primary"
            label={t('save')}
            size="medium"
            onClick={() => { saveAttributesHandler(); }}
          />
        </FlexBox>
      )}
      <FlexBox p="small" width="100%">
        {!editAttributes && (
          <Button
            mr="small"
            variant="primary"
            label={t(hasAttributes ? 'lbl_edit_attributes' : 'lbl_add_attributes')}
            size="medium"
            onClick={editAttributesHandler}
          />
        )}
      </FlexBox>
      <FlexBox p="small" backgroundColor={colors.lighterGray} fontSize="title">
        {hasOwnDescription === false ? t('hotel_has_no_description') : t('hotel_description')}
      </FlexBox>
      <Box
        width="100%"
        p="small"
        style={{
          display: 'grid',
          gridRowGap: '15px',
          gridColumnGap: '20px',
          gridTemplateColumns: isMobile ? 'auto' : 'auto max-content',
        }}
      >
        {(hasOthersDescriptions) && (
          descriptions.map((description) => description.expedient !== expedient && (
            <>
              <Text textAlign="justify">
                {description.desc}
              </Text>
              <Text whiteSpace="nowrap" textAlign={isMobile ? 'right' : 'left'}>
                <Text as="span">{`${t('lbl_added_by')}: `}</Text>
                <Text as="span" fontWeight="bold">{description.expedient}</Text>
              </Text>
            </>
          ))
        )}

        {(hasOwnDescription === true && !editDescription) && (
          <>
            <Text textAlign="justify">
              {description}
            </Text>
            <Text whiteSpace="nowrap" textAlign={isMobile ? 'right' : 'left'}>
              <Text as="span">{`${t('lbl_added_by')}: `}</Text>
              <Text as="span" fontWeight="bold">{expedient}</Text>
            </Text>
          </>
        )}
      </Box>
      {editDescription && (
        <FlexBox p="small" flexDirection="column" style={{ gap: '10px' }}>
          <TextArea
            maxLength={MAX_CHARS}
            onChange={descriptionChangeHandler}
            value={description}
            style={{ resize: 'vertical' }}
          />
          <FlexBox width="100%" justifyContent="right">
            <Text>{`${t('characters_left')}:`}</Text>
            <Text>{charsCount}</Text>
          </FlexBox>
        </FlexBox>
      )}

      {editDescription && (
        <FlexBox px="small" width="100%" flexDirection="column" style={{ gap: '10px' }}>
          <FlexBox width="100%">
            <Checkbox
              name="visOthExp"
              label={t('other_expedients_can_see_description')}
              checked={isOtherExpVis}
              onChange={otherExpVisibleDescriptionHandler}
            />
          </FlexBox>
          <FlexBox width="100%">
            <Button
              mr="small"
              variant="primary"
              label={t('lbl_cancel')}
              size="medium"
              onClick={cancelEditDescriptionHandler}
            />
            <Button
              mr="small"
              variant="primary"
              label={t('save')}
              size="medium"
              onClick={saveDescriptionHandler}
            />
          </FlexBox>
        </FlexBox>
      )}
      <FlexBox p="small" width="100%">
        {!editDescription && (
          <Button
            mr="small"
            variant="primary"
            label={t(hasOwnDescription ? 'lbl_edit_description' : 'lbl_add_description')}
            size="medium"
            onClick={() => setEditDescription(true)}
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};
AgentAttributes.defaultProps = defaultProps;

export default AgentAttributes;
