import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { IOffer } from '@ess/types';

import groupByProps from '@ess/utils/groupByProps';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Chip from '@ess/ui/Chip';
import Tooltip from '@ess/ui/Tooltip';

import getPriceSource from '../utils/getPriceSource';

import CustomTooltip from './CustomTooltip';

import ImportStatistics from '../ImportStatistics';

type TableProps = {
  data: any
  offerData: IOffer
  selectedDot: string
};

const defaultTimeIntervals = {
  '00:00-05:59': [],
  '06:00-11:59': [],
  '12:00-17:59': [],
  '18:00-23:59': [],
};

const getGroupedData = (data: any) => groupByProps(data, ['Date', 'TimeInterval']);

const Table = ({
  data,
  offerData,
  selectedDot,
}: TableProps) => {
  const { t } = useTranslation();
  const groupedData = useMemo(() => getGroupedData(data), [data]);

  return (
    <FlexBox width="100%" p="medium" alignItems="flex-start">
      {offerData?.Base?.Operator && (
        <FlexBox width={400} pr="medium">
          <ImportStatistics operator={offerData?.Base?.Operator as string}/>
        </FlexBox>
      )}
      <FlexBox
        width="100%"
        display="grid"
        borderRadius={6}
        overflow="hidden"
        style={{
          gridTemplateColumns: 'minmax(100px, 150px) 1fr 1fr 1fr 1fr',
          borderTop: '1px solid #ddd',
          borderBottom: '1px solid #ddd',
          borderLeft: '1px solid #ddd',
        }}
      >
        <>
          <FlexBox
            p="small"
            backgroundColor="#f1f1f1"
            style={{
              borderRight: '1px solid #ddd',
            }}
          >
            <Text fontWeight="bold">
              {`${t('lbl_date')} / ${t('lbl_hours')}`}
            </Text>
          </FlexBox>
          {Object.keys(defaultTimeIntervals).map((item) => (
            <FlexBox
              p="small"
              backgroundColor="#f1f1f1"
              style={{
                borderRight: '1px solid #ddd',
              }}
            >
              <Text fontWeight="bold">
                {item}
              </Text>
            </FlexBox>
          ))}
          {Object.keys(groupedData)
            .sort((itemA: string, itemB: string) => {
              const dateA = moment(itemA, 'DD.MM.YYYY');
              const dateB = moment(itemB, 'DD.MM.YYYY');

              return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
            })
            .map((date: any, index: number) => {
              const items = { ...defaultTimeIntervals, ...groupedData[date] };
              const isSelected = selectedDot === date;
              const backgroundColor = isSelected ? '#d1efff' : index % 2 === 0 ? 'white' : '#f7f7f7';

              return (
                <>
                  <FlexBox
                    p="small"
                    alignItems="flex-start"
                    backgroundColor={backgroundColor}
                    style={{
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    <Text lineHeight="24px">
                      {date}
                    </Text>
                  </FlexBox>
                  {Object.keys(items).map((interval) => (
                    <FlexBox
                      p="small"
                      backgroundColor={backgroundColor}
                      style={{
                        borderRight: '1px solid #ddd',
                      }}
                    >
                      <FlexBox flexDirection="column" justifyContent="flex-start">
                        {items[interval].length > 0 ? items[interval].map((item: any, index: number) => {
                          const isLastChild = items[interval].length - 1 === index;
                          const variant = isSelected ? 'highlighted' : 'default';
                          const price = item.Price ? `${item.Price} ${item.Currency}` : t('offer_unavailable');

                          return (
                            <FlexBox key={`price_${index}`} mb={!isLastChild ? '7.5px' : null}>
                              <Tooltip
                                offset={[0, 5]}
                                interactive
                                contentPadding={0}
                                content={<CustomTooltip data={item}/>}
                              >
                                <Chip
                                  label={(
                                    <FlexBox alignItems="center">
                                      <Text mr="tiny" fontWeight="bold">{price}</Text>
                                      <Text mr="tiny">{`(${item.Time})`}</Text>
                                      <Text>{` • ${t(getPriceSource(item.Source))}`}</Text>
                                    </FlexBox>
                                  )}
                                  size="large"
                                  variant={variant}
                                />
                              </Tooltip>
                            </FlexBox>
                          );
                        }) : (
                          <Text lineHeight="24px">-</Text>
                        )}
                      </FlexBox>
                    </FlexBox>
                  ))}
                </>
              );
            })}
        </>

      </FlexBox>
    </FlexBox>
  );
};

export default Table;
