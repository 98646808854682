import React from 'react';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash-es';

import getPriceSource from '@tourop/components/ContentSections/Omnibus/utils/getPriceSource';

import Anchor from '@ess/ui/Anchor';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

type CustomTooltipProps = {
  data: any
}

const CustomTooltip = ({ data }: CustomTooltipProps) => {
  const { t } = useTranslation();
  return (
    <FlexBox
      backgroundColor="#153669"
      borderRadius={4}
      overflow="hidden"
      minWidth={100}
    >
      <FlexBox flexDirection="column">
        <FlexBox alignItems="center" width="100%" px="medium" py="small">
          <FlexBox width={80}>
            <Text color="white">
              {`${t('lbl_date')}:`}
            </Text>
          </FlexBox>
          <Text fontWeight="bold" color="white">
            {`${data.Date} ${data.Time}`}
          </Text>
        </FlexBox>

        <FlexBox backgroundColor="#0a254e" alignItems="center" px="medium" pt="small">
          <FlexBox width={80}>
            <Text color="white">
              {`${t('price')}:`}
            </Text>
          </FlexBox>
          <Text fontWeight="bold" color="white">
            {`${data.Price} ${data.Currency}`}
          </Text>

          {data.PriceDifference ? (
            <FlexBox
              ml="small"
              px="5px"
              py="2px"
              height="20px"
              alignItems="center"
              justifyContent="center"
              backgroundColor={data.PriceDifference > 0 ? 'errorRed' : 'green'}
              borderRadius={6}
            >
              <Text
                fontSize={11}
                letterSpacing={0.4}
                color="white"
              >
                {`${data.PriceDifference > 0 ? `+${data.PriceDifference}` : `${data.PriceDifference}`} ${data.Currency}`}
              </Text>
            </FlexBox>
          ) : null}
        </FlexBox>

        <FlexBox backgroundColor="#0a254e" alignItems="center" px="medium" py="small">
          <FlexBox width={80}>
            <Text color="white">
              {`${t('source')}:`}
            </Text>
          </FlexBox>
          <Text fontWeight="bold" color="white">
            {t(getPriceSource(data.Source))}
          </Text>
        </FlexBox>

        {data.Source === 'O' && Object.keys(data?.Details).map((element) => {
          const item = data.Details[element];

          return (
            <FlexBox backgroundColor="#0a254e" alignItems="center" px="medium" pb="small">
              <FlexBox width={80}>
                <Text color="white">
                  {`${t(element)}:`}
                </Text>
              </FlexBox>
              {includes(item, 'http:') ? (
                <Anchor onClick={() => window.open(item, '_blank')} color="blue">
                  {t('essmonitor')}
                </Anchor>
              ) : (
                <FlexBox maxWidth={200}>
                  <Text
                    whiteSpace="wrap"
                    fontWeight="bold"
                    color="white"
                    style={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {item}
                  </Text>
                </FlexBox>
              )}
            </FlexBox>
          );
        })}
      </FlexBox>
    </FlexBox>
  );
};

export default CustomTooltip;
