import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';

type TooltipContentProps = {
  data: any
}

const TooltipContent = ({ data }: TooltipContentProps) => {
  const { t } = useTranslation();
  const { items, sum } = data;

  return (
    <FlexBox flexDirection="column" width={300}>
      {items.map((item: any) => (
        <FlexBox height={30} alignItems="center" justifyContent="space-between">
          <FlexBox alignItems="center">
            <Text>{`${t(`lbl_${item.name}_desc`)}:`}</Text>
          </FlexBox>
          <Text>{`${item.amount} ${item.currency} / ${t('person_shortcut')}`}</Text>
        </FlexBox>
      ))}
      <FlexBox height={30} alignItems="center" justifyContent="space-between">
        <FlexBox width={50}>
          <Text fontWeight="bold">{`${t('lbl_sum')}:`}</Text>
        </FlexBox>
        <Text fontWeight="bold">{`${sum.amount} ${sum.currency} / ${t('person_shortcut')}`}</Text>
      </FlexBox>
    </FlexBox>
  );
};

export default TooltipContent;
