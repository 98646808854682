import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@ess/ui/Alert';

import BasketControls from './BasketControls';
import SelectedRooms from './SelectedRooms';

import { Styled } from './Summary.styles';

type SummaryProps = {
  selectedRooms: any
  participants: any
  changeRoom: any
  totalPrice: string
  roomsCount: number
  onStatusChange: ({ roomId, status }: { roomId: string, status: any }) => void
  removeRoomHandler: any
  setEditRoom: any
  editRoom: any
  errorMessage?: string
  stickyElementOffsetTop: number
}

const allParticipantsSettled = (remainParticipant: any) => Object.keys(remainParticipant).every((item) => remainParticipant[item] <= 0);

const isMoreParticipants = (remainParticipant: any) => Object.keys(remainParticipant).some((item) => remainParticipant[item] < 0);

const defaultProps = {
  errorMessage: undefined,
};

const Summary = ({
  changeRoom,
  selectedRooms,
  participants,
  totalPrice,
  roomsCount,
  removeRoomHandler,
  setEditRoom,
  editRoom,
  onStatusChange,
  errorMessage,
  stickyElementOffsetTop,
}: SummaryProps) => {
  const { t } = useTranslation();

  return (
    <Styled.Summary stickyElementOffsetTop={stickyElementOffsetTop + 10}>
      {participants.remain.Adult <= 0 && !allParticipantsSettled(participants.remain) && (
        <Alert severity="error" mb="medium">{t('children_left_error')}</Alert>
      )}

      {isMoreParticipants(participants.remain) && (
        <Alert severity="warning" mb="medium">{t('more_participants')}</Alert>
      )}

      {(!selectedRooms.length && roomsCount > 0) && (
        <Alert severity="error" mb="medium">{t('invalid_rooms_count')}</Alert>
      )}

      {errorMessage && (
        <Alert severity="error" mb="medium">{errorMessage}</Alert>
      )}

      <SelectedRooms
        data={selectedRooms}
        swapRoom={editRoom}
        setSwapRoom={setEditRoom}
        changeRoom={changeRoom}
        removeRoom={removeRoomHandler}
        totalPrice={totalPrice}
        participants={participants}
        onStatusChange={onStatusChange}
      />

      <BasketControls
        selectedRooms={selectedRooms}
        swapRoomMode={editRoom}
        allParticipantsSettled={allParticipantsSettled(participants.remain)}
      />
    </Styled.Summary>
  );
};

Summary.defaultProps = defaultProps;

export default Summary;
