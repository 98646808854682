import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle } from '@fortawesome/pro-solid-svg-icons/faPauseCircle';

import Tooltip from '@ess/ui/Tooltip';
import FlexBox from '@ess/ui/FlexBox';
import Loader from '@ess/ui/Loader';

type IconStatusProps = {
  statusData: StatusData
  isLoading: boolean
  isQueued:boolean
}

type StatusData = {
  color: string
  type: string
  icon: React.FunctionComponent | null
  message: string
  description: string
}

const IconStatus = ({
  statusData,
  isLoading,
  isQueued,
}: IconStatusProps) => (
  <Tooltip content={isLoading ? statusData.description : statusData.message}>
    <FlexBox
      height="20px"
      width="20px"
      alignItems="center"
      justifyContent="center"
    >
      {isQueued ? (
        <>
          {React.createElement(statusData.icon as any, { color: statusData.color })}
          <FontAwesomeIcon color="green" icon={faPauseCircle}/>
        </>
      ) : isLoading ? (
        <Loader type="dots" size="18px" color="navy"/>
      ) : React.createElement(statusData.icon as any, { color: statusData.color })}
    </FlexBox>
  </Tooltip>
);

export default IconStatus;
