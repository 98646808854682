import {
  faBusAlt, faCar, faPlaneDeparture, faTrain,
} from '@fortawesome/pro-solid-svg-icons';

export const TRANSPORT_FLIGHT: string = 'TRANSPORT_FLIGHT';
export const TRANSPORT_BUS: string = 'TRANSPORT_BUS';
export const TRANSPORT_TRAIN: string = 'TRANSPORT_TRAIN';
export const OWN_TRANSPORT: string = 'OWN_TRANSPORT';

interface IDictionary<T> {
  [Key: string]: T;
}

export const TRANSPORT_TYPES: IDictionary<string> = {
  Flight: TRANSPORT_FLIGHT,
  Bus: TRANSPORT_BUS,
  Train: TRANSPORT_TRAIN,
};

export const TRANSPORT_ICONS: object = {
  TRANSPORT_FLIGHT: faPlaneDeparture,
  TRANSPORT_BUS: faBusAlt,
  TRANSPORT_TRAIN: faTrain,
  OWN_TRANSPORT: faCar,
};

export const AIRLINE_TYPES: IDictionary<string> = {
  gds: 'CHARTER',
  lowcost: 'LOW',
};
