import { useContext } from 'react';
import { has } from 'lodash-es';

import { IDictionary } from '@ess/types';

import { AppConfigContext } from '@ess/context/AppConfigContext';

type UseAgencyAccessControlProps = {
  moduleName?: string
}

const defaultProps = {
  moduleName: '',
};

/**
 * Set to disabled until service is ready.
 */
const DISABLED = true;

/**
 * Indicates if Agency has access to paid module.
 * @param config
 * @param moduleName
 */
const hasAccess = (config: IDictionary<boolean>, moduleName: string): boolean => {
  const moduleExists = has(config, moduleName);

  if (moduleExists && !DISABLED) {
    return config[moduleName];
  }

  return true;
};

const useAgencyAccessControl = ({ moduleName }: UseAgencyAccessControlProps) => {
  const { state: AppConfig } = useContext(AppConfigContext);

  return !moduleName || hasAccess(AppConfig?.availableModules ?? {}, moduleName);
};

useAgencyAccessControl.defaultProps = defaultProps;

export default useAgencyAccessControl;
