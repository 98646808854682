import { IParticipantList } from '@ess/types';

import { ADULT_CODE, CHILD_CODE, SENIOR_CODE } from '@ess/constants/participants';

interface IRoom {
  [key: string]: {
    value: number
    dates?: string[]
  }
}

interface IMultiRoom {
  enabled: boolean
  mode: string
  roomsCount: number
}

interface IRooms {
  rooms: IRoom[]
  multiRoom: IMultiRoom
}

interface IParticipantsV5Schema {
  code: string
  birthdate?: string
  roomIndex: number
}

/**
 * If MultiRoom mode enabled, we send 2:0 pax as default.
 */
const MULTI_ROOM_PARTICIPANTS = [
  { code: ADULT_CODE, roomIndex: -1 },
  { code: ADULT_CODE, roomIndex: -1 },
];

/**
 * Return Base.ParticipantsList in V5 schema.
 * @param value
 * @param isSeniorMode
 */
const getParticipantsSearchSchema = (value: IRooms | undefined, isSeniorMode = false): IParticipantList => {
  const { rooms = [], multiRoom = undefined } = value || {};
  const adultParticipantCode = isSeniorMode ? SENIOR_CODE : ADULT_CODE;

  let participantsList: IParticipantsV5Schema[] = [];

  if (multiRoom?.enabled) {
    return MULTI_ROOM_PARTICIPANTS;
  }

  for (let i = 0; i < rooms.length; i++) {
    const room = rooms[i];
    const children = [...Array((room[CHILD_CODE]?.value ?? 0) > 0 ? room[CHILD_CODE].value : 0)].map((_, index) => {
      const birthdate = room[CHILD_CODE].dates?.length
        ? room[CHILD_CODE].dates?.[index]
        : '';

      return {
        code: CHILD_CODE,
        roomIndex: i,
        birthdate,
      };
    });

    participantsList = [
      ...participantsList,
      ...[...Array(room[ADULT_CODE].value)].map(() => ({
        code: adultParticipantCode,
        roomIndex: i,
      })),
      ...children,
    ];
  }

  return participantsList;
};

export default getParticipantsSearchSchema;
