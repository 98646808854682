import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { UrlParams } from '@liveroom/types';

import Text from '@ess/ui/Text';

import { Page } from '@liveroom/layout';
import { OffersContainer } from '@liveroom/containers';
import { useLiveRoomStore } from '@liveroom/hooks';

type IbeLinkProps = {
  params?: any
  isFavouritesEnabled?: boolean
}

const defaultProps = {
  params: undefined,
  isFavouritesEnabled: true,
};

const IbeLink = ({ params, isFavouritesEnabled }: IbeLinkProps) => {
  const routeParams = useParams<UrlParams>();
  const fetchConfig = useLiveRoomStore((state) => state.fetchConfig);
  const searchParams = params ?? routeParams;

  useEffect(() => {
    fetchConfig(searchParams.affiliate);
  }, []);

  return (
    <Page>
      <OffersContainer
        params={searchParams}
        isFavouritesEnabled={isFavouritesEnabled}
      />
    </Page>
  );
};

IbeLink.defaultProps = defaultProps;

export {
  IbeLink,
};
