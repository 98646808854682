const flattenObject = (obj: Record<string, any>, parentKey?:string) => {
  let result: Record<string, any> = {};

  Object.keys(obj).forEach((item) => {
    const value = obj[item];
    const key = parentKey ? `${parentKey}.${item}` : item;
    const isObject = typeof value === 'object';

    if (isObject) {
      result = isObject ? { ...result, ...flattenObject(value, key) } : value;
    } else {
      result[key] = value;
    }
  });

  return result;
};

export default flattenObject;
