import { Styled } from '@tourop/components/OfferList/Offer/Offer.styles';
import moment from 'moment/moment';
import { DATE_DISPLAY_FORMAT, DATE_DISPLAY_SHORT_FORMAT } from '@ess/constants/api';
import { isEmpty, isNumber } from 'lodash-es';
import React from 'react';
import { Breakpoints } from '@ess/hooks/useBreakpoint';
import { IOfferBase, IResponseListItem } from '@ess/types';
import { useTranslation } from 'react-i18next';

type DateWithDurationProps = {
  item: IResponseListItem
  onlineOffer?: any,
  commonProps: {
    onContentsChange?:(rowIndex:number)=>void
    sfProtoHash?: any
    priceType?: string
    type?: any
    view?: string
    isMobile?: boolean
    breakpoint?: keyof typeof Breakpoints
    widthHigherThan1200?: boolean
  } | undefined
}

const defaultProps = {
  onlineOffer: undefined,
};

const DateWithDuration = (
  {
    item,
    onlineOffer,
    commonProps,
  }:DateWithDurationProps,
) => {
  const { t } = useTranslation();

  const { Base, Accommodation } = onlineOffer || item;

  const offer = onlineOffer as any || item as any;

  const { StartDate, ReturnDate, Duration } = Base as IOfferBase;

  const hasAccommodation = !isEmpty(offer?.Accommodation);

  return (
    <>
      {commonProps?.breakpoint !== 'xxs' ? (
        <Styled.Offer__Date>
          {moment(StartDate).isValid()
            ? `${moment(StartDate).format(DATE_DISPLAY_SHORT_FORMAT)} - ${moment(ReturnDate).format(DATE_DISPLAY_FORMAT)}`
            : t('invalid_date')}
        </Styled.Offer__Date>
      ) : (
        <Styled.Offer__Date>
          {moment(StartDate).isValid()
            ? `${moment(StartDate).format(DATE_DISPLAY_FORMAT)}`
            : t('invalid_date')}
        </Styled.Offer__Date>
      )}
      {hasAccommodation && (
      <>
        {commonProps ? (
          <>
            {(commonProps?.widthHigherThan1200 && Duration && isNumber(Accommodation?.Nights)) ? (
              <Styled.Offer__Duration>
                {`(${Duration}d / ${Accommodation.Nights}n)`}
              </Styled.Offer__Duration>
            ) : ''}
          </>
        ) : (
          <Styled.Offer__Duration>
            {`(${Duration}d / ${Accommodation.Nights}n)`}
          </Styled.Offer__Duration>
        )}
      </>
      )}
    </>
  );
};

DateWithDuration.defaultProps = defaultProps;

export default DateWithDuration;
