import axios from 'axios';
import * as Sentry from '@sentry/react';

import { BASKET_SERVICE_URL } from '@liveroom/constants';

interface BasketServiceConfig {
  autoRefreshEnabled: false,
}

class BasketService {
  config: BasketServiceConfig;

  constructor(config: BasketServiceConfig) {
    this.config = config;
  }

  /**
   * Makes api call to retrieve offers.
   */
  async fetch(params: any) {
    let response = null;

    try {
      const request = await axios.get(`${BASKET_SERVICE_URL}/Public/Details/${params?.id}`);
      const { status = undefined, details = undefined } = request?.data ?? {};

      if (status === 'ERROR') {
        throw new Error(request?.data?.error);
      }

      response = request?.data;
    } catch (error) {
      Sentry.captureException(error);
    }

    return response;
  }
}

export {
  BasketService,
};
