import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { toNumber } from 'lodash-es';
import moment from 'moment';

import { DATE_DISPLAY_FORMAT } from '@ess/constants/api';

import { SwiperContainer, SwiperSlide } from '@ess/ui/Swiper';

import { NavigationArrow, NavigationArrowsContainer } from './NavigationArrow';
import { TooltipContainer } from './TooltipContainer';

import { Styled } from './PriceTimeLine.styles';

type PriceTimeLineProps = {
  data: any
  isExpandable?: boolean
  isExpanded?: boolean
  onToggle?: () => void
  height?: number
}

const getChartData = (data: any) => {
  const parsedPrices = data.map((item: any) => Math.round(toNumber(item?.Offer?.Base?.Price?.FirstPerson?.amount ?? 0)));
  const max = Math.round(Math.max(...parsedPrices));
  const selectedIndex = data.findIndex((item: any) => item.Selected) ?? 0;
  const prices = parsedPrices.map((item: number, index: number) => ({
    value: item,
    percentage: (item / max) * 100,
  }));

  return {
    max,
    prices,
    selectedIndex,
  };
};

const swiperSettings = {
  slidesPerView: 'auto',
  slidesPerGroup: 4,
  spaceBetween: 10,
};

const defaultProps = {
  isExpandable: false,
  isExpanded: true,
  onToggle: undefined,
  height: 300,
};

const PriceTimeLine = ({
  data,
  height,
  isExpandable,
  isExpanded,
  onToggle,
}: PriceTimeLineProps) => {
  const { t } = useTranslation();
  const [sliderState, setSliderState] = useState({
    progress: 0,
    index: 0,
    showArrows: false,
    isOpen: isExpanded,
  });
  const swiperRef = useRef<any>(null);
  const { Dates = [], Offers = [] } = data?.PriceChart ?? {};
  const { prices, selectedIndex } = getChartData(Offers);

  const togglePrices = () => {
    setSliderState((state) => ({
      ...state,
      isOpen: !state.isOpen,
    }));

    if (onToggle) {
      onToggle();
    }
  };

  const nextHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    swiperRef.current?.swiper.slideNext();
  };

  const previousHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    swiperRef.current.swiper.slidePrev();
  };

  const onActiveIndexChange = (swiper: any) => {
    setSliderState((state) => ({
      ...state,
      progress: swiper.progress,
      index: swiper?.activeIndex,
      showArrows: swiper?.allowSlideNext,
    }));
  };

  useEffect(() => {
    if (swiperRef.current) {
      setSliderState((state) => ({
        ...state,
        showArrows: swiperRef.current.swiper.allowSlideNext,
        progress: swiperRef.current.swiper.progress,
      }));
    }
  }, []);

  return (
    <Styled.Container>
      {isExpandable && (
        <Styled.Chart__ExpandButton onClick={togglePrices}>
          <span>{t(sliderState.isOpen ? 'lbl_hide_prices' : 'lbl_show_prices')}</span>
          <FontAwesomeIcon icon={sliderState.isOpen ? faChevronUp : faChevronDown}/>
        </Styled.Chart__ExpandButton>
      )}
      <Styled.Chart
        isExpandable={isExpandable}
        height={sliderState.isOpen ? height : 70}
      >
        <SwiperContainer
          ref={swiperRef}
          onActiveIndexChange={onActiveIndexChange}
          settings={{
            ...swiperSettings,
            initialSlide: selectedIndex,
          }}
        >
          {Dates?.map((date: any, index: number) => {
            const price = prices[index];
            const offer = Offers[index];

            return (
              <SwiperSlide key={`chart-bar-${index}`} style={{ width: '60px' }}>
                <TooltipContainer
                  offerData={offer?.Offer}
                  isSelected={offer?.Selected}
                >
                  <Styled.ChartBar selected={offer?.Selected}>
                    <Styled.ChartBar__Value>
                      <Styled.ChartBar__Value__Inner style={{ height: `${price.percentage}%` }}>
                        {price.value}
                      </Styled.ChartBar__Value__Inner>
                    </Styled.ChartBar__Value>
                    <Styled.ChartBar__Date>
                      {moment(date, DATE_DISPLAY_FORMAT).format('dd. DD,[\n] MMM. YYYY')}
                    </Styled.ChartBar__Date>
                  </Styled.ChartBar>
                </TooltipContainer>
              </SwiperSlide>
            );
          })}
        </SwiperContainer>
        {sliderState.showArrows && (
          <NavigationArrowsContainer>
            {sliderState.progress > 0 && <NavigationArrow direction="left" onClick={previousHandler}/>}
            {sliderState.progress < 1 && <NavigationArrow direction="right" onClick={nextHandler}/>}
          </NavigationArrowsContainer>
        )}
      </Styled.Chart>
    </Styled.Container>
  );
};

PriceTimeLine.defaultProps = defaultProps;

export {
  PriceTimeLine,
};
