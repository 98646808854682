import React from 'react';
import { useTranslation } from 'react-i18next';

import Image from '@ess/ui/Image';
import Text from '@ess/ui/Text';
import Anchor from '@ess/ui/Anchor';
import FlexBox from '@ess/ui/FlexBox';

type FullRatingProps = {
  data: any
  onClick: () => void
}

const FullRating = ({ data, onClick }: FullRatingProps) => {
  const { t } = useTranslation();
  const { image, rating, count } = data;

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <FlexBox alignItems="center" flexWrap="wrap">
      <Image
        src={image}
        alt={rating as unknown as string}
        height="25px"
        width="130px"
        objectFit="contain"
        onClick={onClickHandler}
      />
      <>
        <Text ml="tiny" mr="tiny" lineHeight="16px" fontSize="inherit" fontWeight="bold">{count}</Text>
        <Text mr="tiny" lineHeight="16px" fontSize="inherit">{`${t('reviews_count')} `}</Text>
      </>
      <Anchor fontSize="inherit" onClick={onClickHandler}>{t('informations')}</Anchor>
    </FlexBox>
  );
};

export default FullRating;
