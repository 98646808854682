import { find } from 'lodash-es';

import { IMultiRoomResponse } from '@tourop/components/MultiRoom/types';

/**
 * Returns maintenance from RoomList.
 */

const getRoomsMaintenance = (data: IMultiRoomResponse) => {
  const maintenance: any = [];

  if (data?.RoomList) {
    data.RoomList.map((room) => {
      room.Offers.map(({ Offer }) => {
        if (!find(maintenance, (service) => service.value === Offer.Accommodation?.XService?.Id)) {
          maintenance.push({
            label: Offer.Accommodation?.Service?.Name,
            value: Offer.Accommodation?.XService?.Id,
          });
        }
      });
    });
  }

  return maintenance;
};

export {
  getRoomsMaintenance,
};
