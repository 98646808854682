import React from 'react';

import NoAccessMessage from './NoAccessMessage';

import useAgencyAccessControl from '../hooks/useAgencyAccessControl';

type AgencyAccessControlProps = {
  children: React.ReactComponentElement<any>
  noAccessRender?: React.ReactComponentElement<any> | null
  moduleName?: string
  enabled?: boolean
}

const defaultProps = {
  noAccessRender: null,
  moduleName: undefined,
  enabled: true,
};

const AgencyAccessControl = ({
  children,
  noAccessRender,
  moduleName,
  enabled,
}: AgencyAccessControlProps) => {
  const name = moduleName || children?.type?.displayName;
  const isAllowed = useAgencyAccessControl({ moduleName: name });

  return !enabled || isAllowed ? children : noAccessRender || <NoAccessMessage/>;
};

AgencyAccessControl.defaultProps = defaultProps;

export default AgencyAccessControl;
