import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { IV5OnlineOffer } from '@ess/v5-data-provider/interfaces';

import { OfferAttributesTags } from '@tourop/components/OfferElements/OfferAttributes';
import { OfferAttributesTagsFunc } from '@tourop/components/OfferElements/OfferAttributes/OfferAttributesTags';
import { AppConfigContext } from '@ess/context/AppConfigContext';

import { Styled } from '../OfferAttributes.style';

type FeaturedOfferAttributesProps = {
  title?: string
  offerData: IV5OnlineOffer
}

const defaultProps = {
  title: undefined,
};

const FeaturedOfferAttributes = ({
  title,
  offerData,
}: FeaturedOfferAttributesProps) => {
  const { t } = useTranslation();
  const { state: AppConfig } = useContext(AppConfigContext);
  const { offerAttributesPriority } = AppConfig;

  const offerAttributesTagsList = OfferAttributesTagsFunc(t, offerData, offerAttributesPriority, false);

  return offerAttributesTagsList.length > 0 ? (
    <>
      {title && (
      <Styled.Featured__Title>
        {title}
      </Styled.Featured__Title>
      )}
      <OfferAttributesTags offerAttributes={offerAttributesTagsList}/>
    </>
  ) : null;
};

FeaturedOfferAttributes.defaultProps = defaultProps;

export default FeaturedOfferAttributes;
