import React, { CSSProperties, forwardRef } from 'react';
import {
  SpaceProps, LayoutProps, ColorProps, BorderProps, PositionProps,
} from 'styled-system';

import { Styled } from './Box.styles';

type StyledSystemProps = SpaceProps & LayoutProps & ColorProps & BorderProps & PositionProps;

type BoxProps = {
  children?: React.ReactNode
  style?: CSSProperties
  id?: string
  dangerouslySetInnerHTML?: {__html: string} | undefined
  as?: any
  clearStyles?: boolean
} & StyledSystemProps;

const defaultProps = {
  children: null,
  style: {},
  dangerouslySetInnerHTML: undefined,
  clearStyles: false,
  id: '',
  as: 'div',
};

const Box = forwardRef<HTMLDivElement, BoxProps>(({
  id,
  children,
  clearStyles,
  ...props
}, ref) => (
  <Styled.Box
    ref={ref}
    className={clearStyles ? 'clear-styles' : null}
    {...{ ...id ? { id } : {} }}
    {...props}
  >
    {children}
  </Styled.Box>
));

Box.defaultProps = defaultProps;

export default Box;
