import { IMultiRoomResponse, IRoom } from '@tourop/components/MultiRoom/types';

import { getUniqueObjectId } from '@ess/utils/offerData/getUniqueObjectId';

/**
 * Returns modified RoomList.
 * @param data
 */
const getRoomList = (data: IMultiRoomResponse): IRoom[] => {
  let rooms: any[] = [];

  if (data?.RoomList?.length) {
    rooms = data?.RoomList.map((item) => ({
      ...item,
      Offers: item.Offers.map((room) => ({
        ...room,
        Offer: {
          ...room.Offer,
          Base: {
            ...room.Offer.Base,
            UniqueObjectId: getUniqueObjectId(room.Offer.Base?.OfferId as string),
          },
        },
      })),
    }));
  }

  return rooms;
};

export {
  getRoomList,
};
