/* *
  v5dataprovider responsible for fetching and paging results
 */
import React, {
  createContext, ReactNode, useContext, useEffect, useRef, useState,
} from 'react';
import { isEmpty } from 'lodash-es';

import { SearchBaseContext } from '@ess/context/SearchBaseContext';

import { debugLogEnabled, V5dataprovider } from './index';

import {
  INetworkStatus,
  IParamOptions,
  IV5DataOfferList,
  IV5DataSet,
  IV5engine,
  IV5OnlineOffer,
  paramsSelector, priorityOrder,
  View,
} from './interfaces';

import { OfferOnline } from './offeronline';

// @ts-ignore
const v5context = createContext<V5dataprovider>(null);

type stateCallback = (state:any, initial:any)=>void

type V5ContextProviderProps = {
  APIServer: string,
  children: ReactNode,
  fieldList: any,
  valueSetter: (callback:stateCallback)=>void | undefined,
  getTokenUrl: string
}

export const V5ContextProvider = ({
  APIServer, children, fieldList, valueSetter, getTokenUrl,
}:V5ContextProviderProps) => {
  const { state: SBContext } = useContext(SearchBaseContext);
  const provider = useRef<V5dataprovider|null>(null);

  if (provider.current === null) {
    provider.current = new V5dataprovider(APIServer, getTokenUrl);
    provider.current.SetFieldList(fieldList);

    if (valueSetter !== undefined) {
      valueSetter((state, initial) => {
        if (state.searchParams !== undefined) {
          // @ts-ignore
          provider.current.SetSearchParams(state.searchParams);
        }
        if (state.filterParams !== undefined) {
          // @ts-ignore
          provider.current.SetFilterParams(state.filterParams);
        }
        if (state.regionsParams !== undefined) {
          // @ts-ignore
          provider.current.SetRegions(state.regionsParams);
        }
        // @ts-ignore
        provider.current.Commit();
      });
    }
  }

  useEffect(() => {
    if (debugLogEnabled) {
      console.log('create', APIServer);
    }
    provider.current?.ping();

    return () => {
      provider.current?.end();
      if (debugLogEnabled) {
        console.log('destroy', APIServer);
      }
    };
  }, [APIServer]);

  useEffect(() => {
    provider?.current?.setSearchbase(SBContext);
  }, [SBContext]);

  return (
    <v5context.Provider value={provider.current}>
      {children}
    </v5context.Provider>
  );
};

export const useV5Engine = (): IV5engine => useContext<V5dataprovider>(v5context);

const useV5Data2 = (view: View, autoDataFetch:boolean, paramOptions:IParamOptions, fieldList:string[] = []):
  IV5DataSet => {
  const t0 = performance.now();
  const c = useContext<V5dataprovider>(v5context);
  const [, setViewStateUpdater] = useState(0);
  const getterCache = useRef({});
  const MountedViewObject = c.GetMountableObject(view, setViewStateUpdater,
    autoDataFetch, paramOptions, fieldList, getterCache);

  useEffect(() => {
    c.mount(MountedViewObject);
    return () => { c.unmount(MountedViewObject); };
  }, []);

  const vo = MountedViewObject.vo;

  if (debugLogEnabled) {
    const t1 = performance.now();
    console.log(`View: ${view} get took ${t1 - t0} milliseconds.`);
  }

  return { ...MountedViewObject.currentStatus, items: vo.items };
};

export const useV5OffersList = (view: View, autoDataFetch:boolean, paramOptions: IParamOptions)
    : IV5DataSet => useV5Data2(view, autoDataFetch, paramOptions);

export const useV5AccommodationSupplementary = (offerDetailsId: string | undefined, autoDataFetch: boolean, paramOptions:IParamOptions)
  : IV5DataOfferList => useV5Data2(View.AccommodationSupplementary, autoDataFetch, { offerDetailsId, ...paramOptions });

export const useV5OfferDetails = (offerDetailsId: string | undefined, autoDataFetch:boolean, paramOptions:IParamOptions)
  : IV5DataOfferList => useV5Data2(View.OfferDetails, autoDataFetch, { offerDetailsId, ...paramOptions });

interface IOfferDetailsId {
  details: string | undefined | null
  supplementary: string | undefined | null
}

export const useV5OfferDetails2 = (offerDetailsId: string | undefined | null)
  : IV5DataOfferList => {
  const [offerId, setOfferId] = useState<IOfferDetailsId>({ details: null, supplementary: null });
  const accommodationSupplementary = useV5AccommodationSupplementary(offerId.supplementary !== null ? offerId.supplementary : '', true, {});
  const offerDetails = useV5OfferDetails(offerId.details !== null ? offerId.details : '', true, {});

  useEffect(() => {
    if (!accommodationSupplementary.isLoading) {
      const { offer = undefined } = accommodationSupplementary.items.length ? accommodationSupplementary.items[0] : {};
      const offerId = offer?.Base ? offer.Base.OfferId as string : offerDetailsId;
      if (offerId) {
        setOfferId({
          details: offerId,
          supplementary: '',
        });
      }
    }
  }, [accommodationSupplementary.isLoading]);

  useEffect(() => {
    if (offerDetailsId !== null) {
      setOfferId({
        details: offerDetailsId,
        supplementary: '',
      });
    }
  }, [offerDetailsId]);

  return {
    ...offerDetails,
    ...offerDetailsId === undefined ? {
      isLoading: false,
    } : {},
  };
};

export const useV5RegionsList = (view: View, autoDataFetch:boolean, paramOptions:IParamOptions)
    : IV5DataSet => useV5Data2(view, autoDataFetch, paramOptions);

export const useV5Filters = (view: View, autoFetch:boolean, paramOptions:IParamOptions, list:string[]):
    IV5DataSet => useV5Data2(view, autoFetch, paramOptions, list);

export const useV5OnlineOffer = (offer:any): IV5OnlineOffer => {
  const t0 = performance.now();
  const [, setOfferStatusUpdate] = useState(0);
  const onlineOffer = !offer || isEmpty(offer) || isEmpty(offer?.offer ?? {}) ? undefined : new OfferOnline(offer);

  useEffect(() => {
    if (onlineOffer) {
      onlineOffer.mount(setOfferStatusUpdate);
    }

    return () => {
      if (onlineOffer) {
        onlineOffer.unmount();
      }
    };
  }, [offer]);

  if (debugLogEnabled && onlineOffer) {
    const t1 = performance.now();
    console.log(`Offer: ${onlineOffer.Base.OfferId} get took ${t1 - t0} milliseconds.`);
  }

  return onlineOffer as IV5OnlineOffer;
};

export const useConditionsSelector = (
  section:string,
  ps: paramsSelector,
  def:any = undefined,
  uncommitted = false,
  deps:(any[]|undefined) = undefined,
) => {
  const v5 = useContext<V5dataprovider>(v5context);
  const setter = useState(0)[1];
  const selectorRef = useRef(null);

  const selector = v5.getParamsSelector(section, ps, setter, def, uncommitted, deps, selectorRef);
  useEffect(() => {
    v5.mountSelector(selector);
    return () => v5.unmountSelector(selector);
  }, [uncommitted, section]);
  return selector.value;
};

export const useNetworkStatus = ():INetworkStatus => {
  const v5 = useContext<V5dataprovider>(v5context);
  const { targets, status } = v5.getNetworkStatus();
  const [, updateNetworkStatus] = useState(0);
  useEffect(() => {
    targets.add(updateNetworkStatus);
    return () => { targets.delete(updateNetworkStatus); };
  }, []);
  return status;
};
