import styled, { css } from 'styled-components';

const RoomsGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 300px) 1fr;
  gap: 0px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;


  & > div:nth-child(4n - 1) {
    background-color: #f3f3f3;
  }

  ${({ theme }) => `${theme.mediaQueries.lg} {
    overflow-y: hidden;
    overflow-x: auto;
  }`}
`;

const StickyHeaderStyles = css`
  position: sticky;
  background: ${({ theme }) => theme.colors.white};

  ${({ theme }) => `${theme.mediaQueries.lg} {
    position: static;
  }`}
`;

const RoomsGrid__Header = styled.div<{ stickyElementOffsetTop: number }>`
  display: grid;
  grid-template-columns: minmax(auto, 150px) minmax(160px, 3fr) 120px 110px;
  top: ${({ stickyElementOffsetTop }) => `${stickyElementOffsetTop}px`};
  gap: 0px;
  ${StickyHeaderStyles}
`;

const RoomsGrid__Header__Item = styled.div<{ textAlign?: string, stickyElementOffsetTop: number }>`
  padding: ${({ theme }) => theme.space.medium};
  top: ${({ stickyElementOffsetTop }) => `${stickyElementOffsetTop}px`};
  font-weight: bold;
  align-items: center;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  border-bottom: 1px solid #ddd;

  &:not(:last-child) {
    border-right: 1px solid #ddd;
  }

  ${StickyHeaderStyles}
`;

const RoomsGrid__RoomType = styled.div<{ highlight?: boolean }>`
  padding: ${({ theme }) => theme.space.medium};
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;

  ${({ highlight }) => (highlight && `
    background-color: #ddf1ff !important;
    border-bottom: 1px solid #a6daff !important;
    border-right: 1px solid #a6daff !important;
  `)}
`;

const RoomsGrid__RoomType__Name = styled.div`
  font-size: 14px;
  margin-bottom: ${({ theme }) => theme.space.medium};
  font-weight: bold;
`;

const RoomsGrid__RoomType__Pax = styled.div`
  margin-bottom: ${({ theme }) => theme.space.small};
`;

const RoomsGrid__Rooms = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 150px) minmax(160px, 3fr) 120px 110px;

  &:nth-child(4n) {
    background-color: #f3f3f3;
  }
`;

const RoomsGrid__Rooms__Item = styled.div<{
  justifyContent?: string, alignItems?: string, highlight?: boolean, isSwipeMode?: boolean
}>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: center;
  min-height: 70px;
  padding: ${({ theme }) => theme.space.medium};
  border-bottom ${({ highlight }) => (highlight ? '1px solid #a6daff' : '1px solid #ddd')};
  border-right: ${({ highlight }) => (highlight ? '1px solid #a6daff' : '1px solid #ddd')};
  background-color: ${({ highlight }) => (highlight ? '#ddf1ff' : 'transparent')};

  &:nth-child(4n) {
    border-right: none;
  }
`;

export const Styled = {
  RoomsGrid,
  RoomsGrid__Header,
  RoomsGrid__Header__Item,
  RoomsGrid__RoomType,
  RoomsGrid__RoomType__Name,
  RoomsGrid__RoomType__Pax,
  RoomsGrid__Rooms,
  RoomsGrid__Rooms__Item,
};
