import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

import { OPERATOR_LOGO } from '@ess/constants/api';

import Tooltip from '@ess/ui/Tooltip';

import { Styled } from './OperatorLogo.styles';

export type OperatorLogoProps = {
  style?: React.CSSProperties
  isSelected?: boolean
  isClickable?: boolean
  isPrioritized?: boolean
  onClick?: (event: React.MouseEvent, code: string) => void
  tooltipEnabled?: boolean
  name: string
  code: string
}

const defaultProps = {
  style: {},
  isSelected: true,
  isClickable: false,
  isPrioritized: false,
  tooltipEnabled: true,
  showPriorityIcon: false,
  onClick: undefined,
  regionId: undefined,
};

const OperatorLogo = ({
  style,
  isSelected,
  isClickable,
  isPrioritized,
  tooltipEnabled,
  onClick,
  name,
  code,
}: OperatorLogoProps) => {
  const onClickHandler = (event: React.MouseEvent, code: string) => {
    if (onClick) {
      onClick(event, code);
    }
  };

  return (
    <Tooltip content={name || ''} disabled={!tooltipEnabled}>
      <Styled.OperatorLogo
        style={style}
        isClickable={isClickable}
        isPrioritized={!!isPrioritized}
        isSelected={isSelected}
        onClick={(event: React.MouseEvent) => onClickHandler(event, code)}
      >
        {isPrioritized && (
          <Styled.OperatorLogo__Priority>
            <FontAwesomeIcon icon={faStar}/>
          </Styled.OperatorLogo__Priority>
        )}
        <Styled.OperatorLogo__Img
          src={`${OPERATOR_LOGO}${code}.png`}
          alt={name}
        />
      </Styled.OperatorLogo>
    </Tooltip>
  );
};

OperatorLogo.defaultProps = defaultProps;

export { OperatorLogo };
