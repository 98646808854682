import React, {
  useCallback, useRef, useState,
} from 'react';

import { useFetchImages, ImagesSizes } from '@ess/hooks/useFetchImages';

import { SwiperContainer, SwiperSlide } from '@ess/ui/Swiper';
import Loader from '@ess/ui/Loader';

import { NavigationArrow } from './NavigationArrow';

import { Styled } from './ImageSlider.styles';

type ImageSliderProps = {
  image: string | undefined
  operator: string
  hotelCode: string
  imageSize?: ImagesSizes
  height?: number
  width?: number
  onClick?: (event?: React.MouseEvent) => void
  serviceUrl?: string
  lazyFetch?: boolean
  arrowStyle?: string
}

const defaultProps = {
  imageSize: ImagesSizes.Full,
  height: undefined,
  width: undefined,
  onClick: undefined,
  serviceUrl: undefined,
  lazyFetch: true,
  arrowStyle: 'regular',
};

const ImageSlider = ({
  image,
  height,
  width,
  operator,
  hotelCode,
  imageSize,
  lazyFetch,
  serviceUrl,
  onClick,
  arrowStyle,
}: ImageSliderProps) => {
  const swiperRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const {
    isLoading,
    isReady,
    data,
    fetch,
  } = useFetchImages({
    lazy: lazyFetch,
    serviceUrl,
    imageSize,
    hotelCode,
    operator,
  });

  /**
   * Next image handler.
   * @param event
   */
  const nextImageHandler = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();

    if (!data.length) {
      (async () => {
        await fetch();
        swiperRef.current.swiper.slideNext();
      })();
    } else {
      swiperRef.current.swiper.slideNext();
    }
  }, [data]);

  /**
   * Previous image handler.
   * @param event
   */
  const previousImageHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    swiperRef.current.swiper.slidePrev();
  };

  const fetchImagesIfNeeded = () => {
    if (!data.length) {
      fetch();
    }
  };

  const onActiveIndexChange = (swiper: any) => {
    setCurrentIndex(swiper?.activeIndex ?? 0);
  };

  /**
   * Slider click handler.
   * @param event
   */
  const onSliderClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Styled.ImageSlider
      height={height}
      width={width}
      onClick={onSliderClick}
    >
      <SwiperContainer
        ref={swiperRef}
        onDragEnter={fetchImagesIfNeeded}
        onActiveIndexChange={onActiveIndexChange}
        onClick={fetchImagesIfNeeded}
      >
        {isReady && data.length ? data?.map((image, index) => (
          <SwiperSlide key={`image-slide-${index}`} style={{ width: '100%' }}>
            <Styled.ImageSlider__Item
              alt={operator}
              loading="lazy"
              src={data[index]}
            />
          </SwiperSlide>
        )) : (
          <SwiperSlide>
            <Styled.ImageSlider__Item
              alt={operator}
              loading="lazy"
              src={image}
            />
          </SwiperSlide>
        )}
      </SwiperContainer>

      {isLoading && (
        <Styled.ImageSlider__Overlay>
          <Loader size="22px" type="dots" color="white"/>
        </Styled.ImageSlider__Overlay>
      )}

      {(!isReady || (isReady && data.length > 0)) && (
        <Styled.ImageSlider__Controls>
          {currentIndex > 0 && (
            <NavigationArrow arrowStyle={arrowStyle as string} direction="left" onClick={previousImageHandler}/>
          )}
          {(!isReady || currentIndex < data.length - 1) && (
            <NavigationArrow arrowStyle={arrowStyle as string} direction="right" onClick={nextImageHandler}/>
          )}
        </Styled.ImageSlider__Controls>
      )}
    </Styled.ImageSlider>
  );
};

ImageSlider.defaultProps = defaultProps;

export {
  ImageSlider,
};
