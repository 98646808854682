import { round, toNumber } from 'lodash-es';

import { ISelectedRoom } from '@tourop/components/MultiRoom/types';

const getTotalPrice = (rooms: ISelectedRoom[]) => {
  const details: any = [];
  let totalPrice = 0;
  let currency = '';

  if (rooms.length) {
    currency = rooms[0].Offer.Base?.Price?.Total?.currency as string;

    rooms.map((room: ISelectedRoom) => {
      const { Base, Accommodation } = room.Offer;
      const { Room } = Accommodation || {};
      const { Price } = Base || {};

      let roomTotalPax = 0;

      Object.keys(room.Group.pax).map((personType) => {
        // @ts-ignore
        roomTotalPax += room.Group.pax[personType].value;
      });

      totalPrice += Price?.Total?.amount
        ? toNumber(Price?.Total?.amount)
        : roomTotalPax * toNumber(Price?.FirstPerson?.amount);

      details.push({
        name: Room?.Name,
        price: Price?.Total?.amount,
        pax: room.Group.pax,
      });
    });
  }

  return {
    currency,
    amount: round(totalPrice, 2).toFixed(2),
    details,
  };
};

export default getTotalPrice;
