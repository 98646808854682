import FlexBox from '@ess/ui/FlexBox';
import styled from 'styled-components';
import Text from '@ess/ui/Text';

const OmnibusSectionHolder = styled(FlexBox)`
  flex-direction: column;
  padding-top: 10px;
`;

const OmnibusLabel = styled(Text)`
font-weight: bold;
`;

const PriceLabel = styled(FlexBox)`
padding: 2px 0 ;
`;

export const Styled = {
  OmnibusSectionHolder,
  OmnibusLabel,
  PriceLabel,
};
