import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import React from 'react';
import { UNGROUPED_VIEW } from '@ess/constants/search';
import { Breakpoints } from '@ess/hooks/useBreakpoint';
import { IOfferBase, IResponseListItem } from '@ess/types';
import { OfferAttributesTags } from '@tourop/components/OfferElements/OfferAttributes';
import { Styled } from '@tourop/components/OfferList/Offer/Offer.styles';
import Tooltip from '@ess/ui/Tooltip';
import MultiRoomPreview from '@tourop/components/OfferList/Offer/MultiRoomPreview';

type ServiceMRFInformationProps = {
  item: IResponseListItem
  onlineOffer: any,
  serviceElement: any
  isMultiRoomMode?: boolean
  offerAttributesLimitedTagsList?: any[]
  commonProps: {
    onContentsChange?:(rowIndex:number)=>void
    sfProtoHash?: any
    priceType?: string
    type?: any
    view?: string
    isMobile?: boolean
    breakpoint?: keyof typeof Breakpoints
    widthHigherThan1200?: boolean
  } | undefined
}

const ServiceMRFInformation = (
  {
    item,
    serviceElement,
    onlineOffer,
    commonProps,
    isMultiRoomMode,
    offerAttributesLimitedTagsList,
  }:ServiceMRFInformationProps,
) => {
  const { Base } = onlineOffer || item;

  const { XRegion } = Base as IOfferBase;

  const offerUngroupedView = !commonProps?.isMobile && commonProps?.view === UNGROUPED_VIEW;

  const offerAttributesElement = !commonProps?.isMobile && offerAttributesLimitedTagsList?.length !== 0 ? (
    <FlexBox ml={isMultiRoomMode ? null : 'auto'} my={isMultiRoomMode ? 'tiny' : null}>
      <OfferAttributesTags offerAttributes={offerAttributesLimitedTagsList as any[]} />
    </FlexBox>
  ) : null;

  return (
    <>
      {(!offerUngroupedView) ? (
        <>
          {isMultiRoomMode && offerAttributesElement}
          {serviceElement}
          {!isMultiRoomMode && offerAttributesElement}
        </>
      ) : (
        <FlexBox>
          <Text color="#99a1ab" fontSize="12px">{Base?.XCountry?.Name}</Text>
          {XRegion?.Name && (
            <Text fontSize="12px" color="#99a1ab" ml="tiny">
              {` / ${Base?.XRegion?.Name}`}
            </Text>
          )}
        </FlexBox>
      )}
    </>
  );
};
export default ServiceMRFInformation;
