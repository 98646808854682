import moment from 'moment';

import { DATE_REQUEST_FORMAT } from '@ess/constants/api';

/**
 * Indicates if date passed.
 * @param date
 */
const isDatePassed = (date: string): boolean => {
  const dateObject = moment(date, DATE_REQUEST_FORMAT);
  return dateObject.isValid() && dateObject.isBefore(moment(), 'day');

};

export {
  isDatePassed,
}
