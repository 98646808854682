import { Styled } from '@tourop/components/OfferList/Offer/Offer.styles';
import Tooltip from '@ess/ui/Tooltip';
import MultiRoomPreview from '@tourop/components/OfferList/Offer/MultiRoomPreview';
import React from 'react';

type ServiceElementProps = {
  onlineOffer: any
  item:any
  isMultiRoomMode?: boolean
  fetchRooms?: any
  rooms?: any
  updateMultiRoomData?:any
  isLoadingRooms?: any
}
const defaultProps = {
  fetchRooms: undefined,
  rooms: undefined,
  updateMultiRoomData: undefined,
  isLoadingRooms: undefined,
  isMultiRoomMode: false,
};

const ServiceElement = (
  {
    isMultiRoomMode,
    onlineOffer,
    item,
    fetchRooms,
    rooms,
    updateMultiRoomData,
    isLoadingRooms,
  }:ServiceElementProps,
) => {
  const { Base, Accommodation } = onlineOffer || item;

  const getServiceTooltipContent = () => (
    `${Accommodation?.Service?.Name}${Accommodation?.Service?.Id ? ` (${Accommodation?.Service?.Id})` : ''}
    ${Accommodation?.Room?.Name}${Accommodation?.Room?.Id ? ` (${Accommodation?.Room?.Id})` : ''}`
  );

  return Accommodation?.Service ? !isMultiRoomMode ? (
    <Styled.Offer__Service>
      <Tooltip content={`${getServiceTooltipContent()}`}>
        <Styled.Offer__Service__Desc>
          {`${(Accommodation?.Service?.Name ? `${Accommodation?.Service?.Name} /` : '')} ${Accommodation?.Room?.Name}`}
        </Styled.Offer__Service__Desc>
      </Tooltip>
    </Styled.Offer__Service>
  ) : (
    <MultiRoomPreview
      fetchRooms={fetchRooms}
      offerId={Base.OfferId as string}
      data={rooms}
      updateMultiRoomData={updateMultiRoomData}
      isLoading={isLoadingRooms}
    />
  ) : null;
};

ServiceElement.defaultProps = defaultProps;

export default ServiceElement;
