import React, { memo } from 'react';
import {
  SpaceProps,
} from 'styled-system';

import { Styled } from './Radio.styles';

type RadioProps = {
  name: string
  checked: boolean
  value: any
  label?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
} & SpaceProps;

const defaultProps = {
  label: undefined,
  onChange: undefined,
};

const Radio = ({
  name, checked, value, label, onChange, ...props
}: RadioProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Styled.Radio {...props}>
      <Styled.RadioInput type="radio" name={name} value={value} onChange={changeHandler} checked={checked}/>
      <Styled.RadioIcon width="32" height="32" viewBox="0 -3 30 30" aria-hidden="true" focusable="false">
        <Styled.RadioIconOutline
          cx="15"
          cy="12"
          r="13"
          fill="none"
          strokeWidth="3"
        />
        <Styled.RadioIconDot cx="15" cy="12" r="6" />
      </Styled.RadioIcon>
      {label && (
        <Styled.RadioLabel>
          {label}
        </Styled.RadioLabel>
      )}
    </Styled.Radio>
  );
};

Radio.defaultProps = defaultProps;

export default memo(Radio);
