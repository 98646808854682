import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { HotKeys } from 'react-hotkeys';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faFloppyDisk,
  faPencil,
  faTimes,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import {
  filter, has, includes, round, toNumber,
} from 'lodash-es';

import { IDictionary } from '@ess/types';

import { ADULT_CODE, CHILD_CODE, INFANT_CODE } from '@ess/constants/participants';
import { ONLINE_SERVICE_TIMEOUT, SEARCH_API } from '@ess/constants/api';

import { promiseRequest } from '@ess/v5-data-provider/request';

import showToast, { TOAST_SUCCESS } from '@ess/utils/form/ShowToast';

import useAgentSettings from '@ess/hooks/useAgentSettings';

import { IconButton } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import TextInput from '@ess/ui/Form/TextInput';
import Dropdown from '@ess/ui/Dropdown';
import Loader from '@ess/ui/Loader';
import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';

import { ISelectedRoom, PersonTypes } from '@tourop/components/MultiRoom/types';

import { OfferStatus } from '@tourop/components/OfferElements';

import useGoogleAnalytics from '@ess/hooks/useGoogleAnalytics';
import { OfferAttributesTags } from '@tourop/components/OfferElements/OfferAttributes';

import { OfferAttributesTagsFunc } from '@tourop/components/OfferElements/OfferAttributes/OfferAttributesTags';
import { AppConfigContext } from '@ess/context/AppConfigContext';

import { Styled } from '../../Summary/Summary.styles';
import ParticipantsModal from '../../ParticipantsModal';

type SelectedRoomsProps = {
  data: ISelectedRoom[]
  swapRoom: any
  setSwapRoom: any
  changeRoom: any
  removeRoom: any
  participants: any
  totalPrice: string
  onStatusChange: any
}

const keyMap = {
  EDIT_GROUP_NAME: 'enter',
  CANCEL_EDIT_GROUP_NAME: 'esc',
};

const participantsTranslations: IDictionary<string> = {
  Adult: ADULT_CODE,
  Child: CHILD_CODE,
  Infants: INFANT_CODE,
};

/**
 * Returns room price.
 * @param room
 */
const getRoomPrice = (room: ISelectedRoom) => {
  const { Offer } = room;
  const { Base } = Offer;
  const { Total, FirstPerson } = Base?.Price || {};

  if (Total) {
    return `${Total.amount} ${Total.currency}`;
  }

  let participantsCount = 0;

  Object.keys(room.Group.pax).map((personType) => {
    participantsCount += room.Group.pax[personType as PersonTypes].value;
  });

  return `${round(participantsCount * toNumber(FirstPerson?.amount), 2).toFixed(2)} ${FirstPerson?.currency}`;
};

const allParticipantsSettled = (participants: any) => Object.keys(participants).every((item) => participants[item] <= 0);

const SelectedRooms = ({
  data,
  swapRoom,
  setSwapRoom,
  changeRoom,
  removeRoom,
  totalPrice,
  participants,
  onStatusChange,
}: SelectedRoomsProps) => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const [agentSettings] = useAgentSettings();
  const [editGroupName, setEditGroupName] = useState<any>(null);
  const [modal, setModal] = useState<any>(null);
  const { state: AppConfig } = useContext(AppConfigContext);
  const { offerAttributesPriority } = AppConfig;

  const options = useMemo(() => [
    {
      label: `${t('change_room')} / ${t('maintenance').toLowerCase()}`,
      value: 'edit',
    },
    {
      label: t('change_pax'),
      value: 'changePax',
    },
    {
      label: t('change_group_name'),
      value: 'groupName',
    },
    {
      label: t('lbl_check_status'),
      value: 'checkAvail',
    },
    {
      label: t('lbl_remove'),
      value: 'remove',
    },
  ], []);

  /**
   * Room change handler.
   * @param offer
   * @param group
   */
  const onRoomChangeApplyHandler = (offer: any, group: any) => {
    setModal(null);

    changeRoom({
      ...modal.selected[0],
      Group: group[0],
    });
  };

  /**
   * Open modal.
   * @param room
   */
  const openModal = (room: any) => {
    setModal({
      room,
      selected: filter(data, (o) => includes(o.RoomId, room.RoomId)),
    });
  };

  /**
   * Change group name handler.
   * @param room
   */
  const changeGroupName = (room: any) => {
    if (editGroupName.value) {
      if (room.Group.description !== editGroupName.value) {
        changeRoom({
          ...room,
          Group: {
            ...room.Group,
            description: editGroupName.value,
          },
        }, false);

        showToast(TOAST_SUCCESS, t('group_name_changed'));
      }

      setEditGroupName(null);
    } else {
      setEditGroupName((state: any) => ({
        ...state,
        error: true,
      }));
    }
  };

  /**
   * Show group name input.
   * @param room
   */
  const showGroupNameInput = (room: any) => {
    setEditGroupName({ roomId: room.RoomId, value: room.Group.description });
  };

  /**
   * Dropdown option click handler.
   * @param item
   * @param room
   */
  const optionItemClick = (item: string, room: ISelectedRoom) => {
    const actionsMap: IDictionary<any> = {
      checkAvail: checkAvailRequest,
      edit: setSwapRoom,
      remove: removeRoom,
      changePax: openModal,
      groupName: showGroupNameInput,
    };

    if (has(actionsMap, item)) {
      actionsMap[item](room);
      trackEvent({
        event: 'multiroom',
        eventCategory: 'B2B_CLICK_EVENT',
        eventAction: 'B2B_MULTIROOM_ROOM_MENU',
        eventLabel: item,
      });
    }
  };

  /**
   * Check room status handler.
   * @param room
   */
  const checkAvailRequest = (room: ISelectedRoom) => {
    const requestData = {
      actions: ['checkstatus'],
      offerIds: [room.Offer?.Base?.OfferId],
      includeTFG: false,
    };

    onStatusChange({
      roomId: room.RoomId,
      status: null,
    });

    promiseRequest(`${SEARCH_API}/v5/data/travel/checkonline`, requestData, 3, undefined, ONLINE_SERVICE_TIMEOUT)
      .then((response) => {
        onStatusChange({
          roomId: room.RoomId,
          status: response.results[0].offer,
        });
      });
  };

  useEffect(() => {
    data.map((item) => {
      if (item.Offer.Base?.Availability === undefined) {
        checkAvailRequest(item);
      }
    });
  }, [data]);

  useEffect(() => {
    if (editGroupName?.roomId) {
      document.getElementById(`${editGroupName.roomId}`)?.focus();
    }
  }, [editGroupName?.roomId]);

  return (
    <>
      {modal && (
        <ParticipantsModal
          isOpen
          room={modal.room}
          onClose={() => setModal(null)}
          onApply={onRoomChangeApplyHandler}
          participants={participants.all}
          showRoomsCount={false}
          selected={modal.selected}
        />
      )}
      {data.length ? (
        <>
          <Styled.Summary__Rooms>
            <Text fontWeight="bold" mb="small">{`${t('selected_rooms')}:`}</Text>
            {data.map((room: any) => {
              const roomPrice = getRoomPrice(room);
              const pax = room.Group.pax as any;
              const isSwapMode = swapRoom && swapRoom.RoomId === room.RoomId;
              const isEditRoomDescription = editGroupName && editGroupName.roomId === room.RoomId;
              const offerAttributesLimitedTagsList = OfferAttributesTagsFunc(t, room.Offer, offerAttributesPriority, true);
              const handlers = {
                EDIT_GROUP_NAME: () => changeGroupName(room),
                CANCEL_EDIT_GROUP_NAME: () => setEditGroupName(null),
              };

              return (
                <Styled.Summary__Room
                  key={room.RoomId}
                  editRoomMode={!!swapRoom}
                  editDescription={isEditRoomDescription}
                  isEditedRoom={isSwapMode}
                >
                  <Styled.Summary__Room__Top>
                    {room.Group.description && (
                      <Styled.Summary__Room__Description>
                        {!isSwapMode ? (
                          <>
                            {!isEditRoomDescription ? (
                              <>
                                <Text fontWeight="bold">
                                  {room.Group.description}
                                </Text>
                                <Styled.Summary__Room__Description__Edit
                                  onClick={() => showGroupNameInput(room)}
                                >
                                  <Tooltip content={t('edit_action')}>
                                    <FontAwesomeIcon color="inherit" icon={faPencil}/>
                                  </Tooltip>
                                </Styled.Summary__Room__Description__Edit>
                              </>
                            ) : (
                              <FlexBox width="100%">
                                <HotKeys keyMap={keyMap} handlers={handlers} allowChanges style={{ width: '100%' }}>
                                  <TextInput
                                    name={room.RoomId}
                                    value={editGroupName.value}
                                    isClearable
                                    onClear={(event: any) => {
                                      setEditGroupName((state: any) => ({
                                        ...state,
                                        value: event.target.value,
                                        error: !event.target.value,
                                      }));
                                    }}
                                    onChange={(event) => setEditGroupName((state: any) => ({
                                      ...state,
                                      value: event.target.value,
                                      error: !event.target.value,
                                    }))}
                                    {...{ ...editGroupName?.error ? { error: t('provide_group_name') } : {} }}
                                    maxLength={40}
                                    style={{
                                      borderTopRightRadius: 0,
                                      borderBottomRightRadius: 0,
                                    }}
                                  />
                                </HotKeys>
                                <Tooltip content={t('save')}>
                                  <IconButton
                                    onClick={() => changeGroupName(room)}
                                    icon={<FontAwesomeIcon color="inherit" icon={faFloppyDisk}/>}
                                    style={{
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      borderTopRightRadius: 0,
                                      borderBottomRightRadius: 0,
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip content={t('lbl_cancel')}>
                                  <IconButton
                                    variant="secondary"
                                    onClick={() => setEditGroupName(null)}
                                    icon={<FontAwesomeIcon color="inherit" icon={faTimes}/>}
                                    style={{
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                    }}
                                  />
                                </Tooltip>
                              </FlexBox>
                            )}
                          </>
                        ) : (
                          <Text fontWeight="bold">
                            {room.Group.description}
                          </Text>
                        )}
                      </Styled.Summary__Room__Description>
                    )}
                    <Text>{room.Offer.Accommodation.Room.Name}</Text>
                    <Text fontSize="12px" color="darkGray">
                      {room.Offer.Accommodation.Service.Name}
                    </Text>
                    {offerAttributesLimitedTagsList.length !== 0 && (
                      <>
                        <OfferAttributesTags
                          maxVisibleAttributes={1}
                          offerAttributes={offerAttributesLimitedTagsList}
                        />
                      </>
                    )}
                    {!isEditRoomDescription && (
                      <FlexBox
                        position="absolute"
                        right={10}
                        top={15}
                        width="20px"
                        height="20px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {isSwapMode ? (
                          <Tooltip content={t('lbl_cancel')}>
                            <IconButton
                              onClick={() => setSwapRoom(null)}
                              icon={(<FontAwesomeIcon icon={faTimes}/>)}
                              variant="navy"
                              radius="rounded"
                              size="small"
                            />
                          </Tooltip>
                        ) : (
                          <Dropdown
                            onItemClick={(item) => optionItemClick(item, room)}
                            options={options}
                            hoverOpensDropdown={agentSettings.dropdownMouseover}
                            target={(
                              <IconButton
                                disabled={swapRoom}
                                icon={(<FontAwesomeIcon icon={faEllipsisV}/>)}
                                radius="rounded"
                                size="small"
                              />
                            )}
                          />
                        )}
                      </FlexBox>
                    )}
                  </Styled.Summary__Room__Top>
                  <Styled.Summary__Room__Bottom>
                    <FlexBox alignItems="baseline">
                      <FlexBox mr="tiny">
                        <FontAwesomeIcon icon={faUser} size="xs"/>
                      </FlexBox>
                      <FlexBox>
                        {Object.keys(pax).map((item, index) => {
                          const isLastChild = Object.keys(pax).length - 1 === index;
                          return (
                            <FlexBox key={item}>
                              <Text>{pax[item].value}</Text>
                              {!isLastChild && (<Text>:</Text>)}
                            </FlexBox>
                          );
                        })}
                      </FlexBox>
                    </FlexBox>
                    <FlexBox alignItems="center">
                      <Text fontWeight="bold">{roomPrice}</Text>
                      <FlexBox ml="small" width={20} height={20} alignItems="center" justifyContent="center">
                        {!room.Offer.Base?.Availability ? (
                          <Loader type="dots" size="18px" color="navy"/>
                        ) : (
                          <OfferStatus
                            status={room.Offer.Base?.Availability}
                            isLoading={false}
                            onClick={() => checkAvailRequest(room)}
                          />
                        )}
                      </FlexBox>
                    </FlexBox>
                  </Styled.Summary__Room__Bottom>
                </Styled.Summary__Room>
              );
            })}
          </Styled.Summary__Rooms>
          {!allParticipantsSettled(participants.remain) && (
            <FlexBox mt="medium" pb="small" flexDirection="column" style={{ borderBottom: '1px solid #c2c2c2' }}>
              <Text fontWeight="bold" mb="2px">{`${t('participants_remained')}:`}</Text>
              {Object.keys(participants.remain).map((personType) => {
                const value = participants.remain[personType];

                return (
                  <FlexBox key={personType} width="100%">
                    {value > 0 && (
                      <FlexBox height={28} width="100%" justifyContent="space-between" alignItems="center">
                        <Text>{`${t(`lbl_${participantsTranslations[personType].toLowerCase()}`)}:`}</Text>
                        <Text fontWeight="bold">{value}</Text>
                      </FlexBox>
                    )}
                  </FlexBox>
                );
              })}
            </FlexBox>
          )}
          {!allParticipantsSettled(participants.selected) && (
            <FlexBox mt="medium" pb="small" flexDirection="column" style={{ borderBottom: '1px solid #c2c2c2' }}>
              <Text fontWeight="bold" mb="2px">{`${t('lbl_chosen')}: ${data.length} x ${t('room')}`}</Text>
              {Object.keys(participants.selected).map((personType) => {
                const value = participants.selected[personType];

                return (
                  <FlexBox key={personType} width="100%">
                    {value > 0 && (
                      <FlexBox height={28} width="100%" justifyContent="space-between" alignItems="center">
                        <Text>{`${t(`lbl_${participantsTranslations[personType].toLowerCase()}`)}:`}</Text>
                        <Text fontWeight="bold">{value}</Text>
                      </FlexBox>
                    )}
                  </FlexBox>
                );
              })}
            </FlexBox>
          )}
          <FlexBox
            py="medium"
            width="100%"
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Text mr="tiny">{`${t('lbl_totalprice')}:`}</Text>
            <Text fontWeight="bold" fontSize="head">
              {totalPrice}
            </Text>
          </FlexBox>
        </>
      ) : null}
    </>
  );
};

export default SelectedRooms;
