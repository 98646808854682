import zlib from 'zlib';
import { util } from 'protobufjs/light';
import {
  findKey, has, mapKeys, replace,
} from 'lodash-es';
import * as Sentry from '@sentry/react';

import { IDictionary } from '@ess/types';

import * as Messages from './messages';

export enum ProtoHashTypes {
  Offer = 'Offer',
  Filters = 'Filters',
  SearchForm = 'SearchForm',
  Basket = 'Basket'
}

const transformKeys: IDictionary<string> = {
  'Content.PhotosRequired': 'Content.photosRequired',
  'Content.DescriptionRequired': 'Content.descriptionRequired',
  'Base.DatasetInfo.Private': 'Base.DatasetInfo.private',
};

const transformValues: IDictionary<string> = {
  unspecified: '',
};

class ProtoHash {
  messageType: ProtoHashTypes;

  compressionLevel: number;

  message: any;

  constructor(messageType: ProtoHashTypes, compressionLevel = 9) {
    this.messageType = messageType;
    this.message = Messages[messageType];
    this.compressionLevel = compressionLevel;
  }

  /**
   * Returns message with transformed keys & values.
   * @param data
   */
  transformMessage(data: any) {
    const parsedData: IDictionary<any> = {};

    Object.keys(data).map((key) => {
      const value = has(transformValues, data[key]) ? transformValues[data[key]] : data[key];
      const name = has(transformKeys, key) ? transformKeys[key] : key;

      parsedData[name] = value;
    });

    return parsedData;
  }

  /**
   * Returns payload with modified object keys (ex. Base.Operator => Base_Operator).
   * @param data
   * @param reverse
   */
  modifyPayloadKeys(data: any, reverse = false) {
    return mapKeys(data, (key, value) => {
      const parsedKey = findKey(transformKeys, (item) => item === value) || value;
      return !reverse ? replace(parsedKey, /\./g, '_') : replace(parsedKey, /_/g, '.');
    });
  }

  /**
   * Returns encoded message hash.
   * @param data
   */
  encode(data: any) {
    try {
      const payload = this.message.fromObject(this.modifyPayloadKeys(data));
      const messageBuffer = Buffer.from(this.message.encode(payload).finish());
      const compressed = zlib.deflateSync(messageBuffer, { level: this.compressionLevel });

      return util.base64.encode(compressed, 0, Buffer.byteLength(compressed));
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  }

  /**
   * Returns decoded message.
   * @param hash
   * @param withDefaults
   */
  decode(hash: string, withDefaults = true) {
    try {
      const buff = Buffer.from(hash, 'base64');
      const unCompressed = zlib.unzipSync(buff, { level: this.compressionLevel });
      const decodedMessage = this.message.decode(unCompressed);
      const message = this.message.toObject(decodedMessage, { enums: String, defaults: withDefaults });

      return this.transformMessage(this.modifyPayloadKeys(message, true));
    } catch (e) {
      console.log(e);
      return {};
    }
  }

  /**
   * Indicates if message is valid.
   * @param data
   */
  isValid(data: any) {
    return this.message.verify(data);
  }
}

export default ProtoHash;
