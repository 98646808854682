// eslint-disable no-return-assign, no-param-reassign

/**
 * Returns grouped array of objects by given object properties.
 * @param data
 * @param props
 */
const groupByProps = (data: any[], props: string[]) => {
  if (data.length && props.length) {
    return data.reduce((acc: any, item: any) => {
      props.reduce((group: any, key, i, { length }) => (
          group[item[key]] = group[item[key]] || (i + 1 === length ? [] : {})
        ), acc).push(item);

      return acc;
    }, {});
  }
  return  {};
}

export default groupByProps;
