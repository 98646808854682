import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';

import { FieldError } from '@ess/types';

import {
  BirthDatePicker,
  DateRangePicker,
  BirthDatePickerProps,
  DateRangePickerProps,
} from '@ess/ui/Form/DatePicker';
import TextInput, { TextInputProps } from '@ess/ui/Form/TextInput';
import Select, { Autosuggest, AutosuggestProps, SelectProps } from '@ess/ui/Form/Select';
import Box from '@ess/ui/Box';
import Tooltip from '@ess/ui/Tooltip';

import { Styled } from './Field.styles';

type FieldErrorContentArgs = {
  t: Function,
  error: string,
}

type FieldTypes = 'Text' | 'Autosuggest' | 'Select' | 'DateRangePicker' | 'BirthDatePicker';

type FieldTypesObject = {
  [key in FieldTypes]: React.ForwardRefExoticComponent<any>
};

type ErrorDisplay = 'tooltip' | 'inline';

type FieldWrapperProps = {
  children?: React.ReactNode
  name?: string
  label?: string
  button?: boolean | React.ReactElement
  error?: FieldError,
  errorDisplay?: ErrorDisplay,
}

const defaultProps = {
  name: '',
  label: '',
  error: '',
  errorDisplay: 'tooltip' as ErrorDisplay,
  placeholder: '',
  button: false,
  values: undefined,
  children: null,
  onChange: undefined,
  onBlur: undefined,
  isLoading: false,
  isDisabled: false,
  multiple: false,
};

const FieldErrorContent = ({ t, error }: FieldErrorContentArgs) => <Box maxWidth={180}>{t(error)}</Box>;

const FieldWrapper = forwardRef<HTMLDivElement, FieldWrapperProps>(({
  name,
  label = '',
  children,
  button,
  error,
  errorDisplay,
}, ref) => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState<FieldError>('');
  const errorTooltipMode = errorDisplay === 'tooltip';
  const offsetTop = label ? -10 : 10;

  useEffect(() => {
    setShowError(!isEmpty(error));

    if (error && error !== errorText) {
      setErrorText(error);
    }
  }, [error]);

  const fieldElement = (
    <Styled.Field ref={ref} label={label}>
      {label && (
        <Styled.Field__Label htmlFor={name}>
          {label}
        </Styled.Field__Label>
      )}
      <Styled.Field__Element>
        {children}
        {button && (button)}
      </Styled.Field__Element>
    </Styled.Field>
  );

  return (
    <>
      {errorTooltipMode ? (
        <Tooltip
          offset={[0, offsetTop]}
          visible={showError}
          content={(<FieldErrorContent t={t} error={errorText as string}/>)}
          zIndex={99999}
          theme="red"
        >
          {fieldElement}
        </Tooltip>
      ) : (
        fieldElement
      )}
    </>
  );
});

const Field: FieldTypesObject = {
  Text: forwardRef<HTMLDivElement, TextInputProps & FieldWrapperProps>(({
    name = '',
    label = '',
    error = '',
    children,
    errorDisplay,
    ...props
  }, ref) => (
    <FieldWrapper
      ref={ref}
      name={name}
      label={label}
      error={error}
      errorDisplay={errorDisplay}
      {...children as object}
    >
      <TextInput name={name} error={errorDisplay !== 'tooltip' ? error : ''} {...props}/>
    </FieldWrapper>
  )),
  Autosuggest: forwardRef<HTMLDivElement, AutosuggestProps & FieldWrapperProps>(({
    name = '',
    label = '',
    error = '',
    button,
    children,
    errorDisplay,
    ...props
  }, ref) => (
    <FieldWrapper
      ref={ref}
      name={name}
      label={label}
      error={error}
      errorDisplay={errorDisplay}
      button={button}
      {...children as object}
    >
      <Autosuggest name={name} {...props}/>
    </FieldWrapper>
  )),
  Select: forwardRef<HTMLDivElement, SelectProps & FieldWrapperProps>(({
    name = '',
    label = '',
    error = '',
    button,
    children,
    errorDisplay,
    ...props
  }, ref) => (
    <FieldWrapper
      ref={ref}
      name={name}
      label={label}
      error={error}
      errorDisplay={errorDisplay}
      button={button}
      {...children as object}
    >
      <Select name={name} button={button} label={label} {...props}/>
    </FieldWrapper>
  )),
  DateRangePicker: forwardRef<HTMLDivElement, DateRangePickerProps & FieldWrapperProps>(({
    name = '',
    label = '',
    error = '',
    children,
    errorDisplay,
    button,
    ...props
  }, ref) => (
    <FieldWrapper
      ref={ref}
      name={name}
      label={label}
      error={error}
      errorDisplay={errorDisplay}
      button={button}
      {...children as object}
    >
      <DateRangePicker {...props} />
    </FieldWrapper>
  )),
  BirthDatePicker: forwardRef<HTMLDivElement, BirthDatePickerProps & FieldWrapperProps>(({
    name = '',
    label = '',
    error = '',
    children,
    errorDisplay,
    button,
    ...props
  }, ref) => (
    <FieldWrapper
      ref={ref}
      name={name}
      label={label}
      error={error}
      errorDisplay={errorDisplay}
      button={button}
      {...children as object}
    >
      <BirthDatePicker error={errorDisplay !== 'tooltip' ? error : ''} {...props} />
    </FieldWrapper>
  )),
};

FieldWrapper.defaultProps = defaultProps;

Field.Text.defaultProps = defaultProps;
Field.Autosuggest.defaultProps = defaultProps;
Field.Select.defaultProps = defaultProps;
Field.DateRangePicker.defaultProps = defaultProps;
Field.BirthDatePicker.defaultProps = defaultProps;

export default Field;
