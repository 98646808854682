import { get, has, isEmpty } from 'lodash-es';

import { IDictionary } from '@ess/types';

import * as Sentry from '@sentry/react';

import {
  GROUPED_VIEW, UNGROUPED_VIEW, RESULTS_SORT_IN_COLUMN, EMapTypes,
} from '@ess/constants/search';

import { DETAILS_PAGE_SHRINK_VIEW } from '@ess/constants/detailsPage';
import { getEnumKeys, pascalCase } from '@ess/constants/api';
import { SECTIONS, TSections } from '@ess/constants/contentWindow';
import { BasketPosition } from "@basket/types";

interface IFilters {
  fields: IDictionary<any>
}

type SearchResultsSliceState = {
  view: string
  extendedViewDetails: false
  initialOrder: string
  regionsType: string | null
  showMap: boolean
  multiRoomMode: boolean
  results: {
    hoveredMarker: {
      region?: string
      hotel?: string
      country?: string
    },
  },
  filters: IFilters
  error: boolean
}

const {
  isReturnDateMode = false,
  isGroupedView = true,
  isFullPrice = false,
  showCovidInfo = false,
} = get(window, '__REACT_APP_BASE_CONFIG__.adminSettings', {});

export const initialAgentSettings = ((): any => {
  const item = window?.localStorage?.getItem('AgentSettings');
  const agentSettings = item ? JSON.parse(item) : {};

  if (isEmpty(agentSettings)) {
    const newAgentSettings: IDictionary<any> = {
      dateSetting: isReturnDateMode ? 'Custom.StartDateRange.returnDateTo' : 'Custom.StartDateRange.startDateTo',
      viewSetting: isGroupedView ? GROUPED_VIEW : UNGROUPED_VIEW,
      sortType: RESULTS_SORT_IN_COLUMN,
      priceType: isFullPrice ?? false,
      covidSetting: showCovidInfo ?? false,
      openOfferInNewWindow: 1,
      filtersExpanded: 1,
      mapSetting: EMapTypes.roadmap,
      mapShowed: 0,
      overwriteBasket: 0,
      basketPosition: BasketPosition.top,
      dropdownMouseover: false,
      detailsPageDescriptions: DETAILS_PAGE_SHRINK_VIEW,
      detailsPagePictures: DETAILS_PAGE_SHRINK_VIEW,
      detailsPageRegionalInfo: DETAILS_PAGE_SHRINK_VIEW,
      detailsPageTripadvisor: DETAILS_PAGE_SHRINK_VIEW,
      detailsPagePriceChart: DETAILS_PAGE_SHRINK_VIEW,
      detailsWeatherStatic: DETAILS_PAGE_SHRINK_VIEW,
      detailsAirportsNearby: DETAILS_PAGE_SHRINK_VIEW,
      detailsBeachesNearby: DETAILS_PAGE_SHRINK_VIEW,
      detailsVisaInfo: DETAILS_PAGE_SHRINK_VIEW,
      detailsHotelGPS: DETAILS_PAGE_SHRINK_VIEW,
      detailsAgentAttributes: DETAILS_PAGE_SHRINK_VIEW,
    };

    getEnumKeys(SECTIONS).map((section: TSections) => {
      newAgentSettings[`detailsPage${pascalCase(section)}`] = DETAILS_PAGE_SHRINK_VIEW;
    });

    window?.localStorage?.setItem('AgentSettings', JSON.stringify(newAgentSettings));
    return newAgentSettings;
  }
  const newAgentSettings: IDictionary<any> = {
    dateSetting: !has(agentSettings, 'dateSetting') ? 'Custom.StartDateRange.returnDateTo' : agentSettings.dateSetting,
    viewSetting: !has(agentSettings, 'viewSetting') ? GROUPED_VIEW : agentSettings.viewSetting,
    sortType: !has(agentSettings, 'sortType') ? RESULTS_SORT_IN_COLUMN : agentSettings.sortType,
    priceType: !has(agentSettings, 'priceType') ? false : agentSettings.priceType,
    covidSetting: showCovidInfo ? !has(agentSettings, 'covidSetting') ? false : agentSettings.covidSetting : false,
    prioritizedOperators: !has(agentSettings, 'prioritizedOperators') ? true : agentSettings.prioritizedOperators,
    openOfferInNewWindow: !has(agentSettings, 'openOfferInNewWindow') ? 1 : agentSettings.openOfferInNewWindow,
    filtersExpanded: !has(agentSettings, 'filtersExpanded') ? 1 : agentSettings.filtersExpanded,
    mapSetting: !has(agentSettings, 'mapSetting') ? EMapTypes.roadmap : agentSettings.mapSetting,
    mapShowed: !has(agentSettings, 'mapShowed') ? 0 : agentSettings.mapShowed,
    overwriteBasket: !has(agentSettings, 'overwriteBasket') ? 0 : agentSettings.overwriteBasket,
    basketPosition: !has(agentSettings, 'basketPosition') ? BasketPosition.right : agentSettings.basketPosition,
    basketOfferView: !has(agentSettings, 'basketOfferView') ? 'list' : agentSettings.basketOfferView,
    openBasketOnHover: !has(agentSettings, 'openBasketOnHover') ? 0 : agentSettings.openBasketOnHover,
    sortChildren: !has(agentSettings, 'sortChildren') ? 1 : agentSettings.sortChildren,
    dropdownMouseover: !has(agentSettings, 'dropdownMouseover') ? false : agentSettings.dropdownMouseover,
  };

  getEnumKeys(SECTIONS).map((section: TSections) => {
    const field = `detailsPage${pascalCase(section)}`;
    newAgentSettings[field] = !has(agentSettings, field) ? DETAILS_PAGE_SHRINK_VIEW : agentSettings[field];
  });

  try {
    window?.localStorage?.setItem('AgentSettings', JSON.stringify(newAgentSettings));
  } catch (error) {
    Sentry.captureException(error);
  }
  return newAgentSettings;
})();

const initialState = {
  view: GROUPED_VIEW,
  extendedViewDetails: false,
  initialOrder: 'Base.Price.FirstPerson',
  regionsType: '',
  showMap: false,
  multiRoomMode: false,
  results: {
    hoveredMarker: {},
  },
  filters: {
    fields: {},
  },
} as SearchResultsSliceState;

export default initialState;
