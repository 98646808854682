export const TIME_FORMAT = 'HH:mm';

export const TIME_INTERVALS = [
  {
    groupId: '00:00-05:59',
    start: '00:00',
    end: '05:59',
  },
  {
    groupId: '06:00-11:59',
    start: '06:00',
    end: '11:59',
  },
  {
    groupId: '12:00-17:59',
    start: '12:00',
    end: '17:59',
  },
  {
    groupId: '18:00-23:59',
    start: '18:00',
    end: '23:59',
  },
];
