type IDictionary<T> = {
    [Key in string | number]: T;
};

/**
 * Transforms object to URL params string.
 *
 * @param object {object}
 * @param separator {string}
 * @returns {string}
 */
const objectToURLParams = (object: IDictionary<any>, separator = '&'): string => (
  Object.keys(object).map((key) => `${key}=${encodeURIComponent(object[key])}`).join(separator)
);

export default objectToURLParams;
