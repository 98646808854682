import React, {
  CSSProperties, useCallback, useEffect, useState,
} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { capitalize } from 'lodash-es';
import moment from 'moment';

import FlexBox from '@ess/ui/FlexBox';
import ScrollBar from '@ess/ui/ScrollBar';
import FlexBoxHeight from '@ess/ui/FlexBoxHeight';
import Text from '@ess/ui/Text';

import PriceCell from './PriceCell';
import OperatorsColumn from './OperatorsColumn';

type MatrixProps = {
  data: any
}

const Matrix = ({ data }: MatrixProps) => {
  const { Operators, Matrix, Dates } = data;
  const [scrollBarWrapperElement, setScrollBarWrapperElement] = useState<HTMLDivElement | null>(null);
  const [scrollBarElement, setScrollBarElement] = useState<Scrollbars | null>(null);
  const getCellStyles = useCallback((isLastRow: boolean, isLastRowChild: boolean): CSSProperties => ({
    borderRight: !isLastRowChild ? '1px solid #ddd' : 'none',
    borderBottom: !isLastRow ? '1px solid #ddd' : 'none',
  }), []);

  /**
   * Wheel handler, also prevents window scroll.
   * @param event
   */
  const onMouseWheelHandler = (event: React.WheelEvent) => {
    if (scrollBarElement) {
      const {
        scrollHeight, scrollWidth, clientWidth, clientHeight, scrollTop,
      } = scrollBarElement.getValues();
      const blockWindowScroll = event.deltaY > 0 && (scrollHeight - clientHeight <= scrollTop + 1);

      if (scrollWidth > clientWidth && (blockWindowScroll || event.deltaY <= 0)) {
        event.preventDefault();
      }
      scrollBarElement.scrollLeft(scrollBarElement.getScrollLeft() + event.deltaY);
    }
  };

  useEffect(() => {
    scrollBarWrapperElement?.addEventListener('wheel', (event: any) => onMouseWheelHandler(event), { passive: false });
    return () => {
      scrollBarWrapperElement?.removeEventListener('wheel', (event: any) => onMouseWheelHandler(event));
    };
  }, [scrollBarWrapperElement]);

  useEffect(() => {
    if (scrollBarElement) {
      scrollBarElement.scrollLeft(0);
    }
  }, [data]);

  return (
    <FlexBox
      p="medium"
      width="100%"
      height="100%"
    >
      <FlexBox
        width="100%"
        height="100%"
        borderRadius={6}
        position="relative"
        flexDirection="column"
        backgroundColor="white"
        style={{
          boxShadow: 'rgb(0 0 0 / 10%) 0 1px 4px 0px',
        }}
      >
        <FlexBox
          id="operator-chart"
          flexShrink={0}
          width="100%"
        >
          <OperatorsColumn data={Operators} getCellStyles={getCellStyles} />
          <FlexBoxHeight>
            {(height) => (
              <ScrollBar
                ref={setScrollBarElement}
                height={height}
              >
                <FlexBox
                  ref={setScrollBarWrapperElement}
                  width="100%"
                  flexDirection="column"
                  onWheel={onMouseWheelHandler}
                >
                  <FlexBox>
                    {Dates.map((date: string, index: number) => {
                      const momentDate = moment(date, 'MM.YYYY', true);
                      const isLastChild = Dates.length - 1 === index;
                      const monthName = momentDate.isValid()
                        ? capitalize(momentDate.format('MMMM'))
                        : null;

                      return (
                        <FlexBox
                          key={date}
                          alignItems="center"
                          justifyContent="center"
                          flexGrow={1}
                          flexShrink={0}
                          height="50px"
                          flexBasis="120px"
                          style={getCellStyles(false, isLastChild)}
                        >
                          <Text as="span" textAlign="center" fontWeight="bold">{monthName || date}</Text>
                        </FlexBox>
                      );
                    })}
                  </FlexBox>
                  {Matrix.map((rowData: any, matrixIndex: number) => {
                    const isLastRow = Matrix.length - 1 === matrixIndex;
                    return (
                      <FlexBox>
                        {rowData.map((item: any, index: number) => {
                          const { Offer = undefined, Selected = false } = item || {};
                          const isLastChild = rowData.length - 1 === index;
                          return (
                            <PriceCell
                              key={`offer_${index}`}
                              offer={Offer}
                              isSelected={Selected}
                              style={getCellStyles(isLastRow, isLastChild) as CSSProperties}
                            />
                          );
                        })}
                      </FlexBox>
                    );
                  })}
                </FlexBox>
              </ScrollBar>
            )}
          </FlexBoxHeight>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default Matrix;
