import { forwardRef } from "react";
import AdvancedSelect, { AdvancedSelectProps } from "./AdvancedSelect/AdvancedSelect";
import SimpleSelect from "./SimpleSelect/SimpleSelect";

const Select = forwardRef<HTMLElement, AdvancedSelectProps>(({
  isMulti,
  ...props
}: AdvancedSelectProps & { isMulti?: boolean }, ref) => {
  return (isMulti && props.gridMenu
    ? <AdvancedSelect ref={ref} {...props} />
    : <SimpleSelect ref={ref} {...props} />);
});

export default Select;

