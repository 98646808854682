import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faParking, faUmbrella, faVideo, faImage,
} from '@fortawesome/pro-solid-svg-icons';

import { IDictionary } from '@ess/types';

import FlexBox from '@ess/ui/FlexBox';
import { faVimeo, faYoutube } from '@fortawesome/free-brands-svg-icons';

type OfferIconProps = {
  offerType: string
  height?: string
  width?: string
}

const offerIconTypes: IDictionary<any> = {
  parking: {
    icon: faParking,
    backgroundColor: '#23a2e2',
  },
  insurance: {
    icon: faUmbrella,
    backgroundColor: '#b8d115',
  },
  vimeo: {
    icon: faVimeo,
    backgroundColor: '#86c9ef',
  },
  youtube: {
    icon: faYoutube,
    backgroundColor: '#b2071d',
  },
  video: {
    icon: faVideo,
    backgroundColor: '#e74c3c',
  },
  image: {
    icon: faImage,
    backgroundColor: '#009688',
  },
};

const defaultProps = {
  height: '100%',
  width: '100%',
};

const OfferIcon = ({ offerType, height, width }: OfferIconProps) => {
  const type = offerIconTypes[offerType] ?? undefined;

  return type ? (
    <FlexBox
      height={height}
      width={width}
      alignItems="center"
      justifyContent="center"
      backgroundColor={type.backgroundColor}
      style={{
        fontSize: '42px',
      }}
    >
      <FontAwesomeIcon color="white" icon={type.icon} />
    </FlexBox>
  ) : null;
};

OfferIcon.defaultProps = defaultProps;

export {
  OfferIcon,
};
