import { initI18n } from '@tourop/config/i18n';
import { CONTENT_SERVICE_URL } from './constants';

const language = window.location.pathname.split('/')[1];
const url = `${CONTENT_SERVICE_URL}ReactTextsCache`;

initI18n({
  language,
  modules: ['common', 'ibe'],
  unstableConnectionSupport: false,
  serviceUrl: url,
  maxRetries: 3,
});
